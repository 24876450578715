
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { PhotoOmniModel } from '../../models/common/photoOmni.model';
import { KV } from '../../models/keyValue/kv.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { KvMemberActivityGlyph } from '../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { MemberViewMgmtModel } from '../../models/member/memberViewMgmtModel.model';
import { PagerMemberActivity } from '../../models/pagination/pagerMemberActivity.model';
import { PagerPhotoMgmt } from '../../models/pagination/pagerPhotoMgmt.model';
import { Preference } from '../../models/profile/preference.model';
import { ProfileInfo } from '../../models/profile/profileInfo.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { SignedInUserService } from '../commonServiceService/signedInUserService.service';
import { SitUserService } from '../commonServiceService/sitUserService.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { KvArrayService } from '../coreServiceService/kvArrayService.service';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { DictionaryIOService } from '../dictionaryServiceService/dictionaryIOService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { MemberService } from '../memberServiceService/memberService.service';
import { AbstractPagerVPhotoNavService } from '../pagerServiceService/abstractPagerVPhotoNavService.service';
import { GlyphiconMenuService } from '../GlyphiconService/glypgiconMenuService.service';
import { PagerPhotoMgmtService } from '../pagerServiceService/pagerPhotoMgmtService.service';
import { PagerServiceService } from '../pagerServiceService/pagerServiceService.service';
import { PagerSupportService } from '../pagerServiceService/pagerSupportService.service';
// import { PagerVKvPhotoNavService } from '../../app/pagerModule/pagerVPhotoNav/pagerVPhotoNavService.service';
import { PhotoBnService } from '../photoServiceService/photoBnService.service';
import { PhotoPrivacyService } from '../photoServiceService/photoPrivacyService.service';
import { PreferenceService } from '../profileServiceService/preferenceService.service';
import { ProfileContentService } from '../profileServiceService/profileContentService.service';
import { ProfileInfoService } from '../profileServiceService/profileInfoService.service';
import { ProfilePicsService } from '../profileServiceService/profilePicsService.service';
import { ProfileTileService } from '../profileTileServiceService/profileTileService.service';
import { BackgroundImageAnimationService } from '../rendererServiceService/backgroundImageAnimationService.service';
import { RendererServiceService } from '../rendererServiceService/rendererServiceService.service';
import { ArrayServiceStatic } from '../staticServices/arrayServiceStatic.service';
import { ArraySupportServiceStatic } from '../staticServices/arraySupportServiceStatic.service';

import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { JsRegExpServiceStatic } from '../staticServices/jsRegExpServiceStatic.service';
//import { LoginSuccess } from '../../../models/account/loginSuccess.model';
//import { SitUser } from '../../../models/account/sitUser.model';
//import { PhotoOmniModel } from '../../../models/common/photoOmni.model';
//import { KV } from '../../../models/keyValue/kv.model';
//import { KvAny } from '../../../models/keyValue/kvAny.model';
//import { KvMemberActivityGlyph } from '../../../models/keyValue/kvMemberActivityGlyph.model';
//import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';
//import { MemberViewMgmtModel } from '../../../models/member/memberViewMgmtModel.model';
//import { PagerMemberActivity } from '../../../models/pagination/pagerMemberActivity.model';
//import { PagerPhotoMgmt } from '../../../models/pagination/pagerPhotoMgmt.model';
//import { Preference } from '../../../models/profile/preference.model';
//import { ProfileInfo } from '../../../models/profile/profileInfo.model';
//import { ProfilePics } from '../../../models/profile/profilePics.model';
//import { ProfileTile } from '../../../models/profile/profileTile.model';
//import { CommonServiceService } from '../../commonServiceService/commonServiceService.service';
//import { SitUserService } from '../../commonServiceService/sitUserService.service';
//import { CoreServiceService } from '../../coreServiceService/coreServiceService.service';
//import { KvArrayService } from '../../coreServiceService/kvArrayService.service';
//import { GlyphiconMenuService } from '../../pagerServiceService/glypgiconMenuService.service';
//import { PagerPhotoMgmtService } from '../../pagerServiceService/pagerPhotoMgmtService.service';
//import { PagerServiceService } from '../../pagerServiceService/pagerServiceService.service';
//import { PagerSupportService } from '../../pagerServiceService/pagerSupportService.service';
//import { PagerVKvPhotoNavService } from '../../pagerServiceService/pagerVKvPhotoNavService.service';
//import { PhotoBnService } from '../../photoServiceService/photoBnService.service';
//import { PhotoPrivacyService } from '../../photoServiceService/photoPrivacyService.service';
//import { PhotoServiceService } from '../../photoServiceService/photoServiceService.service';
//import { PreferenceService } from '../../profileServiceService/preferenceService.service';
//import { ProfileContentService } from '../../profileServiceService/profileContentService.service';
//import { ProfileInfoService } from '../../profileServiceService/profileInfoService.service';
//import { ProfilePicsService } from '../../profileServiceService/profilePicsService.service';
//import { ProfileServiceService } from '../../profileServiceService/profileServiceService.service';
//import { ProfileTileService } from '../../profileTileServiceService/profileTileService.service';
//import { ProfileTileServiceService } from '../../profileTileServiceService/profileTileServiceService.service';
//import { BackgroundImageAnimationService } from '../../rendererServiceService/backgroundImageAnimationService.service';
//import { RendererServiceService } from '../../rendererServiceService/rendererServiceService.service';
//import { ArrayServiceStatic } from '../arrayServiceStatic.service';
//import { ArraySupportServiceStatic } from '../arraySupportServiceStatic.service';
//import { DictionaryIOServiceStatic } from '../dictionaryIOServiceStatic.service';
//import { DictionaryServiceStatic } from '../dictionaryServiceStatic.service';
//import { EmitterSubjectService } from '../emitterObserverStaticServices/emitterSubject.service';
//import { FrequentlyUsedFunctionsServiceStatic } from '../frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
//import { JsRegExpServiceStatic } from '../jsRegExpServiceStatic.service';
// =====================================================================
//  User Profile Rendering System:
// -------------------------------
//  This service is exclusively dedicated to rendering a user's profile;
//  a user's profile can be rendered from three data sources:
//    1) from ProfileTile model, 2) from Dictionary-data  3) from server-data;
//        ...
//    Dicrionary will contain data only after server-sent-data is processed
//    and saved into the dicrionaries.
//
//    But a registered user's limited set of profile-information is available 
//    in Members page via the respective MemberTile, and the MemberTile UI/UX's
//    data is held at ProfileTile model.
//
//    Therefore, this system tries to render the user's profile from the 
//    ProfileTile first if it is available.
//    (some user may have not completed their profile partially)
//     
//     This system uses:
//        PagerVerticalKvPhotoNav UI/UX,
//        PagerVerticalGlyphMenuForPhotoMgmt UI/UX, and
//        PagerVerticalGlyphMenuForViewMember UI/UX
//     in addition to displaying the user's profile information in accordions
//     and primary picture along with other pictures as background image(s) of the app.
//        
// =====================================================================

@Injectable({ providedIn: 'root' })

export class DisplayProfileService implements OnDestroy {
  // public pagerVKvPhotoNavService! : PagerVKvPhotoNavService;
  public currentKvPhoto : KvPhoto = new KvPhoto();
  public distUnit = '';
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  public height : any;
  public heightUnit : any;
  public isKvData = false;
  public isKvFile = false;
  public isKvNext = false;
  public isKvPrev = false;
  public isKvUrlData = false;
  public isMobilevar = false;
  public isMyProfile = false;
  public isPhotoMgmt = false;
  public isViewMember = false;
  public kv : KV;
  public kvGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];
  public kvGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  public kvMemActGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];
  public kvMemActGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  public kvPhotoArr : KvPhoto[] = [];
  public kvPhotoArrBackup : KvPhoto[] = [];
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public message = '';
  public memberViewMgmtModel : MemberViewMgmtModel = new MemberViewMgmtModel();
  public pageSize = 6;
  public pagerVKvPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt();
  public pagerVMemActGlyphForPhotoMgmt : PagerMemberActivity = new PagerMemberActivity();
  public pagerVMemActGlyphForViewMember : PagerMemberActivity = new PagerMemberActivity();
  public photoOmni : PhotoOmniModel = new PhotoOmniModel();
  public preference : Preference = new Preference();
  public preferenceKvAnyArr : KvAny[] = [];
  public profileInfo : ProfileInfo = new ProfileInfo();
  public profileInfoView : ProfileInfo = new ProfileInfo();
  public profilePics : ProfilePics = new ProfilePics();
  public profileTile : ProfileTile = new ProfileTile();
  public profileExecutionOption : string = '';
  public profileExecutionOptionArr : string[] = [ 'serverData', 'profileTile', 'dictionaryData' ];
  public rootScopeTs : Set<KV> = new Set<KV>();
  public signedInUser : SitUser = new SitUser();
  public signedInUserId : number = 0;
  public signedInUserPagerVKvPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt();
  public signedInUserKvPhotoArr : KvPhoto[] = [];
  public sitUser : SitUser = new SitUser();
  public sitUerId : number = 0;

  public width : any;
  public weightUnit : any;

  constructor (
    public abstractPagerVPhotoNavService : AbstractPagerVPhotoNavService,
    public dbDexieToDictionaryService : DbDexieToDictionaryService,
    public dictionaryService : DictionaryService,
    public dictionaryIOService : DictionaryIOService,
    public glyphiconMenuService : GlyphiconMenuService,
    public httpService : HttpService,
    public kvArrayService : KvArrayService,
    public memberService : MemberService,
    public pagerPhotoMgmtService : PagerPhotoMgmtService,
    public pagerSupportService : PagerSupportService,
    public pagerServiceService : PagerServiceService,
    public photoBnService : PhotoBnService,
    public photoPrivacyService : PhotoPrivacyService,
    public preferenceService : PreferenceService,
    public profileContentService : ProfileContentService,
    public profileInfoService : ProfileInfoService,
    public profilePicsService : ProfilePicsService,
    public profileTileService : ProfileTileService,
    public bias : BackgroundImageAnimationService,
    public sitUserService : SitUserService,
    public signedInUserService : SignedInUserService,
    public rendererServiceService : RendererServiceService,
  ) {
    // this.pagerVKvPhotoNavService = this.pagerServiceService.getPagerVKvPhotoNavService();
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ---------------------------------------------------------------
  //public createProfilePicsFromKvPhotoDictionary (sitUserId : number) : ProfilePics | any {
  //	// debugger;
  //	let profilePics = new ProfilePics();
  //	if (sitUserId > 0) {
  //		profilePics.sitUserId = sitUserId;
  //		if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(DictionaryServiceStatic.kvPhotoDictionary) && DictionaryServiceStatic.kvPhotoDictionary.size() > 0) {
  //			// debugger;
  //			profilePics = DictionaryServiceStatic.getProfilePicsFromKvPhotoDictionary(sitUserId); // KvPhotos are not filtered yet.
  //			// debugger;
  //			if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)
  //				&& !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.unFilteredKvPhotoArr)
  //				&& profilePics.unFilteredKvPhotoArr.length > 0) {
  //				// debugger;
  //				this.photoPrivacyService.filterKvPhotoArr(profilePics.unFilteredKvPhotoArr).then(result => {
  //					// debugger;
  //					if (result) {
  //						profilePics.filteredKvPhotoArr = result;
  //						// debugger;
  //						if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.filteredKvPhotoArr) && profilePics.filteredKvPhotoArr.length > 0) {
  //							// debugger;
  //							profilePics.filteredKvPhotoArr.forEach(e => {
  //								if (e) {
  //									profilePics.filteredKvPhotoDictionary.setValue(e.photoBnId, e);
  //								}
  //							})
  //						}
  //					}
  //				})
  //			}
  //		}
  //		return profilePics;
  //	}
  //	else return null;
  // }
  // ------------------------------------------------------------------------------
  // Note: this function will display all the VerticalMenues for ProfilePics model
  // ------------------------------------------------------------------------------
  //public displayPhotoMgmtView (pPics : ProfilePics) : Promise<KvMemberActivityGlyph | any> {
  //  let kvMemActGlyphArr : any = [];
  //  return new Promise<any>((resolve, reject) => {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)) {
  //      // Note: PagerVPhotoNavComponent via AppComponent will display all necessary view-parts:
  //      // ------------------------------------------------------------------------------------
  //      debugger;
  //      this.profilePics.unFilteredKvPhotoArr = pPics.unFilteredKvPhotoArr.sort((a, b) => {
  //        return a.photoId - b.photoId
  //      });

  //      EmitterSubjectService.setProfileImages(this.profilePics.unFilteredKvPhotoArr);

  //      EmitterSubjectService.emitProfileImages(this.profilePics.unFilteredKvPhotoArr);

  //      let currentItem = EmitterSubjectService.getCurrentKvPhoto();
  //      let index = -1;
  //      debugger;
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentItem)) {
  //        index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdOrPhotoIdInKvPhotoArr(this.profilePics.unFilteredKvPhotoArr, currentItem.photoBnId, currentItem.photoId);
  //        if (index <= -1) {
  //          {
  //            debugger;
  //            index = ArrayServiceStatic.getIndexOfItemByProfileIdInKvPhotoArr(this.profilePics.unFilteredKvPhotoArr, currentItem.profileId);
  //          }
  //        }

  //        if (index > -1) {
  //          this.glyphiconMenuService.displayGlyphiconMenus(currentItem.sitUserId).then(result => {
  //            debugger;
  //            kvMemActGlyphArr = result;
  //          });
  //        }
  //      }
  //      else {
  //        debugger;
  //        this.glyphiconMenuService.displayGlyphiconMenus(pPics.sitUserId).then(result => {
  //          debugger;
  //          kvMemActGlyphArr = result;
  //        });
  //      }
  //      resolve(kvMemActGlyphArr);
  //    }
  //    else reject('displayPhotoMgmtView() for profilePics failed @ PhotoMgmtService - line: 275');
  //  });
  //}
  // ------------------------------------------------------------------------------
  // Note:  this function will display all the VerticalMenues for ProfilePics model
  //        This method expects a ProfilePics model's data set in the Emitter,
  //        and that the model's data is complete, meaning both unFilteredKvPhotos
  //        and filteredKvPhotos are present in both arrays and dictionaries.
  //
  //        While selecting the executionOptions, we create a complete ProfilePics
  //        and set it in Emitter.
  // ------------------------------------------------------------------------------
  //	ProfilePics/UserPics methods: Begin
  // ------------------------------------------------------------------------------
  public displayProfilePicsFromEmitterOrDictionary (situserid : number) : Observable<any> {
    let date = new Date();
    let kvMemActGlyphArr : [];
    let pagerVKvPhotoNav : any;
    let pPics = EmitterSubjectService.getProfilePics();
    let sitUserId : number = situserid;

    return new Observable<any>(subscriber => {
      // debugger;
      
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) && pPics.sitUserId > 0 && pPics.sitUserId === situserid) {
        // debugger;
        this.displayProfilePicsView(pPics)
         .subscribe(data => {
           this.pagerVKvPhotoNav = data;
           subscriber.next(this.pagerVKvPhotoNav);
           subscriber.complete();
         });
      }
      else if (sitUserId > 0) {
        pPics = this.dictionaryIOService.getProfilePicsFromDictionary(sitUserId);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) && pPics.sitUserId > 0) {
          this.displayProfilePicsView(pPics)
            .subscribe(data => {
              this.pagerVKvPhotoNav = data;
              subscriber.next(this.pagerVKvPhotoNav);
              subscriber.complete();
            });
          // return this.displayUserPicsFromDictionary(sitUserId).then(data => {
          //  this.pagerVKvPhotoNav = data;
          // });
        }
      }      
    });
	}
  // ------------------------------------------------------------------------------
  // Note: this function will display all the VerticalMenues for ProfilePics model
	//				This method is unique and is used for Breadcrum-based-Navigation.
	//
	//				IMPORTANT!!!:
	//				-------------
	//				Breadcrum-based-navigation is different than RedirectionServiceStatic-based-navigation.
  // ------------------------------------------------------------------------------
  public displayProfilePicsView (profilePics : ProfilePics) : Observable<any> {
    // debugger;
    let date = new Date();
    let kvMemActGlyphArr : [];
    let pagerVKvPhotoNav : any;
    let pPics = profilePics;

    return new Observable((subscriber) => {    
		this.isViewMember = EmitterSubjectService.getIsViewMember();
		this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
		this.isMyProfile = EmitterSubjectService.getIsMyProfile();
      
			// debugger;
			if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) && pPics.sitUserId > 0) {
				// debugger;
				 
				let kvPhotoArr : KvPhoto[] = [];

				// ----------------------------------------------------------------------
				// Note:	During execution of this method via the Breadcrum Navigation,
				//				the booleans(isViewMember/isMyProfile/isPhotoMgmt) values are false /default-valued.
				//			
				//				Hence we determine these values using sitUser, loginSuccess and avatar-profileTile, and emit these values
				// ----------------------------------------------------------------------
				this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        this.sitUser = EmitterSubjectService.getSitUserModel();

        if (!(new ProfilePics()?.isKvPhotoDictionariesEmpty(pPics)) && pPics.unFilteredKvPhotoDictionary?.size > 0) {
          kvPhotoArr = JSON.parse(JSON.stringify(pPics.unFilteredKvPhotoDictionary.values())); // ArraySupportServiceStatic.deepCopyArray(pPics.filteredKvPhotoDictionary.values());
          pPics.unFilteredKvPhotoArr = kvPhotoArr;
          // debugger;
        }
        
        if ((!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0))
        {
					// -----------------------------
          if (this.isViewMember && this.loginSuccess.signedInUserId !== this.sitUser.sitUserId)
          {

            EmitterSubjectService.setIsViewMember(true);  // TODO: may need to delete
						// debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr) && pPics.unFilteredKvPhotoArr.length > 0) {
              kvPhotoArr = JSON.parse(JSON.stringify(pPics.unFilteredKvPhotoArr)); //ArraySupportServiceStatic.deepCopyArray(pPics.filteredKvPhotoArr);
              // debugger;
              //kvPhotoArr.map(e => {
              //  this.dictionaryService.updateDictionary(e, 'filteredKvPhotoDictionary', e.photoBnId > 0 ? e.photoBnId : e.photoId > 0 ? e.photoId : e.sitUserId);
              //})

              this.abstractPagerVPhotoNavService.executeKvPhotoNavTasks(this.profilePics.unFilteredKvPhotoArr[ 0 ], this.profilePics.unFilteredKvPhotoArr).subscribe(data => {
                this.pagerVKvPhotoNav = data;
              })


              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
                kvPhotoArr = kvPhotoArr.sort((a, b) => {
                  return a.photoBnId - b.photoBnId
                });

                // debugger;
                EmitterSubjectService.emitProfileImages(kvPhotoArr);
                EmitterSubjectService.emitProfileImages(kvPhotoArr);
                subscriber.next(kvPhotoArr);// TODO: Caution!!may break badly because it was next(paverVKvPhotoNav)
                subscriber.complete();
              }
              else {
                // debugger;
                console.log('timestamp : ' + new Date().getTime() + ': displayProfilePicsView.filteredKvPhotoArr was null or empty');
              }
            }
					}
					else /*if (this.loginSuccess.signedInUserId === this.sitUser.sitUserId)*/ {
						// debugger;
            // Since myProfile and unfilteredKvPhotos are for the signedInUser:
						// ----------------------------------------------------------------
						this.isPhotoMgmt = true;
            EmitterSubjectService.setIsPhotoMgmt(this.isPhotoMgmt); //  // TODO: may need to delete

						let profileTile = EmitterSubjectService.getAvatarProfileTile();

						if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileTile) && profileTile.sitUserId === this.sitUser.sitUserId) {
							this.isMyProfile = true;
							EmitterSubjectService.setIsMyProfile(this.isMyProfile);
						}
						// -----------------------------

            if (!(new ProfilePics()?.isKvPhotoDictionariesEmpty(pPics)) && pPics.unFilteredKvPhotoArr.length > 0) {
              kvPhotoArr = JSON.parse(JSON.stringify(pPics.unFilteredKvPhotoArr)); 
							// debugger;
						}
            else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr) && pPics.unFilteredKvPhotoArr.length > 0) {
              kvPhotoArr = JSON.parse(JSON.stringify(pPics.unFilteredKvPhotoArr)); 
							// debugger;
						}
						// debugger;
						if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
							kvPhotoArr = kvPhotoArr.sort((a, b) => {
								return a.photoBnId - b.photoBnId
							});

							// debugger;
              EmitterSubjectService.emitProfileImages(kvPhotoArr);

              // debugger;
              subscriber.next(kvPhotoArr);// TODO: Caution!!may break badly because it was next(paverVKvPhotoNav)
              subscriber.complete();
						}
						else {
							// debugger;
              console.log('timestamp : ' + new Date().getTime() + ': displayProfilePicsView.unFilteredKvPhotoArr was null or empty');
						}
					}
				}
			}
			else {
				 // debugger;
        console.log('timestamp : ' + new Date().getTime() + ': displayProfilePicsView() rejected promise');
      }
		})
  }
  // ---------------------------------------------------------------
   // public displayProfileTileDataAndImage (pTile: ProfileTile) : Promise<any> {
   //   return new Promise<any>((resolve, reject) => {
   //   // render vertical menues for profileTile:
   //   // ---------------------------------------
   //   if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
   //     this.pagerVKvPhotoNavService.executeProfileTileKvPhotoAndGlyphMenuTasks(this.profileTile).subscribe(result => {
   //       // debugger;
   //       if (result) {
   //         // debugger;
   //         this.pagerVKvPhotoNav = result;
   //         resolve(this.pagerVKvPhotoNav);
   //       }
   //       else {
   //         // debugger;
   //         reject('timestamp : ' + new Date().getTime() + ': displayProfileTileDataAndImage () for sitUserId==' + pTile.sitUserId + ' promise returned a null result');
   //       }
   //     });
   //   }
   //   }).catch(error => {
   //     // debugger;
   //     console.log('timestamp : ' + new Date().getTime() + ': displayUserPicsFromProfileTile() rejected the promise. error: ' + error);
   //   });
	 //}
	// ---------------------------------------------------------------
  public displayUserPicsFromProfileTile (situserId : number) : Observable<any> {
    let sitUserId = situserId;
    let date = new Date();

    return new Observable<any>((subscriber) => {      
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isViewMember = EmitterSubjectService.getIsViewMember();
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();

			// debugger;
			if (this.isViewMember) {
				// debugger;
				this.profileTile = EmitterSubjectService.getSelectedProfileTile();
			}
			else if (this.isPhotoMgmt || this.isMyProfile) {
				// debugger;
				this.profileTile = EmitterSubjectService.getAvatarProfileTile();
			}

			// render vertical menues for profileTile:
			// ---------------------------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile)) {
        this.profileTileService.executeProfileTileKvPhotoAndGlyphMenuTasks(this.profileTile).subscribe(result => {
          // debugger;
          if (result) {
            // debugger;
            this.pagerVKvPhotoNav = result;
            subscriber.next(this.pagerVKvPhotoNav);
            subscriber.complete();
          }
          else {
            // debugger;
            console.log('timestamp : ' + new Date().getTime() + ': displayProfileService.displayUserPicsFromProfileTile() for sitUserId==' + sitUserId + ' promise returned a null result');
          }
        })
      }
		})
	}
	// ---------------------------------------------------------------
	public displayUserPicsFromDictionary (situserId : number) : Observable<any> {
		// -------------------------------------------------
		// Begin: get user pics( ProfilePics & KvPhotos) from dictionary:
		// -------------------------------------------------
		let tsitUser : SitUser = new SitUser();
		let tpKvPhotoArr : KvPhoto[] = [];
		let sitUserId = situserId;
    let date = new Date();

    return new Observable<any>((subscriber) => {
			// debugger;
			if (sitUserId > 0) {
				// debugger;
				// get user's profilePics from Dictionary:
				// ---------------------------------------
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.dictionaryService.profilePicsDictionary)
          && this.dictionaryService.profilePicsDictionary.size > 0) {
          // debugger;
          this.profilePics = this.dictionaryIOService.getProfilePicsFromDictionary(sitUserId) as ProfilePics;
          // debugger;
          var modelPPics = new ProfilePics();
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics)
            && !(modelPPics?.isKvPhotoDictionariesEmpty(this.profilePics)
              || modelPPics?.isModelKvPhotoArraysEmpty(this.profilePics))) {
            this.displayProfilePicsView(this.profilePics).subscribe(result => {
              // debugger;
              subscriber.next(result);
              subscriber.complete();
            });
          }          
          else {
            // debugger;
            console.log('timestamp : ' + new Date().getTime() + ': displayUserPicsFromDictionary().profilePics for sitUserId==' + sitUserId + ' was null or empty-1');
          }
        }        
			}
			else {
				// debugger;
        console.log('timestamp : ' + new Date().getTime() + ': displayUserPicsFromDictionary().promise for sitUserId==' + sitUserId + ' was null or empty-3');
			}
		})
  }
  // ---------------------------------------------------------------
  //    Currently in use:
	// ---------------------------------------------------------------
  displaySignedInUserPicsFromServer () : Observable<any> {
    return new Observable<any>((subscriber) => {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      this.displayUserPicsFromServer(this.loginSuccess.signedInUserId).subscribe(data => { // data is of type pagerVKvPhotoNav
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
          this.signedInUserPagerVKvPhotoNav = JSON.parse(JSON.stringify(data));
          this.signedInUserKvPhotoArr = JSON.parse(JSON.stringify(data.kvPhotoArr));
          subscriber.next(data);
        }
      })
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.signedInUserPagerVKvPhotoNav)
      //  && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.signedInUserPagerVKvPhotoNav.kvPhotoArr)
      //  && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.signedInUserPagerVKvPhotoNav.kvPhotoArr.length > 0)) {
      //  {
      //    debugger;
      //    subscriber.next(this.signedInUserPagerVKvPhotoNav);
      //  }
      //}
      //else {
      //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      //  this.displayUserPicsFromServer(this.loginSuccess.signedInUserId).subscribe(data => {
      //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
      //      this.signedInUserPagerVKvPhotoNav = JSON.parse(JSON.stringify(data));
      //      this.signedInUserKvPhotoArr = JSON.parse(JSON.stringify(data.kvPhotoArr));
      //      subscriber.next(data);
      //    }
      //  })
      //}
    })
  }

  // ---------------------------------------------------------------
  //    Currently in use:
  //
  //    This method gets the profilePics of the user with sitUseId,
  //    and returns a pagerVKvPhotoNav
	// ---------------------------------------------------------------
  public displayUserPicsFromServer (situserId : number) : Observable<any> {
    let sitUserId = situserId;   
		// ---------------------------------
		// Begin: get user pics from server:
		// ---------------------------------
    EmitterSubjectService.emitShowSpinner('Getting user pics...');

    return new Observable<any>((subscriber) => {
		  let tpKvPhotoArr : KvPhoto[] = [];		  
			// debugger;
      if (sitUserId > 0) {
        // debugger;
        this.kvPhotoArr = [];
        this.photoBnService?.getAllPhotosOfUserBySitUserIdObservable(sitUserId)?.subscribe(result => {
          // debugger;

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result))
          {
            this.profilePics = result as ProfilePics; 
            this.profilePics.populateDictionaries();

            // getAllPhotosOfUserBySitUserIdObservable() method saves the dicitonary to indexedDb
            //this.dbDexieToDictionaryService
            //  .saveDictionaryToDbDexie(
            //    this.dictionaryService.profilePicsDictionary, 'profilePics', this.loginSuccess.signedInUserId);

            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.unFilteredKvPhotoDictionary)
              && this.profilePics.unFilteredKvPhotoDictionary.values().length > 0)
            {
              this.kvPhotoArr = this.profilePics.unFilteredKvPhotoDictionary.values();
              this.abstractPagerVPhotoNavService.executeKvPhotoNavTasks(this.kvPhotoArr[ 0 ], this.kvPhotoArr).subscribe(data1 => {
                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data1)) {
                  this.pagerVKvPhotoNav = data1;
                  subscriber.next(this.pagerVKvPhotoNav);
                  subscriber.complete();
                }
                else {
                  console.log('timestamp : ' + new Date().getTime() + ': DisplayProfileService->executeKvPhotoNavTasks() returned a null or undefined promise;');
                  subscriber.next(new PagerPhotoMgmt());
                  subscriber.complete();
                }
              })
            }
            else {
              console.log('timestamp : ' + new Date().getTime() + ': DisplayProfileService->profilePics.unFilteredKvPhotoArr was null or empty;');
              subscriber.next(new PagerPhotoMgmt());
              subscriber.complete();
            }
          }
          else {
            // debugger;
            console.log('timestamp : ' + new Date().getTime() + ': DisplayProfileService.getProfilePicsFromServer() returned a null or undefined promise;');
            subscriber.next(new PagerPhotoMgmt());
            subscriber.complete();
          }
        })
      }
      else {
        console.log('timestamp : ' + new Date().getTime() + ': DisplayProfileService->getAllPhotosOfUserBySitUserIdObservable() sitUserId === 0');
        subscriber.next(new PagerPhotoMgmt());
        subscriber.complete();
      }
	  })			
		// ---------------------------------
		// End: get user pics from server:
		// ---------------------------------
	}
	// ---------------------------------------------------------------
	//	ProfilePics/UserPics methods: End
	// ---------------------------------------------------------------
	// ---------------------------------------------------------------
	//	UserData methods: Begin
	// ---------------------------------------------------------------
  // ---------------------------------------------------------------
	//	checked on 20230518
  // ---------------------------------------------------------------
  public displayUserDataFromProfileTile (situserId : number) : Observable<any> {   
    let sitUserId = situserId;
    let tProfileTile = null;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    return new Observable<any>((subscriber) => {
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();
      this.isViewMember = EmitterSubjectService.getIsViewMember();
      // debugger;
      if (this.isViewMember) {
        // debugger;
        let selectedProfileTile = EmitterSubjectService.getSelectedProfileTile();
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(selectedProfileTile) && selectedProfileTile.sitUserId > 0) {
          // debugger;
          if (selectedProfileTile.sitUserId === sitUserId) {
            tProfileTile = selectedProfileTile;
          }
          else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0
            && selectedProfileTile.sitUserId === this.loginSuccess.signedInUserId) {
            tProfileTile = EmitterSubjectService.getAvatarProfileTile();
          }
        }
        else {
          // debugger;
          tProfileTile = EmitterSubjectService.getProfileTile();          
        }
      }
      else if (this.isPhotoMgmt || this.isMyProfile) {
        // debugger;
        tProfileTile = EmitterSubjectService.getAvatarProfileTile();
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tProfileTile) && tProfileTile.sitUserId > 0 && tProfileTile.sitUserId === sitUserId) {
        this.profileTile = tProfileTile;
      }
      else this.profileTile = null;

      // debugger;
      // prepare and display profileInfoView from profileTile:
      // -----------------------------------------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile)) {
        // debugger;
        if (this.profileTile.sitUserId > 0) {
          // debugger;
          this.memberViewMgmtModel = this.profileTileService.executeProfileTileTasks(this.profileTile) as MemberViewMgmtModel;
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel) && this.memberViewMgmtModel.sitUserId > 0) {
            // debugger;
            EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);
            subscriber.next(this.memberViewMgmtModel);
            subscriber.complete();
          }
          else {
            // debugger;
            console.log('timestamp : ' + new Date().getTime() + ': displayUserDataFromProfileTile->this.memberViewMgmtModel for sitUserId==' + this.profileTile.sitUserId + ' was null or undefined');
          }
        }
        else {
          // debugger;
          console.log('timestamp : ' + new Date().getTime() + ': displayUserDataFromProfileTile-> this.profileTile.sitUserId: ' + this.profileTile.sitUserId + '.');
        }
      }
      else {
        console.log('timestamp : ' + new Date().getTime() + ': displayUserDataFromProfileTile-> this.profileTile was null or undefined');
      }
    })
  }
  // ---------------------------------------------------------------
	//	checked!!!
  // ---------------------------------------------------------------
  public displayUserDataFromDictionary (situserId : number) : Observable<any> {
    let sitUserId = situserId;
    let date = new Date();

    return new Observable<any>((subscriber) => {    
			// debugger;
      if (sitUserId > 0) {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.dictionaryService.profileInfoDictionary) && this.dictionaryService.profileInfoDictionary.size > 0) {
          this.profileInfo = this.dictionaryService.profileInfoDictionary.get(sitUserId) as ProfileInfo;
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo)) {
            // debugger;
            this.profileTileService.executeAllNonProfileTileTasks(this.profileInfo).subscribe(result => {
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
								// debugger;
								this.memberViewMgmtModel = result;
								EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);
								// debugger;
                subscriber.next(this.memberViewMgmtModel);
                subscriber.complete();
							}
							else {
								// debugger;
                console.log('timestamp : ' + new Date().getTime() + ': displayUserDataFromDictionary()=>executeAllNonProfileTileTasks() for sitUserId==' + sitUserId + ' the promise returned a null result');
							}
						})
          }
        }
      }
			else {
				// debugger;
        console.log('timestamp : ' + new Date().getTime() + ': displayUserDataFromDictionary().getUserData() for sitUserId==  ' + sitUserId );
      }			
		})
  }
  // ---------------------------------------------------------------
  //	TODO: this needs to be checked!!!
  //  Not in use:
  // ------------------------------------------------------------------------------
  public displayUserDataFromServer (situserId : number) : Observable<any> | any{    
		// debugger;
    let date = new Date();
    let sitUserId = situserId;

    return new Observable<any>((subscriber) => {          
      this.profileInfo = new ProfileInfo();
			// debugger;
			if (sitUserId > 0) {
				// debugger;
        this.profileInfoService.getProfileInfo(sitUserId).subscribe((result : any) =>
        {
					// debugger;
					if (result) {
						// debugger;
            this.profileInfo = result;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo)) {
              // debugger;
              let profileContetnKvAnyArr = this.profileContentService.createProfileContentKvAnyArr(this.profileInfo);
              this.memberViewMgmtModel = EmitterSubjectService.getMemberViewMgmtModel();
              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel)) {
                this.memberViewMgmtModel = new MemberViewMgmtModel();
              }
              // debugger;
              this.memberViewMgmtModel.profileContentKvAnyArr = profileContetnKvAnyArr;
              EmitterSubjectService.setMemberViewMgmtModel(this.memberViewMgmtModel);                 
              //  debugger;
              subscriber.next(this.memberViewMgmtModel);
              subscriber.complete();              
            }
            else {
              // debugger;
              console.log('timestamp : ' + new Date().getTime() + ': displayUserPicsFromDictionary().getProfileInfo().memberViewMgmtModel was null of empty');
            }
					}
					else {
						// debugger;
            console.log('timestamp : ' + new Date().getTime() + ': displayUserPicsFromDictionary().getProfileInfo()=>returned result was null of empty');
					}
        })
			}
			else {
				// debugger;
        console.log('timestamp : ' + new Date().getTime() + ': displayUserDataFromServer().getUserData() for sitUserId==  ' + sitUserId);
      }			
		})
  }
  // ---------------------------------------------------------------
	//	UserData methods: End
	// ---------------------------------------------------------------


  // ---------------------------------------------------------------
  //  Note: 1) This method displays the PagerVKvPhotoNav - vertical menu.
  //        This is a stand-alone display system for this purpose.
  //
  //        2) There is another stand-alond PagerVKvPhotoNav - vertical menu
  //        and it's associated PagerViewMemberGlyphMenu display system located in
  //        PagerVKvPhotoNavService.
  //
  //        There should NOT be any other pathway to display these vertical menues,
  //        other than the Emitters's listeners, which in turn will use the (2).
	//
	//				3) This method is triggered via the Emitter and it's listener is located
	//				in the AppComponent. Since the Vertical menues are child-components of
	//				AppComponent, these vertical menues have to be triggered there to launch this method.
  //  ----------------------------------------------------------------
  public executeProfilePicsImagesTasks (kvphotoArr : KvPhoto[]) : Observable<any> {
    // debugger;
    let date = new Date();
    let index = -1;
    let currentKvPhoto : any;
    let kvpArr = kvphotoArr;
    return new Observable<any>((observer) => {
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0) {
        this.kvPhotoArr = [];
        // debugger;
        this.kvPhotoArr.forEach(e => {
          e.value = JsRegExpServiceStatic.setUrlForImageData(e.value);
        })

        this.kvPhotoArrBackup = ArraySupportServiceStatic.mergeArraysUniq(this.kvPhotoArrBackup, this.kvPhotoArr);
        EmitterSubjectService.setProfileImages(this.kvPhotoArrBackup);
        // debugger;
        // this.kvPhotoArr = ArraySupportServiceStatic.mergeArraysUniq(this.kvPhotoArr, kvpArr);
        // debugger;
      }

      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isViewMember = EmitterSubjectService.getIsViewMember();
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();
      this.pageSize = EmitterSubjectService.getPageSize();
      // -------------------------------------------------------------------------------------------
      // if the singedInUser is uploading/editing Photos of himself, turn the isViewMember off:
      // -------------------------------------------------------------------------------------------
      if (this.isPhotoMgmt || this.isMyProfile) {
        this.isViewMember = false;
        EmitterSubjectService.setIsViewMember(this.isViewMember);
      }
      // -------------------------------------------------------------------------------------------
      let tpKvPhotoArr = this.pagerSupportService.setIndexAndPageNumAndElemIdForKvPhotoArr(this.kvPhotoArr, 'pagerVKvPhotoNav-');
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpKvPhotoArr) && tpKvPhotoArr.length > 0) {
        this.kvPhotoArr = tpKvPhotoArr;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvPhotoArr) && this.kvPhotoArr.length > 0) {
        this.abstractPagerVPhotoNavService.setPagerVKvPhotoArr(this.kvPhotoArr);
        EmitterSubjectService.setProfileImages(this.kvPhotoArr);

        // now capture the current-item:
        // -----------------------------
        currentKvPhoto = EmitterSubjectService.getCurrentKvPhoto();
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvPhoto)) {
          index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdInKvPhotoArr(this.kvPhotoArr, currentKvPhoto);

          if (index > -1) { // if found
            // debugger;
            currentKvPhoto.copyIfExists(this.kvPhotoArr[ index ]);
          }
          else { // not found
            currentKvPhoto = new KvPhoto().copySourceToDestination(currentKvPhoto, this.kvPhotoArr[ 0 ]);
            // debugger;
          }

          // debugger;
          
        }
        // debugger;
        this.pagerPhotoMgmtService.getBasicPagerDynamicObservable(this.kvPhotoArr, currentKvPhoto.pageNum > 0 ? currentKvPhoto.pageNum : 1, this.pageSize).subscribe(data => {
          // debugger;
          this.pagerVKvPhotoNav = data as PagerPhotoMgmt;
          // debugger      

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
            // debugger;
            // currentKvPhoto = this.bias.setupKvPhotoIfPrimaryOrPrivate(currentKvPhoto);
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.pagedItems)
              && this.pagerVKvPhotoNav.pagedItems.length > 0) {
              this.currentKvPhoto = this.bias.displayBackgroundAndNavImageForKvPhoto(this.currentKvPhoto, this.pagerVKvPhotoNav);

              this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.pagerVKvPhotoNav.pagedItems, this.currentKvPhoto);
            }
            // this.pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.createAndDisplayViewMemberGlyphMenu(this.pagerVKvPhotoNav?.currentItem?.sitUserId);
            EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(this.pagerVKvPhotoNav?.currentItem?.sitUserId); 

            this.isMyProfile = EmitterSubjectService.getIsMyProfile();
            this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
            if (this.isPhotoMgmt || this.isMyProfile) {
              // debugger;
              EmitterSubjectService.emitDisplayMyPagerVKvPhotoNav(this.pagerVKvPhotoNav);
            }
            else {
              // debugger;
              EmitterSubjectService.emitDisplayPagerVKvPhotoNav(this.pagerVKvPhotoNav);
            }

            // debugger;
            observer.next(this.pagerVKvPhotoNav);
            // debugger;
          }
          else {
            // debugger;
            console.log(date.getTime() + ': in DisplayProfileService.executeProfileImagesTasks().pagerVKvPhotoNav was null or empty.')
          }
        });
      }
    })
  }

  //  ---------------------------------------------------------------------------------
  public executeProfileImagesTasks (kvphotoArr : KvPhoto[]) : Observable<any> {
    // debugger;
		return new Observable<any>((observer) => {
      // debugger;
      let date = new Date();
      let index = -1;
      let currentKvPhoto : any;
      let kvpArr = kvphotoArr;

			if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0) {
        this.kvPhotoArr = [];
         // debugger;
        this.kvPhotoArr.forEach(e => {
          e.value = JsRegExpServiceStatic.setUrlForImageData(e.value);
          e.value = JsRegExpServiceStatic.stripUrlForImageData(e.value);
        });

        let tpKvPhotoArr = this.pagerSupportService.setIndexAndPageNumAndElemIdForKvPhotoArr(this.kvPhotoArr, 'pagerVKvPhotoNav-');
        this.kvPhotoArr = ArraySupportServiceStatic.mergeArraysUniq(this.kvPhotoArr, tpKvPhotoArr);
        this.kvPhotoArrBackup = ArraySupportServiceStatic.mergeArraysUniq(this.kvPhotoArrBackup, this.kvPhotoArr );
        EmitterSubjectService.setProfileImages(this.kvPhotoArrBackup);
      }

      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isViewMember = EmitterSubjectService.getIsViewMember();
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();
			this.pageSize = EmitterSubjectService.getPageSize();
			// -------------------------------------------------------------------------------------------
			// if the singedInUser is uploading/editing Photos of himself, turn the isViewMember off:
			// -------------------------------------------------------------------------------------------
			if (this.isPhotoMgmt || this.isMyProfile) {
				this.isViewMember = false;
				EmitterSubjectService.setIsViewMember(this.isViewMember);
			}
			// -------------------------------------------------------------------------------------------      

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvPhotoArr) && this.kvPhotoArr.length > 0) {
        this.abstractPagerVPhotoNavService.setPagerVKvPhotoArr(this.kvPhotoArr);
        EmitterSubjectService.setProfileImages(this.kvPhotoArr);
        
        // now capture the current-item:
        // -----------------------------
        currentKvPhoto = EmitterSubjectService.getCurrentKvPhoto();
        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvPhoto) || currentKvPhoto.photoBnId === 0) {
          currentKvPhoto = kvphotoArr[ kvphotoArr.length - 1 ];
          // debugger;
        }
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvPhoto)) {
          index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdInKvPhotoArr(this.kvPhotoArr, currentKvPhoto);
          // debugger;

          if (index > -1) { // if found
            // debugger;
            currentKvPhoto.copyIfExists(this.kvPhotoArr[ index ]);
          }
          else { // not found
            currentKvPhoto = new KvPhoto().copySourceToDestination(currentKvPhoto, this.kvPhotoArr[ this.kvPhotoArr.length - 1 ]);
            // debugger;
          }

          // debugger;
          // replace with method in bias:
          currentKvPhoto = this.bias.displayBackgroundAndNavImageForKvPhoto(currentKvPhoto, this.pagerVKvPhotoNav);
          this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.kvPhotoArr, this.pagerVKvPhotoNav.currentItem);

          // debugger;
          this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
          this.isMyProfile = EmitterSubjectService.getIsMyProfile();

          // OR
          if (this.isViewMember) {
            // debugger;
            this.signedInUser = EmitterSubjectService.getSignedInUser();
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.signedInUser)) {
              // this.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.createAndDisplayViewMemberGlyphMenu(this.signedInUser.sitUserId);
              EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(this.signedInUser.sitUserId); 
            }
          }
          else
          if (this.isPhotoMgmt || this.isMyProfile) {
            // debugger;
            this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(currentKvPhoto); // will display photo primary/public/public glyphs
            }

        }

        // debugger;
        this.pagerPhotoMgmtService.getBasicPagerDynamicObservable(this.kvPhotoArr, this.kvPhotoArr.length - 1, this.pageSize).subscribe(data => {
          // debugger;
          this.pagerVKvPhotoNav = data as PagerPhotoMgmt;
          // debugger      

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
            // debugger;

            this.isMyProfile = EmitterSubjectService.getIsMyProfile();
            this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
            if (this.isPhotoMgmt || this.isMyProfile) {
              // debugger;
              EmitterSubjectService.emitDisplayMyPagerVKvPhotoNav(this.pagerVKvPhotoNav);
            }
            else {
              // debugger;
              EmitterSubjectService.emitDisplayPagerVKvPhotoNav(this.pagerVKvPhotoNav);
            }

            // debugger;
            observer.next(this.pagerVKvPhotoNav);
            // debugger;
          }
          else {
            // debugger;
            console.log('timestamp : ' + new Date().getTime() + ': in DisplayProfileService.executeProfileImagesTasks().pagerVKvPhotoNav was null or empty.')
          }
        });
      }  
		})
  }
  
  // ----------------------------------------------------------------
  public getKvPhotoUrl (kvPhoto : KvPhoto) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
      // debugger;
      return 'url(\'' + kvPhoto.value + '\')';
    }
  }
  // ----------------------------------------------------------------
  public getKvPhotoWithoutUrl (kvPhoto : KvPhoto) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
      // debugger;
      kvPhoto.value = JsRegExpServiceStatic.normalizeImageDataWithRegExp(kvPhoto.value);
      return kvPhoto.value;
    }
  }
  // ----------------------------------------------------------------
  public getPagerVKvPhotoNavWithoutUrl (pagerVKvPhotoNav : PagerPhotoMgmt, isStripUrl: boolean) : KvPhoto[] | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.pagedItems) && pagerVKvPhotoNav.pagedItems.length > 0) {
      pagerVKvPhotoNav.pagedItems.forEach(e => {
        e.value = JsRegExpServiceStatic.setUrlForImageData(e.value);
        // e.value = JsRegExpServiceStatic.stripUrlForImageData(e.value);
      });
    }
    else return null;
  }

  // ------------------------------------------------------------
  public getIsKvData (kvPhoto : KvPhoto) : boolean {
    this.isKvData = kvPhoto
      && kvPhoto.value
      && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1
      && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') === -1
      && kvPhoto.value.toString().toLowerCase().indexOf('.png') === -1
      && kvPhoto.value.toString().toLowerCase().indexOf('.jpg') === -1;
    return this.isKvData;
  }
  // ------------------------------------------------------------
  public getIsKvFile (kvPhoto : KvPhoto) : boolean {
    this.isKvFile = kvPhoto
      && kvPhoto.value
      && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') === -1
      && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') === -1
      && (kvPhoto.value.toString().toLowerCase().indexOf('.png') !== -1 || kvPhoto.value.toString().toLowerCase().indexOf('.jpg') !== -1);
    return this.isKvFile;
  }
  // ------------------------------------------------------------
  public getIsKvUrlData (kvPhoto : KvPhoto) : boolean {
    this.isKvUrlData = kvPhoto
      && kvPhoto.value
      && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1
      && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') !== -1
      && kvPhoto.value.toString().toLowerCase().indexOf('.png') === -1
      && kvPhoto.value.toString().toLowerCase().indexOf('.jpg') === -1;
    return this.isKvUrlData;
  }
  // ------------------------------------------------------------
  public isInPagedItems (pagerVKvPhotoNav : PagerPhotoMgmt, kvPhoto : KvPhoto) : boolean {
    let isFound = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)) {
      for (let i = 0; i < pagerVKvPhotoNav.pagedItems.length; i++) {
        if (pagerVKvPhotoNav.pagedItems[ i ].photoBnId === kvPhoto.photoBnId) {
          isFound = true;
          break;
        }
      }
    }
    return isFound;
  }
  // ------------------------------------------------------------
  public isNextOrLastPage (pagerVKvPhotoNav : PagerPhotoMgmt) : boolean {
    this.isKvNext = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav?.endPage)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav?.nextPage)
      && pagerVKvPhotoNav.currentPage >= pagerVKvPhotoNav?.startPage
      && (pagerVKvPhotoNav.currentPage <= pagerVKvPhotoNav?.nextPage
        || pagerVKvPhotoNav.currentPage <= pagerVKvPhotoNav?.endPage)
    return this.isKvNext;
  }
  // ------------------------------------------------------------
  public isPreviousOrFirstPage (pagerVKvPhotoNav : PagerPhotoMgmt) : boolean {
    this.isKvPrev = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.startPage)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.previousPage)
      && (pagerVKvPhotoNav.currentPage >= pagerVKvPhotoNav.startPage
        || pagerVKvPhotoNav.currentPage <= pagerVKvPhotoNav.previousPage)
    return this.isKvPrev;
  }
  // ---------------------------------------------------------------
  // This method normalizes and image's raw data for displaying on
  // an html element. It can be prefixed with a 'url()' around the
  // image-data, or can strip the 'url()' from the image-data.
  // ---------------------------------------------------------------
  //public prepareKvPhotoArrForDisplay (kvPhotoArr : KvPhoto[], isStripUrl : boolean) : KvPhoto[] | any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
  //    kvPhotoArr.forEach(e => {
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value) && e.value.length > 0) {
  //        if (JsRegExpServiceStatic.photoUrlPrefixPattern.test(e.value)) {
  //          // debugger;
  //          if (isStripUrl) {
  //            // debugger;
  //            if (JsRegExpServiceStatic.allImageTypesFilePattern.test(e.value)) {
  //              // debugger;
  //              e.value = JsRegExpServiceStatic.normalizeImageFileName(e.value);
  //            }
  //            else if (JsRegExpServiceStatic.allImageTypesDataPattern3.test(e.value)
  //              || JsRegExpServiceStatic.allImageTypesDataPattern2.test(e.value)
  //              || JsRegExpServiceStatic.allImageTypesDataPattern1.test(e.value)) {
  //              // debugger;
  //              e.value = JsRegExpServiceStatic.setUrlForImageData(e.value); //  .normalizeImageData(e.value);
  //            }
  //          }
  //          else {
  //            // debugger;
  //            /*e.value = JsRegExpServiceStatic.normalizeImageData(e.value);*/
  //            if (JsRegExpServiceStatic.allImageTypesFilePattern.test(e.value)) {
  //              // debugger;
  //              e.value = JsRegExpServiceStatic.normalizeImageFileName(e.value);
  //            }
  //            else if (JsRegExpServiceStatic.allImageTypesDataPattern4.test(e.value)
  //              || JsRegExpServiceStatic.allImageTypesDataPattern5.test(e.value)
  //              || JsRegExpServiceStatic.allImageTypesDataPattern6.test(e.value)) {
  //              // debugger;
  //              e.value = JsRegExpServiceStatic.normalizeImageData(e.value); //  .normalizeImageData(e.value);
  //            }
  //          }
  //        }
  //        else {
  //          // debugger;
  //          e.value = JsRegExpServiceStatic.normalizeImageData(e.value);
  //        }
  //      }
  //    });
  //  }
  //  return kvPhotoArr;
  //}
  // ---------------------------------------------------------------
  // Dictionary data: priority#1 -> should be called last
  // ProfileTile data: priority#2
  // Server data: priority#3 -> should be called first
  // ---------------------------------------------------------------
  //selectProfileExectionType (situserId: number) : Promise<any> {
  //  // debugger;
  //  let date = new Date();
  //  let sitUserId = situserId;
  //  let modelPPics = new ProfilePics();
  //  this.profilePics = new ProfilePics();
  //  this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
  //  this.isMyProfile = EmitterSubjectService.getIsPhotoMgmt();
  //  this.isViewMember = EmitterSubjectService.getIsPhotoMgmt();
  //  this.profileTile = EmitterSubjectService.getSelectedProfileTile();
  //  // debugger;

  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) || this.profileTile.sitUserId === 0) {
		//	if (this.isViewMember) {
		//		this.profileTile = EmitterSubjectService.getSelectedProfileTile();
		//	}
		//	else if (this.isPhotoMgmt || this.isMyProfile) {
		//		this.profileTile = EmitterSubjectService.getAvatarProfileTile();
		//	}
		//	else {
		//		if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {
		//			this.profileTile = DictionaryServiceStatic.getProfileTileBySitUserId(this.sitUser.sitUserId);
		//		}
		//	}
  //    // debugger;
  //  }
  //  // debugger;
  //  return new Promise<any>((resolve, reject) => {
  //    // debugger;
  //    if (sitUserId === 0) {
  //      this.message = "selectProfileExectionType(-sitUserId) cannot be determined for soitUserId = " + sitUserId;
  //      reject(this.message);
		//	}
      

  //    // Server-data case (priority#1):
  //    // ------------------------------
  //    // debugger;
  //    this.profileExecutionOption = this.profileExecutionOptionArr[ 0 ];
  //    console.log('timestamp : ' + new Date().getTime() + 'in  displayProfileService.selectProfileExectionType(), executed profile option: ' + this.profileExecutionOption);
      
      
  //    // ProfileTile-data case (priority#2):
  //    // -----------------------------------
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) && this.profileTile.sitUserId > 0) {
  //      // debugger;
  //      this.profileExecutionOption = this.profileExecutionOptionArr[ 1 ];
  //      console.log('timestamp : ' + new Date().getTime() + ': in  displayProfileService.selectProfileExectionType(), executed profile option: ' + this.profileExecutionOption);
  //    }
  //    // Dictionary-data case (priority#1.a):
  //    // ----------------------------------
  //    // generate the profilePics' data from profilePicsDictionary:      
  //    // ----------------------------------------------------------
  //    else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(DictionaryServiceStatic.profilePicsDictionary)
  //      && DictionaryServiceStatic.profilePicsDictionary.size() == 0) {

  //      // debugger;
  //      this.profilePics = DictionaryServiceStatic.profilePicsDictionary.getValue(sitUserId) as ProfilePics;

  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics)
  //        && (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.filteredKvPhotoDictionary) && this.profilePics.filteredKvPhotoDictionary.values.length > 0
  //          || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.unFilteredKvPhotoDictionary) && this.profilePics.unFilteredKvPhotoDictionary.values.length > 0)
  //        || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.filteredKvPhotoArr) && this.profilePics.filteredKvPhotoArr.length > 0)
  //        || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.unFilteredKvPhotoArr) && this.profilePics.unFilteredKvPhotoArr.length > 0)) {

  //        // save the profilePics model in Emitter for later use:
  //        // ----------------------------------------------------
  //        EmitterSubjectService.setProfilePics(this.profilePics);

  //        this.profileExecutionOption = this.profileExecutionOptionArr[ 0 ];
  //        // debugger;
  //      }
  //      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) && this.profileTile.sitUserId > 0) {
  //        // debugger;
  //        this.profileExecutionOption = this.profileExecutionOptionArr[ 1 ];
  //      }
  //      else {
  //        // debugger;
  //        this.profileExecutionOption = this.profileExecutionOptionArr[ 2 ];
  //      }
  //      console.log('timestamp : ' + new Date().getTime() + ' in  displayProfileService.selectProfileExectionType(), executed profile option: ' + this.profileExecutionOption);

  //    } // end of if profilePicsDictionary
  //    // Dictionary-data case (priority#1.b):
  //    // ----------------------------------
  //    // generate the profilePics' data from KvPhotoDictionary:
  //    // ------------------------------------------------------
  //    else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(DictionaryServiceStatic.kvPhotoDictionary)
  //      && DictionaryServiceStatic.kvPhotoDictionary.size() > 0) {
  //      // debugger;
  //      //  Note: this method gets only unFilteredKvPhotoArray of ProfilePics:
  //      // -------------------------------------------------------------------
  //      this.profilePics = DictionaryServiceStatic.getProfilePicsFromKvPhotoDictionary(sitUserId); // KvPhotos are not filtered yet.

  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics)) {
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.unFilteredKvPhotoArr) && this.profilePics.unFilteredKvPhotoArr.length > 0) {
  //          // filter the kvPhotoArr of ProfilePics:
  //          // -------------------------------------
  //          this.photoPrivacyService.filterKvPhotoArr(this.profilePics.unFilteredKvPhotoArr).then(result => {
  //            this.profilePics.filteredKvPhotoArr = result;
  //            // debugger;
  //            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.filteredKvPhotoArr) && this.profilePics.filteredKvPhotoArr.length > 0) {
  //              // debugger;
  //              this.profilePics.filteredKvPhotoArr.forEach(e => {
  //                if (e) {
  //                  this.profilePics.filteredKvPhotoDictionary.setValue(e.photoBnId, e);
  //                }
  //              })
  //            }
  //          });
  //        }

  //        if (!(modelPPics?.isKvPhotoDictionariesEmpty(this.profilePics) || !modelPPics?.isModelKvPhotoArraysEmpty(this.profilePics))) {

  //          // save the profilePics model in Emitter for later use:
  //          // ----------------------------------------------------
  //          EmitterSubjectService.setProfilePics(this.profilePics);

  //          this.profileExecutionOption = this.profileExecutionOptionArr[ 2 ];
  //          // debugger;
  //        }
  //        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) && this.profileTile.sitUserId > 0) {
  //          // debugger;
  //          this.profileExecutionOption = this.profileExecutionOptionArr[ 1 ];
  //        }
  //        else {
  //          // debugger;
  //          this.profileExecutionOption = this.profileExecutionOptionArr[ 2 ];
  //        }
  //      }
  //      console.log('timestamp : ' + new Date().getTime() + ': in  displayProfileService.selectProfileExectionType(), executed profile option: ' + this.profileExecutionOption);
  //    }

  //    if (this.profileExecutionOption) {
  //      resolve(this.profileExecutionOption)
  //    }
  //    else {
  //      reject('timestamp : ' + new Date().getTime() + ': selectProfileExectionType() failed.');
  //    }
  //  }).catch(error => {
  //    this.message = 'timestamp : ' + new Date().getTime() + 'in displayProfileService.selectProfileExectionType(), error : ' + error;
  //    console.log(this.message);
  //  });
  //}

  // ----------------------------------------------------------------------
  
  // ----------------------------------------------------------------------
}
// ========================================================================
// --------------------------------------------------------------------


