'use-strict'
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component,
    ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { Heartbeat } from '../../../models/common/heartbeat.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';
import { ProfileInfo } from '../../../models/profile/profileInfo.model';
import { ProfileTile } from '../../../models/profile/profileTile.model';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { SignedInUserService } from '../../../services/commonServiceService/signedInUserService.service';
import { SitUserService } from '../../../services/commonServiceService/sitUserService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { HttpService } from '../../../services/coreServiceService/httpService.service';
import { ProfileNameService } from '../../../services/profileServiceService/profileNameService.service';
import { ProfileServiceService } from '../../../services/profileServiceService/profileServiceService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { ModuleComponentLoaderServiceStatic } from '../../../services/staticServices/commonStaticServices/moduleComponentLoaderServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

// TODO: create a new service named ProfileNameService!! 

@Component({
  selector: 'app-profile-name',
  templateUrl: './profileName.component.html',
  styleUrls: [ './profileName.component.scss' ],
  // 1)OnPush->requires an Observable to refresh the view; 2)Default->automatic refresh
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ProfileNameComponent implements OnInit, OnDestroy, AfterViewInit {
  public httpService! : HttpService;
  public profileNameService! : ProfileNameService;
  public redirectionService! : RedirectionService;
  public rendererService! : RendererService;
  public renderer : Renderer2;
  public sitUserService! : SitUserService;
  public signedInUserService! : SignedInUserService;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  // ----------------------------------------------------------
  public hbCounter = 0;
  public heartbeat : Heartbeat = new Heartbeat();
  public isMouseIn = false;
  public isMobilevar = false;
  public isOnLine = false;
  public isRequired = false;
  public isPhotoMgmt = false;
  public isViewMember = false;
  public loginSuccess! : LoginSuccess;
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  @Input() profileName = '';
  public profileNameMap : Map<any, any> = new Map();
  public profileInfoView : ProfileInfo = new ProfileInfo();
  public profileTile : ProfileTile = new ProfileTile();
  public sitUserId : number = 0;
  @Input() sitUser : SitUser = new SitUser();
  public signedInUser : SitUser = new SitUser();
  public tempElem! : HTMLElement;
  public timer : any;
  public timerMap : Map<any, any> = new Map();
  public tempElemName = '';
  // -----------------------------------------------------------
  constructor (private cdr : ChangeDetectorRef,
    private coreServiceService : CoreServiceService,
    private commonServiceService : CommonServiceService,
    private profileServiceService : ProfileServiceService,
    private rendererServiceService : RendererServiceService,
    private router : Router
  ) {
    if (this.rendererServiceService) { this.rendererService = this.rendererServiceService.getRendererService(); }
    if (this.coreServiceService) this.httpService = this.coreServiceService.getHttpService();
    if (this.commonServiceService) {
      this.redirectionService = this.commonServiceService.getRedirectionService();
      this.sitUserService = this.commonServiceService.getSitUserService();
      this.signedInUserService = this.commonServiceService.getSignedInUserService();
    }
    if (this.rendererService) { this.renderer = this.rendererService.getRenderer(); }

    if (this.profileServiceService) this.profileNameService = this.profileServiceService.getProfileNameService();


    // this.renderer = this.rendererFactory.createRenderer(null, null);
    // ref:https:// stackoverflow.com/questions/58989990/error-staticinjectorerrorappmodulesomecomponent-renderer2-when-creating
    this.renderer = this.rendererService?.getRenderer();
    // --------------------------------
  }
  // ----------------------------------------------------------------
  ngOnInit () : void {
    // --------------------------------------------------------------
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) || this.sitUser.sitUserId === 0) {
      this.sitUser = EmitterSubjectService.getSitUserModel();
    }  
    this.signedInUser = EmitterSubjectService.getSignedInUser();

    //  if parent-component-provided profileName is empty,
    //  then get it from either loginSuccess or sitUser:
    // ----------------------------------------------------
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileName)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {
        if (this.sitUser.sitUserId === this.loginSuccess.signedInUserId) {
          this.profileName = this.loginSuccess.profileName;
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser.profileName)){
          this.profileName = this.sitUser.profileName;
        }
      }
    }
    // -----------------------------------------------------------
    EmitterSubjectService.profileInfoViewEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.profileInfoView = result as ProfileInfo;
        // We update only for non-signedInUser:
        // ------------------------------------
        this.getProfileNameFromProfileInfo(this.profileInfoView);
      });
      // debugger;
    // -----------------------------------------------------------
    EmitterSubjectService.profileTileEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.profileTile = result as ProfileTile;
        this.getProfileNameFromTile(result);        
      });
      // debugger;
    // -----------------------------------------------------------
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngAfterViewInit () : void {
    this.cdr.detectChanges();
    // debugger;
    
    this.profileName = this.getProfileName(this.sitUser.sitUserId);
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileName)) {
      this.profileName = this.profileNameService.fetchProfileName(this.sitUser.sitUserId);
      // debugger;
    }
  }
  // ---------------------------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();

    this.timerMap.forEach(timer => clearTimeout(timer));
    this.timerMap.clear();
    // debugger;
  }
  // ---------------------------------------------------------------------------------
  clearTimerMap () : void {
    this.timerMap.clear();
  }
  // --------------------------------------------------------------
  public getProfileNameFromProfileInfo (pInfo : ProfileInfo) : string {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.sitUserId > 0
      && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileName)
      && pInfo.sitUserId !== this.loginSuccess.signedInUserId  ) {
      this.profileName = this.profileNameService.getProfileNameFromProfileInfo(pInfo);
    }
    return this.profileName;
  }
  // --------------------------------------------------------------
  // Note:  we prefer profileName from ProfileInfo to ProfileTile
  //        and only for non-signedInUser:
  // --------------------------------------------------------------
  public getProfileNameFromTile (pTile : ProfileTile) : string {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId > 0
      && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileName)
      && pTile.sitUserId !== this.loginSuccess.signedInUserId
      && (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfoView))) {
      this.profileName = this.profileNameService.getProfileNameFromProfileTile(pTile);
    }
    else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfoView)) {
      this.profileName = this.getProfileNameFromProfileInfo(this.profileInfoView);
    }
    return this.profileName;
  }
  // ---------------------------------------------------------------------------------
  getProfileName (sitId: number) {
    this.profileName = this.profileNameService.getProfileName(sitId);
    return this.profileName;
  }
  // ---------------------------------------------------------------
  setProfileName (pName : string, sitId: number) : void {
    this.profileNameService.setProfileNameMap(pName, sitId);
    // debugger;
  }
  // ---------------------------------------------------------------------------------
  setupKvPhotoModel (kvp : KvPhoto) : KvPhoto | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.image) && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value)) {
        kvp.value = kvp.image;
      }
      else if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.image) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value)) {
        kvp.image = kvp.value;
      }
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.pic)) {
        kvp.pic = kvp.image;
      }

      if (kvp.isPrimary) {
        kvp.key = kvp.glyphName = "Primary";
        kvp.privacy = "Public";
      }
    }
    return kvp;
  }
  // ---------------------------------------------------------------
  viewProfile () : void {
    this.mcLoader.router = this.router;
    this.mcLoader.moduleName = this.mcLoader.moduleName = 'memberm';
    this.mcLoader.componentName = this.mcLoader.componentName = 'memberViewMgmt';
    this.mcLoader.returnUrl = this.redirectionService.getUrlAddress();
    
    this.sitUser = EmitterSubjectService.getSitUserModel();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)
      && this.loginSuccess.signedInUserId > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser)
      && this.sitUser.sitUserId > 0 ) {

      if (this.sitUser.sitUserId !== this.loginSuccess.signedInUserId) {
        // debugger;       
        this.mcLoader.id = this.sitUser.sitUserId;
        // this.isViewMember = true;
        // EmitterSubjectService.emitIsViewMember(this.isViewMember);
      }
      else {
        this.mcLoader.id = this.loginSuccess.signedInUserId;
        // this.isPhotoMgmt = true;
        // EmitterSubjectService.emitIsPhotoMgmt(this.isPhotoMgmt);
      }
    }
    // Note: setupMcLoderModel before fireMcLoaderRoute()!!
    // ---------------------------------------------------
    // this.mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(this.mcLoader);
    this.redirectionService.setRoute(this.mcLoader.router, this.mcLoader.componentName, this.mcLoader.id).subscribe(data => {
      this.pagerBrdCrm = data;
    });

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile)) {
      this.profileTile.isPrimaryPhoto = true;
      this.profileTile.copyToKvPhoto(this.profileTile.kvPhoto, this.profileTile); // will create a new KvPhoto model and populate it's data from ProfileTile model
      EmitterSubjectService.emitProfileTile(this.profileTile); // will setProfileTile also.
    }
  }
  // ---------------------------------------------------------------
}
