
import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { Dictionary } from 'dictionaryjs';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { EmitOffOnResult } from '../../models/common/emitOffOnResult.model';
import { Communication } from '../../models/communication/communication.model';
import { CommunicationActivity } from '../../models/communication/communicationActivity.model';
import { Communicator } from '../../models/communication/communicator.model';
import { EnoteBox } from '../../models/communication/enoteBox.model';
import { MailBox } from '../../models/communication/mailBox.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { KvMemberActivityGlyph } from '../../models/keyValue/kvMemberActivityGlyph.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { GlyphiconMenuService } from '../GlyphiconService/glypgiconMenuService.service';
import { QuicksortService } from '../searchSortServiceService/quicksort.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { DictionaryServiceStatic } from '../staticServices/dictionaryServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { JsRegExpServiceStatic } from '../staticServices/jsRegExpServiceStatic.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
import { CommunicatorService } from './communicatorService.service';

@Injectable({ providedIn: 'any' })

export class EnoteService implements OnDestroy {
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  public commActivityIds: number[] = [];
  public enote: CommunicationActivity = new CommunicationActivity();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isMobilevar = false;
  public isOnLine = false;
  public kvGlyph : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public mailBox: MailBox = new MailBox();
  public message = '';
  public myCommunicationActivityBns: BoxNonceEntity[] = [];
  public myCommunicationActivityIds: number[] = [];
  public myEnotes : CommunicationActivity[] = [];
  public myEnoteTilesSubscriber : Observable<any> = null;
  public myStaticEnotes: CommunicationActivity[] = [];
  public myEnotesMenuKvArr : KvAny[] = [];
  public enoteTileDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();

  // ------for notification
  public myInboxEnotes: CommunicationActivity[] = [];
  public mySavedEnotes: CommunicationActivity[] = [];
  public mySentEnotes: CommunicationActivity[] = [];
  public myTrashedEnotes : CommunicationActivity[] = [];

  // ------for pushNotification
  public myAllEnotes : CommunicationActivity[] = [];
  public myAllEnoteDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  public myBuddyEnotes : CommunicationActivity[] = [];
  public myFriendEnotes : CommunicationActivity[] = [];
  public myLikeEnotes : CommunicationActivity[] = [];
  public myStarEnotes : CommunicationActivity[] = [];
  public myTrackEnotes : CommunicationActivity[] = [];
  public myUnlockEnotes : CommunicationActivity[] = [];
  public myWinkEnotes : CommunicationActivity[] = [];

  public myConversationsBoxNonceArr: BoxNonceEntity[] = [];
  public offOnResult: EmitOffOnResult = new EmitOffOnResult();
  public profileTile: ProfileTile = new ProfileTile();
  public receiverInfo: Communicator = new Communicator();
  public renderer!: Renderer2;
  public senderInfo: Communicator = new Communicator();
  public sitUserId = 0;
  public tempElem: any;
  public timerArray: any[] = [];
  public timer : any;

  // All totalCount-related variables:
  public buddyTotal = 0;
  public friendTotal = 0;
  public likeTotal = 0;
  public starTotal = 0;
  public total = 0;
  public trackTotal = 0;
  public unlockTotal = 0;
  public winkTotal = 0;
  // ---------------------------------
  constructor (
    public communicatorService : CommunicatorService,
    public dbDexieToDictionaryService : DbDexieToDictionaryService,
    public dictionaryService : DictionaryService,
    public httpService : HttpService,
    public glyphiconMenuService : GlyphiconMenuService,
    public quickSortService: QuicksortService,
  )
  {
    this.initialize();
  }

  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ---------------------------------------------------------------
  initialize () {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    EmitterSubjectService.loginSuccessEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        this.loginSuccess = result as LoginSuccess;
      });

    EmitterSubjectService.isMobileEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        this.isMobilevar = result as boolean;
      });
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
  }
  // -------------------------------------------------------------
  createActionGlyphicon (enoteTile : CommunicationActivity) : KvMemberActivityGlyph {
    // debugger;
    let names : string[] = [];
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enoteTile)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enoteTile.memberActivity)) {
      // debugger;
      names.push(enoteTile.memberActivity.toLowerCase());
      let tKvGlyphs = this.glyphiconMenuService.createGlyphMenu(names, enoteTile.receiverSitUserId);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tKvGlyphs) && tKvGlyphs.length > 0) {
        // debugger;
        this.kvGlyph = tKvGlyphs[ 0 ];

      }
    }
    return this.kvGlyph;
  }
  // -----------------------------------------------------------
  editNotificationSubject (input: string) : string
  {
    let output = '';
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(input))
    {
      let parts = input.split( '!' );
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( parts ) && parts.length > 0 )
      {
        output = parts[ 0 ] + '! Click his profile to view.';
      }
    }
    return output;
  }
  // ---------------------------------------------------------------
  extractMailBox (mailBox : MailBox) : MailBox {
    // debugger;
    var outMailBox = new MailBox();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mailBox)) {

      // ------for notification
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mailBox)) {
        outMailBox.myAllEnotes = mailBox.myAllEnotes;
        outMailBox.myBuddyEnotes = mailBox.myBuddyEnotes;
        outMailBox.buddyTotal = this.getNewMemberActivityNotificationTotal(mailBox.myBuddyEnotes, 'buddy');

        outMailBox.myFriendEnotes = mailBox.myFriendEnotes;
        // debugger;
        outMailBox.friendTotal = this.getNewMemberActivityNotificationTotal(mailBox.myFriendEnotes, 'friend');

        outMailBox.myLikeEnotes = mailBox.myLikeEnotes;
        outMailBox.likeTotal = this.getNewMemberActivityNotificationTotal(mailBox.myLikeEnotes, 'like');

        outMailBox.myStarEnotes = mailBox.myStarEnotes;
        outMailBox.starTotal = this.getNewMemberActivityNotificationTotal(mailBox.myStarEnotes, 'star');

        outMailBox.myTrackEnotes = mailBox.myTrackEnotes;
        outMailBox.trackTotal = this.getNewMemberActivityNotificationTotal(mailBox.myTrackEnotes, 'track');

        outMailBox.myUnlockEnotes = mailBox.myUnlockEnotes;
        outMailBox.unlockTotal = this.getNewMemberActivityNotificationTotal(mailBox.myUnlockEnotes, 'unlock');

        outMailBox.myWinkEnotes = mailBox.myWinkEnotes;
        outMailBox.winkTotal = this.getNewMemberActivityNotificationTotal(mailBox.myWinkEnotes, 'wink');
        // debugger;
      }
      outMailBox.allTotal = this.getNewTotal(mailBox.myAllEnotes);      
      return outMailBox;
    }
  }
  // ---------------------------------------------------------------
  extractPremiumEnoteBox (enoteBox : EnoteBox) : EnoteBox {
    // debugger;
    var outEnoteBox : EnoteBox = new EnoteBox();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enoteBox)) {
      outEnoteBox.allPremiumEnoteTotal = 0;
      outEnoteBox.inboxPremiumEnoteTotal = 0;
      outEnoteBox.savedPremiumEnoteTotal = 0;
      outEnoteBox.sentPremiumEnoteTotal = 0;
      outEnoteBox.trashedPremiumEnoteTotal = 0;

      // ------for notification
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enoteBox)) {
        outEnoteBox.allPremiumEnotes = enoteBox.allPremiumEnotes 
        outEnoteBox.inboxPremiumEnotes = enoteBox.inboxPremiumEnotes ;
        outEnoteBox.inboxPremiumEnoteTotal = this.getNewPremiumNotificationTotal(enoteBox.inboxPremiumEnotes, 'inboxPremium');

        outEnoteBox.savedPremiumEnotes = enoteBox.savedPremiumEnotes ;
        // debugger;
        outEnoteBox.savedPremiumEnoteTotal = this.getNewPremiumNotificationTotal(enoteBox.savedPremiumEnotes, 'savedPremium');

        outEnoteBox.sentPremiumEnotes = enoteBox.sentPremiumEnotes;
        outEnoteBox.sentPremiumEnoteTotal = this.getNewPremiumNotificationTotal(enoteBox.sentPremiumEnotes, 'sentPremium');       

        outEnoteBox.trashedPremiumEnotes = enoteBox.trashedPremiumEnotes;
        outEnoteBox.trashedPremiumEnoteTotal = this.getNewPremiumNotificationTotal(enoteBox.trashedPremiumEnotes, 'trashedPremium');
        // debugger;
      }
      this.total = this.getNewTotal(this.myAllEnotes);

     
      return outEnoteBox;
    }
  }
  // ---------------------------------------------------------------
  getNewPremiumNotificationTotal (memberActivityArr : CommunicationActivity[], action : string) {
    // debugger;
    let total = 0;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(action) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memberActivityArr) && memberActivityArr.length > 0) {
      memberActivityArr.map(e => {
        if (e.communicationSubType.toLocaleLowerCase().indexOf(action.toLocaleLowerCase()) !== -1) {
          if (e.receiverAction.toLowerCase().indexOf('received') != -1) {
            total++;
            // debugger;
          }
        }
      });
    }
    // debugger;
    return total;
  }
  // ---------------------------------------------------------------
  getNewMemberActivityNotificationTotal (memberActivityArr : CommunicationActivity[], action : string) {
    // debugger;
    let total = 0;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(action) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memberActivityArr) && memberActivityArr.length > 0) {
      memberActivityArr.map(e => {
        if (e.communicationSubType.toLocaleLowerCase().indexOf(action.toLocaleLowerCase()) !== -1) {
          if (e.receiverAction.toLowerCase().indexOf('received') != -1) {
            total++;
            // debugger;
          }
        }
      });
    }
    // debugger;
    return total;
  }
  // ---------------------------------------------------------------
  getMailBox () : MailBox {
    return this.mailBox;
  }
  // ---------------------------------------------------------------
  getMyAllEnoteDictionary () : Dictionary<number, CommunicationActivity> {
    return this.myAllEnoteDictionary;
  }  
  // ---------------------------------------------------------------
  // TODO: There should be separate SetCommunicator()
  // Note: 1. Currently, the backend saves the Communicator when we try to fetch
  // Note: 2. The composeEnoteComponent  sends the GeoLocation data that is saved into the serverDb
  // Note: 3: This method should also send Geolocation data
  // ---------------------------------------------------------------
  getCommunicator (sitUserId : number) : Promise<any> {
    let date = new Date();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    const commCator : Communicator = new Communicator();
     debugger;
    return new Promise<any>((resolve, reject) => {

      if (sitUserId > 0) {
        const bnComm : Communication = new Communication();
        bnComm.sitUserId = sitUserId;
        this.boxNonceEntity = this.salt(bnComm);
         debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          // debugger;
          this.httpService.postObservable('api/Communicator/GetCommunicator', JSON.stringify({
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
          }), 'json2text')
            .subscribe(result => {
              if (result) {
                debugger;
                resolve(this.processCommunicatorResult(sitUserId, result));
              }
              else {
                debugger;
                this.message = 'the result of enoteService.getCommunicator (sitUserId:\'' + sitUserId + '\') the promise returned a result that was null or undefined';
                // EmitterSubjectService.emitMyErrorLog(this.message);
                reject(this.message);
              }
            })
        } else {
          this.message = 'the result of enoteService.getCommunicator (sitUserId:\'' + sitUserId + '\') the promise was rejected because box or nonce value was null or undefined';
          // EmitterSubjectService.emitMyErrorLog(this.message);
          reject(this.message);
        }
      }
      else {
        this.message = 'the result of enoteService.getCommunicator (sitUserId:\'' + sitUserId + '\') the promise was rejected';
        // EmitterSubjectService.emitMyErrorLog(this.message);
        reject(this.message);
      }
    }).catch((error) => {
      console.log(date.getTime() + 'enoteService.getCommunicator (sitUserId: ' + sitUserId + ') retrned a null promise.');
    })
  }
  // ---------------------------------------------------------------
  //  Not in use
  // ---------------------------------------------------------------
  getEnote (commActId : number, isInbox : boolean) : Observable<any> {
    let date = new Date();
    let tEnote : any;
    const enote : CommunicationActivity = new CommunicationActivity();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (commActId > 0) {
      // debugger;
      return new Observable((subscriber) => {
        if (this.loginSuccess.signedInUserId > 0) {
          const bnComm : Communication = new Communication();
          bnComm.communicationActivityId = commActId;
          bnComm.isInbox = isInbox;
          bnComm.signedInUserId = this.loginSuccess.signedInUserId;
          this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
            this.httpService.postObservable('api/Enote/GetEnote',
              {
                box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
                nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
              }, 'json2text').subscribe(result => {
                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                  // debugger;
                  this.processEnoteResult(result).subscribe(data => { // data typeof CommunicationActivity
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
                      // debugger;  
                      subscriber.next(data);
                      subscriber.complete();
                    }
                    else {
                      this.message = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + 'the processEnoteResult() of the result of getEnote(sitUserId:\"' + this.loginSuccess.signedInUserId + '\") was null or undefined.');
                      console.log(this.message);
                     
                    }
                  });
                }
                else {
                  this.message = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + 'thr promise result in getEnote(sitUserId:\"' + this.loginSuccess.signedInUserId + '\") was null or undefined;\n result: ' + result);
                  console.log(this.message);
                  
                }
              })
          }
          else {
            this.message = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + 'the box or nonce of getEnote(sitUserId:\"' + this.loginSuccess.signedInUserId + '\") was null or undefined.');
            // EmitterSubjectService.emitMyErrorLog(this.message);
            console.log(this.message);
           
          }
        }
        else {
          this.message = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + 'the loginSucess.signedInUserId of getEnote(sitUserId:\"' + this.loginSuccess.signedInUserId + '\") was 0 or null or undefined');
          // EmitterSubjectService.emitMyErrorLog(this.message);
          console.log(this.message);
          
        }
      })
    }
    else {
      this.message = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + '| enoteService.getEnote (commActId : ' + commActId + ') is zero(0).');
      console.log(this.message);
		}
  }
  // ---------------------------------------------------------------
  //  Not in use
  // ---------------------------------------------------------------
  getEnoteIds () : Observable<any> {
    let date = new Date();
    let enote : CommunicationActivity = new CommunicationActivity();
    // debugger;
    return new Observable<any>((subscriber) => {
      if (this.loginSuccess.signedInUserId > 0) {

        let bnComm : Communication = new Communication();
        bnComm.isInbox = true;
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        bnComm.sitUserId = this.loginSuccess.signedInUserId;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          this.httpService.postObservable('api/Enote/FetchNotificationIds',
            JSON.stringify({
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            }), 'json2text').subscribe(result => {
              if (result) {
                // debugger;
                let bn = result;
                if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
                  this.commActivityIds = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as number[];
                  // debugger;
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.commActivityIds) && this.commActivityIds.length > 0) {
                    DictionaryServiceStatic.updateDictionary(this.commActivityIds, 'myNotificationId', this.loginSuccess.signedInUserId);
                    // debugger;
                    if (DictionaryServiceStatic.myNotificationIdDictionary.size > 0) {
                      // debugger;
                      this.dbDexieToDictionaryService.saveDictionaryToDbDexie(this.dictionaryService.myNotificationIdDictionary, 'myNotificationId', this.loginSuccess.signedInUserId);
                    }
                  }
                  subscriber.next(this.commActivityIds);
                  subscriber.complete();
                }
                else {
                  this.message = date.getTime() + 'Error occured in getMyEnoteIds(sitUserId:"' + this.loginSuccess.signedInUserId + '");\n result: ' + result;
                  // EmitterSubjectService.emitMyErrorLog(this.message);
                }
              }
              else {
                this.message = date.getTime() + 'the promise result of getMyEnoteIds(sitUserId:"' + this.loginSuccess.signedInUserId + '") was null or undefined;  result: ' + result;
                // EmitterSubjectService.emitMyErrorLog(this.message);
              }
            })
        }
      }
      else {
        this.message = date.getTime() + 'the promise result of getMyEnoteIds(sitUserId:"' + this.loginSuccess.signedInUserId + '") was null or undefined;';
        // EmitterSubjectService.emitMyErrorLog(this.message);
      }
    })
  }
  // ---------------------------------------------------------------
  // Deprecated!
  // ---------------------------------------------------------------
  getMyNotifications (isinbox : boolean) : Observable<any> {
    let date = new Date();
    let tEnote : any;
    var isInbox = isinbox

    // debugger;
    return new Observable((subscriber) => {
      // debugger;
      this.httpService.getObservable('api/Enote/GetMyNotifications', 'json2text').subscribe(result => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
          // debugger;
          this.processMyNotificationResults(result).subscribe(data => { // data typeof CommunicationActivity
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
              // debugger;
              subscriber.next(data);
              // subscriber.complete();
            }
            else {
              this.message = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + 'the processMyNotificationResults() of the result of getMyNotifications() was null or undefined.');
              console.log(this.message);

            }
          });
        }
        else {
          this.message = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + 'thr promise result in getMyNotifications() was null or undefined;\n result: ' + result);
          console.log(this.message);

        }
      })
    })
  }
  // ---------------------------------------------------------------
  postMyNotifications (isinbox : boolean) : Observable<any> {
    let date = new Date();
    let tEnote : any;
    const enote : CommunicationActivity = new CommunicationActivity();
    var loginSuccess = EmitterSubjectService.getLoginSuccess();
    var isInbox = isinbox

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(loginSuccess)  && loginSuccess.signedInUserId > 0) {
      // debugger;
      return new Observable((subscriber) => {
        if (loginSuccess.signedInUserId > 0) {
          const bnComm : Communication = new Communication();
          bnComm.isInbox = isInbox;
          bnComm.signedInUserId = loginSuccess.signedInUserId;
          bnComm.signedInUserKey = loginSuccess.signedInUserKey;
          this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
            this.httpService.postObservable('api/Enote/PostMyNotifications',
              {
                box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
                nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
              }, 'json2text').subscribe(result => {
                 // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                   // debugger;
                  this.processMyNotificationResults(result).subscribe(data => { // data typeof CommunicationActivity
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
                      // debugger;  
                      subscriber.next(data);
                      // subscriber.complete();
                    }
                    else {
                      this.message = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + 'the processMyNotificationResults() of the result of getMyNotifications(sitUserId:\"' + this.loginSuccess.signedInUserId + '\") was null or undefined.');
                      console.log(this.message);

                    }
                  });
                }
                else {
                  this.message = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + 'thr promise result in getMyNotifications(sitUserId:\"' + this.loginSuccess.signedInUserId + '\") was null or undefined;\n result: ' + result);
                  console.log(this.message);

                }
              })
          }
          else {
            this.message = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + 'the box or nonce of getMyNotifications(sitUserId:\"' + this.loginSuccess.signedInUserId + '\") was null or undefined.');
            // EmitterSubjectService.emitMyErrorLog(this.message);
            console.log(this.message);

          }
        }
        else {
          this.message = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + 'the loginSucess.signedInUserId of getMyNotifications(sitUserId:\"' + this.loginSuccess.signedInUserId + '\") was 0 or null or undefined');
          // EmitterSubjectService.emitMyErrorLog(this.message);
          console.log(this.message);

        }
      })
    }
    else {
      this.message = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + '| enoteService.getMyNotifications (loginSuccess.signedInUserId : ' + this.loginSuccess.signedInUserId + ') is zero(0).');
      console.log(this.message);
    }
  }
  // ---------------------------------------------------------------
  //  Deprecated
  // ---------------------------------------------------------------
  getMyEnoteTiles (ids : number[]) : Observable<any> {
    let date = new Date();
    let totalEnotes = 0;
    let index = -1;
    // debugger;
    this.myEnoteTilesSubscriber = new Observable<any>((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ids) && ids.length > 0) {
        this.commActivityIds = ids;
        // debugger;        

        for (let i = 0; i < this.commActivityIds.length; i++) {
          index = i;
          // accumulate all unsalted enotes for View in the enoteTileEmitter
           debugger;
          this.getEnote(this.commActivityIds[ i ], true).subscribe((enote) => {
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote)) {     
              // debugger;
              subscriber.next(enote);
            }
            else {
              this.message = 'getEnote(commActId: ' + this.commActivityIds[ i ] + ') returned result was null of undefined.';
              console.log(this.message);
            }
            if (i === this.commActivityIds.length) {
              subscriber.complete();
              debugger;
            }
          })
        }
      }
      else {
        this.message = ' getMyEnoteTiles(commActIds: ) was null of undefined.';
      }
    })
    return this.myEnoteTilesSubscriber;
  }
  // --------------------------------------------------------------
  getNewTotal (tEnoteTiles : CommunicationActivity[]) : number {
    this.total = 0;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tEnoteTiles) && tEnoteTiles.length > 0) {

      tEnoteTiles.map(e => {
        if (e.receiverAction.toLowerCase().indexOf('received') !== -1) {
          this.total++;
        }
      });
    }
    return this.total;
  } 

  // ---------------------------------------------------
  // ref:https:// www.positronx.io/angular-8-es-6-typescript-promises-examples/
  ePromise (data : any) : any {
    const promise = new Promise((resolve, reject) => {
      this.timer = setTimeout(() => {
        console.log('promise returning resolve!');
        resolve(data);
      }, 1000);
      clearTimeout(this.timer);
    });
    return promise;
  }
  // ---------------------------------------------------------------
  // Note: this method selects the latest action of a kind such as like/friend etc.
  //       where the doer is the same and doee is the signedInUsr.
  // ---------------------------------------------------------------
  isEnoteTileFound (eTileArr : CommunicationActivity[], eTile : CommunicationActivity) : number {
    // debugger;
    let index = -1;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTileArr)
      && eTileArr.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile.communicationType)
      && (eTile.communicationType.toLowerCase().indexOf('notification') !== -1
        || eTile.communicationType.toLowerCase().indexOf('note') !== -1)
    ) {
      // debugger;
      let i = 0;
      for (const e of eTileArr) {
        // debugger;
        if (e.communicationActivityId === eTile.communicationActivityId) {
          // debugger;
          index = i;
          break;
        }
        i++;
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  isLatestEnoteTileFound (eTileArr : CommunicationActivity[], eTile : CommunicationActivity) : number {
    let index = -1;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTileArr)
      && eTileArr.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile.communicationType)
      && (eTile.communicationType.toLowerCase().indexOf('notification') !== -1)
      || (eTile.communicationType.toLowerCase().indexOf('note') !== -1)){
      let i = 0;
      for (const e of eTileArr) {
        if (e.communicationSubType.indexOf(eTile.communicationSubType) !== -1 && DateStringServiceStatic.compareDate(eTile.date, e.date) > 0){
          // debugger;
          index = i;
          break;
        }
        i++;
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  isSenderFound (senders : Communicator[], sender : Communicator) : number {
    let index = -1;
    if (senders.length > 0 && sender) {
      let i = 0;
      for (const e of senders) {
        if (e.sitUserId === sender.sitUserId) {
          // debugger;
          index = i;
          break;
        }
        i++;
      }
    }
    return index;
  }
  // -----------------------------------------------------------
  nullPromise () : any {
    this.timer = setTimeout(() => {
      // debugger;
    }, 500);
    clearTimeout(this.timer);
    if (this.timer) {
      this.timerArray.push(this.timer);
    }
  }
  // ---------------------------------------------------------------
  populateSubTypeMailBox (mailBox : MailBox, allEnotes : CommunicationActivity[]) : MailBox {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mailBox)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(allEnotes)
      && allEnotes.length > 0) {
      mailBox.myAllEnotes = allEnotes;

      // debugger;
      this.mailBox.myAllEnotes.map(e => {
        switch (e.communicationSubType.toLowerCase()) {
          case 'buddy':
            // debugger;
            this.mailBox.myBuddyEnotes.push(JSON.parse(JSON.stringify(e)));
            this.mailBox.buddyTotal = this.mailBox.myBuddyEnotes.length;
            this.mailBox.allTotal = this.mailBox.allTotal + this.mailBox.buddyTotal;
            break;
          case 'friend':
            // debugger;
            this.mailBox.myFriendEnotes.push(JSON.parse(JSON.stringify(e)));
            this.mailBox.friendTotal = this.mailBox.myFriendEnotes.length;
            this.mailBox.allTotal = this.mailBox.allTotal + this.mailBox.friendTotal;
            break;
          case 'like':
            // debugger;
            this.mailBox.myLikeEnotes.push(JSON.parse(JSON.stringify(e)));
            this.mailBox.likeTotal = this.mailBox.myLikeEnotes.length;
            this.mailBox.allTotal = this.mailBox.allTotal + this.mailBox.likeTotal;
            break;
          case 'star':
            // debugger;
            this.mailBox.myStarEnotes.push(JSON.parse(JSON.stringify(e)));
            this.mailBox.starTotal = this.mailBox.myStarEnotes.length;
            this.mailBox.allTotal = this.mailBox.allTotal + this.mailBox.starTotal;
            break;
          case 'track':
            this.mailBox.myTrackEnotes.push(JSON.parse(JSON.stringify(e)));
            this.mailBox.trackTotal = this.mailBox.myTrackEnotes.length;
            this.mailBox.allTotal = this.mailBox.allTotal + this.mailBox.trackTotal;
            break;
          case 'unlock':
            this.mailBox.myUnlockEnotes.push(JSON.parse(JSON.stringify(e)));
            this.mailBox.unlockTotal = this.mailBox.myUnlockEnotes.length;
            this.mailBox.allTotal = this.mailBox.allTotal + this.mailBox.unlockTotal;
            break;
          case 'wink':
            // debugger;
            this.mailBox.myWinkEnotes.push(JSON.parse(JSON.stringify(e)));
            this.mailBox.winkTotal = this.mailBox.myWinkEnotes.length;
            this.mailBox.allTotal = this.mailBox.allTotal + this.mailBox.winkTotal;
            break;
        }
      })
		}
    return mailBox;
  }
  
  // ---------------------------------------------------------------
  //  Currently in use
  // ---------------------------------------------------------------
  prepareEnoteForView (enote : CommunicationActivity) : Promise<CommunicationActivity> {
    return new Promise<CommunicationActivity>((resolve, reject) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote)) {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote.sender)) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote.sender.primaryImage)) {
            enote.sender.primaryImage = JsRegExpServiceStatic.normalizeImageData(enote.sender.primaryImage);
            // enote.sender.primaryImage = JsRegExpServiceStatic.setUrlForImageData(enote.sender.primaryImage);
          }
          else {
            enote.sender.primaryImage = 'url(\'/photos/DefaultImageMale.jpg\')';
          }
        }
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote.memberActivity)) {
          // debugger;
          this.kvGlyph = this.createActionGlyphicon(enote);
          // debugger;
          enote.kvGlyph = JSON.parse(JSON.stringify(this.kvGlyph));
        }
        // debugger;
        var tEnoteTile = JSON.parse(JSON.stringify(enote));
        this.dictionaryService.updateDictionary(tEnoteTile, 'myNotification', tEnoteTile.notificationActivityId);
        // debugger;
        resolve(tEnoteTile);
        // debugger;         
      }
      else {
        reject(null);
      }
    })
  }
  // ---------------------------------------------------------------
  //  Currently NOT in use
  // ---------------------------------------------------------------
  prepareEnotesForView (enotes : CommunicationActivity[]) : Observable<CommunicationActivity[]> {
    let communicatorService = this.communicatorService;
    var senderCount = 0;
    var tEnoteTile : any;
    var eNotes : CommunicationActivity[] = enotes;
    var tCommCator : Communicator = new Communicator();
    return new Observable<CommunicationActivity[]>((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNotes) && eNotes.length > 0) {     
        // debugger;
        for (var e of eNotes)
        {
          // temporary:
          // EmitterSubjectService.emitHideSpinner('');

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.sender)) {
            if (e.sender.sitUserId > 0) {
              // debugger;
              tCommCator.sitUserId = e.sender.sitUserId;
              tCommCator.communicationActivityId = e.notificationActivityId; // important!!
              communicatorService.getCommunicatorWithImage(tCommCator).subscribe(data => {
                // Note: update all enotes after getting senders' data:
                // ----------------------------------------------------
                var commcator = data;
                for (var s of eNotes) {
                   debugger;
                  if (s.notificationActivityId === commcator.communicationActivityId) {
                    s.sender = commcator;
                    senderCount++;
                    // debugger;
                    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(s.sender.primaryImage)) {
                       debugger;
                      s.sender.primaryImage = '/photos/DefaultImageMale.jpg';
                    }
                    tEnoteTile = JSON.parse(JSON.stringify(s));
                    tEnoteTile.communicationSubType = s.memberActivity; // backend holds communicationSubType as memberActivity
                    debugger;
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tEnoteTile.memberActivity)) {
                      // debugger;
                      this.kvGlyph = this.createActionGlyphicon(tEnoteTile);
                       debugger;
                      tEnoteTile.kvGlyph = this.kvGlyph;
                    }

                     debugger;
                   // EmitterSubjectService.emitEnoteTile(tEnoteTile); // important!

                    // this.myAllEnoteDictionary.set(tEnoteTile.communicationActivityId, tEnoteTile);
                    this.dictionaryService.updateDictionary(tEnoteTile, 'MyNotification', tEnoteTile.communicationActivityId);
                      debugger;
                    subscriber.next(this.dictionaryService.myNotificationDictionary.values());
                  }

                  if (enotes.length === senderCount) {
                    this.dbDexieToDictionaryService
                      .saveDictionaryToDbDexie(
                        this.dictionaryService.myNotificationDictionary, 'MyNotification', this.loginSuccess.signedInUserId);

                    // EmitterSubjectService.emitHideSpinner('');
                    // subscriber.complete();
                    // debugger;
                  }
                }
              })             
            }            
          }       
        }      
      }      
    })
  }
  // ---------------------------------------------------------------
  processCommunicatorResult (sitUserId : number, result : any) : Promise<any> {
    let date = new Date();
    let commCator : Communicator = new Communicator();

    return new Promise<any>((resolve, reject) => {

      if (result) {
         debugger;
        const bn = result;
        if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
          commCator = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as Communicator;
          debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commCator)) {
            EmitterSubjectService.emitCommunicator(commCator);
            resolve(commCator);
          }
          else {
            reject('Communicator data is null or empty');
          }
        }
        else {
          reject('encrypted Communicator data is null or empty');
        }
      }
      else {
        this.message = 'the result of enoteService.processCommunicatorResult (sitUserId:\'' + sitUserId + '\') the promise was rejected;\n<br/> result: ' + result;
        // EmitterSubjectService.emitMyErrorLog(this.message);
        reject(this.message);
      }
      return this.nullPromise();
    }).catch((error) => {
      console.log(date.getTime() + 'enoteService.processCommunicatorResult (sitUserId: ' + sitUserId + ') retrned a null promise.');
    })
  }
  // ---------------------------------------------------------------
  // Note:  this method should accumulate all enotes.
  //        Therefore, we use class-scoped this.mailBox.
  // ---------------------------------------------------------------
  //  Not in use
  // ---------------------------------------------------------------
  processEnoteResult (result : BoxNonceEntity ): Observable<any>
  {    
    // debugger;
    let date = new Date();
    let enote : CommunicationActivity = new CommunicationActivity();
    let unsalted : any;
    let senderSitUserId = 0;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
      return new Observable<CommunicationActivity>((subscriber) => {
        // debugger; 
        const bn = result as BoxNonceEntity;
        // debugger;
        if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
          unsalted = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn));
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
            let dateAssay : any;
            let senderSitUserId = 0;
            let receiverSitUserId = 0;
            // debugger;
            enote = unsalted;                                   
            // debugger;
            // Note: on enote.Date, sometimes there is only UNIX epoch time, so we use receiver.date since it's the latest timestamp of the communication
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote.date)) {
              dateAssay = DateStringServiceStatic.getDateAssayOfCsTicks(enote.date);
            }
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dateAssay)) {
              enote.dateFormatted = dateAssay.dateFormatted;
            }
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote.subject)) {
              enote.subjectShort = this.editNotificationSubject(enote.subject);
            }
            // ----------------
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote.messageBody)) {
              if (enote.messageBody.length >= 150) {
                enote.messageBodyShort = enote.messageBody.slice(0, 160) + '...';
              }
              else {
                enote.messageBodyShort = enote.messageBody;
              }
            }
            subscriber.next(enote);
            subscriber.complete();
          }
        }
      })
    }
    else {
      this.message = date.getTime() + 'provided result was null or undefined in  enoteService.processEnoteResult(sitUserId:\'' + this.loginSuccess.signedInUserId + '\');\n result: ' + result;
      // EmitterSubjectService.emitMyErrorLog( this.message );
      console.log(this.message);
    }    
  }
  // ---------------------------------------------------------------
  //  Currently in use
  // ---------------------------------------------------------------
  processMyNotificationResults (result : BoxNonceEntity) : Observable<any> {
    // debugger;
    let date = new Date();
    let enote : CommunicationActivity = new CommunicationActivity();
    let enoteArr : CommunicationActivity[] = [];
    let unsalted : any;
    let senderSitUserId = 0;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
      return new Observable<CommunicationActivity[]>((subscriber) => {
        // debugger; 
        const bn = result as BoxNonceEntity;
        // debugger;
        if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
          unsalted = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn));
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
            let dateAssay : any;
            let senderSitUserId = 0;
            let receiverSitUserId = 0;
            // debugger;
            enoteArr = unsalted as CommunicationActivity[];
            // debugger;
            // Note: on enote.Date, sometimes there is only UNIX epoch time, so we use receiver.date since it's the latest timestamp of the communication
            for (var e of enoteArr) {
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.date)) {
                dateAssay = DateStringServiceStatic.getDateAssayOfCsTicks(e.date);
              }
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dateAssay)) {
                e.dateFormatted = dateAssay.dateFormatted;
              }
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.subject)) {
                e.subjectShort = this.editNotificationSubject(e.subject);
              }
              // ----------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.messageBody)) {
                if (e.messageBody.length >= 150) {
                  e.messageBodyShort = e.messageBody.slice(0, 160) + '...';
                }
              }

              this.prepareEnoteForView(e).then(data => {
                e = data;
              })
            }
            // debugger;
            this.dbDexieToDictionaryService
              .saveDictionaryToDbDexie(
                this.dictionaryService.myNotificationDictionary, 'myNotification', this.loginSuccess.signedInUserId);
            subscriber.next(enoteArr);
            // subscriber.complete();
          }
        }
      })
    }
    else {
      this.message = date.getTime() + 'provided result was null or undefined in  enoteService.processEnoteResult(sitUserId:\'' + this.loginSuccess.signedInUserId + '\');\n result: ' + result;
      // EmitterSubjectService.emitMyErrorLog( this.message );
      console.log(this.message);
    }
  }
  // ---------------------------------------------------------------
  // Note: Tested and works! August 15, 2020.
  //       Currently in use
  // Note: this is intended to be deprecated. But varify it's necessity first.
  //       This feature is for premium members!!!
  // ---------------------------------------------------------------
  putInPremiumEnoteStorage (enote : CommunicationActivity, mailBox: MailBox) : Observable<EnoteBox> {
    var counter = 0;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mailBox)
      && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mailBox.enoteBox)
      || mailBox.enoteBox.allPremiumEnotes.length === 0) {
      mailBox.enoteBox = new EnoteBox();
      counter++;
      console.log('in putInPremiumEnoteStorage()->this.mailBox is initialized ' + counter + ' times.');
    }

    return new Observable<EnoteBox>((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote)) {
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        // debugger;
        if (DateStringServiceStatic.compareSitUserKeys(this.loginSuccess.signedInUserKey, enote.receiverSitUserKey) === 0) {
          // debugger;
          switch (enote.receiverAction.toString().toLowerCase()) {
            case 'received': case 'read': case 'madeunread':
              enote.boxName = 'myAllEnotesBox';
              // mailBox.enoteBox.inboxPremiumEnotes = this.sortEnoteBoxByDate(this.uniqueAddEnoteTile(mailBox.enoteBox.inboxPremiumEnotes, enote, mailBox.enoteBox.allPremiumEnotes));
              // OR
              // mailBox.enoteBox.inboxPremiumEnotes = this.sortEnoteBoxByDate(this.uniqueAddNotificationTile(mailBox.enoteBox.inboxPremiumEnotes, enote)); //
              // mailBox.enoteBox.inboxPremiumEnotes = this.sortEnoteBoxByDate(ArraySupportServiceStatic.uniquelyMergeKeepingLatestInCommunicationActivityArrays(mailBox.enoteBox.inboxPremiumEnotes, [enote]));
              // mailBox.enoteBox.allPremiumEnotes = ArraySupportServiceStatic.uniquelyMergeKeepingLatestInCommunicationActivityArrays(mailBox.enoteBox.allPremiumEnotes, mailBox.enoteBox.inboxPremiumEnotes);
              // debugger;
               mailBox.enoteBox.inboxPremiumEnotes.push(enote);//  = this.removeEarlierOrDuplicate(enote, mailBox.enoteBox.inboxPremiumEnotes); // ArraySupportServiceStatic.mergeArraysUniq(mailBox.enoteBox.inboxPremiumEnotes, [ enote ]);
              //mailBox.myAllEnotes = this.uniquelyMergeKeepingLatestInCommunicationActivityArrays(mailBox.myAllEnotes, mailBox.enoteBox.inboxPremiumEnotes);
              //mailBox.myAllEnotes = this.quickSortService.quick_sort(mailBox.myAllEnotes)
              break;
            case 'saved': case 'readandsaved':
              enote.boxName = 'savedBox';
              // debugger;
               mailBox.enoteBox.savedPremiumEnotes.push(enote); 
              break;
            case 'trashed': case 'readandtrashed':
              enote.boxName = 'trashedBox';
              mailBox.enoteBox.trashedPremiumEnotes.push(enote); 
              break;
          }
        }
        else if (DateStringServiceStatic.compareSitUserKeys(this.loginSuccess.signedInUserKey, enote.senderSitUserKey) === 0) {
          // debugger;
          switch (enote.senderAction.toString().toLowerCase()) {
            case 'sent': case 'replied':
              enote.boxName = 'sentBox';
               mailBox.enoteBox.sentPremiumEnotes.push(enote);
              break;
          }
        }

        subscriber.next(mailBox.enoteBox);
        subscriber.complete();
      }     
    });
  }

  // ---------------------------------------------------------------
  // TODO: Test
  //       Currently in use
  // ---------------------------------------------------------------
  putInEnoteStorageByCommunicationSubType (enote : CommunicationActivity, mailBox:MailBox) : Observable<MailBox> {
    var allNoteArrAndTypeNoteArr : any;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mailBox)
      || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mailBox.myAllEnotes)
      || mailBox.myAllEnotes.length === 0 ) {
      mailBox = new MailBox();
		}
    return new Observable<MailBox>((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote.communicationSubType)) {
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        // debugger;
        if (DateStringServiceStatic.compareSitUserKeys(this.loginSuccess.signedInUserKey, enote.senderSitUserKey) === 0
          || DateStringServiceStatic.compareSitUserKeys(this.loginSuccess.signedInUserKey, enote.receiverSITUserKey) === 0) {
          // debugger;

          // we will need to get two models returned (typeEnotes and myAllEnotes), 
          // hence we use this new model allNoteArrandTypeNoteArr that contians both.
          // -----------------------------------------------------------------------
          switch (enote.communicationSubType.toString().toLowerCase()) {
            case 'buddy':
              enote.boxName = 'Buddy';
              // allNoteArrAndTypeNoteArr = this.uniqueAddEnoteTile(mailBox.myBuddyEnotes, enote, mailBox.myAllEnotes);
              //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(allNoteArrAndTypeNoteArr)) {
              //  mailBox.myBuddyEnotes = allNoteArrAndTypeNoteArr.arr;
              //  mailBox.myAllEnotes = allNoteArrAndTypeNoteArr.allArr;
              //}
              // OR
              // mailBox.myBuddyEnotes = this.sortEnoteBoxByDate(this.uniqueAddNotificationTile(mailBox.myBuddyEnotes, enote)); //
              // mailBox.myAllEnotes = this.uniqueAddNotificationTile(mailBox.myAllEnotes, enote);
              // debugger;
              // mailBox.myBuddyEnotes = ArraySupportServiceStatic.uniquelyMergeKeepingLatestInCommunicationActivityArrays(mailBox.myBuddyEnotes, [ enote ]);
              // mailBox.myAllEnotes = ArraySupportServiceStatic.uniquelyMergeKeepingLatestInCommunicationActivityArrays(mailBox.myAllEnotes, mailBox.myBuddyEnotes);
              // 
              mailBox.myBuddyEnotes.push(enote); // = this.removeEarlierOrDuplicate(enote, mailBox.myBuddyEnotes); // ArraySupportServiceStatic.mergeArraysUniq(mailBox.myBuddyEnotes, [ enote ]);
              //mailBox.myAllEnotes = this.uniquelyMergeKeepingLatestInCommunicationActivityArrays(mailBox.myAllEnotes, mailBox.myBuddyEnotes);
              //debugger;
              //mailBox.myAllEnotes = this.quickSortService.quick_sort(mailBox.myAllEnotes);
              debugger;
              // debugger;
              break;
            case 'friend':
              enote.boxName = 'Friend';
              
              mailBox.myFriendEnotes.push(enote);
              debugger;
              break;
            case 'like':
              enote.boxName = 'Like';
              mailBox.myLikeEnotes.push(enote);
              debugger;
              break;
            case 'star':
              enote.boxName = 'Star';
              mailBox.myStarEnotes.push(enote); 
              break;
            case 'track':
              enote.boxName = 'Track';
              mailBox.myTrackEnotes.push(enote);
              break;
            case 'unlock':
              enote.boxName = 'Unlock';
              mailBox.myUnlockEnotes.push(enote); 
              break;
            case 'wink':
              enote.boxName = 'Wink';
              mailBox.myWinkEnotes.push(enote); 
              // debugger;
              break;
          }
          // debugger;
          subscriber.next(mailBox);
          subscriber.complete();
        }        
      }      
    });
  }
  // -----------------------------------------------------------
  removeEarlierOrDuplicate (enote : CommunicationActivity, allArr : CommunicationActivity[]) : CommunicationActivity[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(enote.communicationSubType)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(allArr) && allArr.length > 0) {
        for (let e of allArr) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.communicationSubType)) {
            if (e.communicationSubType.toLowerCase().indexOf(enote.communicationSubType.toLowerCase()) !== -1) {
              if (enote.receiverSitUserId == e.receiverSitUserId
                && enote.senderSitUserId == e.senderSitUserId) {
                e = CopyServiceStatic.copyCommunicationActivityIfExists(e, enote);
              }
            }
            else {
              allArr.push(enote);
              // debugger;
            }
          }
        }
      }
      else {
        allArr.push(enote);
      }
      return allArr;
    }
    else return null;
  }
  
  // ---------------------------------------------------------------
  salt (model : any ): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt( JSON.stringify( model ) );
      return this.boxNonceEntity;
    }
    else {
      return null;
    }
  }

  // ---------------------------------------------------------------
  sort( mailArr: CommunicationActivity[] ): CommunicationActivity[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mailArr) && mailArr.length > 0) {
      mailArr.sort( (a, b) => {
        return a.communicationActivityId - b.communicationActivityId;
      });
    }
    return mailArr;
  }
  // ---------------------------------------------------------------
  sortEnoteBoxByDate( mailArr: CommunicationActivity[] ): CommunicationActivity[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mailArr) && mailArr.length > 0) {
      mailArr.sort( (a, b) => {
        // return a.date.localeCompare(b.date);
        return Number(b.date) - Number(a.date);
      });
    }
    return mailArr;
  }
  // ---------------------------------------------------------------
  // Tested works, on 202107101600
  // ---------------------------------------------------------------
  uniqueAddEnoteTileInArrays( arr: CommunicationActivity[], eTile: CommunicationActivity, allArr: CommunicationActivity[] ): any {
    // debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( arr )
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( eTile )
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile.communicationType)
      && ( eTile.communicationType.toLowerCase().indexOf( 'notification' ) !== -1 )
      || eTile.communicationType.toLowerCase().indexOf( 'note' ) !== -1
    ){
      // debugger;
      if ( arr.length > 0 )
      {
        
        arr = arr.sort((a, b) => { return b.communicationActivityId - a.communicationActivityId; } );
        // debugger;
        let j = 0;
        for ( const e of arr )
        {
          if ( e.communicationSubType.toLowerCase().indexOf( eTile.communicationSubType.toLowerCase() ) !== -1 // same subType
            && e.senderSitUserId === eTile.senderSitUserId ) // same sender
          {
            // debugger;
            // Note:  (this.dateTick, that.dateTick): if that.DateTicks - this.dateTicks >= 0, then we add
            //        Date cane be in ticks(numbers) or formatted like yyyymmddhhmmssAMPM
            // Date:  Written on 20210709
            // -----------------------------------------------------------------------------------------------------------
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile.date)) {
              let diff = Number(eTile.date) - Number(e.date);

              if (diff > 0) {
                // debugger;
                arr.splice(j, 1, eTile);

                // Note: since allArray have a different length, hence index, we recompute just for the allArray here:
                // ---------------------------------------------------------------------------------------------------
                let aIndex = this.isEnoteTileFound(allArr, eTile);
                if (aIndex >= 0) {
                  allArr.splice(aIndex, 1, eTile);
                }
                else {
                  allArr.push(eTile);
                }

                break;
              }
              else {
                // do nothing => older/earlier that.dateTicks, so we discard it
              }
            }
          }
          else
          {
            // debugger;
            arr.push( eTile ); // add
            allArr.push( eTile );
            break;
          }
          j++;
        }
      }
       else // add the first item
       {
        // debugger;
        arr.push( eTile );
        allArr.push( eTile );
      }
      // debugger;
      return {
        arr: arr,
        allArr: allArr,
      }
    }
    else {
      return null;
    }
  }
  // ---------------------------------------------------------------
  uniqueAddEnoteTile (arr : CommunicationActivity[], eTile : CommunicationActivity) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile.communicationType)
      && (eTile.communicationType.toLowerCase().indexOf('notification') !== -1)
      || eTile.communicationType.toLowerCase().indexOf('note') !== -1
    ) {
      // debugger;
      if (arr.length > 0)
      {

        arr = arr.sort((a, b) => { return b.communicationActivityId - a.communicationActivityId; });
        // debugger;
        let j = 0;
        for (const e of arr)
        {
          if (e.communicationSubType.toLowerCase().indexOf(eTile.communicationSubType.toLowerCase()) !== -1 // same subType
            && e.senderSitUserId === eTile.senderSitUserId) // same sender
          {
            // debugger;
            // Note:  (this.dateTick, that.dateTick): if that.DateTicks - this.dateTicks >= 0, then we add
            //        Date cane be in ticks(numbers) or formatted like yyyymmddhhmmssAMPM
            // Date:  Written on 20210709
            // -----------------------------------------------------------------------------------------------------------
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile.date)) {
              let diff = Number(eTile.date) - Number(e.date);

              if (diff > 0) {
                // debugger;
                arr.splice(j, 1, eTile);
                break;
              }
              else {
                // do nothing => older/earlier that.dateTicks, so we discard it
              }
            }
            else {
              // debugger;
              arr.push(eTile); // add
              break;
            }            
          } 
          j++;
        }       
      }
      else {
        // debugger;
        arr.push(eTile); // add
      } 
    }    
    return arr;   
  }
  // ---------------------------------------------------------------

  public uniquelyMergeKeepingLatestInCommunicationActivityArrays (destArr : CommunicationActivity[], srcArr : CommunicationActivity[]) : CommunicationActivity[] {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destArr) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(srcArr)) {
      // debugger;
      if (destArr.length > 1) {
        // debugger;
        destArr.sort((a, b) => {
          var aDate = parseInt(a.date)
          var bDate = parseInt(b.date)
          return bDate - aDate;
        })
      }
      if (srcArr.length > 0) {

        var index = -1;
        srcArr.map(e => {
          if (destArr.length > 0) {
            index = this.isLatestEnoteTileFound(destArr, e);
          }

          // debugger;
          if (index === -1) {
            destArr.push(e);
            // debugger;
          }
          else {
            if (destArr[ index ].communicationSubType == e.communicationSubType && destArr[ index ].date <= e.date) {
              // destArr[ index ] = e;
              destArr.splice(index, 1, e);
              // debugger;
            }
          }
        })
      }
    }
    return destArr;
  }
  // ---------------------------------------------------------------
  uniqueAddNotificationTile( arr: CommunicationActivity[], eTile: CommunicationActivity ): CommunicationActivity[] {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile.communicationType)
      && (eTile.communicationType.toLowerCase().indexOf('notification') !== -1)
      || (eTile.communicationType.toLowerCase().indexOf('note') !== -1)) {
      const index = this.isEnoteTileFound(arr, eTile);
      if (index < 0) {
        arr.push(eTile);
      }
      else {
        arr[index] = CopyServiceStatic.copyCommunicationActivityIfExists(arr[index], eTile);
      }
      return arr;
    }
    else {
      return [];
    }
  }

  // ---------------------------------------------------------------
  updateNotificationReceiverAction ( eNote : CommunicationActivity ) : Promise<any>
  {
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess ) || this.loginSuccess.signedInUserId === 0 )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    }
    // debugger;
    return new Promise( ( resolve, reject ) =>
    {
      if ( this.loginSuccess.signedInUserId > 0 )
      {
        eNote.signedInUserId = this.loginSuccess.signedInUserId;
        this.boxNonceEntity = this.salt( eNote );
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity )
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity.box )
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity.nonce ) )
        {
          this.httpService.postObservable( 'api/Enote/UpdateNotificationReceiverAction', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box ),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce ),
          }, 'json2text').subscribe( result =>
          {
            // debugger;
            this.processEnoteResult(result).subscribe(data => {

              let tEnote = data as CommunicationActivity;

              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tEnote)) {
                // debugger;
                resolve(tEnote);
              }
              else {
                this.message = 'enoteService. updateNotificationReceiverAction(eNote: ' + this.loginSuccess.signedInUserId + ') promise returned a null or undefined result;';
                reject(this.message);
              }
            });
          })
        }
        else {
          this.message = 'emailService. updateNotificationReceiverAction(eNote: ' + this.loginSuccess.signedInUserId + ') promise is rejected;';
          reject(this.message);
        }
      }
    } );
  }
  // ---------------------------------------------------------------
}
