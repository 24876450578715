
// //import { Injectable, OnDestroy, OnInit } from '@angular/core';
// //import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
// import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Subject } from 'rxjs';
// import * as Collections from 'typescript-collections';
import { Dictionary } from "dictionaryjs"; // ref: https://github.com/phanxgames/dictionaryjs
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Chat } from '../../models/chat/chat.model';
import { Heartbeat } from '../../models/common/heartbeat.model';
import { LogError } from '../../models/common/logError.model';
import { Photo } from '../../models/common/photo.model';
import { SpinnerModel } from '../../models/common/spinnerModel.model';
import { CommunicationActivity } from '../../models/communication/communicationActivity.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { MemberActivity } from '../../models/member/memberActivity.model';
import { Preference } from '../../models/profile/preference.model';
import { ProfileInfo } from '../../models/profile/profileInfo.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
// import { IndexedDbService } from '../dictionaryServiceService/indexedDbService.service';
import { ChatActivity } from '../../models/chat/chatActivity.model';
import { BinarySearchService } from '../searchSortServiceService/binarySearchService.service';
import { ArrayServiceStatic } from '../staticServices/arrayServiceStatic.service';
import { ArraySupportServiceStatic } from '../staticServices/arraySupportServiceStatic.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Content } from '../../models/common/content.model';
import { Profile } from '../../models/profile/profile.model';
import { Person } from '../../models/common/person';
//import { ArrayServiceStatic } from './arrayServiceStatic.service';
//import { ArraySupportServiceStatic } from './arraySupportServiceStatic.service';
//import { CopyServiceStatic } from './commonStaticServices/copyServiceStatic.service';
//import { SlakezSaltServiceStatic } from './commonStaticServices/slakezSaltServiceStatic.service';
//import { FrequentlyUsedFunctionsServiceStatic } from './frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
//import { StringServiceStatic } from './stringServiceStatic.service';





@Injectable({
  providedIn: 'root',
})
/*
 * ref:https:// github.com/basarat/typescript-collections/blob/release/README.md#a-sample-on-dictionary
 * TODO: This service should read data from the indexedDB and save it to indexedDb periodically.
 */
export class DictionaryService implements OnDestroy {
  public allDictionaries = new Dictionary<string, Dictionary<number | string | any, any>>();
  public allDictionaryDataCount = new Dictionary<string, number>();
  public binarySearchService : BinarySearchService = new BinarySearchService();

  public buddyDictionary = new Dictionary<number, CommunicationActivity> ();

  public chatConversationDictionary = new Dictionary<number, ChatActivity[]>();
  public chatConversationIdDictionary = new Dictionary<number, number>(); // ConversationId == sitUserId
  public count : number = 0;
  public chatActivityDictionary = new Dictionary<number, ChatActivity>(); // to hold all chatActivities; can be used to create a conversation if chatAccivityIds are present
  // public chatActivityIdDictionary = new Dictionary<number, number[]>(); 

  public dexieTableDictionary : any;

  public emailTileDictionary = new Dictionary<number, CommunicationActivity>(); // DO NOT save Picture in Email
  public emailTileIdDictionary = new Dictionary<number, number[]>();
  public enoteTileDictionary = new Dictionary<number, CommunicationActivity>();
  public enoteTileIdDictionary = new Dictionary<number, number[]>();
  public errorLogDictionary = new Dictionary<number, any>();
  public heartbeatDictionary = new Dictionary<number, Heartbeat>();
  public idbStoreIndexDictionary = new Dictionary<string, any>();
  public idbStoreTransactionDictionary = new Dictionary<string, any>();
  public kvPhotoDictionary = new Dictionary<number, KvPhoto>();
  public loginSuccessDictionary = new Dictionary<number, LoginSuccess>();


  public masterErrorLogMap = new Map(); // key==JsDate.Ticks
  // ================================================================================
  // MVC (Model-View-Controller) Dictionaries:
  // -----------------------------------------
  
  public componentDictionary = new Dictionary<string, any>();
  public filteredKvPhotoDictionary = new Dictionary<number, KvPhoto>();
  public serviceDictionary = new Dictionary<string, any>();
  public moduleDictionary = new Dictionary<string, any>();  
  public scssClassDictionary = new Dictionary<string, any>();
  public scssFileDictionary = new Dictionary<string, any>();
  public scssIdDictionary = new Dictionary<string, any>();  
  public modelDictionary = new Dictionary<string, Dictionary<number | string | any, any>>();

  public unFilteredKvPhotoDictionary = new Dictionary<number, KvPhoto>();
  public viewDictionary = new Dictionary<string, Dictionary<number | string | any, any>>();
  public controllerDictionary = new Dictionary<string, Dictionary<number | string | any, any>>();


  // communication dictionaries:
  public myChatIdDictionary = new Dictionary<number, number[]>();
  public myChatDictionary = new Dictionary<number, ChatActivity>();
  public myEmailIdDictionary = new Dictionary<number, number[]>();
  public myEmailDictionary = new Dictionary<number, CommunicationActivity>();
  public myNotificationIdDictionary = new Dictionary<number, number[]>();
  public myNotificationDictionary = new Dictionary<number, CommunicationActivity>();
  public myOutboxChatIdDictionary = new Dictionary<number, number[]>();
  public myOutboxChatDictionary = new Dictionary<number, Chat>();

  public myErrorLogMap = new Map(); // key==JsDate.Ticks

  // first group of memberActivities: Block/Friend/Like: //for indexedDb
  // public myMemberActivityDictionary = new Dictionary<number, MemberActivity>();
  // public myBlockDictionary = new Dictionary<number, MemberActivity>();
  // public myFriendDictionary = new Dictionary<number, MemberActivity>();
  // public myLikeDictionary = new Dictionary<number, MemberActivity>();

  public myMemberActivityDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  public myMemberTrackingActivityDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  public myMemberLockingActivityDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  public myBlockDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  public myFriendDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  public myLikeDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  public myStarDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  public myTrackDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  public myUnlockDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  public myWinkDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  

  // second group of memberStarTrackWinkActivities: Star/Track/Wink: //for indexedDb
  // public myMemberStarTrackWinkActivityDictionary = new Dictionary<number, MemberActivity>();
  // public myStarDictionary = new Dictionary<number, MemberActivity>();
  // public myTrackDictionary = new Dictionary<number, MemberActivity>();
  // public myWinkDictionary = new Dictionary<number, MemberActivity>();

  // third group of memberLockingActivities: Unlock: //for indexedDb
  // public myMemberLockingActivityDictionary = new Dictionary<number, MemberActivity>();
  // public myUnlockDictionary = new Dictionary<number, MemberActivity>();

  // Note: These dictionaries has a matching counterparts in MemberActivitiesService where used to populate these dictionaries:
  // --------------------------------------------------------------------------------------------------------------------------
  public myProfilePicsDictionary = new Dictionary<number, ProfilePics>();

  public personDictionary = new Dictionary<number, Person>();
  public photoDictionary = new Dictionary<number, Photo>();
  public preferenceDictionary = new Dictionary<number, Preference>();
  public profileDictionary = new Dictionary<string, Profile>(); 
  public profileContentDictionary = new Dictionary<number, Content>();
  public profileInfoDictionary = new Dictionary<number, ProfileInfo>();
  public profilePicsDictionary = new Dictionary<number, ProfilePics>();
  public profileTileIdDictionary = new Dictionary<number, number[]>();
  public profileTileDictionary = new Dictionary<number, ProfileTile>();
  public sitUserDictionary = new Dictionary<number, SitUser>();

  // SitUserIds are used to create ProfileTiles at MembersIndividually
  public sitUserIdDictionary = new Dictionary<number, number[]>();
  public profileTileDexieDictionary = new Dictionary<number, ProfileTile>(); // for testing purpose onloy. delete before deployment
  public storeNameDictionary = new Dictionary<number, string>();
  
  // Note: These dictionaries has a matching counterparts in MemberActivitiesService where used to populate these dictionaries:
  // --------------------------------------------------------------------------------------------------------------------------

  // first group of memberActivities: Block/Friend/Like: //for indexedDb
  public yourMemberActivityDictionary = new Dictionary<number, CommunicationActivity>();
  public yourBlockDictionary = new Dictionary<number, CommunicationActivity>();
  public yourFriendDictionary = new Dictionary<number, CommunicationActivity>();
  public yourLikeDictionary = new Dictionary<number, CommunicationActivity>();

  // second group of memberStarTrackWinkActivities: Star/Track/Wink: //for indexedDb
  public yourMemberTrackingActivityDictionary = new Dictionary<number, CommunicationActivity>();
  public yourStarDictionary = new Dictionary<number, CommunicationActivity>();
  public yourTrackDictionary = new Dictionary<number, CommunicationActivity>();
  public yourWinkDictionary = new Dictionary<number, CommunicationActivity>();


  // third group of memberLockingActivities: Unlock: //for indexedDb
  public yourMemberLockingActivityDictionary = new Dictionary<number, CommunicationActivity>();
  public yourUnlockDictionary = new Dictionary<number, CommunicationActivity>();

  // --------------------------------------------------------------------------------
  // Note:  Bn dictionaries are used to hold encrypted data that when complete for a certain user, will be saved in indexedDb.
  //        When indexedDb data is read, it will be unencrypted and stored in respected regular-unencrypted-dictionary (non-Bn-dictionary).
  // -------------------------------------------------------------------------------
  public preferenceBnDictionary = new Dictionary<number, BoxNonceEntity>(); // key=sitUserId, value=Preference
  public profileInfoBnDictionary = new Dictionary<number, BoxNonceEntity>(); // key=sitUserId, value=ProfileInfo
  // public photoBnArrDictionary = new Dictionary<number, BoxNonceEntity[]>(); // key=sitUserId, value=BoxNonceEntity[]


  public profileTileIdBnDictionary = new Dictionary<number, BoxNonceEntity>(); // key=sitUserId, value=ProfileTileId[]
  public profileTileBnDictionary = new Dictionary<number, BoxNonceEntity>(); // key=sitUserId, value=ProfileTile
  // public sitUserBnDictionary = new Dictionary<number, BoxNonceEntity>(); // key=sitUserId, value=SitUser

  // public photoBnIdDictionary = new Dictionary<number, number[]>(); // key=sitUserId, value=PhotoBnIds[]
  public photoBnDictionary = new Dictionary<number, BoxNonceEntity>(); // key=sitUserId, value=BoxNonceEntity[]
  // public photoDictionary = new Dictionary<number, Photo>(); // key=photoId, value=Photo 
  public userPhotoBnIdDictionary = new Dictionary<number, number[]>(); // key=sitUserId, value=PhotoBnId[]
  // public photoBnDictionary = new Dictionary<number, PhotoBn>(); // key=photoBnId, value=PhotoBn
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public sitUser : SitUser = new SitUser();

  // public pageNumKvPhotoDictionary = new Dictionary<number, KvPhoto[]>(); // key=pageNum, value=KvPhoto[]
  public spinnerTimerDictionary = new Dictionary<number, SpinnerModel>(); // key=sitUserId, value=PhotoBn
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  // --------------------------------------------------------------------------------
  
  // ================================================================================
  // All components' dictonaries:
  public myAllEnoteDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  // --------------------------------------------------------------------------------
  constructor () {
      // this.inInitialize();
     this.kvPhotoDictionary = new Dictionary<number, KvPhoto>();

    // this.allDictionaries = new Dictionary<string, Dictionary<number | string | any, any>>(); // = new Dictionary<string, Dictionary<number | string | any, any>>();
    // this.allDictionaryDataCount = new Dictionary<string, number>();
    this.storeNameDictionary = new Dictionary<number, string>();
    this.populateStoreNameDictionary();
    this.populateAllDictionaries();
  }
  // --------------------------------------------------------------------------------

  public populateAllDictionaries () : void {
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.storeNameDictionary) || this.storeNameDictionary.size === 0) {
      this.populateStoreNameDictionary();
      // debugger;
    }
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.allDictionaries) || this.allDictionaries.size === 0) {
      this.allDictionaries = new Dictionary<string, Dictionary<number | string | any, any>>();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.allDictionaries)) {
      this.storeNameDictionary?.values()?.map((k : any) => {
        // debugger;

        // TODO: add each dictionary to one of the three dictionaries: i) modelDictionary ii) viewDictionary iii) controllerDictionary
        //        i.e categorize each doctionary to the appropriate one:  i) modelDictionary ii) viewDictionary iii) controllerDictionary
        switch (k.toLowerCase()) {
          case 'buddy':
            // this.buddyDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('buddyDictionary', this.buddyDictionary);
            break;
          case 'chatactivity':
            // this.chatHistoryDictionary = new Dictionary<number, Chat>(); // DO NOT save Picture in Chat
            this.allDictionaries.set('chatActivityDictionary', this.chatActivityDictionary);
            break;
          case 'chatconversationid':
            // this.chatHistoryIdDictionary = new Dictionary<number, number>(); // DO NOT save Picture in Chat
            this.allDictionaries.set('chatConversationIdDictionary', this.chatConversationIdDictionary);
            break;
          case 'chatconversation':
            // this.chatHistoryDictionary = new Dictionary<number, Chat>(); // DO NOT save Picture in Chat
            this.allDictionaries.set('chatConversationDictionary', this.chatConversationDictionary);
            break;
          case 'dexietable':
            this.dexieTableDictionary = new Dictionary<string, Dictionary<any, any>>(); // key=storeName, value=objecStore === dexieTable // (indexedDb.objectStore)
            this.allDictionaries.set('dexieTableDictionary', this.dexieTableDictionary);
            break;
          case 'emailtileid':
            // this.eMailTileIdDictionary = new Dictionary<number, number[]>();
            this.allDictionaries.set('emailTileIdDictionary', this.emailTileIdDictionary);
            break;
          case 'emailtile':
            // this.eMailTileDictionary = new Dictionary<number, CommunicationActivity>();
            this.allDictionaries.set('emailTileDictionary', this.emailTileDictionary);
            break;
          case 'enotetileid':
            // this.eNoteTileIdDictionary = new Dictionary<number, number[]>();
            this.allDictionaries.set('enoteTileIdDictionary', this.enoteTileIdDictionary);
            break;
          case 'enotetile':
            // this.eNoteTileDictionary = new Dictionary<number, CommunicationActivity>(); // DO NOT save Picture in Notification
            this.allDictionaries.set('enoteTileDictionary', this.enoteTileDictionary);
            break;
          case 'errorlog':
            // this.errorLogDictionary = new Dictionary<number, any>();
            this.allDictionaries.set('errorLogDictionary', this.errorLogDictionary);
            break;
          case 'heartbeat':
            // this.heartbeatDictionary = new Dictionary<number, Heartbeat>();
            this.allDictionaries.set('heartbeatDictionary', this.heartbeatDictionary);
            break;
          case 'idbstoreindex':
            // this.idbStoreIndexDictionary = new Dictionary<string, any>(); // key=storeName, value=objectStore // (indexedDb.objectStore)
            this.allDictionaries.set('idbStoreIndexDictionary', this.idbStoreIndexDictionary);
            break;
          case 'idbstoretransaction':
            // this.idbStoreTransactionDictionary = new Dictionary<string, any>(); // key=storeName, value=objectStoreTransaction
            this.allDictionaries.set('idbStoreTransactionDictionary', this.idbStoreTransactionDictionary);
            break;
          case 'kvphoto':
           //  this.kvPhotoDictionary = new Dictionary<number, KvPhoto>();
            this.allDictionaries.set('kvPhotoDictionary', this.kvPhotoDictionary);
            break;
          case 'loginsuccess':
            // this.loginSuccessDictionary = new Dictionary<number, LoginSuccess>();
            this.allDictionaries.set('loginSuccessDictionary', this.loginSuccessDictionary);
            break;
          case 'myblock':
            // this.myBlockDictionary = new Dictionary<number, MemberActivity>(); // for view
            // this.allDictionaries.set('myBlockDictionary', this.myBlockDictionary);
            break;
          case 'mychatid':
            // this.myChatIdDictionary = new Dictionary<number, number[]>();
            this.allDictionaries.set('myChatIdDictionary', this.myChatIdDictionary);
            break;
          case 'mychat':
            // this.myChatDictionary = new Dictionary<number, Chat>();
            this.allDictionaries.set('myChatDictionary', this.myChatDictionary);
            break;
          case 'myoutboxchatid':
            // this.myChatIdDictionary = new Dictionary<number, number[]>();
            this.allDictionaries.set('myOutboxChatIdDictionary', this.myOutboxChatIdDictionary);
            break;
          case 'myoutboxchat':
            // this.myChatDictionary = new Dictionary<number, Chat>();
            this.allDictionaries.set('myOutboxChatDictionary', this.myOutboxChatDictionary);
            break;
          case 'myemailid':
            // this.myEmailIdDictionary = new Dictionary<number, number[]>();
            this.allDictionaries.set('myEmailIdDictionary', this.myEmailIdDictionary);
            break;
          case 'myemail':
            // this.myEmailDictionary = new Dictionary<number, CommunicationActivity>();
            this.allDictionaries.set('myEmailDictionary', this.myEmailDictionary);
            break;
          case 'myfriend':
            // this.myFriendDictionary = new Dictionary<number, MemberActivity>(); // for view
            // this.allDictionaries.set('myFriendDictionary', this.myFriendDictionary);
            break;
          case 'mylike':
            // this.myLikeDictionary = new Dictionary<number, MemberActivity>(); // for view
            // this.allDictionaries.set('myLikeDictionary', this.myLikeDictionary);
            break;
          case 'mymemberactivity':
            // this.myMemberActivityDictionary = new Dictionary<number, MemberActivity>(); // key==(action-name).(member-activity-id) //for indexedDb
            // this.allDictionaries.set('myMemberActivityDictionary', this.myMemberActivityDictionary);
            break;
          case 'mymemberlockingactivity':
            // this.myMemberLockingActivityDictionary = new Dictionary<number, MemberActivity>();
            // this.allDictionaries.set('myMemberLockingActivityDictionary', this.myMemberLockingActivityDictionary);
            break;
          case 'mymemberstartrackwinkactivity':
            // this.myMemberStarTrackWinkActivityDictionary = new Dictionary<number, MemberActivity>();
            // this.allDictionaries.set('myMemberStarTrackWinkActivityDictionary', this.myMemberStarTrackWinkActivityDictionary);
            break;
          case 'mynotificationid':
            // this.myNotificationIdDictionary = new Dictionary<number, number[]>();
            this.allDictionaries.set('myNotificationIdDictionary', this.myNotificationIdDictionary);
            break;
          case 'mynotification':
            // this.myNotificationDictionary = new Dictionary<number, CommunicationActivity>();
            this.allDictionaries.set('myNotificationDictionary', this.myNotificationDictionary);
            break;
          case 'mystar':
            // this.myStarDictionary = new Dictionary<number, MemberActivity>(); // for view
            // this.allDictionaries.set('myStarDictionary', this.myStarDictionary);
            break;
          case 'mytrack':
            // this.myTrackDictionary = new Dictionary<number, MemberActivity>(); // for view
            // this.allDictionaries.set('myTrackDictionary', this.myTrackDictionary);
            break;
          case 'myunlock':
            // this.myUnlockDictionary = new Dictionary<number, MemberActivity>(); // for view
            // this.allDictionaries.set('myUnlockDictionary', this.myUnlockDictionary);
            break;
          case 'mywink':
            // this.myWinkDictionary = new Dictionary<number, MemberActivity>(); // for view
            // this.allDictionaries.set('myWinkDictionary', this.myWinkDictionary);
            break;
          case 'photo':
            // this.photoDictionary = new Dictionary<number, Photo>(); // key=photoId, value=Photo;
            this.allDictionaries.set('photoDictionary', this.photoDictionary);
            break;
          case 'photobn':
            // this.photoBnDictionary = new Dictionary<number, PhotoBn>(); // key=photoId, value=Photo;
            this.allDictionaries.set('photoBnDictionary', this.photoBnDictionary);
            break;
          case 'photobnid':
            this.userPhotoBnIdDictionary = new Dictionary<number, number[]>(); // key=photoId, value=Photo;
            this.allDictionaries.set('userPhotoBnIdDictionary', this.userPhotoBnIdDictionary);
            break;
          case 'preference':
            // this.preferenceDictionary = new Dictionary<number, Preference>(); // key=sitUserId, value=Preference
            this.allDictionaries.set('preferenceDictionary', this.preferenceDictionary);
            break;
          case 'profileinfo':
            // this.profileInfoDictionary = new Dictionary<number, ProfileInfo>(); // key=sitUserId, value=ProfileInfo
            this.allDictionaries.set('profileInfoDictionary', this.profileInfoDictionary);
            break;
          case 'profilecontent':
            // this.profileContentDictionary = new Dictionary<number, Content>(); // key=sitUserId, value=Content
            this.allDictionaries.set('profileContentDictionary', this.profileContentDictionary);
            break;
          case 'profilepics':
            // this.profilePicsDictionary = new Dictionary<number, ProfilePics>(); // key=sitUserId, value=Preference
            this.allDictionaries.set('profilePicsDictionary', this.profilePicsDictionary);
            break;
          case 'profiletile':
            // this.profileTileDictionary = new Dictionary<number, ProfileTile>(); // key=sitUserId, value=Preference
            this.allDictionaries.set('profileTileDictionary', this.profileTileDictionary);
            break;
          case 'situserid':
            // this.sitUserIdDictionary = new Dictionary<number, number[]>(); // key=sitUserId, value=sitUserId
            this.allDictionaries.set('sitUserIdDictionary', this.sitUserIdDictionary);
            break;
          case 'situser':
            // this.sitUserDictionary = new Dictionary<number, SitUser>(); // key=sitUserId, value=sitUserId
            this.allDictionaries.set('sitUserDictionary', this.sitUserDictionary);
            break;
          case 'yourblock':
            // this.yourBlockDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourBlockDictionary', this.yourBlockDictionary);
            break;
          case 'yourfriend':
            // this.yourFriendDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourFriendDictionary', this.yourFriendDictionary);
            break;
          case 'yourlike':
            // this.yourLikeDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourLikeDictionary', this.yourLikeDictionary);
            break;
          case 'yourmemberactivity':
            // this.yourMemberActivityDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourMemberActivityDictionary', this.yourMemberActivityDictionary);
            break;
          case 'yourmemberlockingactivity':
            // this.yourMemberLockingActivityDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourMemberLockingActivityDictionary', this.yourMemberLockingActivityDictionary);
            break;
          case 'yourmembertrackingactivity':
            // this.yourMemberStarTrackWinkActivityDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourMemberTrackingActivityDictionary', this.yourMemberTrackingActivityDictionary);
            break;
          case 'yourstar':
            // this.yourStarDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourStarDictionary', this.yourStarDictionary);
            break;
          case 'yourtrack':
            // this.yourTrackDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourTrackDictionary', this.yourTrackDictionary);
            break;
          case 'yourunlock':
            // this.yourUnlockDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourUnlockDictionary', this.yourUnlockDictionary);
            break;
          case 'yourwink':
            // this.yourWinkDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourWinkDictionary', this.yourWinkDictionary);
            break;
        }
        // debugger;
      })
      // debugger;
    }
  }
  // ---------------------------------------------------------------------------------
  public populateStoreNameDictionary () {
    // debugger;
    let i = 0;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.storeNameDictionary) || this.storeNameDictionary.size === 0) {
      this.storeNameDictionary = new Dictionary<number, string>();
    }
    this.storeNameDictionary.set(i++, "buddy");
    this.storeNameDictionary.set(i++, "chatActivity");
    this.storeNameDictionary.set(i++, "chatConversation");
    this.storeNameDictionary.set(i++, "chatConversationId");
    this.storeNameDictionary.set(i++, "dexieTable");
    this.storeNameDictionary.set(i++, "emailTile");
    this.storeNameDictionary.set(i++, "emailTileId");
    this.storeNameDictionary.set(i++, "enoteTile");
    this.storeNameDictionary.set(i++, "enoteTileId");
    this.storeNameDictionary.set(i++, "errorLog");
    this.storeNameDictionary.set(i++, "heartbeat");
    this.storeNameDictionary.set(i++, "idbStoreIndex");
    this.storeNameDictionary.set(i++, "idbStoreTransaction");
    this.storeNameDictionary.set(i++, "kvPhoto");
    this.storeNameDictionary.set(i++, "loginSuccess");

    this.storeNameDictionary.set(i++, "myBlock");
    this.storeNameDictionary.set(i++, "myChat");
    this.storeNameDictionary.set(i++, "myChatId");
    this.storeNameDictionary.set(i++, "myOutboxChat");
    this.storeNameDictionary.set(i++, "myOutboxChatId");
    this.storeNameDictionary.set(i++, "myEmail");
    this.storeNameDictionary.set(i++, "myEmailId");
    this.storeNameDictionary.set(i++, "myFriend");
    this.storeNameDictionary.set(i++, "myLike");
    this.storeNameDictionary.set(i++, "myMemberActivity");
    this.storeNameDictionary.set(i++, "myMemberLockingActivity");
    this.storeNameDictionary.set(i++, "myMemberStarTrackWinkActivity");
    this.storeNameDictionary.set(i++, "myNotification");
    this.storeNameDictionary.set(i++, "myNotificationId");
    this.storeNameDictionary.set(i++, "myStar");
    this.storeNameDictionary.set(i++, "myTrack");
    this.storeNameDictionary.set(i++, "myUnlock");
    this.storeNameDictionary.set(i++, "myWink");
    
    this.storeNameDictionary.set(i++, "photo");
    this.storeNameDictionary.set(i++, "photoBn");
    this.storeNameDictionary.set(i++, "preference");
    this.storeNameDictionary.set(i++, "profileInfo");
    this.storeNameDictionary.set(i++, "profileContent");
    this.storeNameDictionary.set(i++, "profilePics");
    this.storeNameDictionary.set(i++, "profileTile");

    this.storeNameDictionary.set(i++, "sitUser");
    this.storeNameDictionary.set(i++, "sitUserId");

    this.storeNameDictionary.set(i++, "yourBlock");
    this.storeNameDictionary.set(i++, "yourFriend");
    this.storeNameDictionary.set(i++, "yourLike");
    this.storeNameDictionary.set(i++, "yourMemberActivity");
    this.storeNameDictionary.set(i++, "yourMemberLockingActivity");
    this.storeNameDictionary.set(i++, "yourMemberStarTrackWinkActivity");
    this.storeNameDictionary.set(i++, "yourStar");
    this.storeNameDictionary.set(i++, "yourTrack");
    this.storeNameDictionary.set(i++, "yourUnlock");
    this.storeNameDictionary.set(i++, "yourWink");
  }
  // ---------------------------------------------------------------
  //  Note: this method gets only unFilteredKvPhotoArray of ProfilePics.
  // ---------------------------------------------------------------
  public getProfilePicsFromKvPhotoDictionary (sitUserId : number) : ProfilePics | any {
    let outKvPhotoArr : KvPhoto[] = [];
    let profilePics = new ProfilePics();
    if (sitUserId > 0) {
      profilePics.unFilteredKvPhotoArr = this.getUserKvPhotoArrFromKvPhotoDictionary(sitUserId);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.unFilteredKvPhotoArr) && profilePics.unFilteredKvPhotoArr.length > 0) {
        // debugger;
        profilePics.unFilteredKvPhotoArr.forEach(e => {
          if (e) {
            profilePics.unFilteredKvPhotoDictionary.set(e.photoBnId, e);
          }
        });

        // Note: the calling function should filter the KvPhotos:
        // ------------------------------------------------------
        //this.photoPrivacyService.filterKvPhotoArr(profilePics.unFilteredKvPhotoArr).then(result => {
        //  profilePics.filteredKvPhotoArr = result;
        //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.filteredKvPhotoArr) && profilePics.filteredKvPhotoArr.length > 0) {
        //    // debugger;
        //    profilePics.filteredKvPhotoArr.forEach(e => {
        //      if (e) {
        //        profilePics.filteredKvPhotoDictionary.set(e.photoBnId, e);
        //      }
        //    })
        //  }
        //});
      }
      return profilePics;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  public getUserKvPhotoArrFromKvPhotoDictionary (sitUserId : number) : KvPhoto[] | any {
    let outKvPhotoArr : KvPhoto[] = [];
    if (sitUserId > 0 && this.kvPhotoDictionary.size > 0) {
      this.kvPhotoDictionary.values().forEach((e : any) => {
        if (e.sitUserId === sitUserId) {
          outKvPhotoArr.push(e);
        }
      })
    }
    return outKvPhotoArr;
  }
  // ---------------------------------------------------------------
  //  Note: this method gets a profileTile from the dictionary by
  //        a non-key propertiy==sitUserId where the key=profileTileId
  //
  //        This is a quentasential example of how to get a dictionary's
  //        value by a non-key-property of the model.
  // ---------------------------------------------------------------
  public getProfileTileBySitUserId (sitUserId : number) : any {
    if (sitUserId > 0 && this.profileTileDictionary.size > 0 ) {
      let pTileArr = this.profileTileDictionary.values();
      pTileArr = pTileArr.sort((a : any, b : any) => a.sitUserId - b.sitUserId);
      // debugger;
      let index = this.binarySearchService.nonRecursiveBinarySearchProfileTile(pTileArr, sitUserId);
      let tpTile = pTileArr[ index ];
      // debugger;
      return tpTile;
    }
    else return null;
  }

  // --------------------------------------------------------------------------------
  public getMasterErrorLogMap () : Map<number, LogError> {
    // debugger;
    return this.masterErrorLogMap;
  }
  public setMasterErrorLogMap (bDic : Map<number, LogError>) : void {
    this.masterErrorLogMap = bDic;
  }
  public resetMasterErrorLogMap () : void {
    this.masterErrorLogMap = new Map<number, LogError>();
  }
  // ---------------------------------------------------------------
  public getMyErrorLogMap () : Map<number, LogError> {
    // debugger;
    return this.myErrorLogMap;
  }
  public setMyErrorLogMap (bDic : Map<number, LogError>) : void {
    this.myErrorLogMap = bDic;
  }
  public resetMyErrorLogMap () : void {
    this.myErrorLogMap = new Map<number, LogError>();
  }
  // -------------------------------------------------------------------------------------
  public getDictionaryValues (dictionaryName : string) : any | void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionaryName)) {
      // debugger;
      switch (dictionaryName.toLowerCase()) {

        // Note: This does not have a store in indexedDb:
        // ----------------------------------------------
        case 'buddy':
          return this.buddyDictionary.values();
        // -------------------------------------------------------------------------
        case 'chatactivity':
          // debugger;
          return this.chatActivityDictionary.values();
        // -------------------------------------------------------------------------       
        case 'chatconversationid':
          return this.chatConversationIdDictionary.values();
        // -------------------------------------------------------------------------
        case 'chatconversation':
          // debugger;
          return this.chatConversationDictionary.values();
        // -------------------------------------------------------------------------
        case 'emailtileid':
          return this.emailTileIdDictionary.values();
        // -------------------------------------------------------------------------
        case 'emailtile':
          return this.emailTileDictionary.values();
        
        // -------------------------------------------------------------------------
        case 'enotetileid':
          return this.enoteTileIdDictionary.values();
        // -------------------------------------------------------------------------
        case 'enotetile':
          return this.enoteTileDictionary.values();
        // -------------------------------------------------------------------------
        case 'errorlog':
          return this.errorLogDictionary.values();
        // ----------------------------------------------
        case 'kvphoto':
          return this.kvPhotoDictionary.values();
        // ----------------------------------------------
        case 'loginsuccess':
          return this.loginSuccessDictionary.values();
        // -------------------------------------------------------------------------
        case 'myblock':
          return this.myBlockDictionary.values();
        // -------------------------------------------------------------------------
        case 'yourblock':
          return this.yourBlockDictionary.values();
        // -------------------------------------------------------------------------
        case 'myfriend':
          return this.myFriendDictionary.values();
        // -------------------------------------------------------------------------
        case 'yourfriend':
          return this.yourFriendDictionary.values();
        // -------------------------------------------------------------------------
        case 'mylike':
          debugger;
          return this.myLikeDictionary?.values();
        // -------------------------------------------------------------------------
        case 'yourlike':
          return this.yourLikeDictionary.values();
        // -------------------------------------------------------------------------
        case 'mystar':
          return this.myStarDictionary.values();
          // -------------------------------------------------------------------------
        case 'yourstar':
          return this.yourStarDictionary.values();
        // -------------------------------------------------------------------------
        case 'mytrack':
          return this.myTrackDictionary.values();
        // -------------------------------------------------------------------------
        case 'yourtrack':
          return this.yourTrackDictionary.values();
        // -------------------------------------------------------------------------
        case 'mywink':
          return this.myWinkDictionary.values();
        // -------------------------------------------------------------------------
        case 'yourwink':
          return this.yourWinkDictionary.values();
        // -------------------------------------------------------------------------
        case 'myunlock':
          return this.myUnlockDictionary.values();
        // -------------------------------------------------------------------------
        case 'yourunlock':
          return this.yourUnlockDictionary.values();
        // -------------------------------------------------------------------------
        case 'mymemberactivity':
          return this.myMemberActivityDictionary.values();
        // -------------------------------------------------------------------------
        case 'yourmemberactivity':
          return this.yourMemberActivityDictionary.values();
        // -------------------------------------------------------------------------
        case 'mymemberlockingactivity':
          return this.myMemberLockingActivityDictionary.values();
        // -------------------------------------------------------------------------
        case 'yourmemberlockingactivity':
          return this.yourMemberLockingActivityDictionary.values();
        // -------------------------------------------------------------------------
        case 'mymembertrackingactivity':
          return this.myMemberTrackingActivityDictionary.values();
        // -------------------------------------------------------------------------
        case 'yourmembertrackingactivity':
          return this.yourMemberTrackingActivityDictionary.values();
        // -------------------------------------------------------------------------
        case 'mychatid':
          return this.myChatIdDictionary.values();
        // -------------------------------------------------------------------------
        // TODO:
        case 'mychat':
          return this.myChatDictionary.values();
        // -------------------------------------------------------------------------
        case 'myoutboxchatid':
          return this.myOutboxChatIdDictionary.values();
        // -------------------------------------------------------------------------
        // TODO:
        case 'myoutboxchat':
          return this.myOutboxChatDictionary.values();
        // -------------------------------------------------------------------------
        case 'myemailid':
          return this.myEmailIdDictionary.values();
        // -------------------------------------------------------------------------
        case 'myemail':
          return this.myEmailDictionary.values();
        // -------------------------------------------------------------------------
        case 'mynotificationid':
          return this.myNotificationIdDictionary.values();
        // -------------------------------------------------------------------------
        case 'mynotification':
          return this.myNotificationDictionary.values();
        // -------------------------------------------------------------------------
        //case 'photobnid':
        //  return this.photoBnIdDictionary.values();
        // -------------------------------------------------------------------------
        case 'photobn':
          return this.photoBnDictionary.values();
        // -------------------------------------------------------------------------
        case 'photo':
          return this.photoDictionary.values();
        // -------------------------------------------------------------------------
        case 'preference':
          return this.preferenceDictionary.values();
        // -------------------------------------------------------------------------
        case 'profileinfo':
          // debugger;
          return this.profileInfoDictionary.values();
        // -------------------------------------------------------------------------
        case 'profilecontent':
          // debugger;
          return this.profileContentDictionary.values();
        // -------------------------------------------------------------------------
        // TODO:
        case 'profilepics':
          return this.profilePicsDictionary.values();
        // -------------------------------------------------------------------------
        case 'profiletileid':
          return this.profileTileIdDictionary.values();
        // -------------------------------------------------------------------------
        case 'profiletile':
          return this.profileTileDictionary.values();
        // -------------------------------------------------------------------------
        case 'situserid':
          return this.sitUserIdDictionary.values();
        // -------------------------------------------------------------------------
        case 'situser':
          return this.sitUserDictionary.values();
        // -------------------------------------------------------------------------
        case 'spinnertimer':
          return this.spinnerTimerDictionary.values();
        // -------------------------------------------------------------------------
        case 'filteredkvphoto':
          return this.filteredKvPhotoDictionary.values();
        // -------------------------------------------------------------------------
        case 'unfilteredkvphoto':
          return this.unFilteredKvPhotoDictionary.values();
        // -------------------------------------------------------------------------
        default:
          // ;
          break;
        // -------------------------------------------------------------------------
      }
    }
  }
  /*
  * ---------------------------------------------------------------
  * Begin of Communication Dictionaries:
  * ---------------------------------------------------------------
  */
  // ---------------------------------------------------------------
  public getChatActivityDictionary () : Dictionary<number, ChatActivity> {
    // debugger;
    return this.chatActivityDictionary;
  }
  public setChatActivityDictionary (ccaDic : Dictionary<number, ChatActivity>) : void {
    this.chatActivityDictionary = ccaDic;
  }

  // ---------------------------------------------------------------
  public getChatConversationDictionary () : Dictionary<number, ChatActivity[]> {
    // debugger;
    return this.chatConversationDictionary;
  }
  public setChatConversationDictionary (cconDic : Dictionary<number, ChatActivity[]>) : void {
    this.chatConversationDictionary = cconDic;
  }

  // ---------------------------------------------------------------
  public getChatConversationIdDictionary () : Dictionary<number, number> {
    // debugger;
    return this.chatConversationIdDictionary;
  }
  public setChatConversationIdDictionary (chiDic : Dictionary<number, number>) : void {
    this.chatConversationIdDictionary = chiDic;
  }
  // ---------------------------------------------------------------
  public getEmailTileIdDictionary () : Dictionary<number, number[]> {
    // debugger;
    return this.emailTileIdDictionary;
  }
  public setEmailTileIdDictionary (emDic : Dictionary<number, number[]>) : void {
    this.emailTileIdDictionary = emDic;
  }
  // ---------------------------------------------------------------
  public getEnoteTileIdDictionary () : Dictionary<number, number[]> {
    // debugger;
    return this.enoteTileIdDictionary;
  }
  public setEnoteTileIdDictionary (chiDic : Dictionary<number, number[]>) : void {
    this.enoteTileIdDictionary = chiDic;
  }
  // ---------------------------------------------------------------
  public getEmailTileDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.emailTileDictionary;
  }
  public setEmailTileDictionary (emDic : Dictionary<number, CommunicationActivity>) : void {
    this.emailTileDictionary = emDic;
  }
  // ---------------------------------------------------------------
  public getEnoteTileDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.enoteTileDictionary;
  }
  public setEnoteTileDictionary (enDic : Dictionary<number, CommunicationActivity>) : void {
    this.enoteTileDictionary = enDic;
  }
  // ---------------------------------------------------------------
  public getMyChatDictionary () : Dictionary<number, ChatActivity> {
    // debugger;
    return this.myChatDictionary;
  }
  public setMyChatDictionary (cDic : Dictionary<number, ChatActivity>) : void {
    this.myChatDictionary = cDic;
  }
  // ---------------------------------------------------------------
  public getMyOutboxChatDictionary () : Dictionary<number, Chat> {
    // debugger;
    return this.myOutboxChatDictionary;
  }
  public setMyOutboxChatDictionary (cDic : Dictionary<number, Chat>) : void {
    this.myOutboxChatDictionary = cDic;
  }
  // ---------------------------------------------------------------
  public getMyEmailTileDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.myEmailDictionary;
  }
  public setMyEmailTileDictionary (eDic : Dictionary<number, CommunicationActivity>) : void {
    this.myEmailDictionary = eDic;
  }
  // ---------------------------------------------------------------
  public getMyNotificationIdDictionary () : Dictionary<number, number[]> {
    // debugger;
    return this.myNotificationIdDictionary;
  }
  public setMyNotificationIdDictionary (nIdDic : Dictionary<number, number[]>) : void {
    this.myNotificationIdDictionary = nIdDic;
  }
  // ---------------------------------------------------------------
  public getMyNotificationDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.myNotificationDictionary;
  }
  public setMyNotificationDictionary (nDic : Dictionary<number, CommunicationActivity>) : void {
    this.myNotificationDictionary = nDic;
  }
  /*
   * ---------------------------------------------------------------
   * End of Communication Dictionaries:
   * ---------------------------------------------------------------
   */
  /*
   * ---------------------------------------------------------------
   * Begin of MemberAcvitiy Dictionaries:
   * ---------------------------------------------------------------
   */
  public getBuddyDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.buddyDictionary;
  }
  public setBuddyDictionary (bDic : Dictionary<number, CommunicationActivity>) : void {
    this.buddyDictionary = bDic;
  }
  // ---------------------------------------------------------------
  public getMyBlockDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.myBlockDictionary;
  }
  public setMyBlockDictionary (bDic : Dictionary<number, CommunicationActivity>) : void {
    this.myBlockDictionary = bDic;
  }

  // ---------------------------------------------------------------
  ////public getMyFriendDictionary () : Dictionary<number, CommunicationActivity> {
  ////  // debugger;
  ////  return this.myFriendDictionary;
  ////}
  ////public setMyFriendDictionary (fDic : Dictionary<number, CommunicationActivity>) : void {
  ////  this.myFriendDictionary = fDic;
  ////}
  // ---------------------------------------------------------------
  public getHeartbeatDictionary () : Dictionary<number, Heartbeat> {
    // debugger;
    return this.heartbeatDictionary;
  }
  public setHeartbeatDictionary (hbDic : Dictionary<number, Heartbeat>) : void {
    this.heartbeatDictionary = hbDic;
  }
  // ---------------------------------------------------------------
  public getMyLikeDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.myLikeDictionary;
  }
  public setMyLikeDictionary (lDic : Dictionary<number, CommunicationActivity>) : void {
    this.myLikeDictionary = lDic;
  }
  public getMyLikeDictionaryValues () : CommunicationActivity[] {
     debugger;
    var myLikeArray = this.myLikeDictionary.values();
    debugger;
    return myLikeArray;
  }
  // ---------------------------------------------------------------
  public getMyUnlockDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.myUnlockDictionary;
  }
  public setMyUnlockDictionary (uDic : Dictionary<number, CommunicationActivity>) : void {
    this.myUnlockDictionary = uDic;
  }

  // ---------------------------------------------------------------
  public getMyStarDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.myStarDictionary;
  }
  public setMyStarDictionary (tDic : Dictionary<number, CommunicationActivity>) : void {
    this.myStarDictionary = tDic;
  }
  // ---------------------------------------------------------------
  public getMyTrackDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.myTrackDictionary;
  }
  public setMyTrackDictionary (tDic : Dictionary<number, CommunicationActivity>) : void {
    this.myTrackDictionary = tDic;
  }
  // ---------------------------------------------------------------
  public getMyWinkDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.myWinkDictionary;
  }
  public setMyWinkDictionary (wDic : Dictionary<number, CommunicationActivity>) : void {
    this.myWinkDictionary = wDic;
  }
  /*
   * ---------------------------------------------------------------
   * ---------------------------------------------------------------
   */
  public getYourBlockDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.yourBlockDictionary;
  }
  public setYourBlockDictionary (bDic : Dictionary<number, CommunicationActivity>) : void {
    this.yourBlockDictionary = bDic;
  }
  // ---------------------------------------------------------------
  public getYourFriendDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.yourFriendDictionary;
  }
  public setYourFriendDictionary (fDic : Dictionary<number, CommunicationActivity>) : void {
    this.yourFriendDictionary = fDic;
  }
  // ---------------------------------------------------------------
  public getMyYourLikeDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.yourLikeDictionary;
  }
  public setYourLikeDictionary (lDic : Dictionary<number, CommunicationActivity>) : void {
    this.yourLikeDictionary = lDic;
  }
  // ---------------------------------------------------------------
  public getYourUnlockDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.yourUnlockDictionary;
  }
  public setYourUnlockDictionary (uDic : Dictionary<number, CommunicationActivity>) : void {
    this.yourUnlockDictionary = uDic;
  }
  // ---------------------------------------------------------------
  public getYourStarDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.yourStarDictionary;
  }
  public setYourStarDictionary (tDic : Dictionary<number, CommunicationActivity>) : void {
    this.yourStarDictionary = tDic;
  }
  // ---------------------------------------------------------------
  public getYourTrackDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.yourTrackDictionary;
  }
  public setYourTrackDictionary (tDic : Dictionary<number, CommunicationActivity>) : void {
    this.yourTrackDictionary = tDic;
  }
  // ---------------------------------------------------------------
  public getYourWinkDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.yourWinkDictionary;
  }
  public setYourWinkDictionary (wDic : Dictionary<number, CommunicationActivity>) : void {
    this.yourWinkDictionary = wDic;
  }
  /*
   * ---------------------------------------------------------------
   * End of MemberAcvitiy Dictionaries:
   * ---------------------------------------------------------------
   * ---------------------------------------------------------------
   */
  /*
   * ---------------------------------------------------------------
   * Begin of Member(View/Profile) Dictionaries:
   * ---------------------------------------------------------------
   */
  
  // ---------------------------------------------------------------
 
  // ---------------------------------------------------------------
  public getMyProfilePicsDictionary () : Dictionary<number, ProfilePics> {
    // debugger;
    return this.myProfilePicsDictionary;
  }
  public setMyProfilePicsDictionary (profPicDic : Dictionary<number, ProfilePics>) : void {
    this.myProfilePicsDictionary = profPicDic;
  }
  // ---------------------------------------------------------------
  public getKvPhotoDictionary () : Dictionary<number, KvPhoto> {
    // debugger;
    return this.kvPhotoDictionary;
  }
  public setKvPhotoDictionary (pnumKvpDic : Dictionary<number, KvPhoto>) : void {
    this.kvPhotoDictionary = pnumKvpDic;
  }
  // ---------------------------------------------------------------
  public getPreferenceDictionary () : Dictionary<number, Preference> {
    // debugger;
    return this.preferenceDictionary;
  }
  public setPreferenceDictionary (prefDic : Dictionary<number, Preference>) : void {
    this.preferenceDictionary = prefDic;
  }
  // ---------------------------------------------------------------
  public getProfilePicsDictionary () : Dictionary<number, ProfilePics> {
    // debugger;
    return this.profilePicsDictionary;
  }
  public setProfilePicsDictionary (profPicDic : Dictionary<number, ProfilePics>) : void {
    this.profilePicsDictionary = profPicDic;
  }
  // ---------------------------------------------------------------
  public getProfileInfoDictionary () : Dictionary<number, ProfileInfo> {
    // debugger;
    return this.profileInfoDictionary;
  }
  public setProfileInfoDictionary (profInfoDic : Dictionary<number, ProfileInfo>) : void {
    this.profileInfoDictionary = profInfoDic;
  }
  // ---------------------------------------------------------------
  public getProfileContentDictionary () : Dictionary<number, Content> {
    // debugger;
    return this.profileContentDictionary;
  }
  public setProfileContentDictionary (profContentDic : Dictionary<number, Content>) : void {
    this.profileContentDictionary = profContentDic;
  }
  // ---------------------------------------------------------------
  public getProfileTileIdsDictionary () : Dictionary<number, number[]> {
    return this.profileTileIdDictionary;
  }
  public setProfileTileIdDictionary (pTileIdDic : Dictionary<number, number[]>) : void {
    this.profileTileIdDictionary = pTileIdDic;
  }
  // ---------------------------------------------------------------
  public getProfileTileDictionary () : Dictionary<number, ProfileTile> {
    return this.profileTileDictionary;
  }
  public setProfileTileDictionary (pTileDic : Dictionary<number, ProfileTile>) : void {
    this.profileTileDictionary = pTileDic;

    this.profileTileDexieDictionary = pTileDic;
    // debugger;
  }
  // ---------------------------------------------------------------
  public getSitUserDictionary () : Dictionary<number, SitUser> {
    // debugger;
    return this.sitUserDictionary;
  }
  public setSitUserDictionary (sUserDic : Dictionary<number, SitUser>) : void {
    this.sitUserDictionary = sUserDic;
  }
  // ---------------------------------------------------------------
  public getSitUserIdDictionary () : Dictionary<number, number[]> {
    // debugger;
    return this.sitUserIdDictionary;
  }
  public setSitUserIdDictionary (sUserDic : Dictionary<number, number[]>) : void {
    this.sitUserIdDictionary = sUserDic;
  }
  // ---------------------------------------------------------------
  // public getPhotoIdDictionary () : Dictionary<number, number[]>
  // {
  //  // debugger;
  //  return this.photoIdDictionary;
  // }
  // public setPhotoIdDictionary ( userPhIdDic : Dictionary<number, number[]> ) : void
  // {
  //  this.photoIdDictionary = userPhIdDic;
  // }
  // ---------------------------------------------------------------
  public getPhotoDictionary () : Dictionary<number, Photo> {
    // debugger;
    return this.photoDictionary;
  }
  public setPhotoDictionary (userPhDic : Dictionary<number, Photo>) : void {
    this.photoDictionary = userPhDic;
  }
  // ---------------------------------------------------------------
  public getUserPhotoBnIdDictionary () : Dictionary<number, number[]> {
    // debugger;
    return this.userPhotoBnIdDictionary;
  }
  public setUserPhotoBnIdDictionary (userPhBnIdDic : Dictionary<number, number[]>) : void {
    this.userPhotoBnIdDictionary = userPhBnIdDic;
  }

  // ---------------------------------------------------------------
  //public getPhotoBnDictionary () : Dictionary<number, PhotoBn> {
  //  // debugger;
  //  return this.photoBnDictionary;
  //}
  //public setPhotoBnDictionary (userPhBnDic : Dictionary<number, PhotoBn>) : void {
  //  this.photoBnDictionary = userPhBnDic;
  //}

  // ---------------------------------------------------------------
  public getSpinnerTimerDictionary () : Dictionary<number, SpinnerModel> {
    // debugger;
    return this.spinnerTimerDictionary;
  }
  public setSpinnerTimerDictionary (spinnerDic : Dictionary<number, SpinnerModel>) : void {
    this.spinnerTimerDictionary = spinnerDic;
  }

  /*
   * ---------------------------------------------------------------
   * End of Member(View/Profile) Dictionaries:
   * ---------------------------------------------------------------
   */

  // ---------------------------------------------------------------
  //  Note: Utility Methods for Dictionary Service:
  // ---------------------------------------------------------------
  public populateMasterErrorLogMap (bn : BoxNonceEntity) : any {
    // MemberActivity Dictionaries:
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.sitUser = EmitterSubjectService.getSitUserModel();

    if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
      // debugger;
      const mErrorLogs = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as Map<any, LogError>;
      // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mErrorLogs) && mErrorLogs.size > 0) {
        mErrorLogs.forEach((e) => {
          this.masterErrorLogMap.set(e.key, e);
        });
        return true;
      }
    }
    return false;
  }
  // ---------------------------------------------------------------
  public populateMyErrorLogMapFromIndexedDb (errLogArr : LogError[]) : void {
    /*
     * MyErrorLog Dictionary:
     * debugger;
     */
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(errLogArr) && errLogArr.length > 0) {
      errLogArr.map((e) => {
        this.myErrorLogMap.set(e.key, e);
      });
    }
  }
  // ---------------------------------------------------------------
  // Not Used under current scheme:
  // ---------------------------------------------------------------
  public modelArrayToDictionary (modelArr : any[], modelDic : Dictionary<number, any>) : void {
    if (!!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelArr) && modelArr.length > 0) {
      modelArr.map((e) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.doerSitUserId)) {
          modelDic.set(e.doerSitUserId, e);
        } else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.sitUserId)) {
          modelDic.set(e.sitUserId, e);
        }
      });
    }
  }
  // ---------------------------------------------------------------
  // Not Used under current scheme:
  // ---------------------------------------------------------------
  public modelActivityArrayToDictionary (modelArr : any[], modelDic : Dictionary<number, any>) : void {
    if (!!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelArr) && modelArr.length > 0) {
      modelArr.map((e) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.doerSitUserId)) {
          modelDic.set(e.doerSitUserId, e);
        } else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.sitUserId)) {
          modelDic.set(e.sitUserId, e);
        }
      });
    }
  }
  // ---------------------------------------------------------------
  // Not Used under current scheme:
  // ---------------------------------------------------------------
  public modelArrayToMemberActivityDictionary (modelArr : any[], modelDictionary : Dictionary<number, any>) : void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelArr) && modelArr.length > 0) {
      modelArr.map((e) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.key)) {
          modelDictionary.set(e.key, e);
        } else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.id)) {
          modelDictionary.set(e.id.toString(), e);
        }
      });
    }
  }

  // ----------------------------------------------------------------
  public populateKvPhotoOfUserInProfilePicsDictionary (data : KvPhoto, pPics : ProfilePics) : any {
    let index : number = -99;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && (data.photoId > 0 || data.photoBnId > 0)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) && pPics.sitUserId > 0) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data.value) || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data.image)) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoDictionary) && pPics.unFilteredKvPhotoDictionary.size >= 0) {
            if (data.photoBnId > 0) {
              if (!pPics.unFilteredKvPhotoDictionary.has(data.photoBnId)) {
                pPics.unFilteredKvPhotoDictionary.set(data.photoBnId, data);
                // debugger;
              }
            }
          }

          if (data.photoId > 0) {
            let tPhoto = CopyServiceStatic.copyFromKvPhotoToPhoto(data);
            if (!pPics.profilePhotoDictionary.has(data.photoId)) {
              pPics.profilePhotoDictionary.set(data.photoId, tPhoto);
              // debugger;
            }
          }
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoDictionary) && pPics.filteredKvPhotoDictionary?.values?.length >= 0) {
            // debugger;
            index = ArrayServiceStatic.getIndexOfKvPhotoInKvPhotoArr(pPics.filteredKvPhotoArr, data);
            if (index !== -1) {
              pPics.filteredKvPhotoArr = ArrayServiceStatic.mergeArraysUniq(pPics.filteredKvPhotoArr, [ data ]);
            }
            else {
              pPics.filteredKvPhotoArr.push(data);
            }

            if (data.photoBnId > 0) {
              if (!pPics.filteredKvPhotoDictionary.has(data.photoBnId)) {
                pPics.filteredKvPhotoDictionary.set(data.photoBnId, data);
                // debugger;
              }
            }
          }
        }
        return pPics;
      }
      else return null;
    }
    else return null;
  }

  /*
   * ---------------------------------------------------------------
   * NOTE: This can be used as general template for
   *       all other dictionaries with case -statement:
   * ---------------------------------------------------------------
   *  TODO: Test,
   * ---------------------------------------------------------------
   */
  public updateDictionary (inModel : any, modelName : string, id? : number) : any | void {
    
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inModel) ) {

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelName)) {
        // debugger;
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        // debugger;
        // let model = FrequentlyUsedFunctionsServiceStatic.deepCloneJson(inModel) as any; //deepCloneJson, deepCloneJson

        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inModel)) {
          let model = inModel; // JSON.parse(JSON.stringify(inModel)); // <--deep clone
          // let modelNameLowerCased = modelName.toLowerCase();
          // debugger;
          switch (modelName.toLowerCase()) {

            // Note: This does not have a store in indexedDb:
            // ----------------------------------------------
            case 'buddy':
              this.count = 0;
              debugger;
              this.buddyDictionary.set(id, model);
              break;

            // -------------------------------------------------------------------------
            case 'chatactivity':
              this.chatActivityDictionary.set(id, model);              
              break;
            // -------------------------------------------------------------------------
            // Note: conversationId  must appear beore chathistory
            // -------------------------------------------------------------------------
            case 'chatconversationid':
              this.chatConversationIdDictionary.set(id, model);
              // debugger;

              break;
            // -------------------------------------------------------------------------
            // NOTE: 
            //        if (id !== null | undefined ) { return id; }else {return model.id; } 
            //        the above expression is equal to this expression == id? id : model.id
            // -------------------------------------------------------------------------
            case 'chatconversation':
            case 'chats':
              this.chatConversationDictionary.set(id, model);
              // debugger;
              //if (model instanceof Array) {
              //  // debugger;
              //  model.map(e => {
              //    this.chatHistoryDictionary.set(e.chatActivityId, e);
              //  })
              //}
              //else if (typeof (model) === 'object') {
              //  // debugger;
              //  this.chatHistoryDictionary.set(model.chatActivityId, model);
              //  // this.saveDictionaryToDbDexie( this.photoIdDictionary, 'PhotoId', this.loginSuccess.signedInUserId );
              //}
              break;
            // -------------------------------------------------------------------------
            // Note: emailtileId  must appear beore emailtile
            // -------------------------------------------------------------------------
            case 'emailtileid':
              debugger;
              this.emailTileIdDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'emailtile':
              debugger;
              this.emailTileDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // Note: enotetileId  must appear beore enotetile
            // -------------------------------------------------------------------------
            case 'enotetileid':
              // debugger;
              this.enoteTileIdDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'enotetile':
              // debugger;
              this.enoteTileDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'heartbeat':
              // debugger;
              if (model instanceof Array) {
                // debugger;

                model.map(e => {
                  // debugger;
                  this.heartbeatDictionary?.set( e.signOnActivityId, e);
                });
              }
              else if (model) {               
                // debugger;
                this.heartbeatDictionary?.set(model.signOnActivityId, model);
                
              }
              break;

            // -------------------------------------------------------------------------
            case 'errorlog':
              // debugger;
              this.errorLogDictionary.set(id, model);
              break;
            // ----------------------------------------------
            case 'kvphoto':
              // debugger;
              this.kvPhotoDictionary.set(model.id, model);
              break;
            // ----------------------------------------------
            case 'loginsuccess':
              // debugger;
              this.loginSuccessDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'myblock':
            case 'yourblock':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myBlockDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourBlockDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'myfriend':
            case 'yourfriend':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myFriendDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourFriendDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mylike':
            case 'yourlike':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myLikeDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourLikeDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mystar':
            case 'yourstar':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myStarDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourStarDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mytrack':
            case 'yourtrack':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myTrackDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourTrackDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mywink':
            case 'yourwink':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myTrackDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourTrackDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'myunlock':
            case 'yourunlock':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myUnlockDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourUnlockDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mymemberactivity':
            case 'yourmemberactivity':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === model.senderSitUserId) {
                  // todo: check if it is NotificationActivityId or notificationActivityId?
                  this.myMemberActivityDictionary.set(model.notificationActivityId, model);
                }
                else if (this.loginSuccess.signedInUserId == model.receiverUserId) {
                  this.yourMemberActivityDictionary.set(model.notificationActivityId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mymemberlockingactivity':
            case 'yourmemberlockingactivity':
              // determine myMemberActivity and yourMemberActivity then update:
              // -----------------------------------------------------------------------
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === model.senderSITUserId || this.loginSuccess.signedInUserId === model.senderSitUserId) {
                  this.myMemberLockingActivityDictionary.set(model.lockingActivityId, model);
                }
                else if (this.loginSuccess.signedInUserId === model.receiverSITUserId || this.loginSuccess.signedInUserId === model.receiverSitUserId) {
                  this.yourMemberLockingActivityDictionary.set(model.lockingActivityId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mymembertrackingactivity':
            case 'yourmembertrackingactivity':
              // determine myMemberActivity and yourMemberActivity then update:
              // -----------------------------------------------------------------------
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === model.senderSITUserId || this.loginSuccess.signedInUserId === model.senderSitUserId) {
                  this.myMemberTrackingActivityDictionary.set(model.trackingActivityId, model);
                }
                else if (this.loginSuccess.signedInUserId === model.receiverSITUserId || this.loginSuccess.signedInUserId === model.receiverSitUserId) {
                  this.yourMemberTrackingActivityDictionary.set(model.trackingActivityId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------

            // -------------------------------------------------------------------------
            // Note: mychatId  must appear beore mychat
            // -------------------------------------------------------------------------
            case 'mychatid':
              // debugger;
              if (this.myChatIdDictionary.has(id)) {
                this.myChatIdDictionary.remove(id);
              }
              this.myChatIdDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'mychat':
              // debugger;
              if (this.myChatDictionary.has(id)) {
                this.myChatDictionary.remove(id);
              }
              this.myChatDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // Note: mychatId  must appear beore mychat
            // -------------------------------------------------------------------------
            case 'myoutboxchatid':
              // debugger;
              this.myOutboxChatIdDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'myoutboxchat':
              // debugger;
              this.myOutboxChatDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // Note: myEmailId  must appear beore myEmail
            // -------------------------------------------------------------------------
            case 'myemailid':
              // debugger;
              // this.myEmailIdDictionary.set(id, model);
              // debugger;
              let eArr : any[] = [];
              if (this.myEmailIdDictionary?.size > 0) {
                eArr = this.myEmailIdDictionary?.get(this.loginSuccess.signedInUserId) as number[];
              }
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
                if (typeof (model) === 'number') {
                  // debugger;
                  eArr = ArraySupportServiceStatic.mergeArraysUniq(eArr, [ model ]);
                  this.myEmailIdDictionary?.set(this.loginSuccess.signedInUserId, eArr);
                }
                else if (model instanceof Array) {
                  eArr = ArraySupportServiceStatic.mergeArraysUniq(eArr, model);
                  this.myEmailIdDictionary?.set(this.loginSuccess.signedInUserId, eArr);
                  // debugger;
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'myemail':
              // debugger;
              if (this.myEmailDictionary.has(id)) {
                this.myEmailDictionary.remove(id);
              }
              this.myEmailDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // Note: myNotificationId  must appear beore myNotificationr
            // -------------------------------------------------------------------------
            case 'mynotificationid':
              // debugger;
              this.myNotificationIdDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'mynotification':
              // debugger;
              if (this.myNotificationDictionary.has(id)) {
                this.myNotificationDictionary.remove(id);
              }
              this.myNotificationDictionary.set(id, model);
              break;
            // ---------------------------------------------------------------------------------------------------------
            case 'person':
              // debugger;
              this.personDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // Note: photoBnId  must appear beore photoBn
            // -------------------------------------------------------------------------
            case 'photobnid':
              // debugger;
              if (typeof (model) === 'number') {
                // debugger;
                this.userPhotoBnIdDictionary.set(id, [ model ]);
              }
              else if (model instanceof Array) {
                // debugger;
                this.userPhotoBnIdDictionary.set(id, model);
              }
              break;
            // -------------------------------------------------------------------------
            case 'photobn':
              // debugger;
              // this.photoBnDictionary.set(id, model);
              if (model instanceof Array) {
                // debugger;
                model.map(e => {
                  this.photoBnDictionary.set(e.photoBnId, e);
                })
              }
              else if (typeof (model) === 'object' || model instanceof BoxNonceEntity) {
                // debugger;
                this.photoBnDictionary.set(model.photoBnId, model);
              }
              break;

            // ---------------------------------------------------------------------------------------------------------
            case 'photo':
              // debugger;
              this.photoDictionary.set(model.id, model);
              break;
            // ---------------------------------------------------------------------------------------------------------
            case 'preference':
              // debugger;
              this.preferenceDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // Note: This does not have a store in indexedDb:
            // ----------------------------------------------
            // ---------------------------------------------------------------------------------------------------------
            case 'profile':
              // debugger;
              this.profileDictionary.set(model.id, model);
              break;
            // -------------------------------------------------------------------------
            case 'profileinfo':
              // debugger;
              this.profileInfoDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'profilecontent':
              // debugger;
              this.profileContentDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'profilepics':
              // debugger;
              this.profilePicsDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // model == number[]
            case 'profiletileid':
              // debugger;
              this.profileTileIdDictionary.set(id, model);

              // if ( typeof ( model ) === 'number' )
              // {
              //  // debugger;
              //  this.profileTileIdsDictionary.set( model, model );
              //  // this.saveDictionaryToDbDexie( this.profileTileIdDictionary, 'ProfileTileId', this.loginSuccess.signedInUserId );
              // }
              // else if ( model instanceof Array )
              // {
              //  // debugger;
              //  model.map( e =>
              //  {
              //    // debugger;
              //    this.profileTileIdsDictionary.set( e, e );
              //    // this.saveDictionaryToDbDexie( this.profileTileIdDictionary, 'ProfileTileId', this.loginSuccess.signedInUserId );
              //  } );
              // }
              break;

            // -------------------------------------------------------------------------
            case 'profiletile':
              // debugger;
              this.profileTileDictionary.set(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) ? id : model.id, model)

              //if (this.profileTileDictionary.size() == 0 || !this.profileTileDictionary.has(model.sitUserId)) {
              //  this.profileTileDictionary.set(model.sitUserId, model);
              //}
              //else {
              //  // debugger;
              //  let dTile : ProfileTile = this.profileTileDictionary.getValue(model.sitUserId) as ProfileTile;
              //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dTile)) {
              //     // debugger;
              //    // CopyServiceStatic.copyProfileTileIfExists(dTile, model);
              //    dTile.copyIfExists(model);
              //    this.profileTileDictionary.set(dTile.sitUserId, dTile);
              //  }

              //}
              // debugger;

              // debugger;
              break;
            // -------------------------------------------------------------------------
            // Note: sitUserId  must appear beore sitUser
            //       teated on 20230801, works!
            // -------------------------------------------------------------------------
            // NOte: model == number[]
            case 'situserid':

              let tArr : any[] = [];
              if (this.sitUserIdDictionary?.values().length > 0) {
                tArr = this.sitUserIdDictionary?.get(this.loginSuccess.signedInUserId) as number[];
              }

              if (model instanceof Array) {
                tArr = ArraySupportServiceStatic.mergeArraysUniq(tArr, model);
                this.sitUserIdDictionary?.set(this.loginSuccess.signedInUserId, tArr);
                // debugger;
              }
              else if (typeof (model) === 'number') {
                // debugger;
                tArr = ArraySupportServiceStatic.mergeArraysUniq(tArr, [ model ]);
                this.sitUserIdDictionary?.set(this.loginSuccess.signedInUserId, tArr);
              } 
             
              break;
            // -------------------------------------------------------------------------
            case 'situser':
              // debugger;
              if (model instanceof Array) {
                // debugger;

                model.map(e => {
                  // debugger;
                  this.sitUserDictionary?.set(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) ? id : e.id, e);
                });
              }
              else if (model) {
                if (id > 0) {
                  // debugger;
                  this.sitUserDictionary?.set(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) ? id : model.id, model);
                }
                else if (model.sitUserId > 0) {
                  // debugger;
                  this.sitUserDictionary?.set(model.sitUserId, model);
                }
              }
              break;

            // -------------------------------------------------------------------------
            case 'filteredkvphoto':
              // debugger;
              this.filteredKvPhotoDictionary.set(model.photoBnId, model);

              break;
            // -------------------------------------------------------------------------
            case 'unfilteredkvphoto':
              // debugger;
              this.unFilteredKvPhotoDictionary.set(model.photoBnId, model);

              break;
            // -------------------------------------------------------------------------
            case 'spinnertimer':
              // debugger;
              this.spinnerTimerDictionary.set(model.startTimer, model);
              // this.saveDictionaryToDbDexie( this.spinnerTimerDictionary, 'SpinnerTimer', this.loginSuccess.signedInUserId );

              break;
            default:
              // ;
              break;
            // -------------------------------------------------------------------------
          }
          // debugger;
          // Note: TODO: figure out if it is still in use:
          // ----------------------------------------------
          //this.count = this.allDictionaryDataCount.getValue(modelName);
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.count) && this.count >= 0) {
          //  this.count++;
          //}
          //else this.count = 1;

          //this.allDictionaryDataCount.set(modelName, this.count);
        }
      }
    }
  }
  // -------------------------------------------------------------------------------------
  public resetDictionary (modelName : string) : any | void {    
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelName)) {        
      // debugger;
      switch (modelName.toLowerCase()) {

        // Note: This does not have a store in indexedDb:
        // ----------------------------------------------
        case 'buddy':
          this.buddyDictionary = new Dictionary<number, CommunicationActivity>();
          break;
        // -------------------------------------------------------------------------
        case 'chatactivity':
          // debugger;
          this.chatActivityDictionary = new Dictionary<number, ChatActivity>();

          break;
        // -------------------------------------------------------------------------
        // Note: chathistoryId  must appear beore chathistory
        // -------------------------------------------------------------------------
        case 'chatconversationid':
          this.chatConversationIdDictionary = new Dictionary<number, number>();
          // debugger;

          break;
        // -------------------------------------------------------------------------
        // NOTE: 
        //        if (id !== null | undefined ) { return id; }else {return model.id; } 
        //        the above expression is equal to this expression == id? id : model.id
        // -------------------------------------------------------------------------
        case 'chatconversation':
          // this.chatHistoryDictionary.set(id, model);
          // debugger;
          this.chatConversationDictionary = new Dictionary<number, ChatActivity[]>();
              
          break;
        // -------------------------------------------------------------------------
        // Note: emailtileId  must appear beore emailtile
        // -------------------------------------------------------------------------
        case 'emailtileid':
          debugger;
          this.emailTileIdDictionary = new Dictionary<number, number[]>();
          break;
        // -------------------------------------------------------------------------
        case 'emailtile':
          debugger;
          this.emailTileDictionary = new Dictionary<number, CommunicationActivity>();
          break;
        // -------------------------------------------------------------------------
        // Note: enotetileId  must appear beore enotetile
        // -------------------------------------------------------------------------
        case 'enotetileid':
          // debugger;
          this.enoteTileIdDictionary = new Dictionary<number, number[]>();
          break;
        // -------------------------------------------------------------------------
        case 'enotetile':
          // debugger;
          this.enoteTileDictionary = new Dictionary<number, CommunicationActivity>();
          break;
        // -------------------------------------------------------------------------
        case 'errorlog':
          // debugger;
          this.errorLogDictionary = new Dictionary<number, any>();
          break;
        // ----------------------------------------------
        case 'kvphoto':
          // debugger;
          this.kvPhotoDictionary = new Dictionary<number, KvPhoto>();
          break;
        // ----------------------------------------------
        case 'loginsuccess':
          // debugger;
          this.loginSuccessDictionary = new Dictionary<number, LoginSuccess>();
          break;
        //// -------------------------------------------------------------------------
        //case 'myblock':
        //  this.myBlockDictionary = new Dictionary<number, CommunicationActivity>();
        //  break;
        //case 'yourblock':
        //  this.yourBlockDictionary = new Dictionary<number, CommunicationActivity>();
        //  break;
        //// -------------------------------------------------------------------------
        //case 'myfriend':
        //  this.myFriendDictionary = new Dictionary<number, CommunicationActivity>();              
        //  break;
        //case 'yourfriend':
        //  this.yourFriendDictionary = new Dictionary<number, CommunicationActivity>();
        //  break;
        //// -------------------------------------------------------------------------
        //case 'mylike':
        //  this.myLikeDictionary = new Dictionary<number, CommunicationActivity>();
        //  break;
        //case 'yourlike':
        //  this.yourLikeDictionary = new Dictionary<number, CommunicationActivity>();
        //  break;
        //// -------------------------------------------------------------------------
        //// TODO:
        //case 'mystar':
        //case 'yourstar':
        //  // determine myMemberActivity and yourMemberActivity then update:
        //  // ---------------------------------------------------------------------------------
        //  //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
        //  //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
        //  //    this.myStarDictionary.set(model.receiverUserId, model);
        //  //  }
        //  //  else if (id == model.senderUserId) {
        //  //    this.yourStarDictionary.set(model.senderUserId, model);
        //  //  }
        //  //}
        //  break;
        //// -------------------------------------------------------------------------
        //// TODO:
        //case 'mytrack':
        //case 'yourtrack':
        //  // determine myMemberActivity and yourMemberActivity then update:
        //  // ---------------------------------------------------------------------------------
        //  //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
        //  //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
        //  //    this.myTrackDictionary.set(model.receiverUserId, model);
        //  //  }
        //  //  else if (id == model.senderUserId) {
        //  //    this.yourTrackDictionary.set(model.senderUserId, model);
        //  //  }
        //  //}
        //  break;
        //// -------------------------------------------------------------------------
        //// TODO:
        //case 'mywink':
        //case 'yourwink':
        //  // determine myMemberActivity and yourMemberActivity then update:
        //  // ---------------------------------------------------------------------------------
        //  //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
        //  //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
        //  //    this.myTrackDictionary.set(model.receiverUserId, model);
        //  //  }
        //  //  else if (id == model.senderUserId) {
        //  //    this.yourTrackDictionary.set(model.senderUserId, model);
        //  //  }
        //  //}
        //  break;
        //// -------------------------------------------------------------------------
        //// TODO:
        //case 'myunlock':
        //case 'yourunlock':
        //  // determine myMemberActivity and yourMemberActivity then update:
        //  // ---------------------------------------------------------------------------------
        //  //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
        //  //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
        //  //    this.myUnlockDictionary.set(model.receiverUserId, model);
        //  //  }
        //  //  else if (id == model.senderUserId) {
        //  //    this.yourUnlockDictionary.set(model.senderUserId, model);
        //  //  }
        //  //}
        //  break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'mymemberactivity':
        case 'yourmemberactivity':
          // determine myMemberActivity and yourMemberActivity then update:
          // ---------------------------------------------------------------------------------
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
          //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
          //    this.myMemberActivityDictionary.set(model.receiverUserId, model);
          //  }
          //  else if (id == model.senderUserId) {
          //    this.yourMemberActivityDictionary.set(model.senderUserId, model);
          //  }
          //}
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'mymemberlockingactivity':
        case 'yourmemberlockingactivity':
          // determine myMemberActivity and yourMemberActivity then update:
          // ---------------------------------------------------------------------------------
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
          //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
          //    this.myMemberLockingActivityDictionary.set(model.receiverUserId, model);
          //  }
          //  else if (id == model.senderUserId) {
          //    this.yourMemberLockingActivityDictionary.set(model.senderUserId, model);
          //  }
          // }
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'mymembertrackingactivity':
        case 'yourmembertrackingactivity':
          // determine myMemberActivity and yourMemberActivity then update:
          // --------------------------------------------------------------
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
          //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
          //    this.myMemberStarTrackWinkActivityDictionary.set(model.receiverUserId, model);
          //  }
          //  else if (id == model.senderUserId) {
          //    this.yourMemberStarTrackWinkActivityDictionary.set(model.senderUserId, model);
          //  }
          //}
          break;

        // -------------------------------------------------------------------------
        // Note: mychatId  must appear beore mychat
        // -------------------------------------------------------------------------
        // TODO:
        case 'mychatid':
          // debugger;
          // this.myChatIdDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'mychat':
          // debugger;
          // this.myChatDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // Note: mychatId  must appear beore mychat
        // -------------------------------------------------------------------------
        // TODO:
        case 'myoutboxchatid':
          // debugger;
          // this.myOutboxChatIdDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'myoutboxchat':
          // debugger;
          // this.myOutboxChatDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // Note: myEmailId  must appear beore myEmail
        // -------------------------------------------------------------------------
        // TODO:
        case 'myemailid':
          // debugger;
          // this.myEmailIdDictionary.set(id, model);
          // debugger;
          //let eArr : any[] = [];
          //if (this.myEmailIdDictionary?.size() > 0) {
          //  eArr = this.myEmailIdDictionary?.getValue(this.loginSuccess.signedInUserId) as number[];
          //}
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
          //  if (typeof (model) === 'number') {
          //    // debugger;
          //    eArr = ArraySupportServiceStatic.mergeArraysUniq(eArr, [ model ]);
          //    this.myEmailIdDictionary?.set(this.loginSuccess.signedInUserId, eArr);
          //  }
          //  else if (model instanceof Array) {
          //    eArr = ArraySupportServiceStatic.mergeArraysUniq(eArr, model);
          //    this.myEmailIdDictionary?.set(this.loginSuccess.signedInUserId, eArr);
          //    // debugger;
          //  }
          //}
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'myemail':
          // debugger;
          // this.myEmailDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // Note: myNotificationId  must appear beore myNotificationr
        // -------------------------------------------------------------------------
        // TODO:
        case 'mynotificationid':
          // debugger;
          // this.myNotificationIdDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        case 'mynotification':
          // debugger;
          // this.myNotificationDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // Note: photoBnId  must appear beore photoBn
        // -------------------------------------------------------------------------
        // TODO:
        case 'photobnid':
          // debugger;
          //if (typeof (model) === 'number') {
          //  // debugger;
          //  this.userPhotoBnIdDictionary.set(id, [ model ]);
          //}
          //else if (model instanceof Array) {
          //  // debugger;
          //  this.userPhotoBnIdDictionary.set(id, model);
          //}
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'photobn':
          // debugger;
          // this.photoBnDictionary.set(id, model);
          //if (model instanceof Array) {
          //  // debugger;
          //  model.map(e => {
          //    this.photoBnDictionary.set(e.photoBnId, e);
          //  })
          //}
          //else if (typeof (model) === 'object' || model instanceof BoxNonceEntity) {
          //  // debugger;
          //  // this.photoBnDictionary.set(model.photoBnId, model);
          //}
          break;

        // ---------------------------------------------------------------------------------------------------------
        // TODO:
        case 'photo':
          // debugger;
          // this.photoDictionary.set(model.id, model);
          break;
        // ---------------------------------------------------------------------------------------------------------
        // TODO:
        case 'preference':
          // debugger;
          // this.preferenceDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // Note: This does not have a store in indexedDb:
        // ----------------------------------------------
        //case 'profileimage':
        //  // debugger;
        //  this.profileImageDictionary.set(model.sitUserId, model);
        //  // this.saveDictionaryToDbDexie( this.profileImageDictionary, 'ProfileImage', this.loginSuccess.signedInUserId );
        //  break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'profileinfo':
          // debugger;
          // this.profileInfoDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        case 'profilecontent':
          // debugger;
          // this.profileContentDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'profilepics':
          // debugger;
          // this.profilePicsDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // TODO:
        // model == number[]
        case 'profiletileid':
          // debugger;
          // this.profileTileIdDictionary.set(id, model);

          // if ( typeof ( model ) === 'number' )
          // {
          //  // debugger;
          //  this.profileTileIdsDictionary.set( model, model );
          //  // this.saveDictionaryToDbDexie( this.profileTileIdDictionary, 'ProfileTileId', this.loginSuccess.signedInUserId );
          // }
          // else if ( model instanceof Array )
          // {
          //  // debugger;
          //  model.map( e =>
          //  {
          //    // debugger;
          //    this.profileTileIdsDictionary.set( e, e );
          //    // this.saveDictionaryToDbDexie( this.profileTileIdDictionary, 'ProfileTileId', this.loginSuccess.signedInUserId );
          //  } );
          // }
          break;

        // -------------------------------------------------------------------------
        // TODO:
        case 'profiletile':
          // debugger;
          // this.profileTileDictionary.set(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) ? id : model.id, model)

          //if (this.profileTileDictionary.size() == 0 || !this.profileTileDictionary.has(model.sitUserId)) {
          //  this.profileTileDictionary.set(model.sitUserId, model);
          //}
          //else {
          //  // debugger;
          //  let dTile : ProfileTile = this.profileTileDictionary.getValue(model.sitUserId) as ProfileTile;
          //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dTile)) {
          //     // debugger;
          //    // CopyServiceStatic.copyProfileTileIfExists(dTile, model);
          //    dTile.copyIfExists(model);
          //    this.profileTileDictionary.set(dTile.sitUserId, dTile);
          //  }

          //}
          // debugger;

          // debugger;
          break;
        // -------------------------------------------------------------------------
        // Note: sitUserId  must appear beore sitUser
        // -------------------------------------------------------------------------
        // TODO:
        // NOte: model == number[]
        case 'situserid':
          // debugger;
          //let tArr : any[] = [];
          //if (this.sitUserIdDictionary?.values().length > 0) {
          //  tArr = this.sitUserIdDictionary?.getValue(this.loginSuccess.signedInUserId) as number[];
          //}

          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tArr)) {
          //  if (typeof (model) === 'number') {
          //    // debugger;
          //    tArr = ArraySupportServiceStatic.mergeArraysUniq(tArr, [ model ]);
          //    this.sitUserIdDictionary?.set(id, tArr);
          //  }
          //  else if (model instanceof Array) {
          //    tArr = ArraySupportServiceStatic.mergeArraysUniq(tArr, model);
          //    this.sitUserIdDictionary?.set(id, tArr);
          //    // debugger;
          //  }
          //}
          //else {
          //  this.sitUserIdDictionary?.set(id, model);
          //}
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'situser':
          // debugger;
          //if (model instanceof Array) {
          //  // debugger;

          //  model.map(e => {
          //    // debugger;
          //    this.sitUserDictionary?.set(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) ? id : e.id, e);
          //  });
          //}
          //else if (model) {
          //  if (id > 0) {
          //    // debugger;
          //    this.sitUserDictionary?.set(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) ? id : model.id, model);
          //  }
          //  else if (model.sitUserId > 0) {
          //    // debugger;
          //    this.sitUserDictionary?.set(model.sitUserId, model);
          //  }
          //}
          break;

        // -------------------------------------------------------------------------
        // TODO:
        case 'spinnertimer':
          // debugger;
         // this.spinnerTimerDictionary.set(model.startTimer, model);
          // this.saveDictionaryToDbDexie( this.spinnerTimerDictionary, 'SpinnerTimer', this.loginSuccess.signedInUserId );

          break;
        case 'filteredkvphoto':
          // debugger;
          this.filteredKvPhotoDictionary = new Dictionary<number, KvPhoto>();

          break;
        case 'unfilteredkvphoto':
          this.unFilteredKvPhotoDictionary = new Dictionary<number, KvPhoto>();

          break;
        default:
          // ;
          break;
        // -------------------------------------------------------------------------
      }
      // debugger;
      // Note: TODO: figure out if it is still in use:
      // ----------------------------------------------
      //this.count = this.allDictionaryDataCount.getValue(modelName);
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.count) && this.count >= 0) {
      //  this.count++;
      //}
      //else this.count = 1;

      //this.allDictionaryDataCount.set(modelName, this.count);
    }
  }
  // ---------------------------------------------------------------
  // TODO: comment/remove before deployment. Keep until testing.
  // ---------------------------------------------------------------
  //public updateChatHistoryDictionary ( chatArr: Chat[] ): void
  //{
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( chatArr ) && chatArr.length > 0 )
  //  {
  //    chatArr.map( e =>
  //    {
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e )
  //        && e.receiverSitUserId === this.loginSuccess.signedInUserId // make sure that I am the recipient
  //        && this.chatHistoryDictionary.has( e.senderSitUserId ) ) // Note: dictionary key==senderSitUserId
  //      {
  //        this.chatHistoryDictionary.remove( e.senderSitUserId );
  //      }
  //      this.chatHistoryDictionary.set( e.senderSitUserId, e );
  //    } );
  //  }
  //}
  // ---------------------------------------------------------------
  // Intend to deprecate.
  // ---------------------------------------------------------------
  public updateMyChatDictionary ( chatarr : any ): void
  {
    // debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( chatarr ) && chatarr.length > 0)
    {
      let chatArr = JSON.parse(chatarr);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatArr) && chatArr.length > 0) {
        for (var i = 0; i < chatArr.length; i++) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatArr[ i ])
            && chatArr[ i ].receiverSitUserId === this.loginSuccess.signedInUserId // make sure that I am the recipient
            && this.myChatDictionary.has(chatArr[ i ].senderSitUserId)) // Note: dictionary key==senderSitUserId
          {
            this.myChatDictionary.remove(chatArr[ i ].senderSitUserId);
          }
          this.myChatDictionary.set(chatArr[ i ].senderSitUserId, chatArr[ i ]);
        };
      }
    }
  }
  // ---------------------------------------------------------------
  // public updateMyEmailTileDictionary ( commActArr: CommunicationActivity[] ): void
  // {
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commActArr ) && commActArr.length > 0 )
  //  {
  //    // debugger;
  //    commActArr.map( e =>
  //    {
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e )
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e.communicationType )
  //        && ( e.communicationType.toLowerCase().indexOf( 'email' ) !== -1) // TODO: check the magic-string
  //        && ( e.receiverSitUserId === this.loginSuccess.signedInUserId // make sure that I am the recipient
  //        || e.senderSitUserId === this.loginSuccess.signedInUserId)) // or sender
  //      {

  //        // if ( this.myEmailTileDictionary.has( e.senderSitUserId ) ) // Note: dictionary key==senderSitUserId
  //        // {
  //        //  // debugger;
  //        //  this.myEmailTileDictionary.remove( e.senderSitUserId );
  //        // }
  //        // debugger;
  //        this.myEmailTileDictionary.set( e.senderSitUserId, e );
  //        // debugger;
  //      }
  //    } );
  //  }
  // }
  // ---------------------------------------------------------------
  // public updateMyNotificationDictionary ( commActArr: CommunicationActivity[] ): void
  // {
  //  // debugger;
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commActArr ) && commActArr.length > 0 )
  //  {
  //    commActArr.map( e =>
  //    {
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e )
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e.communicationType )
  //        && ( e.communicationType.toLowerCase().indexOf( 'note' ) !== -1
  //          || e.communicationType.toLowerCase().indexOf( 'notification' ) !== -1 ) // TODO: check the magic-string
  //        && e.receiverSitUserId === this.loginSuccess.signedInUserId )// make sure that I am the recipient
  //      {
  //        if (this.myNotificationDictionary.has( e.senderSitUserId ) ) // Note: dictionary key==senderSitUserId
  //        {
  //          // debugger;
  //          let dictEnote = this.myNotificationDictionary.getValue( e.senderSitUserId ) as CommunicationActivity;
  //          if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dictEnote ) && dictEnote.communicationSubType.toLowerCase().indexOf( e.communicationSubType.toLowerCase() ) !== -1 )
  //          {
  //            // debugger;
  //            this.myNotificationDictionary.remove( e.senderSitUserId );
  //          }
  //        }
  //        // debugger;
  //        this.myNotificationDictionary.set( e.senderSitUserId, e );
  //      }
  //    } );
  //  }
  // }
  /*
 * ---------------------------------------------------------------
 * ===============================================================
 *  BoxNonceEntity(Bn) Dictionaries:
 * ---------------------------------------------------------------
 */
  public getPreferenceDicBn () : Dictionary<number, BoxNonceEntity>
  {
    return this.preferenceBnDictionary;
  }
  public setPreferenceDictionaryBn ( prefDic : Dictionary<number, BoxNonceEntity> ) : void
  {
    this.preferenceBnDictionary = prefDic;
  }
  // ---------------------------------------------------------------
  // public getPhotoBnArrDictionarytionary () : Dictionary<number, BoxNonceEntity[]>
  // {
  //  return this.photoBnArrDictionary;
  // }
  // public setPhotoBnArrDictionarytionary ( profPicDic : Dictionary<number, BoxNonceEntity[]> ) : void
  // {
  //  this.photoBnArrDictionary = profPicDic;
  // }
  // ---------------------------------------------------------------
  public getProfileInfoDictionaryBn () : Dictionary<number, BoxNonceEntity>
  {
    return this.profileInfoBnDictionary;
  }
  public setProfileInfoDictionaryBn ( profInfoDic : Dictionary<number, BoxNonceEntity> ) : void
  {
    this.profileInfoBnDictionary = profInfoDic;
  }
  // ---------------------------------------------------------------
  public getProfileTileIdDictionaryBn () : Dictionary<number, BoxNonceEntity>
  {
    return this.profileTileIdBnDictionary;
  }
  public setProfileTileIdDictionaryBn ( pTileIdDic : Dictionary<number, BoxNonceEntity> ) : void
  {
    this.profileTileIdBnDictionary = pTileIdDic;
  }
  // ---------------------------------------------------------------
  public getProfileTileDictionaryBn () : Dictionary<number, BoxNonceEntity>
  {
    return this.profileTileBnDictionary;
  }
  public setProfileTileDictionaryBn ( pTileDic : Dictionary<number, BoxNonceEntity> ) : void
  {
    this.profileTileBnDictionary = pTileDic;
  }
 
  // ---------------------------------------------------------------
  //public populateBnDictionariesFromIndexedDb (): void
  //{
  //  // debugger;
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  this.indexedDbService.getAllStoreData( 'Preference', this.loginSuccess.signedInUserId.toString()).then( modelArr =>
  //  {
  //    this.modelArrayToDictionary( modelArr, this.preferenceDictionary );
  //  } );
  //  this.indexedDbService.getAllStoreData('ProfileInfo', this.loginSuccess.signedInUserId.toString() ).then( modelArr =>{
  //      this.modelArrayToDictionary( modelArr, this.profileInfoDictionary );
  //  } );
  //  this.indexedDbService.getAllStoreData('ProfilePics', this.loginSuccess.signedInUserId.toString() ).then( modelArr =>{
  //      this.modelArrayToDictionary( modelArr, this.profilePicsDictionary );
  //  } );
  //  this.indexedDbService.getAllStoreData('ProfileTile', this.loginSuccess.signedInUserId.toString() ).then( modelArr =>{
  //    this.modelArrayToDictionary( modelArr, this.profileTileDictionary );
  //  } );
  //  this.indexedDbService.getAllStoreData('ProfileTileId', this.loginSuccess.signedInUserId.toString() ).then( modelArr =>{
  //    this.modelArrayToDictionary( modelArr, this.profileTileIdsDictionary );
  //  } );
  //  this.indexedDbService.getAllStoreData('SitUser', this.loginSuccess.signedInUserId.toString() ).then( modelArr =>{
  //    this.modelArrayToDictionary( modelArr, this.sitUserDictionary );
  //  } );
  //  // this.indexedDbService.getAllStoreData( 'PhotoId', this.loginSuccess.signedInUserId.toString()  ).then( modelArr =>{
  //  //  this.modelArrayToDictionary( modelArr, this.photoIdDictionary );
  //  // } );
  //  this.indexedDbService.getAllStoreData('Photo', this.loginSuccess.signedInUserId.toString() ).then( modelArr => {
  //    this.modelArrayToDictionary( modelArr, this.photoDictionary );
  //  } );
  //  // this.indexedDbService.getAllStoreData( 'PhotoBnId', this.loginSuccess.signedInUserId.toString()  ).then( modelArr =>{
  //  //  this.modelArrayToDictionary( modelArr, this.photoBnIdDictionary );
  //  // } );
  //  this.indexedDbService.getAllStoreData('PhotoBn', this.loginSuccess.signedInUserId.toString() ).then( modelArr =>{
  //    this.modelArrayToDictionary( modelArr, this.photoBnDictionary );
  //  } );

  //  // debugger;
  //}
  // ---------------------------------------------------------------
  public modelArrayToBnDictionary ( modelArr : any[], modelDic : Dictionary<number, BoxNonceEntity> ) : void
  {
    if ( !!modelArr && modelArr.length > 0 )
    {
      modelArr.map( ( e ) =>
      {
        modelDic.set( e.sitUserId, e );
      } );
    }
  }
  // ---------------------------------------------------------------------------
  // private inInitialize (): void
  // {
  //  this.httpService = new HttpService(this.httpClient, this.router);
  //  this.photoPrivacyService = new PhotoPrivacyService(this.httpService);
  // }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    /*
     * prevent memory leak when component /how-can-i-select-an-element-in-a-component-template
     *       (2) https:// www.concretepage.com/angular-2/angular-4-renderer2-example
     *       (3) https:// stackoverflow.com/questions/7439519/setinterval-to-loop-through-array-in-javascript/7440323
     *       (4) https:// stackblitz.com/edit/timer-with-pdestroyed
     */
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    /*
     * this.timerArray.forEach(timer => clearInterval(timer));
     * debugger;
     */
  }
  // ---------------------------------------------------------------
}

// =================================================================

  // ---------------------------------------------------------------
  // savePhotoIdsInDictionaryAndIndexedDb ( sitUserId: number, result: number[] ) : void
  // {
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) && result.length > 0 )
  //  {
  //    this.userPhotoIds = result;
  //    EmitterSubjectService.setUserPhotoIds( this.userPhotoIds );

  //    this.updateDictionary( this.userPhotoIds, 'UserPhotoId', sitUserId );

  //    this.indexedDbToDictionaryService
  //      .saveDictionaryToDbDexie(
  //        this.photoIdDictionary, 'UserPhotoId', this.loginSuccess.signedInUserId );

  //    // since successfully unsalted UserPhotoIds, we can save the BoxNonce of it in indexedDb:
  //    // --------------------------------------------------------------------------------------
  //    // this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt( result.toString() );
  //    // this.boxNonceEntity.box = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box );
  //    // this.boxNonceEntity.nonce = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce );
  //    // this.boxNonceEntity.date = DateStringServiceStatic.getTicks( new Date() ).toString();
  //    // this.boxNonceEntity.id = sitUserId;
  //    // this.boxNonceEntity.key = this.boxNonceEntity.id.toString();
  //    // this.boxNonceEntity.entityName = 'PhotoId';
  //    // this.boxNonceEntity.sitUserId = sitUserId;
  //    // debugger;
  //    // this.triageAllDataForIndexedDbAndDictionaryService.savePhotoIdDictionaryToIndexedDb( this.boxNonceEntity );

  //    // debugger; // TODO: check to see the values
  //    // return this.userPhotoIds;
  //    // debugger;
  //  }
  // }



