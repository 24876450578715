
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ProfileInfoServiceInterface } from '../../interfaces/serviceInterfaces/profileServiceInterfaces/profileInfoServiceInterface';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Heartbeat } from '../../models/common/heartbeat.model';
import { Unit } from '../../models/common/unit.model';
import { Communication } from '../../models/communication/communication.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { MemberViewMgmtModel } from '../../models/member/memberViewMgmtModel.model';
import { ProfileInfo } from '../../models/profile/profileInfo.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { GlyphiconMenuService } from '../GlyphiconService/glypgiconMenuService.service';
import { CoreServiceService } from '../coreServiceService/coreServiceService.service';
import { DbServiceService } from '../dbServiceService/dbServiceService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { MemberServiceService } from '../memberServiceService/memberServiceService.service';
import { AbstractPagerVPhotoNavService } from '../pagerServiceService/abstractPagerVPhotoNavService.service';
import { PagerServiceService } from '../pagerServiceService/pagerServiceService.service';
import { PhotoServiceService } from '../photoServiceService/photoServiceService.service';
import { RendererServiceService } from '../rendererServiceService/rendererServiceService.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
import { AbstractProfileService } from './abstractProfileService.service';
import { PreferenceService } from './preferenceService.service';
import { ProfileContentService } from './profileContentService.service';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';

@Injectable({
  providedIn: 'root',
} )

// ------------------------------------------------------------------------------------------------
// Disclaimer:  this service accomplishes all activities regarding signedInUser's ProfilePics only,
//              i.e. creating the profilePics for the signedInUser (1st  person user), and all of
//              the activities regarding managing the profilePics such as making a photo private
//              if it is public and vice versa, and generating the PhotoMgmtNavigation buttons.
// ------------------------------------------------------------------------------------------------
export class ProfileInfoService extends AbstractProfileService implements OnDestroy, ProfileInfoServiceInterface {  
  
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  public emitterDestroyed$: Subject<boolean> = new Subject();
  public isOnLine = false;
  public kvAnyArr : KvAny[] = [];
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public memberViewMgmtModel : MemberViewMgmtModel = new MemberViewMgmtModel();
  public message = '';
  public profileInfo : ProfileInfo = new ProfileInfo();
  public profileInfoView : ProfileInfo = new ProfileInfo();
 
  // will contain member-selection of KvPhoto as final state of currentStateOfPhoto
  public signedInUser : SitUser = new SitUser();
  public sitUser : SitUser = new SitUser();
  public timer : any;
  constructor ( 
    public abstractPagerVPhotoNavService : AbstractPagerVPhotoNavService,
    public dictionaryService : DictionaryService,
    public coreServiceService : CoreServiceService,
    public dbServiceService : DbServiceService,
    public dbDexieToDictionaryService : DbDexieToDictionaryService,
    public glyphiconMenuService : GlyphiconMenuService,
    public memberServiceService : MemberServiceService,
    public photoServiceService : PhotoServiceService,   
    public preferenceService : PreferenceService,
    public profileContentService : ProfileContentService,
    public rendererServiceService : RendererServiceService,
    public router : Router,
  )
  {
    super(
      abstractPagerVPhotoNavService,
      coreServiceService,
      dbServiceService,
      dictionaryService,
      glyphiconMenuService,
      memberServiceService,
      photoServiceService,
      preferenceService,
      profileContentService,
      rendererServiceService,
      router,
    );
    //if (this.coreServiceService) {
    //  this.httpService = this.coreServiceService.getHttpService();
    //  this.kvArrayService = this.coreServiceService.getKvArrayService();
    //  this.heartbeatService = this.coreServiceService.getHeartbeatService();
    //  this.httpService = this.coreServiceService.getHttpService();
    //  this.liaisonService = this.coreServiceService.getLiaisonService();
    //}
    // -----------------------------------------------------------
    this.initialize();
  }
  // ------------------------------------------------------------
  initialize(): void {
    // debugger;
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.memberViewMgmtModel = EmitterSubjectService.getMemberViewMgmtModel();
    this.signedInUser = EmitterSubjectService.getSignedInUser();

  }
  // ---------------------------------------------------------------
  ngOnDestroy () : any {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    return true;
  }
  
  // ---------------------------------------------------------------
  // Note: Keep the following three methods as a group:
  //       1. getDictionaryMemberProfile()
  //       2. getServerDbMemberProfile()
  // ---------------------------------------------------------------
  // Note: treat dictionary-data as all-good-or-nothing. important!!!
  // ---------------------------------------------------------------
  // public getDictionaryMemberProfile (sitUserId : number) : any {
  //  if (sitUserId > 0) {
  //    let tpPics : any;
  //    let tProfileInfo : any;
  //    let tProfilePics : any;
  //    let tPreference : any;
  //    let tUserPhotoIds : any;
  //    let emitterUserPhotoIds : any;
  //    let isDictionaryPrefDataGood = false;
  //    let isDictionaryProfInfoDataGood = false;
  //    let isDictionaryProfPicsDataGood = false;

  //    // debugger;

  //    tProfileInfo = DictionaryServiceStatic.profileInfoDictionary.getValue(sitUserId);
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tProfileInfo)) {
  //      // debugger;
  //      this.profileInfo = tProfileInfo;
  //      // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo)) {
  //      //  this.executeAllNonProfileTileTasks(this.profileInfo).then(data => {
  //      //    // debugger;
  //      //    this.memberViewMgmtModel = data;

  //      //    // if all tasks including preference were completed:
  //      //    // -------------------------------------------------
  //      //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel)
  //      //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.preferenceKvAnyArr)
  //      //      && this.memberViewMgmtModel.preferenceKvAnyArr.length > 0) {
  //      //      // debugger;
  //      //      isDictionaryProfInfoDataGood = true;
  //      //    }
  //      //    else {
  //      //      // debugger;
  //      //      this.profileInfo = data;
  //      //      isDictionaryProfInfoDataGood = false;
  //      //    }
  //      //    debugger;
  //      //    EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);
  //      //  });
  //      // }
  //    }
  //    // debugger;

  //    // TODO: check to see if memberActivcityDictionary need to be created or not:
  //    // --------------------------------------------------------------------------
  //    // if (!this.activityService.isDictionaryMemberActivityEmptyForSitUser(sitUserId)) {
  //    // }
  //    // debugger;
  //    // this.spinnerModalService.stopSpinner();
  //  }
  //  return this.profileInfo;
  // }
  // ---------------------------------------------------------------
  // public getServerDbMemberProfile (situserId : number) : any {
  //  // debugger;
  //  if (situserId > 0) {
  //    // debugger;
  //    this.getProfileInfo(situserId).then(result => {
  //      // debugger;
  //      this.profileInfo = result;
  //      //this.executeAllNonProfileTileTasks(this.profileInfo).then(data1 => {
  //      //  // debugger;
  //      //  this.memberViewMgmtModel = data1;
  //      //  // if preference data is found (either from dictionary or previous run), then display it:
  //      //  // --------------------------------------------------------------------------------------
  //      //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)
  //      //    && this.preference.sitUserId > 0 && this.preference.doesModelHaveData()) {
  //      //    // debugger;
  //      //    this.preferenceKvAnyArr = this.kvArrayService.getPeferenceToArrKvAny(this.preference);

  //      //    this.memberViewMgmtModel.populateModelData(this.preference, this.preferenceKvAnyArr);
  //      //  }
  //      //  // get it from the backend-server regardless:
  //      //  // ------------------------------------------
  //      //  // debugger;
  //      //  this.preferenceService.getPreference(this.profileInfo.sitUserId).subscribe(data2 => {
  //      //    // debugger;
  //      //    this.preference = data2;
  //      //    this.memberViewMgmtModel.populateModelData(this.preference, this.preferenceKvAnyArr);

  //      //    EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);
  //      //  });
  //      //  // debugger;
  //      //  EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);
  //      //});
  //    })
  //  }
  //  return this.profileInfo;
  // }
  // ---------------------------------------------------------------
  // ******* This is where the user's data is obtained  *******
  // ---------------------------------------------------------------
  //  Note: If the user has not completed his profile (at least the
  //        preScreen page) redirect them to their own incomplete
  //        profile page and show a modal with the message to
  //        complete thier profile (with a 'ask me later' button)
  // ---------------------------------------------------------------
  // public getUserData (sitUserId : number, isDictionary : boolean) : any {
  //  let mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && (this.loginSuccess.isProfileComplete)) {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUserId)) {
  //      let tId : any;

  //      // Note: Get the member profile data from Dictionaries.
  //      // Since the Dictionaries are populated from the indexedDB,
  //      // we do not get member's profile from indexedDB.
  //      // -----------------------------------------------
  //      if (isDictionary) {
  //        tId = this.getDictionaryMemberProfile(sitUserId);
  //        debugger;
  //      }
  //      else {
  //        this.profileInfo = this.getServerDbMemberProfile(sitUserId);
  //      }
  //      return true;
  //    }
  //  }
  //  else if (!this.loginSuccess.isPreScreenComplete) {
  //    mcLoader = new ModuleComponentLoader();
  //    mcLoader.componentName = 'preScreen';
  //    mcLoader.moduleName = 'registerm';
  //    mcLoader.sitUserId = this.loginSuccess.signedInUserId;
  //    EmitterSubjectService.setMCLoader(mcLoader);
  //    return false;
  //  }
  //  else {
  //    mcLoader = new ModuleComponentLoader();
  //    mcLoader.componentName = 'index';
  //    mcLoader.moduleName = 'app';
  //    mcLoader.sitUserId = this.loginSuccess.signedInUserId;
  //    EmitterSubjectService.setMCLoader(mcLoader);
  //    return false;
  //  }
  // }
  // ---------------------------------------------------------------
  // Note: Keep the following three methods as a group:
  //       1. getDictionaryMemberProfile()
  //       2. getServerDbMemberProfile()
  // ---------------------------------------------------------------
  // Note: treat dictionary-data as all-good-or-nothing. important!!!
  // ---------------------------------------------------------------
  // public getDictionaryMemberProfile (sitUserId : number) : any {
  //  if (sitUserId > 0) {
  //    let tpPics : any;
  //    let tProfileInfo : any;
  //    let tProfilePics : any;
  //    let tPreference : any;
  //    let tUserPhotoIds : any;
  //    let emitterUserPhotoIds : any;
  //    let isDictionaryPrefDataGood = false;
  //    let isDictionaryProfInfoDataGood = false;
  //    let isDictionaryProfPicsDataGood = false;

  //    // debugger;

  //    tProfileInfo = DictionaryServiceStatic.profileInfoDictionary.getValue(sitUserId);
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tProfileInfo)) {
  //      // debugger;
  //      this.profileInfo = tProfileInfo;
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo)) {
  //        this.profileTileService.executeAllNonProfileTileTasks(this.profileInfo).then(data => {
  //          // debugger;
  //          this.memberViewMgmtModel = data;

  //          // if all tasks including preference were completed:
  //          // -------------------------------------------------
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel)
  //            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.preferenceKvAnyArr)
  //            && this.memberViewMgmtModel.preferenceKvAnyArr.length > 0) {
  //            // debugger;
  //            isDictionaryProfInfoDataGood = true;
  //          }
  //          else {
  //            // debugger;
  //            this.profileInfo = data;
  //            isDictionaryProfInfoDataGood = false;
  //          }
  //          debugger;
  //          EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);
  //        });
  //      }
  //    }
  //    // debugger;

  //    // TODO: check to see if memberActivcityDictionary need to be created or not:
  //    // --------------------------------------------------------------------------
  //    // if (!this.activityService.isDictionaryMemberActivityEmptyForSitUser(sitUserId)) {
  //    // }
  //    // debugger;
  //    // this.spinnerModalService.stopSpinner();
  //  }
  //  return sitUserId;
  // }
  // ---------------------------------------------------------------
  // public getServerDbMemberProfile (situserId : number) : any {
  //  // debugger;
  //  if (situserId > 0) {
  //    // debugger;
  //    this.getProfileInfo(situserId).then(result => {
  //      // debugger;
  //      this.profileInfo = result;
  //      this.profileTileService.executeAllNonProfileTileTasks(this.profileInfo).then(data1 => {
  //        // debugger;
  //        this.memberViewMgmtModel = data1;
  //        // if preference data is found (either from dictionary or previous run), then display it:
  //        // --------------------------------------------------------------------------------------
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)
  //          && this.preference .sitUserId > 0 && this.preference.doesModelHaveData()) {
  //          // debugger;
  //          this.preferenceKvAnyArr = this.kvArrayService.getPeferenceToArrKvAny(this.preference);

  //          this.memberViewMgmtModel.populateModelData(this.preference, this.preferenceKvAnyArr);
  //        }
  //        // get it from the backend-server regardless:
  //        // ------------------------------------------
  //        // debugger;
  //        this.preferenceService.getPreference(this.profileInfo.sitUserId).subscribe(data2 => {
  //          // debugger;
  //          this.preference = data2;
  //          this.memberViewMgmtModel.populateModelData(this.preference, this.preferenceKvAnyArr);

  //          EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);
  //        });
  //        // debugger;
  //        EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);
  //      });
  //    })
  //  }
  //  return situserId;
  // }
  // ---------------------------------------------------------------
  public getProfileInfo (situserId : number) : Observable<any> {   
    let bnInput : any;
    bnInput = new Communication();
    return new Observable<any>((subscriber) => {
      let date = new Date();
      let sitUserId = situserId;
      if (sitUserId > 0) {
        // debugger;
        
        bnInput.sitUserId = sitUserId;
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        // if (this.isOnLine) {
        if (this.loginSuccess.signedInUserId > 0) {
          bnInput.signedInUserId = this.loginSuccess.signedInUserId;
        }
        // debugger;
        this.getProfileInfoBoxNonce(bnInput).subscribe((data : ProfileInfo) =>
        {
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
            
            //debugger;
            this.profileInfo = new ProfileInfo().copySourceToDestination(this.profileInfo, data);
            // debugger;
            this.kvAnyArr = this.createKVModelArrFromProfileInfoView(this.profileInfo);

            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo) && this.profileInfo.sitUserId > 0
              && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvAnyArr) && this.kvAnyArr.length > 0)
            {

              let tsitUser = EmitterSubjectService.getSitUserModel();
              if (tsitUser.sitUserId == sitUserId) {
                this.sitUser = tsitUser;
              }

              EmitterSubjectService.emitProfileInfoKvAnyArr(this.kvAnyArr);

              this.loginSuccess = EmitterSubjectService.getLoginSuccess();
              // debugger;
              this.dbDexieToDictionaryService
                .saveDictionaryToDbDexie(
                  this.dictionaryService.profileInfoDictionary, 'profileInfo', this.loginSuccess.signedInUserId);
              subscriber.next(this.profileInfo);
              subscriber.complete();
            }
            else {
              console.log(date.getTime() + ': getProfileInfo()=>getProfileInfoBoxNonce(sitUserId=' + sitUserId + ') profileInfo & kvAnyArr were null or undefined value.');
              subscriber.next(null);
              subscriber.complete();
            }
          }
          else {
            // debugger;
            console.log(date.getTime() + ': getProfileInfo()=>getProfileInfoBoxNonce(sitUserId=' + sitUserId + ') promise returned null or undefined value.');
            subscriber.next(null);
            subscriber.complete();
          }
          // debugger; 
        })
      }
      else {
        // debugger;
        console.log('ProfileInfoService.getProfileInfo(sitUserId==' + sitUserId + ') promise returned null.');
        subscriber.next(null);
        subscriber.complete();
      }
    })
  }
  // ---------------------------------------------------------------
  // Note:  This method's Observable is a better example of how an
  //        Observable shoudl be written.
  // ---------------------------------------------------------------
  public getProfileInfoBoxNonce (commu : Communication) : Observable<any> {
    let bncomm : Communication = commu;
    // debugger;
    let pInfo : ProfileInfo = null;
    let tpInfo: ProfileInfo = null;
    let tLoginSuccess = EmitterSubjectService.getLoginSuccess();
    let profileInfoObject : any;
    return new Observable<any>((subscriber) => {
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commu)) {
        // debugger;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(commu);
        // debugger;
       
        let bne : BoxNonceEntity = new BoxNonceEntity();;
      
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          // debugger;
          this.httpService.postObservable('/api/Member/GetProfileInfo',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            }, 'json2text').subscribe(result =>
            {
                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result))
                {
                   // debugger; 

                  let bne : BoxNonceEntity = new BoxNonceEntity();
                  bne.box = result.box;
                  bne.nonce = result.nonce;
                  profileInfoObject = SlakezSaltServiceStatic.boxUnsalt(bne) as unknown;

                  // debugger;
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileInfoObject))
                  {
                    // debugger;
                    pInfo = JSON.parse(profileInfoObject) as ProfileInfo;
                    // debugger;
                      // debugger;
                      this.profileInfo = new ProfileInfo().copySourceToDestination(this.profileInfo, pInfo);
                      // debugger;
                      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo) && this.profileInfo.sitUserId > 0)
                      {
                        // debugger;
                        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bncomm) && bncomm.sitUserId > 0) {
                          this.dictionaryService.updateDictionary(this.profileInfo, 'profileInfo', bncomm.sitUserId);
                          // debugger;
                        }
                        // debugger;
                        subscriber.next(this.profileInfo);
                        subscriber.complete();                            
                      }
                      else {
                        this.message = StringServiceStatic.stringBuilder(new Date().getTime() + ' :  of getProfileInfoBoxNonce().bncomm.sitUserId== 0 or bncomm was null or undefined.');
                        console.log(this.message)
                        subscriber.next(null);
                        subscriber.complete();
                      }  
                    //}                   
                  }
                  else {
                    this.message = StringServiceStatic.stringBuilder(new Date().getTime() + ' : promise of getProfileInfoBoxNonce() BoxNonceEntity result was null or undefined.');
                    console.log(this.message)
                    subscriber.next(null);
                    subscriber.complete();
                  }
                }
                else {
                  this.message = StringServiceStatic.stringBuilder(new Date().getTime() + ' : promise of getProfileInfoBoxNonce() returned a null result');
                  console.log(this.message)
                  subscriber.next(null);
                  subscriber.complete();
                }
                // console.log('got value ' + result);
                          
            })
        }
        else {
          this.message = StringServiceStatic.stringBuilder(new Date().getTime() + ' : parameter model boxNonceEntity of getProfileInfoBoxNonce() was null or undefined');
          console.log(this.message)
          subscriber.next(null);
          subscriber.complete();
        }
      }
      else {
        this.message = StringServiceStatic.stringBuilder(new Date().getTime() + ' : parameter model Communication of getProfileInfoBoxNonce() was null or undefined');
        console.log(this.message)
        subscriber.next(null);
        subscriber.complete();
      }
    })
  }
  
  
  // ---------------------------------------------------------------
  public processProfileInfoForHeartbeat (hb : Heartbeat) : ProfileInfo {
    let distanceUnit = new Unit();

    // if Heartbeat's distance is not already calculated:
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfoView)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.distance)) {
      if (hb.distance === 0) {
        // compute distance and unit:
        distanceUnit = this.heartbeatService.calculateDistance(hb, this.loginSuccess.heartbeat, true); // (dest, origin, isKm)
        // debugger;

        let tProfileInfoView = this.processDistanceUnitForProfileInfoView(distanceUnit) as ProfileInfo;
        this.profileInfoView = CopyServiceStatic.copyProfileInfoIfExists(this.profileInfoView, tProfileInfoView);
      }
    }
    else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
      this.profileInfoView.distance = hb.distance.toFixed(2);
      this.profileInfoView.unit = hb.unit.length > 0 ? hb.unit : 'meter';
      // debugger;
    }
    // EmitterSubjectService.emitProfileInfoView(this.profileInfoView);
    return this.profileInfoView;
  }
  // ---------------------------------------------------------------
  public processDistanceUnitForProfileInfoView (distanceUnit : Unit) : ProfileInfo {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit.distance)
      && distanceUnit.distance >= 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit.unit)) {
      let result = this.heartbeatService.processDistanceUnit(distanceUnit);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfoView)) {
          this.profileInfoView.distance = distanceUnit.distance.toFixed(2);
          this.profileInfoView.unit = distanceUnit.unit;
        }
      }
      // debugger;
    }
    return this.profileInfoView;
  }
  // ==================================================================
}
