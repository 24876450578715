
import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { Dictionary } from 'dictionaryjs';
import { Observable, Subject } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Chat } from '../../models/chat/chat.model';
import { ChatActivity } from '../../models/chat/chatActivity.model';
import { ChatConversationModel } from '../../models/chat/chatConversationModel';
import { SpinnerModel } from '../../models/common/spinnerModel.model';
import { Communication } from '../../models/communication/communication.model';
import { CommunicationActivity } from '../../models/communication/communicationActivity.model';
import { Communicator } from '../../models/communication/communicator.model';
import { ClientHttpDataModel } from '../../models/data/clientHttpDataModel.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { Pager } from '../../models/pagination/pager.model';
import { PagerChat } from '../../models/pagination/pagerChat.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { DbDexieService } from '../dbServiceService/dbDexieService.service';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { PagerServiceChatHistory } from '../pagerServiceService/pagerServiceChatHistory.service';
import { BackgroundImageAnimationService } from '../rendererServiceService/backgroundImageAnimationService.service';
import { RendererService } from '../rendererServiceService/rendererService.service';
import { ArraySupportServiceStatic } from '../staticServices/arraySupportServiceStatic.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
// import { ChatConversationModel } from '../../../models/communication/chat/chatConversationModel';
// import { ChatActivity } from '../../models/communication/chat/chatActivity.model';



@Injectable({ providedIn: 'any' })

export class ChatService implements OnDestroy {
  
  public currentPageNo = 0;
  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public chat : Chat = new Chat();
  public chats : Chat[] = [];
  public chatDictionary : Dictionary<number, Chat> = new Dictionary<number, Chat>(); 
  public chatMap : Map<any, any> = new Map<any, any>();
  public chatHistoryBnEntities : BoxNonceEntity[] = [];
  public conversationIds : number[] = [];
  public chatActivityArr : ChatActivity[] = [];
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public i = 0;
  public isInitComplete = false;
  public isMobilevar = false;
  public isOnLine = false;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  // public mailBox: MailBox = new MailBox();
  public message = '';
  // public myCommunicationActivityBns: BoxNonceEntity[] = [];
  // public myCommunicationActivityIds: number[] = [];
  // public myEmails: CommunicationActivity[] = [];
  // public myStaticEmails: CommunicationActivity[] = [];
  // public myEmialsMenuKvArr: KvAny[] = [];
  // public myInboxEmails: CommunicationActivity[] = [];
  // public mySavedEmails: CommunicationActivity[] = [];
  // public mySentEmails: CommunicationActivity[] = [];
  // public myTrashedEmails: CommunicationActivity[] = [];
  // public offOnResult: EmitOffOnResult = new EmitOffOnResult();
  // public profileTile: ProfileTile = new ProfileTile();
  // public setupOffOnStatusColorObservable: Observable<any[]> = new Observable<any[]>();

  public myConversations : ChatActivity[] = [];
  public myConversationsBoxNonceArr : BoxNonceEntity[] = [];
  public myInfo : Communicator = new Communicator();
  public myPrimaryImage = '';
  public pagedChats : Chat[] = [];
  public pager : Pager = new Pager();
  public pagerChat : PagerChat = new PagerChat();
  public pageSize = 10; // Max-page-size. important!
  public renderer! : Renderer2;
  public sitUserId = 0;
  public slicedChats : Chat[] = [];
  public spinnerModel : SpinnerModel = new SpinnerModel();
  public tempElem : any;
  // public timerArray: any[] = [];
  public timer : any;
  public timerArr : any[] = [];
  public yourInfo : Communicator = new Communicator();
  public yourPrimaryImage = '';
  public unsalted : string = '';
  // ---------------------------------
  constructor (
    private bias : BackgroundImageAnimationService,
    private dbDexieToDictionaryService : DbDexieToDictionaryService,
    private dbDexieService : DbDexieService,
    private dictionaryService : DictionaryService,
    private httpService : HttpService,
    private pagerServiceChatHistory : PagerServiceChatHistory,
    private rendererService : RendererService,
  ) {
    // if (rendererService) this.renderer = rendererService.getRenderer();
    this.initialize();
  }
  // ---------------------------------------------------------------
  ngOnDestroy () {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.clearTimers();
  }
  // ---------------------------------------------------------------
  attachSentImages (messages : any) : any {
    if (messages && messages.length > 0) {
      messages.map((msg : any) => {
        msg = this.attachSentImage(msg);
      })
    }
    return messages;
  }
  // ---------------------------------------------------------------
  attachSentImagesOfMessages (messages : any) : any {
    if (messages && messages.length > 0) {
      messages.map((msg : any) => {
        if (msg.fileBytes && msg.fileBytes.length > 0) {
          messages.sentPic = '<img src=\'data:image/png;base64,' + messages.fileBytes + '\' class=\'increase sentPic img-responsive img-rounded\'>';
        }
      });
    }
    return messages;
  }
  // ---------------------------------------------------------------
  attachSentImage (msg : any) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(msg)) {
      if (msg.fileBytes && msg.fileBytes.length > 0) {
        msg.sentPic = '<img src=\'data:image/png;base64,' + msg.fileBytes + '\' class=\'increase sentPic img-responsive img-rounded\'>';
      }
    };
    return msg;
  }
  // ---------------------------------------------------------------
  clearTimers () : void {
    this.timerArr.map(e => clearInterval(e));
  }
  // ----------------------------------------------------------------
  //  ref: https://javascript.info/promise-error-handling
  //  NOte:  browser we can catch any uncaught errors using the event `unhandledrejection`:
  //        If an error occurs, and there�s no .catch, the`unhandledrejection` handler triggers,
  //        and gets the event object with the information about the error.
  // ----------------------------------------------------------------
  catchUnhandledPromises () {
    window.addEventListener('unhandledrejection', function (event) {
      // the event object has two special properties:
      console.log('unhandled promise event: ', + event); 
      console.log('unhandled promise: ', + event.promise); 
      console.log('unhandled promise reason: ', + event.reason); 
    });
  }
  // ---------------------------------------------------------------
  checkSenderPics (myconvo : any) : ChatActivity[] {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myconvo) && myconvo.length > 0) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myconvo) && myconvo.length > 0) {
        // debugger;
        for (var i = 0; i < myconvo.length; i++) {
          if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myconvo[ i ].senderPic)) {
            myconvo[ i ].senderPic = '/assets/photos/DefaultImageMale.jpg'; // e.senderPic;
            // debugger;
          }
          // else e.senderPic = '/assets/photos/DefaultImageEmoji.jpg';
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myconvo[ i ].sender) && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myconvo[ i ].sender.primaryImage)) {
            myconvo[ i ].sender.primaryImage = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myconvo[ i ].senderPic) ? myconvo[ i ].senderPic : '/assets/photos/DefaultImageMale.jpg';
            // debugger;
          }
        }
      }
    }
    return myconvo;
  }
  // ---------------------------------------------------------------
  determineSenderReceiverOfChat (chat : Chat) : Chat {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat)) {
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.messageBody)) {
        chat.messageBody.replace('\r\n', '<br/>');
        chat.messageBody.replace('\r', '<br/>');
        chat.messageBody.replace('\n', '<br/>');
      }
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      // check if isSender/isReceiver is null or undefined:
      // --------------------------------------------------
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.isSender)) {
        chat.isSender = false;
      }
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.isReceiver)) {
        chat.isReceiver = false;
      }

      // check using SITUserKeys and SitUserKeys:
      // ----------------------------------------
      if (!chat.isSender && !chat.isReceiver)
      {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.receiverSITUserKey)
          && chat.receiverSITUserKey.indexOf(this.loginSuccess.signedInUserKey) !== -1) {
          chat.isReceiver = true;
          chat.isSender = false;
          // debugger;
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.senderSITUserKey)
          && chat.senderSITUserKey.indexOf(this.loginSuccess.signedInUserKey) !== -1) {
          chat.isSender = true;
          chat.isReceiver = false;
          // debugger;
          console.log('isSender = true; chatActivityId = ' + chat.chatActivityId);
        }

        if (!chat.isSender && !chat.isReceiver) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.receiver)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.receiver.sitUserKey)
            && chat.receiver.sitUserKey.indexOf(this.loginSuccess.signedInUserKey) !== -1) {
            chat.isReceiver = true;
            chat.isSender = false;
            // debugger;
          }
          else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.sender)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.sender.sitUserKey)
            && chat.sender.sitUserKey.indexOf(this.loginSuccess.signedInUserKey) !== -1) {
            chat.isSender = true;
            chat.isReceiver = false;
            // debugger;
          }
        }

        // check using SITUserId and SitUserId:
        // ------------------------------------
        if (!chat.isSender && !chat.isReceiver) {
          if (chat.receiverSITUserId > 0 && chat.receiverSITUserId === this.loginSuccess.signedInUserId) {
            chat.isReceiver = true;
            chat.isSender = false;
            // debugger;
          }
          else if (chat.senderSITUserId > 0 && chat.senderSITUserId === this.loginSuccess.signedInUserId) {
            chat.isSender = true;
            chat.isReceiver = false;
            // debugger;
          }
        }

        // check using receiver & sender:
        // ------------------------------
        if (!chat.isSender && !chat.isReceiver)
        {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.receiver)
            && chat.receiver.sitUserId === this.loginSuccess.signedInUserId) {
            chat.isReceiver = true;
            chat.isSender = false;
            debugger;
          }

          else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.sender)
            && chat.sender.sitUserId === this.loginSuccess.signedInUserId) {
            chat.isSender = true;
            chat.isReceiver = false;
            debugger;
          }
        }
      }
    }
    return chat;
  }
  // ---------------------------------------------------------------
  determineSenderReceiverOfChatActivity (chatA : ChatActivity) : ChatActivity {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatA)) {
      // debugger;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      // check if isSender/isReceiver is null or undefined:
      // --------------------------------------------------
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatA.isSender)) {
        chatA.isSender = false;
      }
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatA.isReceiver)) {
        chatA.isReceiver = false;
      }

      // check using SITUserKeys and SitUserKeys:
      // ----------------------------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatA.receiverSITUserKey)
        && chatA.receiverSITUserKey.indexOf(this.loginSuccess.signedInUserKey) !== -1) {
        chatA.isReceiver = true;
        chatA.isSender = false;
        // debugger;
      }
      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatA.senderSITUserKey)
        && chatA.senderSITUserKey.indexOf(this.loginSuccess.signedInUserKey) !== -1) {
        chatA.isSender = true;
        chatA.isReceiver = false;
        // debugger;
        console.log('isSender = true; chatActivityId = ' + chatA.chatActivityId);
      }   
    }
    return chatA;
  }
  // ---------------------------------------------------------------
  determineSenderReceiverOfChats (chats : Chat[]) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chats) && chats.length > 0) {
      chats.forEach(c => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(c)) {
          c = this.determineSenderReceiverOfChat(c);
        }
      });
      return chats;
    }
    else return null;
  }

  // ---------------------------------------------------------------
  // ref:https:// www.positronx.io/angular-8-es-6-typescript-promises-examples/
  ePromise (data : any) : any {
    const promise = new Promise((resolve, reject) => {
      let timer = setTimeout(() => {
        console.log('promise returning resolve!');
        resolve(data);
      }, 1000);
      clearTimeout(this.timer);
    });
    return promise;
  }
  // ---------------------------------------------------------------
  getChats () : Chat[] {
    return this.chats;
  }
  // ---------------------------------------------------------------
  //  Not in use:
  // ---------------------------------------------------------------
  getConversationChatActivitiesObservable (sitUserId : number) : Observable<any> {
    var tChatIds : any
    var unsalted : any;
    var chatActs : ChatActivity[] = [];
    var ccm : ChatConversationModel = new ChatConversationModel();
    return new Observable((subscriber) => {
      if (sitUserId > 0) {
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        // debugger;
        const bnComm : Communication = new Communication();
        bnComm.sitUserId = sitUserId;
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        this.boxNonceEntity = this.salt(bnComm);
        // debugger;
        try {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
            // debugger;
            this.httpService.postObservable('api/Chat/GetConversationChatActivities', {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
            }, 'json2text')
              .subscribe(result => {
                 debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result) && result.length > 0)
                {
                  chatActs = result as ChatActivity[]; // JSON.parse(result as string) as ChatActivity[];
                  debugger;
                  subscriber.next(chatActs); 

                  // The following code is when result is salted:
                  // --------------------------------------------
                  //const bn = result as BoxNonceEntity
                  //if (SlakezSaltServiceStatic.isBoxUnsalt(bn))
                  //{
                  //  unsalted = SlakezSaltServiceStatic.unsalted as unknown;
                  //   debugger;
                  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
                  //    chatActs = JSON.parse(unsalted) as ChatActivity[];
                  //  }
                  //   debugger;
                  //  subscriber.next(chatActs);
                  //}
                  //else {
                  //  // debugger;
                  //  subscriber.next(chatActs);  
                  //}
                }
                else {
                  // debugger;
                  this.message = StringServiceStatic.stringBuilder('Error occured in getChatConversationModelObservable(sitUserId:\'' + sitUserId + '\');\n<br/> result: ' + result);
                  console.log(this.message);
                  // debugger;
                  subscriber.next(chatActs);  
                }
              })
          }
          else {
            this.message = StringServiceStatic.stringBuilder('Error occured in getChatConversationModelObservable(sitUserId:\'' + sitUserId + '\') box-nonce were null or undefined. ');
            console.log(this.message);
          }
        }
        catch (error) {
          this.message = StringServiceStatic.stringBuilder('Error occured in getChatConversationModelObservable(sitUserId:\'' + sitUserId + '\') try error-message: ' + error.message);
          console.log(this.message);
        }
      }
      else {
        this.message = StringServiceStatic.stringBuilder('Error occured in getChatConversationModelObservable(sitUserId:\'' + sitUserId + '\');');
        console.log(this.message);
      }
    })
  }
  // ---------------------------------------------------------------
  // This method will slice an array at an interval of 10,
  // and send a zagged array(array of array, [[],[],...]).
  // ---------------------------------------------------------------
  //  Currently in use:
  // ---------------------------------------------------------------
  getZaggedArrayOfCommIdModArray (ids : number[]) : KvAny[] {
    var commIdsModKvArr :KvAny[] = [];
    var aModArr : number[] = [];
    var j = 0;
    var isAsc = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ids) && ids.length > 0) {
      // -------------------------------------------------------------------------------------------------------
      // Note:  if the Ids are sorted according to the need, which is to obtain the chats in descending order,
      //        and to get the latest chat messages sooner byu getting higher numberd chats as the early chunks.
      // -------------------------------------------------------------------------------------------------------
      let sortedIds = this.sortByItem(ids, isAsc);

      var totalDivs = Math.floor(ids.length / 10);
      var diff = ids.length - (totalDivs * 10);
      var i = 1;
      for (; i < totalDivs + 1; i++) {
        for (j; j < (i * 10); j++) {
          aModArr.push(ids[ j ]);
          // debugger;
        }
        // debugger;
        var commIdsModKv = new KvAny();
        commIdsModKv.key = j.toString();
        commIdsModKv.value = JSON.parse(JSON.stringify(aModArr));
        commIdsModKvArr.push(commIdsModKv);
        aModArr = [];
      }
      // debugger;
      aModArr = [];
      for (var k = totalDivs * 10; k < diff; k++) {
        aModArr.push(ids[ k ]);
      }
      var commIdsModKv = new KvAny();
      commIdsModKv.key = j.toString();
      commIdsModKv.value = JSON.parse(JSON.stringify(aModArr));
      commIdsModKvArr.push(commIdsModKv);
      aModArr = [];
    }
    // debugger;
    return commIdsModKvArr;
  }
  // ---------------------------------------------------------------
  //  Currently in use:
  // ---------------------------------------------------------------
  getConversationIdsObservable (sitUserId : number) : Observable<any> {
    var tChatIds : any
    var unsalted : any;
    return new Observable((subscriber) => {
      if (sitUserId > 0) {
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        // debugger;
        const bnComm : Communication = new Communication();
        bnComm.sitUserId = sitUserId;
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        this.boxNonceEntity = this.salt(bnComm);
        // debugger;
        try {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
            // debugger;
            this.httpService.postObservable('api/Chat/GetConversationIds', {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
            }, 'json2text')
              .subscribe(result => {
                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                  const bn = result as BoxNonceEntity
                  if (SlakezSaltServiceStatic.isBoxUnsalt(bn)) {
                    unsalted = SlakezSaltServiceStatic.unsalted;

                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
                      tChatIds = JSON.parse(unsalted) as number[];

                      // debugger;
                      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tChatIds) && tChatIds.length > 0) {
                        this.conversationIds = tChatIds;

                        // save the conversationIds of sitUser whom the signedInUser is having a conversation with:
                        // ----------------------------------------------------------------------------------------
                        this.dictionaryService.updateDictionary(this.conversationIds, 'ChatConversationId', sitUserId);
                        // debugger;

                        // save salted conversationIds in indexedDb:
                        // -----------------------------------------
                        this.dbDexieToDictionaryService
                          .saveDictionaryToDbDexie(
                            this.dictionaryService.chatConversationIdDictionary, 'chatConversationId', this.loginSuccess.signedInUserId);

                        EmitterSubjectService.emitCurrentFocus('chatMsgFocus');
                        // debugger;

                        subscriber.next(this.conversationIds);
                        subscriber.complete();
                        // debugger;
                      }
                      else {
                        // debugger;
                        // TODO: check the relevance of this segment of the messaging to the user:
                        // -----------------------------------------------------------------------
                        if (this.yourInfo.profileName?.length > 0) {
                          this.message = 'Currently there is no message/conversation between you and ';
                          this.message += this.yourInfo.profileName + '. <br/>To start a conversation, please send a message via the below<br/> Chat Message Console.';
                          // EmitterSubjectService.emitMyErrorLog( this.message );
                          EmitterSubjectService.emitMessage(this.message);
                        }
                        else {
                          this.message = 'Currently there is no message/conversation between you and ' + this.yourInfo.profileName;
                          this.message += '. <br/>To start a conversation, please send a message via the Chat Message Console below.';
                          this.timer = setTimeout(() => {
                            // EmitterSubjectService.emitMyErrorLog( this.message );
                            EmitterSubjectService.emitMessage(this.message);
                          }, 2000);
                          clearTimeout(this.timer);
                          // Note:uniqueAddTimer
                          if (!DateStringServiceStatic.isTimerFound(this.timerArr, this.timer)) {
                            this.timerArr.push(this.timer);
                          }
                        }
                      }
                    }
                    else {
                      this.message = StringServiceStatic.stringBuilder('Error occured in getConversationIds(sitUserId:\'' + sitUserId + '\') unsalted data was null or undefined. ');
                      console.log(this.message);
                    }
                  }
                  else {
                    this.message = StringServiceStatic.stringBuilder('Error occured in getConversationIds(sitUserId:\'' + sitUserId + '\') unsalting failed. ');
                    console.log(this.message);
                  }
                }
                else {
                  // debugger;
                  this.message = StringServiceStatic.stringBuilder('Error occured in getConversationIds(sitUserId:\'' + sitUserId + '\');\n<br/> result: ' + result);
                  // EmitterSubjectService.emitMyErrorLog( this.message );
                  // EmitterSubjectService.emitMessage(this.message);
                  console.log(this.message);
                }
              })
          }
          else {
            this.message = StringServiceStatic.stringBuilder('Error occured in getConversationIds(sitUserId:\'' + sitUserId + '\') box-nonce were null or undefined. ');
            console.log(this.message);
          }
        }
        catch (error) {
          this.message = StringServiceStatic.stringBuilder('Error occured in getConversation(sitUserId:\'' + sitUserId + '\') try error-message: ' + error.message);
          console.log(this.message);
        }
      }
      else {
        this.message = StringServiceStatic.stringBuilder('Error occured in getConversationIds(sitUserId:\'' + sitUserId + '\');');
        console.log(this.message);
      }
    })
  }
  // ---------------------------------------------------------------
  // The MyChatComponent has similar method that it uses:
  // ---------------------------------------------------------------
  getMyConversations () : Observable<any> {
    let dbChats : any;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    const url = 'api/Chat/GetMyConversations';
    this.chat = new Chat();
    this.chat.signedInUserId = this.loginSuccess.signedInUserId;
    this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(this.chat);
    // debugger;
    return new Observable((subscriber) => {
      this.httpService.post<Chat[]>(url, {
        box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
        nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
      }, 'json').subscribe((data : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
          // debugger;
          let unsalted = SlakezSaltServiceStatic.boxUnsalt(data as BoxNonceEntity);
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
            dbChats = JSON.parse(unsalted) as ChatActivity[];
            // debugger;
          }
          else {
            console.log('getMyConversations (sitUserId :  ' + this.loginSuccess.signedInUserId + ') unsalted data is null or undefined.');
          }
          this.myConversations = [];
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dbChats) && dbChats.length > 0) {
            dbChats.forEach(item => {
             
              var chatAct = JSON.parse(item) as ChatActivity;
              // debugger;
              if (!StringServiceStatic.isNullOrEmpty(chatAct.date)) { 
                chatAct.dateFormatted = DateStringServiceStatic.convertNetTicsToJsDateString(chatAct.date);
              }
              this.myConversations.push(chatAct);
            })
            
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.myConversations) && this.myConversations.length > 0) {
              // debugger;
              this.myConversations = this.checkSenderPics(this.myConversations);
              
              // debugger;
              EmitterSubjectService.setMyConversations(this.myConversations);

              this.dictionaryService.updateDictionary(this.myConversations, 'myChat', this.loginSuccess.signedInUserId);
            }

            EmitterSubjectService.emitMyConversations(this.myConversations);
            subscriber.next(this.myConversations);
            // debugger;
            subscriber.complete();
          }
          else {
            this.message = StringServiceStatic.stringBuilder('getMyConversations (sitUserId :  ' + this.loginSuccess.signedInUserId + ') unsalted and parsed data is null or undefined.');
            console.log(this.message);
          }
        }
        else {
          this.message = StringServiceStatic.stringBuilder('getMyConversations (sitUserId :  ' + this.loginSuccess.signedInUserId + ') returned a null or undefined result.');
          console.log(this.message);
        }
      })
    })
  }

  // ---------------------------------------------------------------
  //  Currently Not in use:
  // ---------------------------------------------------------------
  fetchSlicedConversationObservable (sitUserId : number, commIds : number[]) : Observable<any> {
    // debugger;
    const clientData : ClientHttpDataModel = new ClientHttpDataModel();
    return new Observable<Chat[]>(subscriber => {
      if (sitUserId > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commIds) && commIds.length > 0) {
     
        // debugger;        
        clientData.sitUserId = sitUserId;
        clientData.signedInUserId = this.loginSuccess.signedInUserId;
        clientData.commActIds = commIds;
        this.boxNonceEntity = this.salt(clientData);

        this.spinnerModel.message = 'Getting chat messages...';
        EmitterSubjectService.emitShowSpinner(this.spinnerModel);

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          // debugger;

          this.httpService.postObservable('api/Chat/GetSlicedConversation', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
          }, 'json2text').subscribe(result => {
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
              const bn = result as BoxNonceEntity;
              if (SlakezSaltServiceStatic.isBoxUnsalt(bn)) {
                this.unsalted = SlakezSaltServiceStatic.unsalted as string;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.unsalted)) {
                  // debugger;
                  this.spinnerModel.message = '';
                  EmitterSubjectService.emitHideSpinner(true);
                  this.processUnsaltedSlicedDataObservable(this.unsalted).subscribe(data => {
                    // debugger;
                    subscriber.next(data);
                    subscriber.complete();
                  });
                }
              }
            }
          })
        }    
      }
      else {
        this.message = 'Error occured in fetchSlicedSlicedConversation(sitUserId:\'' + sitUserId + '\') && commmActIds: ' + commIds + '; ';
        console.log(this.message);
      }
    })
  }
  // ---------------------------------------------------------------
  initialize () : any {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    EmitterSubjectService.loginSuccessEmitter
      .subscribe(result => {
        this.loginSuccess = result;
        this.emitterDestroyed$.next(true);
        this.emitterDestroyed$.complete();
        this.emitterDestroyed$.unsubscribe();
      });
    EmitterSubjectService.isMobileEmitter.subscribe(result => {
      this.isMobilevar = result;
      this.emitterDestroyed$.next(true);
      this.emitterDestroyed$.complete();
      this.emitterDestroyed$.unsubscribe();
    });
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    this.catchUnhandledPromises();
    return true;
  }
  // ---------------------------------------------------------------
  isBoxNonceEntityFound( chatBneArr: BoxNonceEntity[], chatBne: BoxNonceEntity ): number {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatBneArr)
      && chatBneArr.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatBne)) {
      // debugger;
      for (let i = 0; i < chatBneArr.length; i++) {
        if (chatBneArr[i].id === chatBne.id) {
          // debugger;
          return i;
        }
      }
    }
    return -1;
  }
  // ---------------------------------------------------------------
  isChatFound( chatArr: Chat[], chat: Chat ): number {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatArr)
      && chatArr.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat)) {
      // debugger;
      for (let i = 0; i < chatArr.length; i++) {
        if (chatArr[ i ].chatActivityId === chat.chatActivityId || chatArr[i].chatMessageId === chat.chatMessageId) {
          // debugger;
          return i;
        }
      }
    }
    return -1;
  }
  // ---------------------------------------------------------------
  isChatIdFound (chatIdArr : any, chat : any ): number {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatIdArr)
      && chatIdArr.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat)) {
      // debugger;
      for (let i = 0; i < chatIdArr.length; i++) {
        if (chatIdArr[i] === chat.chatActivityId) {
          // debugger;
          return i;
        }
      }
    }
    return -1;
  }
  // ---------------------------------------------------------------
  isChatActFound (chatActArr : ChatActivity[], chatAct : ChatActivity) : number {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatActArr)
      && chatActArr.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatAct)) {
      // debugger;
      for (let i = 0; i < chatActArr.length; i++) {
        if (chatActArr[ i ].chatActivityId === chatAct.chatActivityId) {
          // debugger;
          return i;
        }
      }
    }
    return -1;
  }
  // ---------------------------------------------------------------
  isChatActFoundByChatMessageId (chatActArr : ChatActivity[], chatMessageId : number) : number {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatActArr)
      && chatActArr.length > 0 && chatMessageId > 0) {
      // debugger;
      for (let i = 0; i < chatActArr.length; i++) {
        if (chatActArr[ i ].chatMessageId === chatMessageId) {
          // debugger;
          return i;
        }
      }
    }
    return -1;
  }
  // ---------------------------------------------------------------
  isSenderFound( senders: Communicator[], sender: Communicator ): number {
    if (senders.length > 0 && sender) {
      for (let i = 0; i < senders.length; i++) {
        if (senders[i].sitUserId === sender.sitUserId) {
          // debugger;
          return i;
        }
      }
    }
    return -1;
  }

  // -----------------------------------------------------------
  nullPromise () : any {
    this.timer = setTimeout(() => {
      // debugger;
    }, 500);
    clearTimeout(this.timer);
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  // ---------------------------------------------------------------
  //  This method is used when backend sends the chat-data along with
  //  the sender and receiver info with it. Hence in  this back-end
  //  version, the back-end determines the receiver and sender.
  //
  //  Note: This is different than processChatResult() method that 
  //        is used for clent that fetches the yourCommunicatiorInto and
  //        determines the receiver and sender on the client side.
  // ---------------------------------------------------------------
  processChatAndYourInfoResult (commActId : number, result : any) : any {
    let chat : Chat = new Chat();
    let tChat1 : any;
    let tChat2 : any;
    if (result) {
      const bn = result;
      // debugger;
      if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
        tChat1 = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as CommunicationActivity;
        // debugger;
        // MUST Check the tChat's data !!!
        // console.log("chat-commActId: " + commActId + "\n" + tChat1);
        try {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tChat1)) {
            tChat2 = JSON.parse(tChat1) as CommunicationActivity;
            // debugger;
          }
        }
        catch (e) {
          console.log("error.message: " + e.message);
          tChat2 = JSON.parse(JSON.stringify(JSON.parse(tChat1))) as CommunicationActivity
          // debugger;
        }
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tChat2)) {
          chat = tChat2 as Chat;
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat)) {
            let sitUserId = 0;
            if (chat.senderSITUserId > 0 && chat.senderSITUserId !== this.loginSuccess.signedInUserId) {
              sitUserId = chat.senderSITUserId;
            }
            else if (chat.senderSitUserId > 0 && chat.senderSitUserId !== this.loginSuccess.signedInUserId) {
              sitUserId = chat.senderSitUserId;
            }
            else if (chat.sender && chat.sender.sitUserId > 0 && chat.sender.sitUserId !== this.loginSuccess.signedInUserId) {
              sitUserId = chat.sender.sitUserId;
            }
            if (chat.receiverSITUserId > 0 && chat.receiverSITUserId !== this.loginSuccess.signedInUserId) {
              sitUserId = chat.receiverSITUserId;
            }
            else if (chat.receiverSitUserId > 0 && chat.receiverSitUserId !== this.loginSuccess.signedInUserId) {
              sitUserId = chat.receiverSitUserId;
            }
            else if (chat.receiver && chat.receiver.sitUserId > 0 && chat.receiver.sitUserId !== this.loginSuccess.signedInUserId) {
              sitUserId = chat.receiver.sitUserId;
            }

            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat)) {
              // debugger;
              const dateAssay = DateStringServiceStatic.getDateAssayOfCsTicks(chat.date);
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dateAssay)) {
                chat.dateFormatted = dateAssay.dateFormatted;
              }

              if ((FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.receiver) || chat.receiver.sitUserId === 0)
                && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.receiverModelString)) {
                chat.receiver = JSON.parse(chat.receiverModelString) as Communicator;
                // debugger;
              }
              if ((FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.sender) || chat.sender.sitUserId === 0)
                && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.senderModelString)) {
                chat.sender = JSON.parse(chat.senderModelString) as Communicator;
                // debugger;

                // debugger;
                // if (email.subject.length > 150) email.subjectShort = email.subject.slice(0, 149) + '...';

                // if (email.messageBody.length > 200) email.messageBodyShort = email.messageBody.slice(0, 199) + '...';
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.subject)) {
                  if (chat.subject.length >= 100) {
                    chat.subjectShort = StringServiceStatic.stringBuilder(chat.subject.slice(0, 99) + '...');
                  }
                  else {
                    chat.subjectShort = chat.subject;
                  }
                }
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.messageBody)) {
                  if (chat.messageBody.length >= 150) {
                    chat.messageBodyShort = StringServiceStatic.stringBuilder(chat.messageBody.slice(0, 149) + '...');
                  }
                  else {
                    chat.messageBodyShort = chat.messageBody;
                  }
                }
              }
              // debugger;
              return chat;
            }
          }
        }
        else {
          EmitterSubjectService.emitMyErrorLog('Error occured in getChat(commActId:\'' + commActId + '\');\n result: ' + result);
          return null;
        }
      }
    }
  }
  // ---------------------------------------------------------------
  processChatMessageBody (chat : Chat) : Chat {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat)) {
      // debugger;
      // if (email.subject.length > 150) email.subjectShort = email.subject.slice(0, 149) + '...';

      // if (email.messageBody.length > 200) email.messageBodyShort = email.messageBody.slice(0, 199) + '...';
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.subject)) {
        if (chat.subject.length >= 100) {
          chat.subjectShort = StringServiceStatic.stringBuilder(chat.subject.slice(0, 99) + '...');
        }
        else {
          chat.subjectShort = chat.subject;
        }

      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.messageBody)) {
        if (chat.messageBody.length >= 150) {
          chat.messageBodyShort = StringServiceStatic.stringBuilder(chat.messageBody.slice(0, 149) + '...');
        }
        else {
          chat.messageBodyShort = chat.messageBody;
        }
      }
    }
    return chat;
  }
  // ---------------------------------------------------------------
  processCommunicatorResult (sitUserId : number, result : any) : any {
    let commCator : Communicator = new Communicator();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)){
      // debugger;
      const bn = result;
      if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
        var unsalted = SlakezSaltServiceStatic.boxUnsalt(bn);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
          commCator = JSON.parse(unsalted) as Communicator;
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commCator)) {
            this.yourInfo = commCator;
            EmitterSubjectService.emitCommunicator(commCator);
            return commCator;
          }
        }
      }
    }
    else {
      this.message = StringServiceStatic.stringBuilder('Error occured in GetCommunicator(sitUserId:\'' + sitUserId + '\');\n<br/> result: ' + result);
      EmitterSubjectService.emitMyErrorLog(this.message);
      return null;
    }
  }

  // ---------------------------------------------------------------
  processUnsaltedSlicedDataObservable (unsalted : string) : Observable<Chat[]> {
    var slicedChatArr : Chat[] = [];
    var commActStrArr : string[] = [];
    var tCommAct : CommunicationActivity = null;
    var tChat : Chat = null;
    this.unsalted = unsalted
    // debugger;
    return new Observable<Chat[]>((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.unsalted) && this.unsalted.length > 0) {
        if (this.unsalted.toLowerCase().indexOf('communicationactivityid') !== -1) {

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.unsalted)) {
            this.chats = JSON.parse(unsalted) as Chat[];
            // debugger
            for (var i = 0; i < this.chats.length; i++) {
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chats[ i ])) {
                this.chats[ i ] = this.attachSentImage(this.chats[ i ]);
                // debugger;

                // -----------------------------------------------------------------------------------------------
                // Note:  instead of having each chat message carry the sender's and receiver's primaryImage, 
                //        we use the service's yourInfo and loginSuccess to render these images and profileNames.
                // -----------------------------------------------------------------------------------------------
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.yourInfo)
                  && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.yourInfo.primaryImage)) {
                  // debugger;
                  this.chats[ i ] = this.setupChatSenderReceiverInfoOfChat(this.chats[ i ], this.yourInfo);
                }


                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chats[ i ])) {
                  this.chats[ i ] = this.setupDateAssayOfChat(this.chats[ i ]);
                }
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chats[ i ])) {

                  this.chats[ i ] = this.determineSenderReceiverOfChat(this.chats[ i ]);
                }
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chats[ i ])) {
                  // debugger;                 
                  EmitterSubjectService.emitCurrentFocus('chatMsgFocus');
                  this.chatDictionary.set(this.chats[ i ].chatActivityId, this.chats[ i ]);
                }
                else {
                  this.message = StringServiceStatic.stringBuilder("processUnsaltedSlicedData()-> tChat==null or undefined-1");
                  console.log(this.message);
                }
              }
              else {
                this.message = StringServiceStatic.stringBuilder("processUnsaltedSlicedData()-> tChat==null or undefined-2");
                console.log(this.message);
              }
            }
            this.loginSuccess = EmitterSubjectService.getLoginSuccess();
            this.chats = this.chatDictionary.size > 0 ? this.chatDictionary.values() : [];
            // debugger;
            // Note: chatDictionary where the incoming data is held is different than the ChatConversationDictionary.
            this.dictionaryService.updateDictionary(this.chats, "ChatConversation", this.loginSuccess.signedInUserId);
            // debugger;
            console.log("ChatConversation.size: " + this.dictionaryService.chatConversationDictionary.size);
            EmitterSubjectService.emitConversation(this.chats);
            subscriber.next(this.chats);
            subscriber.complete();
          }
        }

      }
      else {
        this.message = StringServiceStatic.stringBuilder("processUnsaltedSlicedData unsalted data was null or undefined-3.");
        console.log(this.message);
      }
    })
  }
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  runViewTask (chats : Chat[], cPageNo ?: number) : PagerChat {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chats) && this.chats.length > 0) {
      this.pagerServiceChatHistory.setChats(this.chats);
      EmitterSubjectService.setChats(this.chats);
      this.pageSize = this.pageSize > 0 ? this.pageSize : 15;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cPageNo) && cPageNo > 0 && this.isInitComplete) {
        this.pagerChat = this.pagerServiceChatHistory.setPagerChatDynamic(this.chats, cPageNo, this.pageSize);
      }
      else {
        // if currentPageNo, i.e. cPageNo is not provided, we need to first create a pager with 1, then with lastPageNo:
        this.pagerChat = EmitterSubjectService.getPagerChat();
        //  Now set the  page to the last page from the pager:
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerChat)) {
          this.pagerChat = this.pagerServiceChatHistory.setPagerChatDynamic(this.chats, this.pagerChat.currentPageNo, this.pageSize);
        }
        else {
          this.pagerChat = this.pagerServiceChatHistory.setPagerChatDynamic(this.chats, 1, this.pageSize);
          // this.pagerChat = this.pagerServiceChatHistory.setPagerChatDynamic(this.chats, this.pagerChat.totalPageNo, this.pageSize);
        }
      }

      this.currentPageNo = this.pagerChat.currentPageNo;

      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerChat)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerChat.pagedChats)
        && this.pagerChat.pagedChats.length > 0) {
        this.pagedChats = this.pagerChat.pagedChats;

        // Note:  pagedChats are in descending order. but we show the latest chat messgae closest to the typing area,
        //        for which the order has to be ascending, so we just reverse the order of the array.
        this.pagedChats = ArraySupportServiceStatic.reverseArrayOnItself(this.pagedChats);
      }
      this.isInitComplete = true;
      return this.pagerChat;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  salt (model : any ): BoxNonceEntity {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      return this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt(JSON.stringify(model));
    }
    else {
      return new BoxNonceEntity();
    }
  }

  // ---------------------------------------------------------------
  setChats (chats : Chat[]) : void {
    this.chats = chats;
  }
  // ---------------------------------------------------------------
  setupDateAssayOfChat (chat : Chat) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat)) {
      const dateAssay = DateStringServiceStatic.getDateAssayOfCsTicks(chat.date);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dateAssay)) {
        chat.dateFormatted = dateAssay?.date?.toLocaleString();
        if (/*FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.timeLapsed) && */
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.date)) {
          // debugger;
          var date = DateStringServiceStatic.convertNetTicsToJsDate(chat.date);
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(date)) {
            // debugger;
            //ref: https://stackoverflow.com/questions/3552461/how-do-i-format-a-date-in-javascript
            chat.timeLapsed = chat.dateFormatted; // DateStringServiceStatic.timeAgoSince(date);
            // chat.timeLapsedPlaceholder = chat.timeLapsed.slice(0, 40) + '...';
          }
				}
      }
      // debugger;          
      return chat;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  setupDateAssayOfChatActivity (chatAct : ChatActivity) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatAct)) {
      const dateAssay = DateStringServiceStatic.getDateAssayOfCsTicks(chatAct.date);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dateAssay)) {
        chatAct.dateFormatted = dateAssay?.date?.toLocaleString();
        if (/*FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat.timeLapsed) && */
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatAct.date)) {
          // debugger;
          var date = DateStringServiceStatic.convertNetTicsToJsDate(chatAct.date);
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(date)) {
            // debugger;
            //ref: https://stackoverflow.com/questions/3552461/how-do-i-format-a-date-in-javascript
            chatAct.timeLapsed = chatAct.dateFormatted; // DateStringServiceStatic.timeAgoSince(date);
            // chat.timeLapsedPlaceholder = chat.timeLapsed.slice(0, 40) + '...';
          }
        }
      }
      // debugger;          
      return chatAct;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  setupDateAssayOfChats (chats : Chat[]) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chats) && chats.length > 0) {
      chats?.forEach(e => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e)) {
          e = this.setupDateAssayOfChat(e);
        }
      });
      return chats;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  setChatMesssageImage (chat: Chat) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat)) {
      var elemId = '';
      if (chat.isSender) {
        elemId = StringServiceStatic.stringBuilder("sender-" + this.chat.chatActivityId);
        // this.bias.setImage(elemId, this.chat.receiverPic);
        // or
        // this.bias.setBackgroundStillImageForElemId(elemId, this.chat.senderPic);
        debugger;
        this.bias.updateBackgroundImageOutsideAngularById(elemId, this.chat.receiverPic);
      }
      if (this.chat.isReceiver) {
        elemId = StringServiceStatic.stringBuilder("receiver-" + this.chat.chatActivityId);
        // this.bias.setImage(elemId, this.chat.senderPic);
        // or
        // this.bias.setBackgroundStillImageForElemId(elemId, this.chat.senderPic);
        // debugger;
        this.bias.updateBackgroundImageOutsideAngularById(elemId, this.chat.senderPic)
      }
    }
	}
  // ---------------------------------------------------------------
  setupChatSenderReceiverInfoOfChat(chat : Chat, yourInfo: Communicator) : any {
    // debugger;   
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(yourInfo) || yourInfo.sitUserId === 0) {
      yourInfo = EmitterSubjectService.getCommunicator();
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(yourInfo)) {
        chat.sender = yourInfo;
        chat.senderPic = yourInfo.primaryImage;
        chat.senderProfileName = yourInfo.profileName;
        chat.senderSitUserId = chat.senderSITUserId = yourInfo.sitUserId;
        chat.senderSitUserKey = chat.senderSITUserKey = yourInfo.sitUserKey;    
      }
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      chat.receiverPic = this.loginSuccess.primaryImage;
      chat.receiverProfileName = this.loginSuccess.profileName;
      chat.receiverSITUserId = chat.receiverSitUserId = this.loginSuccess.signedInUserId;
      chat.receiverSITUserKey = chat.receiverSitUserKey = this.loginSuccess.signedInUserKey;
      // debugger;
      return chat;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  setupChatSenderReceiverInfoOfChats (chats : Chat[], yourInfo : Communicator) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(yourInfo)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chats)
      && chats.length > 0) {
      // debugger;
      chats.forEach(c => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(c)) {
          var tC = this.setupChatSenderReceiverInfoOfChat(c, yourInfo);
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tC)) {
            c = tC;
          }
        }
      });
      return chats;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  //  Currently Not in use:
  // ---------------------------------------------------------------
  setupSlicedChatConversation (sitUserId : number) : Observable<Chat[]|any> | any{

    return new Observable<Chat[]|any>(subscriber => {
      var c = 0;
      var commIdArr : number[] = [];
      var commIdsModKvArr : KvAny[] = [];
      var kvAny : KvAny = new KvAny();
      var modChats : Chat[] = [];
      var tChatArr : Chat[] = [];

      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      this.spinnerModel.message = 'Getting chat messages...';
      EmitterSubjectService.emitShowSpinner(this.spinnerModel);
      if (sitUserId > 0) {
        // get the conversationIds then get the conversation-messages in segments/sliced:
        // ------------------------------------------------------------------------------
        this.getConversationIdsObservable(sitUserId).subscribe(data => {
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && data.length > 0) {
            // NOTE: ChatHistoryId dictionary is saved in DexieDb in ChatService.ts    
            this.conversationIds = data
            // debugger;     

            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.conversationIds) && this.conversationIds.length > 0) {
              // debugger;
              commIdsModKvArr = this.getZaggedArrayOfCommIdModArray(this.conversationIds);
              // Get the chats:
              // --------------
              // debugger;
              c = 0; // Note: needs to be  outside thef or loop. important!
              for (; c < commIdsModKvArr.length; c++) {
                // this method holds the incoming data into a dictionary:
                // ------------------------------------------------------
                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commIdsModKvArr)
                  && commIdsModKvArr.length > 0 && c > -1 && c < commIdsModKvArr.length) {

                  kvAny = commIdsModKvArr[ c ] as KvAny;
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvAny)) {
                    commIdArr = kvAny.value as number[];
                  }
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commIdArr) && commIdArr.length > 0) {
                    // this.chatService = this.communicationServiceService?.getChatService();
                    // debugger;

                    this.fetchSlicedConversationObservable(sitUserId, commIdArr).subscribe((sChatArr) => {
                      // debugger;
                      // -----------------------------------------------
                      //  we want the chats in descending order in order
                      //  to get the latest chat messages sooner.
                      //  
                      //  And at the PagerServiceChatHistory, the pagedChats
                      //  are reversed because we want to display the latest
                      //  message close to the chat-message-typing-UI.
                      // -----------------------------------------------
                      // debugger;
                      this.chats = this.chatDictionary.size > 0 ? this.chatDictionary.values() : [];

                      // -------------------------------------------------------------------
                      //  Note: runViewTask(a, b?) has an optional currentPageNo parameter.
                      //        if the parameter exists, then it will set to that page, 
                      //        otherwise, it will set to the last page.
                      // -------------------------------------------------------------------
                      this.pagerChat = this.runViewTask(this.chats);

                      // debugger;
                      // ------------------------------------------------------------------------------------
                      // save the chats at the following cases:
                      //  1. if the total chats exceed the total conversationIds (in case of chat duplicate)
                      //  2. when all chats have arrived equalling total conversationIds.
                      //  3. at each slice of chats arrival
                      // ------------------------------------------------------------------------------------
                      if (this.chats.length === this.conversationIds.length) {
                        EmitterSubjectService.emitHideSpinner(true);

                      // debugger;                      
                      // if (DictionaryServiceStatic.chatHistoryDictionary.size() > 0) {
                         debugger;
                        this.dbDexieToDictionaryService
                          .saveChatConversationDictionaryToDbDexie(
                            this.dictionaryService.chatConversationDictionary, 'chatConversation', this.loginSuccess.signedInUserId);
                        console.log(new Date().getTime() + ': ChatConversationDictionary is saved in the indexedDb.')
                      }
                    //  }
                      subscriber.next(this.chats);
                    })
                  }
                  else {
                    this.message = 'chatService.setupSlicedChatConversation.getSlicedConversation (commIdArr[ ' + c + ']) is null oir undefined or empty.';
                    console.log(this.message);
                  }
                }
                else {
                  this.message = 'chatService.setupSlicedChatConversation() is null or undefined.';
                  console.log(this.message);
                }
              } // end of for-loop            
            }
            else {
              this.message = 'chaService.setupSlicedChatConversation.getSlicedConversation (sitUserId :  ' + this.loginSuccess.signedInUserId + ') conversationIds data is null or undefined.';
              console.log(this.message);
            }
          }
          else {
            this.message = "setupSlicedChatConversation.getConversationIds() returned null.";
            console.log(this.message);
          }
        })        
      }
     
    })
  }
  // ---------------------------------------------------------------
  sort( chatArr: Chat[] ): Chat[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatArr) && chatArr.length > 0) {
      chatArr.sort ( (a, b) => {
        return (a.chatActivityId as number) - (b.chatActivityId as number);
      });
    }
    return chatArr;
  }
  // ---------------------------------------------------------------
  sortByDate (items : any, isAsc : any) : any {
    if (isAsc) {
      items = [ ...items.sort((a : any, b : any) => Number(b.date) - Number(a.date)) ];
    }
    else {
      items = [ ...items.sort((a : any, b : any) => Number(a.date) - Number(b.date)) ];
    }
    return items;
  }

  // ---------------------------------------------------------------
  sortById (items : any, isAsc : any) : any {
    if (isAsc) {
      items = [ ...items.sort((a : any, b : any) => a.id - b.id) ];
    }
    else {
      items = [ ...items.sort((a : any, b : any) => b.id - a.id) ];
    }
    return items;
  }

  // ---------------------------------------------------------------
  sortByItem (items : any, isAsc : any) : any {
    if (isAsc) {
      items = [ ...items.sort((a : any, b : any) => a - b) ];
    }
    else {
      items = [ ...items.sort((a : any, b : any) => b - a) ];
    }
    return items;
  }
  // ---------------------------------------------------------------
  // ref:https:// github.com/angular/components/issues/13854
  sortByMsgId (items : any, isAsc : any) : any {
    if (isAsc) {
      items = [ ...items.sort((a : any, b : any) => b.commMessageId - a.commMessageId) ];
    }
    else {
      items = [ ...items.sort((a : any, b : any) => a.commMessageId - b.commMessageId) ];
    }
    return items;
  }
  // --------------------------------------------
  sortChatArr (chats : Chat[], isAsc : boolean) : Chat[] {
    if (isAsc) {
      return chats.sort((a, b) => (a.chatActivityId as number) - (b.chatActivityId as number));
    }
    else {
      return chats.sort((a, b) => (b.chatActivityId as number) - (a.chatActivityId as number));
    }
  }
  // ---------------------------------------------------------------
  spinnerOff () {
    this.spinnerModel.message = '';
    EmitterSubjectService.emitHideSpinner(this.spinnerModel);
  }
  // ---------------------------------------------------------------
  uniqueAddChat( chatArr: Chat[], chat: Chat ): Chat[] {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat)) {
      // debugger;
      const index = this.isChatFound(chatArr, chat);
      if (index < 0) {
        chatArr.push(chat);
      }
      else {
        // chatArr[index] = CopyServiceStatic.copyChatIfExists(chatArr[index], chat);
        chatArr.splice( index, 1, chat );
      }
      //chatArr = this.sort(chatArr);
      //return chatArr;
      chatArr = chatArr.sort((a, b) => { return a.chatActivityId - b.chatActivityId });
      return chatArr
    }
    else {
      return chatArr;
    }
  }

  // ---------------------------------------------------------------
  uniqueAddChatId( chatIdArr: number[], chat: Chat ): number[] {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat)) {
      // debugger;
      const index = this.isChatIdFound(chatIdArr, chat);
      if (index < 0) {
        chatIdArr.push(chat.chatActivityId);
      }
      //chatIdArr = chatIdArr.sort((a, b) => a - b);
      //return chatIdArr;
      // debugger;
      return chatIdArr.sort((a, b) => a - b);
    }
    else {
      return [];
    }
  }
  // ---------------------------------------------------------------
  uniqueAddChatActivity (chatActArr : ChatActivity[], chatAct : ChatActivity) : ChatActivity[] {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatActArr) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatAct)) {
      // debugger;
      const index = this.isChatActFound(chatActArr, chatAct);
      if (index < 0) {
        chatActArr.push(chatAct); //debugger;
      }
      else {
        chatActArr.splice(index, 1, chatAct);
      }
      chatActArr = chatActArr.sort((a, b) => { return a.chatActivityId - b.chatActivityId });
      return chatActArr
    }
    else {
      // debugger;
      chatActArr = chatActArr.sort((a, b) => { return a.chatActivityId - b.chatActivityId });
      return chatActArr;
    }
  }

  // ---------------------------------------------------------------
  uniqueAddChatBoxNonceEntity( chatBneArr: BoxNonceEntity[], chatBne: BoxNonceEntity ): BoxNonceEntity[] {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatBne)) {
      // debugger;
      const index = this.isBoxNonceEntityFound(chatBneArr, chatBne);
      if (index < 0) {
        chatBneArr.push(chatBne);
      }
      else {
        chatBneArr[index].id = chatBne.id;
        chatBneArr[index].box = chatBne.box;
        chatBneArr[index].nonce = chatBne.nonce;
      }
      this.sortById(chatBneArr, chatBne);
      return chatBneArr;
    }
    else {
      return [];
    }
  }
  
  // ---------------------------------------------------------------
  // Note: This method does the following:
  //       1. accepts input as string that was sent from the client and then directly sent back to the client by Hub
  //       2. it goes through the similar process of extracting the unit8Arr of the box and nonce from the JSON-string.
  //       3. then it unsalts the box and nonce into a Chat model
  // Note: Hub goes through a similar extracting process since it accepts only string as parameter, and no Cutom-Model-Parameter
  // ---------------------------------------------------------------
  unwrapData( boxedData: string ): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxedData)) {
      const chatBn = JSON.parse(boxedData) as BoxNonceEntity;
      // debugger;
      //const boxArr: number[] = [];
      //const nonceArr: number[] = [];
      //const boxArr : Uint8Array;
      //const nonceArr : Uint8Array = [];
      //const boxObj = Object.entries(chatBn.box);
      //const nonceObj = Object.entries(chatBn.nonce);
      //// debugger;

      //// extracting values
      //nonceObj.map (e => {
      //  const bi = e as any[];
      //  nonceArr.push(Number(bi[1]));
      //});

      //// debugger;
      //boxObj.map (e => {
      //  const bi = e as any[];
      //  boxArr.push(Number(bi[1]));
      //});

      const boxArrB64Str = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(chatBn.box);
      const nonceArrB64Str = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(chatBn.nonce);
      // debugger;
      const tBn: BoxNonceEntity = new BoxNonceEntity();
      tBn.box = boxArrB64Str;
      tBn.nonce = nonceArrB64Str;
      const chat = SlakezSaltServiceStatic.boxUnsalt(tBn) as Chat;
      tBn.id = chat.chatActivityId;
      tBn.sitUserId = chat.sitUserId;
      // -------------------------------------------------------
      // Note: Temporary location for saving to indexedDb
      //     It should be at a component
      // -------------------------------------------------------
      this.updateDexieDbChatHistory(tBn, tBn.sitUserId);
      // debugger;
      return chat;
    }
    else {
      return '';
    }
  }
  // ---------------------------------------------------------------
  updateDexieDbChatHistory( boxedTile: BoxNonceEntity, sitUserId: number ): void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxedTile)) {
      boxedTile.key = boxedTile?.id?.toString(); // to be used by indexedDb
      boxedTile.sitUserId = sitUserId; // to uniquely identify a user's data
      boxedTile.entityName = 'chatHistory';
      boxedTile.date = DateStringServiceStatic.getTicks(new Date()).toString();
      // debugger;
      this.boxNonceEntity = boxedTile;
      // Note: instead of ChatComponent doing the saving of chats into indexedDb, we do it here:
      this.dbDexieService.addModelObservable(this.boxNonceEntity).subscribe(data => {
        requestAnimationFrame(() => {
          console.log('updateIndexedDbChatHistory() is successful for: ' + data);
        });
      })
    }
  }
  // ---------------------------------------------------------------
  // Note: parameter value of of type {'0':3}
  //      Not used. Deprecated.
  // ---------------------------------------------------------------
  fetchChat (commActid : number) : Observable<any> {
    const bnComm : Communication = new Communication();
    let chat : Chat = new Chat();
    let message = '';
    let chatId = 0;
    var commActId = commActid;
    if (commActId > 0) {
      chatId = commActId;
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) || this.loginSuccess.signedInUserId === 0) {
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      }
      // debugger;  
      return new Observable((subscriber) => {

        bnComm.chatActivityId = commActId;
        if (this.loginSuccess.signedInUserId > 0) {
          bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        }
        this.boxNonceEntity = this.salt(bnComm);
        //  debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          this.httpService.postObservable('api/Chat/GetChat', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
          }, 'json2text').subscribe(result => {
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
              // debugger;
              chat = this.processChatResult(chatId, result);
              // debugger;
              subscriber.next(chat);
              // subscriber.complete();
            }
            else {
              message = 'GetChat (commActId :  ' + commActId + ');  returned a null or undefined result.';
              console.log(message);
            }
          }, (error) => {
            console.log(error);
          });
        }
        else {
          message = 'GetChat (commActId :  ' + commActId + ');  box-nonce wasnull or undefined.';
          console.log(message);
        }
      });
    }
  }
  // ---------------------------------------------------------------
  //  Not in use:
  // ---------------------------------------------------------------
  fetchChatActivity (commActid : number) : Observable<any> {
    const bnComm : Communication = new Communication();
    let chatAct : ChatActivity = new ChatActivity();
    let dbChats: any;
    let message = '';
    let chatId = 0;
    var commActId = commActid;
    if (commActId > 0) {
      chatId = commActId;
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) || this.loginSuccess.signedInUserId === 0) {
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      }
      // debugger;  
      return new Observable((subscriber) => {
        bnComm.chatActivityId = commActId;
        if (this.loginSuccess.signedInUserId > 0) {
          bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        }
        this.boxNonceEntity = this.salt(bnComm);
        //  debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          this.httpService.postObservable('api/Chat/PostChatActivity', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
          }, 'json2text').subscribe(result => {
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
              // debugger;
              dbChats = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result)) as ChatActivity[];
              // var chatActs = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result)) as ChatActivity[];
              // var chatActs = result as ChatActivity[];

              debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dbChats) && dbChats.length > 0) {
                chatAct = dbChats[ 0 ] as ChatActivity;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatAct) && chatAct.chatActivityId > 0) {
                  // debugger;
                  chatAct = this.determineSenderReceiverOfChatActivity(chatAct);
                  // debugger;
                  this.dictionaryService.updateDictionary(chatAct, "ChatActivity", chatAct.chatActivityId);
                  this.chatActivityArr = EmitterSubjectService.getChatHistoryArr();
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chatActivityArr)) {
                    this.chatActivityArr = this.uniqueAddChatActivity(this.chatActivityArr, chatAct);
                    EmitterSubjectService.setChatHistoryArr(this.chatActivityArr);
                    // debugger;
                    subscriber.next(chatAct);
                  }
                }
                else {
                  subscriber.next(new ChatActivity());
                }
              }
            }
            else {
              message = 'GetChatActivity (commActId :  ' + commActId + ');  returned a null or undefined result.';
              console.log(message);
            }
          }, (error) => {
            console.log(error);
          });
        }
        else {
          message = 'GetChatActivity (commActId :  ' + commActId + ');  box-nonce wasnull or undefined.';
          console.log(message);
        }
      });
    }
  }
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  // Note: parameter value of of type {'0':3}
  //      Not used. Deprecated.
  // ---------------------------------------------------------------
  fetchChatMessage (commActid : number) : Observable<any> {
    const bnComm : Communication = new Communication();
    let chat : Chat = new Chat();
    let message = '';
    let chatId = 0;
    var commActId = commActid;
    if (commActId > 0) {
      chatId = commActId;
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) || this.loginSuccess.signedInUserId === 0) {
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      }
      // debugger;  
      return new Observable((subscriber) => {

        bnComm.chatMessageId = commActId;
        if (this.loginSuccess.signedInUserId > 0) {
          bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        }
        this.boxNonceEntity = this.salt(bnComm);
        //  debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          this.httpService.postObservable('api/Chat/PostChatMessage', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
          }, 'json2text').subscribe(result => {
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
              // debugger;
              subscriber.next(result);
              // subscriber.complete();
            }
            else {
              message = 'fetchetChatMessage (commActId :  ' + commActId + ');  returned a null or undefined result.';
              console.log(message);
            }
          }, (error) => {
            console.log(error);
          });
        }
        else {
          message = 'fetchetChatMessage (commActId :  ' + commActId + ');  box-nonce wasnull or undefined.';
          console.log(message);
        }
      });
    }
  }
  // ---------------------------------------------------------------
  getvalue (e : any) : number {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e) && e.toString().indexOf(':') !== -1) {
      const parts = e.toString().split(':');
      // debugger;
      if (parts.length === 2) {
        return Number(parts[ 1 ]);
      }
    }
    return -1; // TODO: check if it is the proper default value
  }

  // ---------------------------------------------------------------
  // NOTE: This method is intended to be deprecated!
  // Note: getting the entire conversation takes a while.
  //       therefore, we first get the conversationIds, and then
  //       get the individual chat of the respective id, and accumulate into chats
  //
  //        Not in use:
  // ---------------------------------------------------------------
  getConversation (sitId : number) : Observable<any> {

    var sitUserId = sitId;
    return new Observable<any>(subscriber => {
      if (sitUserId > 0) {
        // debugger;
        const bnComm : Communication = new Communication();
        bnComm.sitUserId = sitUserId;
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        this.boxNonceEntity = this.salt(bnComm);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          // debugger;
          this.httpService.postObservable('api/Chat/GetConversation', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
          }, 'json2text')
            .subscribe(result => {
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                const bn = result;
                const tChatMessages = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result as BoxNonceEntity)) as Chat[];
                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tChatMessages) && tChatMessages.length > 0) {
                  // var tchats = this.attachSentImages(tChatMessages);
                  // debugger;
                  this.chats = this.determineSenderReceiverOfChats(tChatMessages);
                  this.chats = this.setupDateAssayOfChats(this.chats);
                  this.chats = this.attachSentImages(this.chats);
                  // this.ngAfterViewInit();
                  EmitterSubjectService.emitConversation(this.chats);
                  EmitterSubjectService.emitCurrentFocus('chatMsgFocus');
                  subscriber.next(this.chats);
                  subscriber.complete();
                  // debugger;
                }
                else {
                  // debugger;
                  if (this.yourInfo.profileName?.length > 0) {
                    this.message = 'Currently there is no message/conversation between you and ' + this.yourInfo.profileName;
                    this.message += '. <br/>To start a conversation, please send a message via the below<br/> Chat Message Console.';
                    EmitterSubjectService.emitMyErrorLog(this.message);
                  }
                  else {
                    this.message = 'Currently there is no message/conversation between you and ' + this.yourInfo.profileName;
                    this.message += '. <br/>To start a conversation, please send a message via the Chat Message Console below.';
                    this.timer = setTimeout(() => EmitterSubjectService.emitMyErrorLog(this.message), 2000);
                    clearTimeout(this.timer);
                    // Note:uniqueAddTimer
                    if (!DateStringServiceStatic.isTimerFound(this.timerArr, this.timer)) {
                      this.timerArr.push(this.timer);
                    }
                  }
                }
              }
              else {
                // debugger;
                this.message = 'Error occured in getConversation(sitUserId:\'' + sitUserId + '\');\n<br/> result: ' + result;
                EmitterSubjectService.emitMyErrorLog(this.message);
              }
            }, error => {
              // debugger;
              // alert ('Error occured in UpdateParagraph();\n Error-mag:' + error);
              this.message = 'Error occured in getConversation(sitUserId:\'' + sitUserId + '\');\n<br/> Error-message: ' + error.message;
              EmitterSubjectService.emitMyErrorLog(this.message);
            });
        }
      }
    })
  }

  // ---------------------------------------------------------------
  getConversationIdsPromise (sitUserId : number) : Promise<any> {
    var tChatIds : any
    var unsalted : any;

    if (sitUserId > 0) {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      // debugger;
      const bnComm : Communication = new Communication();
      bnComm.sitUserId = sitUserId;
      bnComm.signedInUserId = this.loginSuccess.signedInUserId;
      this.boxNonceEntity = this.salt(bnComm);
      // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
        // debugger;
        return new Promise((resolve, reject) => {
          // debugger;
          this.httpService.post('api/Chat/GetConversationIds', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
          }, 'json')
            .subscribe(result => {
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                const bn = result as BoxNonceEntity
                unsalted = SlakezSaltServiceStatic.boxUnsalt(bn);
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
                  tChatIds = JSON.parse(unsalted) as number[];
                }
                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tChatIds) && tChatIds.length > 0) {
                  //  We wanna show the latest messages first,
                  //  so reverse the order of chat - communicationActivityIds and 
                  //  setpager to the first page.important!
                  // -------------------------------------------------------------
                  this.conversationIds = ArraySupportServiceStatic.reverseArrayOnItself(tChatIds);

                  // save the chatIds in indexedDb
                  bn.id = sitUserId; // to be used by indexedDb
                  bn.key = bn.id.toString(); // to be used by indexedDb
                  bn.sitUserId = sitUserId; // to uniquely identify a user's data
                  bn.date = DateStringServiceStatic.getTicks(new Date()).toString();
                  bn.entityName = 'ChatConversationId';
                  // debugger;

                  // save salted conversationIds in indexedDb
                  this.dictionaryService.updateDictionary(bn, 'ChatConversationId', this.loginSuccess.signedInUserId);
                  // debugger;
                  this.dbDexieToDictionaryService
                    .saveDictionaryToDbDexie(
                      this.dictionaryService.chatConversationIdDictionary, 'chatConversationId', this.loginSuccess.signedInUserId);

                  EmitterSubjectService.emitCurrentFocus('chatMsgFocus');
                  // debugger;
                  resolve(this.conversationIds);
                  // debugger;
                }
                else {
                  // debugger;
                  if (this.yourInfo.profileName?.length > 0) {
                    this.message = 'Currently there is no message/conversation between you and ';
                    this.message += this.yourInfo.profileName + '. <br/>To start a conversation, please send a message via the below<br/> Chat Message Console.';
                    // EmitterSubjectService.emitMyErrorLog( this.message );
                    EmitterSubjectService.emitMessage(this.message);
                  }
                  else {
                    this.message = 'Currently there is no message/conversation between you and ' + this.yourInfo.profileName;
                    this.message += '. <br/>To start a conversation, please send a message via the Chat Message Console below.';
                    this.timer = setTimeout(() => {
                      // EmitterSubjectService.emitMyErrorLog( this.message );
                      EmitterSubjectService.emitMessage(this.message);
                    }, 2000);
                    clearTimeout(this.timer);
                    // Note:uniqueAddTimer
                    if (!DateStringServiceStatic.isTimerFound(this.timerArr, this.timer)) {
                      this.timerArr.push(this.timer);
                    }
                  }
                }
              }
              else {
                // debugger;
                this.message = 'Error occured in getConversation(sitUserId:\'' + sitUserId + '\');\n<br/> result: ' + result;
                // EmitterSubjectService.emitMyErrorLog( this.message );
                EmitterSubjectService.emitMessage(this.message);
              }
            }, error => {
              // debugger;
              // alert ('Error occured in UpdateParagraph();\n Error-mag:' + error);
              this.message = 'Error occured in getConversation(sitUserId:\'' + sitUserId + '\');\n<br/> Error-message: ' + error.message;
              // EmitterSubjectService.emitMyErrorLog( this.message );
              EmitterSubjectService.emitMessage(this.message);
            });

        });
      }
      else return this.nullPromise();
    }
    else return this.nullPromise();
  }

  // ---------------------------------------------------------------
  processChatResult (commActId : number, result : BoxNonceEntity) : any {
    let bn : any;
    let chat : Chat = new Chat();
    let commAct : any;
    let comActArr : any[] = [];
    let unsalted : any;
    if (result) {
      bn = result;
      // debugger;
      if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
        try {
          //debugger;
          unsalted = SlakezSaltServiceStatic.boxUnsalt(bn);
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
            commAct = JSON.parse(unsalted) as CommunicationActivity;
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct)) {
              chat = commAct as Chat;
              // debugger;


              //this.yourInfo = EmitterSubjectService.getCommunicator();
              //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chat)) {
              //  chat = this.processChatMessage(chat);

              //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.yourInfo)
              //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.yourInfo.primaryImage)) {
              //    // debugger;
              //    chat = this.setupChatSenderReceiverInfoOfChat(chat, this.yourInfo);
              //    // debugger;
              //  }

              //  chat = this.setupDateAssayOfChat(chat);

              //  chat = this.determineSenderReceiverOfChat(chat);
              //  // debugger;
              //  this.chats = this.uniqueAddChat(this.chats, chat);
              //  EmitterSubjectService.setChats(this.chats);
              //  this.pagerChat = this.setupChatHistoryPager(this.chats);
              //  EmitterSubjectService.emitPagerChat(this.pagerChat);
              return chat;
              //}
              //else return null;
            }
            else return null;
          }
          else return null;
        }
        catch (e) {
          this.message = StringServiceStatic.stringBuilder("error.message: " + e.message);
          console.log(this.message);
          // debugger;
          return null;
        }
      }
      else return null;
    }
    else {
      EmitterSubjectService.emitMyErrorLog('Error occured in getChat(commActId:\'' + commActId + '\');\n result: ' + result);
      return null;
    }
  }
  // ---------------------------------------------------------------
  processUnsaltedSlicedData (unsalted : string) : Chat[] {
    var slicedChatArr : Chat[] = [];
    var commActStrArr : string[] = [];
    var tCommAct : CommunicationActivity = null;
    var tChat : Chat = null;
    this.unsalted = unsalted
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.unsalted) && this.unsalted.length > 0) {
      if (this.unsalted.toLowerCase().indexOf('communicationactivityid') !== -1) {

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.unsalted)) {
          this.chats = JSON.parse(unsalted) as Chat[];
          // debugger
          for (var i = 0; i < this.chats.length; i++) {
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chats[ i ])) {
              this.chats[ i ] = this.attachSentImage(this.chats[ i ]);
              // debugger;

              // -----------------------------------------------------------------------------------------------
              // Note:  instead of having each chat message carry the sender's and receiver's primaryImage, 
              //        we use the service's yourInfo and loginSuccess to render these images and profileNames.
              // -----------------------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.yourInfo)
                && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.yourInfo.primaryImage)) {
                // debugger;
                // tChats = this.setupChatSenderReceiverInfoOfChats(tChats, this.yourInfo);
                // debugger;
                this.chats[ i ] = this.setupChatSenderReceiverInfoOfChat(this.chats[ i ], this.yourInfo);
              }


              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chats[ i ])) {
                this.chats[ i ] = this.setupDateAssayOfChat(this.chats[ i ]);
              }
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chats[ i ])) {

                this.chats[ i ] = this.determineSenderReceiverOfChat(this.chats[ i ]);
              }
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chats[ i ])) {
                // debugger;                 

                EmitterSubjectService.emitCurrentFocus('chatMsgFocus');
                this.chatDictionary.set(this.chats[ i ].communicationActivityId, this.chats[ i ]);

              }
              else {
                this.message = StringServiceStatic.stringBuilder("processUnsaltedSlicedData()-> tChat==null or undefined-1");
                console.log(this.message);
              }
            }
            else {
              this.message = StringServiceStatic.stringBuilder("processUnsaltedSlicedData()-> tChat==null or undefined-2");
              console.log(this.message);
            }
          }
          // debugger;
          this.chats = this.chatDictionary.size > 0 ? this.chatDictionary.values(): [];
          this.loginSuccess = EmitterSubjectService.getLoginSuccess();
          // Note: chatDictionary where the incoming data is held is different than the ChatConversationDictionary.
          this.dictionaryService.updateDictionary(this.chats, "ChatConversation", this.loginSuccess.signedInUserId);
          debugger;
          console.log("ChatConversation.size: " + this.dictionaryService.chatConversationDictionary.size);
          // debugger;
          EmitterSubjectService.emitConversation(this.chats);
          return this.chats;
        }
      }

    }
    else {
      this.message = StringServiceStatic.stringBuilder("processUnsaltedSlicedData unsalted data was null or undefined-3.");
      console.log(this.message);
    }
  }
  // ---------------------------------------------------------------
}
