<div>
  <span class="whiteBlueInvB"> Feature-name :</span>
  <span class="text-left blkB" style="opacity:1;font-size:1rem;">
    {{appFeature.featureName}}
  </span>
</div>
<div>
  <span class="whiteBlueInvB">Premium (max):</span>
  <span class="text-left blkB" style="opacity:1;">
    <span *ngIf="appFeature.isPrimary" class="glyphicon glyphicon-ok-circle" style="font-size: 1rem;color: forestgreen;" aria-hidden="true"></span>
    <span *ngIf="appFeature.maxPrimaryCount.length > 0" style="opacity:1;font-size:1rem;">{{appFeature.maxPrimaryCount}}</span>
  </span>
</div>
<div>
  <span class="whiteBlueInvB">Valued (max):</span>
  <span class="text-left blkB" style="opacity:1;">
    <span *ngIf="appFeature.maxValuedCount.length === 0" class="glyphicon glyphicon-remove-circle" style="font-size: 1rem;color: darkred;" aria-hidden="true"></span>
    <span *ngIf="appFeature.maxValuedCount.length > 0" style="opacity:1;font-size:1rem;">{{appFeature.maxValuedCount}}</span>
  </span>
</div>
<br />
<div>
  <span class="whiteBlueInvB">Description: </span>
  <span class="wordwrap text-left blkB" style="opacity:1;font-size:1rem;">{{ appFeature.description}}</span>
</div>
<hr class="hrGray" style="display:inline-block;width:98%;padding:0;margin:0 2.5rem 0 0rem;opacity:1;" />
<!------------------------------------------------------------------------------------------------------------------>

