
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Communication } from '../../models/communication/communication.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { DictionaryServiceService } from '../dictionaryServiceService/dictionaryServiceService.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { MessageBuilderServiceStatic } from '../staticServices/messgeBuilderServiceStatic.service';
@Injectable({
  providedIn: 'any',
})
export class SitUserService {
  public dictionaryService! : DictionaryService;
  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public isMyProfile = false;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public sitUser : SitUser = new SitUser(); // for sending data to the server
  public signedInUser : SitUser = new SitUser();
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  public message = '';
  public timer : any;
  public timerArray : any[] = [];
  constructor (
    private dictionaryServiceService : DictionaryServiceService,
    public httpService : HttpService,
    public dbDexieToDictionaryService: DbDexieToDictionaryService,
  ) {
    if (this.dictionaryServiceService) {
      this.dictionaryService = this.dictionaryServiceService.getDictionaryService();
    }
  }

  // ---------------------------------------------------------------
  ngOnDestroy () : any {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    this.timerArray.forEach((timer) => clearInterval(timer));
    return true;
  }
  // -----------------------------------------------------------
  nullPromise () : any {
    this.timer = setTimeout(() => {
      // debugger;
      return null;
    }, 500);
    clearTimeout(this.timer);
    if (this.timer) {
      this.timerArray.push(this.timer);
    }
  }
  // ---------------------------------------------------------------
  salt (model : any) : BoxNonceEntity | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      // debugger;
      this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt(JSON.stringify(model));
      return this.boxNonceEntity;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  getProfileName (sitUserId : number) : Promise<any> {
    // debugger;
   
    return new Promise((resolve, reject) => {
      if (sitUserId > 0) {
        // debugger;
        let profileName = '';
        this.sitUser.sitUserId = sitUserId;
        this.boxNonceEntity = this.salt(this.sitUser);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          // debugger;
          this.httpService.post('/api/Member/GetProfileName',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
            }, 'json').subscribe((result) => {
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                profileName = SlakezSaltServiceStatic.boxUnsalt(result) as string;
                // debugger;
                resolve(profileName);
              }
              else {
                this.message = MessageBuilderServiceStatic.printMessage(sitUserId, 'the returned data of getProfileName(sitUserId:\'' + sitUserId + '\') was null or undefined');
                reject(this.message);
              }
            })
        }
        else {
          this.message = MessageBuilderServiceStatic.printMessage(sitUserId, 'boxNonceEntity\'s data was null or undefined in GetProfileName(sitUserId:\'' + sitUserId + '\')');
          console.log(this.message);
          // reject(this.nullPromise());
          reject(this.message);
        }
      }
      else {
        this.message = MessageBuilderServiceStatic.printMessage(sitUserId, 'Error occured in GetProfileName()');
        console.log(this.message);
        // reject(this.nullPromise());
        reject(this.message);
      }
    }).catch((error) => {
      this.message = MessageBuilderServiceStatic.printMessage(sitUserId, ' promise of getProfileName() has some errors. Error-mag: ' + error);
      console.log(this.message);
    });
  }
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  // Note:  this.processSitUserResult() returns a promise,
  //        therefore, this method returns a promise as well
  // ---------------------------------------------------------------
  // Note: this XHR api call will trigger top to bottom changeDetection
  // ---------------------------------------------------------------
  public fetchSitUser (situserid : number) : Observable<any> {
    // debugger;
    let unsaltedSitUser : SitUser = null;
    let sitUserId = situserid;   
    let tsitUser : any;
    return new Observable((subscriber) => {
      // debugger;
      if (!EmitterSubjectService.isSitUserIdCalled(sitUserId)) {
        // debugger;
        EmitterSubjectService.setSitUserIdCalled(sitUserId);
        // debugger;
        if (sitUserId > 0) {
          // debugger;
          this.sitUser = new SitUser();
          this.sitUser.sitUserId = sitUserId;
          this.boxNonceEntity = this.salt(this.sitUser);
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {

            // debugger;
            this.httpService.postObservable('Member/GetSitUser',
              {
                box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
                nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
              }, 'json2text').subscribe((result) => {
                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result) 
                  && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result.box) 
                  && result.box.length > 0
                  && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result.nonce)
                  && result.nonce.length > 0) {
                  // debugger;
                  let bn : BoxNonceEntity = new BoxNonceEntity();
                  bn.box = result.box;
                  bn.nonce = result.nonce;
                  var unsalted = SlakezSaltServiceStatic.boxUnsalt(bn);
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
                    tsitUser = JSON.parse(unsalted) as SitUser;


                   // debugger;
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tsitUser)) {
                      //let tp = JSON.parse(tsitUser.primaryImage) as any;
                      //// debugger;
                      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tp)) {
                      //  tsitUser.primaryImage = tp.pic;
                      // debugger;
                      this.sitUser = tsitUser as SitUser;
                      // debugger;
                      this.dictionaryService.sitUserDictionary.set(this.sitUser.sitUserId, this.sitUser); // important!
                      // debugger;
                      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
                      this.dbDexieToDictionaryService
                        .saveDictionaryToDbDexie(
                          this.dictionaryService.sitUserDictionary, 'sitUser', this.loginSuccess.signedInUserId);
                      // debugger;
                      subscriber.next(tsitUser);
                      //}
                    }
                    else {
                      this.message = MessageBuilderServiceStatic.printMessage(sitUserId, 'unsalting bn failed for GetSitUser(sitUserId: \'' + sitUserId + '\'); tsitUser: ' + tsitUser);
                      console.log(this.message);
                      // debugger;
                    }
                  }
                }
                else {
                  this.message = MessageBuilderServiceStatic.printMessage(sitUserId, 'result or result.box or result.nonce was null for GetSitUser(sitUserId:\'' + sitUserId + '\'); result: ' + result);
                  console.log(this.message);
                  // debugger;
                }
              })
          }
          else {
            this.message = MessageBuilderServiceStatic.printMessage(sitUserId, 'bn/bn.box/bn.nonce were null or undefined in GetSitUser(sitUserId:\'' + sitUserId + '\')');
            console.log(this.message);
          }
        }
        else {
          this.message = MessageBuilderServiceStatic.printMessage(sitUserId, 'sitUserId == 0 in GetSitUser()');
          console.log(this.message);
        }
     
      }
      else {
        // debugger;
        subscriber.next(this.getSitUserFromDictionary(sitUserId));
        subscriber.complete();
      }
    })
  }
  // ---------------------------------------------------------------
  getSitUser () : SitUser {
    return this.sitUser;
  }
  // ---------------------------------------------------------------
  getSitUserById (id : number) : SitUser {
    let sitUserId : number = id;
    this.sitUser = this.getSitUserFromDictionary(sitUserId);
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser)) {
      return this.sitUser;
    }
    else {
      this.fetchSitUser(sitUserId).subscribe(data => {
        this.sitUser = data;
        return this.sitUser;
      })
    }
  }
  // ---------------------------------------------------------------
  getSitUserFromDictionary (sitUserId : number) : SitUser | any {
    if (sitUserId > 0) {
      this.sitUser = EmitterSubjectService.getSitUserModel();
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) || this.sitUser.sitUserId === 0) {
        // debugger;
        return this.dictionaryService.sitUserDictionary.get(sitUserId);
      }
      else if (this.sitUser.sitUserId === sitUserId) {
        return this.sitUser;
      }
      else return null;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  getSitUserFromDictionaryOrServer (situserid : number) : Observable<SitUser> | any {   
    let sitUserId : number = situserid;

    return new Observable<SitUser>((subscriber) => {
      if (sitUserId > 0) {
        this.sitUser = this.dictionaryService.sitUserDictionary.get(sitUserId);
        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) || this.sitUser.sitUserId === 0) {
          // debugger;
          console.log('calling fetchSitUser() from sitUserService@ln248')
          this.fetchSitUser(sitUserId).subscribe(data => {
            this.sitUser = data;
            subscriber.next(this.sitUser);
            subscriber.complete();
          })
        }
        else {
          subscriber.next(this.sitUser);
          subscriber.complete();
        }
      }
      else {
        subscriber.next(null);
        subscriber.complete();
      }
    })
	}
  // ---------------------------------------------------------------
  // Note : This method will get 100 SitUserIds at a time
  // ---------------------------------------------------------------
  // Note: this XHR api call will trigger top to bottom changeDetection
  // ---------------------------------------------------------------
  getBatchOfSitUserIds (bIndex : number) : Observable<any> {
    // debugger;
    let beginIndex : number = bIndex;
    let batchOfSitUserIds : number[] = [];
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    return new Observable((subscriber) => {
      if (beginIndex > -1) {
        const bnComm : Communication = new Communication();

        bnComm.beginIndex = beginIndex;
        bnComm.sitUserId = 0;
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        // debugger;
        this.boxNonceEntity = this.salt(bnComm);

        // debugger;
        if (this.boxNonceEntity) {
          this.httpService.post('Member/GetBatchOfSitUserIds', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
          },
            'json',
          ).subscribe((result) => {
            // debugger;
            var unsalted = SlakezSaltServiceStatic.boxUnsalt(result as BoxNonceEntity);
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
              batchOfSitUserIds = JSON.parse(unsalted) as number[];
            }
            // debugger;
            this.dictionaryService.updateDictionary(batchOfSitUserIds, 'sitUserId', this.loginSuccess.signedInUserId);
            // debugger;
            // Note: the sitUserId is saved on the indexedDb by the caller method:
            // -------------------------------------------------------------------
            // debugger;
            this.dbDexieToDictionaryService
              .saveDictionaryToDbDexie(
                this.dictionaryService.sitUserIdDictionary, 'sitUserId', this.loginSuccess.signedInUserId);

            EmitterSubjectService.emitHideSpinner(true);
            subscriber.next(batchOfSitUserIds);
            subscriber.complete();
            // debugger;
          })
        }
        else {
          this.message = MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, 'the boxNonceEntity\'s data was null or undefined in sitUserService.getBatchOfSitUserIds()');
          console.log(this.message);
        }
      }
      else {
        this.message = MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, 'the boxNonceEntity\'s data was null or undefined in sitUserService.getBatchOfSitUserIds()');
        console.log(this.message);
      }
    })
  }

  // ---------------------------------------------------------------
  isSignedInUserProfile (sitUser : SitUser) : boolean {
    let isSignedInUserProfile = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && sitUser.sitUserId > 0) {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if (sitUser.sitUserId == this.loginSuccess.signedInUserId) {
        isSignedInUserProfile = true;
      }
    }
    return isSignedInUserProfile;
  }
  // ---------------------------------------------------------------
  processBatchOfMemberIdsResult (result : any) : any {
    let tBatchOfIds : number[] = [];
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
      const bn = result;
      tBatchOfIds = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result as BoxNonceEntity)) as number[];
      // debugger;
    }
    return tBatchOfIds;
  }

  // ---------------------------------------------------------------
  processSitUserResult (sitUserId : number, result : any) : Promise<any> | any {
    let tSitUser : SitUser = null;
    let situserId = sitUserId;
    let reSult = result;
    return new Promise((resolve, reject) => {
      if (situserId > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(reSult)) {

        const bn = reSult as BoxNonceEntity;
        // debugger;

        // debugger;
        if (bn.box.length > 0 && bn.nonce.length > 0) {
          // debugger;
          let tsitUser = SlakezSaltServiceStatic.boxUnsalt(bn) as undefined;
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tsitUser)) {
            tSitUser = JSON.parse((tsitUser as string).toString()) as SitUser;
            // debugger;
          }
          else {
            this.message = MessageBuilderServiceStatic.printMessage(sitUserId, 'unsalting bn failed for GetSitUser(sitUserId:\'' + sitUserId + '\'); bn: ' + result);
            console.log(this.message);
            // debugger;
            reject(null);
          }
        }
        else {
          this.message = MessageBuilderServiceStatic.printMessage(sitUserId, 'bn or bn.box or bn.noncewas null for GetSitUser(sitUserId:\'' + sitUserId + '\'); result: ' + result);
          console.log(this.message);
          // debugger;
          reject(null);
        }

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tSitUser) && tSitUser.sitUserId > 0 && tSitUser.sitUserId === sitUserId) {
          // debugger;

          // Note:  getSitUserModel & setSitUserModel do not have an associated EmitterMethod
          //        but there is an emitSitUser that sets the same value of sitUser on EmitterSubjectService
          //        which can be obtained via getSitUserModel or getSitUser
          //
          //        !!!! DO NOT EMIT sitUser: EmitterSubjectService.emitSitUser(this.sitUser); !!!!     
          // -----------------------------------------------------------------------------------------------
          this.loginSuccess = EmitterSubjectService.getLoginSuccess();
          if (tSitUser.sitUserId > 0 && tSitUser.sitUserId === this.loginSuccess.signedInUserId) {
            this.signedInUser = tSitUser;
            // debugger;
            EmitterSubjectService.setSignedInUser(tSitUser); // DO NOT EMIT sitUser
          }
          else {
            // debugger;
            this.sitUser = tSitUser;
            EmitterSubjectService.setSitUserModel(tSitUser); // DO NOT EMIT sitUser
          }

          // save the sitUser in dictionary (unencrypted):
          this.dictionaryService.updateDictionary(tSitUser, 'sitUser', tSitUser.sitUserId);
          this.dictionaryService.updateDictionary(tSitUser.sitUserId, 'sitUserId', tSitUser.sitUserId);

          // debugger;
          // this.loginSuccess = EmitterSubjectService.getLoginSuccess();
          if (this.loginSuccess.signedInUserId > 0) {
            // debugger;
            this.dbDexieToDictionaryService
              .saveDictionaryToDbDexie(this.dictionaryService.sitUserDictionary, 'sitUser', this.loginSuccess.signedInUserId);
            // debugger;
          }

          // debugger;
          resolve(tSitUser);
        }
        else {
          // debugger;
          this.message = MessageBuilderServiceStatic.printMessage(sitUserId, 'Error occured in GetSitUser(sitUserId:\'' + sitUserId + '\'); sitUser: ' + tSitUser.toString());
          console.log(this.message);
          // return this.nullPromise()
          reject(null);
        }
      }
      else {
        this.message = MessageBuilderServiceStatic.printMessage(sitUserId, 'either sitUserId or the result parameter were null in GetSitUser(sitUserId:\'' + sitUserId + '\'); result: ' + reSult.toString());
        console.log(this.message);
        // return this.nullPromise()
        reject(null);
      }
    }).catch((error) => {
      // debugger;
      this.message = MessageBuilderServiceStatic.printMessage(sitUserId, ' promise of processSitUserResult() has some errors. Error-msg: ' + error);
      console.log(this.message);
    });    
  }
  // ---------------------------------------------------------------
}
