
import { ChangeDetectorRef, ElementRef, Injectable, OnDestroy, Output, ViewContainerRef } from '@angular/core';
import { Dictionary } from "dictionaryjs"; // ref: https://github.com/phanxgames/dictionaryjs
import { AsyncSubject, Observable, Subject, Subscription } from 'rxjs';
import { LogRegModel } from '../../../models/account/logReg.model';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../../models/boxNonce/boxNonceEntity.model';
import { BrdCrmKvAny } from '../../../models/breadcrum/brdcrmKvAny.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { Chat } from '../../../models/chat/chat.model';
import { ChatActivity } from '../../../models/chat/chatActivity.model';
import { ActionId } from '../../../models/common/actionId.model';
import { AppFeature } from '../../../models/common/appFeture.model';
import { Bool } from '../../../models/common/bool.model';
import { Content } from '../../../models/common/content.model';
import { EmitCheckboxResult } from '../../../models/common/emitCheckboxResult.model';
import { EmitDatePickerResult } from '../../../models/common/emitDatePicResult.model';
import { EmitDropDownResult } from '../../../models/common/emitDropDownResult.model';
import { EmitOffOnResult } from '../../../models/common/emitOffOnResult.model';
import { EmitRadioboxResult } from '../../../models/common/emitRadioboxResult.model';
import { Heartbeat } from '../../../models/common/heartbeat.model';
import { LogError } from '../../../models/common/logError.model';
import { ModalModel } from '../../../models/common/modalModel.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { Photo } from '../../../models/common/photo.model';
import { PhotoMgmtAction } from '../../../models/common/photoMgmtAction.model';
import { SpinnerModel } from '../../../models/common/spinnerModel.model';
import { UserAndPageTitle } from '../../../models/common/userAndPageTitle.model';
import { BoxEmailBn } from '../../../models/communication/boxEmailBn.model';
import { CommunicationActivity } from '../../../models/communication/communicationActivity.model';
import { Communicator } from '../../../models/communication/communicator.model';
import { ENoteTile } from '../../../models/communication/eNoteTile.model';
import { MailBox } from '../../../models/communication/mailBox.model';
import { SenderReceiverEmails } from '../../../models/communication/senderReceiverEmails.model';
import { NaclPairClient } from '../../../models/crypto/naclPairClient.model';
import { Salt } from '../../../models/crypto/salt.model';
import { IndexedDbData } from '../../../models/data/indexedDbData.model';
import { RemoteDataModel } from '../../../models/data/remoteDataModel.model';
import { User } from '../../../models/data/user.model';
import { ProfileTileArrEmitterModel } from '../../../models/emitter/profileTileArrEmitterModel';
import { Group } from '../../../models/group/group.model';
import { KeyValueResult } from '../../../models/keyValue/keyValueResult.model';
import { KV } from '../../../models/keyValue/kv.model';
import { KvAny } from '../../../models/keyValue/kvAny.model';
import { KvMemberActivityGlyph } from '../../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';
import { Member } from '../../../models/member/member.model';
import { MemberActivity } from '../../../models/member/memberActivity.model';
import { MemberViewMgmtModel } from '../../../models/member/memberViewMgmtModel.model';
import { CenterCoordinates } from '../../../models/misc/centerCoordinates.model';
import { OffOn } from '../../../models/offOn/offOn.model';
import { BasicPagerModel } from '../../../models/pagination/basicPagerModel.model';
import { Pager } from '../../../models/pagination/pager.model';
import { PagerChat } from '../../../models/pagination/pagerChat.model';
import { PagerPhotoMgmt } from '../../../models/pagination/pagerPhotoMgmt.model';
import { Preference } from '../../../models/profile/preference.model';
import { Profile } from '../../../models/profile/profile.model';
import { ProfileImage } from '../../../models/profile/profileImage.model';
import { ProfileInfo } from '../../../models/profile/profileInfo.model';
import { ProfilePics } from '../../../models/profile/profilePics.model';
import { ProfileTile } from '../../../models/profile/profileTile.model';
import { AmountModel } from '../../../models/sales/amountModel.model';
import { PayPalOrder } from '../../../models/sales/payPalOrder';
import { Upgrade } from '../../../models/upgrade/upgrade.model';
import { HttpService } from '../../coreServiceService/httpService.service';
import { DbDexieToDictionaryService } from '../../dbServiceService/dbDexieToDictionaryService.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { HttpEvent } from '@angular/common/http';

@Injectable( { providedIn: 'root' } )

export abstract class EmitterSubjectService implements OnDestroy
{
  
  public static chabi : 'KA8j/zeXyTPXt0+UTogm1tQk2r0+mUQ9G34PBtxYnHA=';
  public static chabiGopon : 'dJKh9zxbQquNHzKCIkyR11h9svmQBzazOcAuqmWPb/A=';
  static talaChabi : any;
  // -------------------------------------------
  @Output() static actedGlyphNamesEmitter : Subject<any[]> = new Subject(); //
  @Output() static actedUnlockAndBlockGlyphNamesEmitter : Subject<any[]> = new Subject();
  @Output() static actionIdEmitter: Subject<ActionId> = new Subject();
  @Output() static activatedRouteEmitter : Subject<any> = new Subject();
  @Output() static addressEmitter : Subject<any> = new Subject();
  @Output() static amountModelEmitter : Subject<AmountModel> = new Subject();
  @Output() static articleRejectedEmitter : Subject<string> = new Subject(); // 
  @Output() static isAppFeaturePageEmitter : Subject<boolean> = new Subject();
  @Output() static appFeatureArrEmitter : Subject<AppFeature[]> = new Subject();
  // to determine the appropriate module and component to load after app initializes
  @Output() static appInitUrlEmitter: Subject<any> = new Subject();
  @Output() static articleDownloadFileNameEmitter: Subject<string> = new Subject();
  @Output() static articleSubjectsEmitter : Subject<string[]> = new Subject(); // 
  @Output() static avatarProfileTileEmitter : Subject<ProfileTile> = new Subject();
  @Output() static backgroundProcessMethodDataForComponentEmitter : Subject<RemoteDataModel> = new Subject(); // bacgroundProcessMethodEmitter
  @Output() static backgroundProcessMethodDataForServiceEmitter : Subject<RemoteDataModel> = new Subject();
  @Output() static basicPagerEmitter : Subject<BasicPagerModel> = new Subject();
  @Output() static batchOfProfileTileIdsEmitter : Subject<any[]> = new Subject();
  @Output() static blackListCredentialArrEmitter : Subject<KV[]> = new Subject();
  @Output() static boxEmailBnEmitter: Subject<BoxEmailBn> = new Subject();
  @Output() static boxNonceEmitter: Subject<BoxNonceEntity> = new Subject();
  @Output() static breadcrumRingEmitter : Subject<ModuleComponentLoader[]> = new Subject();
  @Output() static catchAllBackgroundImageEmitter : Subject<any> = new Subject();
  @Output() static centerCoordinatesEmitter : Subject<CenterCoordinates> = new Subject();
  @Output() static chatActivityHistoryEmitter : Subject<ChatActivity> = new Subject();
  @Output() static chatMessageEmitter : Subject<BoxNonceEntity> = new Subject();
  @Output() static chatModelToSetupComponentEmitter : Subject<Chat> = new Subject();
  @Output() static checkLocalStorageForLoginSuccessEmitter : Subject<any> = new Subject();
  @Output() static checkboxResultEmitter : Subject<EmitCheckboxResult> = new Subject();
  @Output() static checkOffOnStatusEmitter : Subject<OffOn> = new Subject();
  @Output() static checkOffOnStatusFromProfileTileEmitter : Subject<ProfileTile> = new Subject();
  @Output() static clearCreditCardDataEmitter : Subject<any> = new Subject();
  @Output() static clearGlyphiconMenuEmitter: Subject<any> = new Subject();
  @Output() static clearMembersTilesEmitter : Subject<boolean> = new Subject();
  @Output() static clearMessageEmitter : Subject<any> = new Subject();
  @Output() static clearPageTitleEmitter : Subject<any> = new Subject();
  @Output() static clearPagerPhotoNavDataEmitter : Subject<any> = new Subject();
  @Output() static clearProfileImagesEmitter : Subject<KvPhoto[]> = new Subject();
  @Output() static clearUserDataEmitter : Subject<any> = new Subject();
  @Output() static channelStreamMessageEmitter : Subject<BoxNonceEntity> = new Subject();
  @Output() static chatImageUploadDataEmitter : Subject<any> = new Subject();  
  @Output() static chatModelEmitter : Subject<Chat> = new Subject();  
  @Output() static closeSidebarEmitter : Subject<any> = new Subject();
  @Output() static commActivityBoxNonceEmitter : Subject<BoxNonceEntity> = new Subject(); 
  @Output() static communicationActivityEmitter : Subject<CommunicationActivity> = new Subject();
  @Output() static commTypeEmitter: Subject<string> = new Subject();
  @Output() static communicatorEmitter: Subject<Communicator> = new Subject();
  @Output() static connectionTokenEmitter : Subject<string> = new Subject();
  @Output() static computeDistanceEmitter : Subject<Heartbeat> = new Subject();
  @Output() static conversationEmitter : Subject<Chat[]> = new Subject();
  @Output() static createDictionaryFromDbDixieEmitter : Subject<string> = new Subject();
  @Output() static currentFocusEmitter: Subject<string> = new Subject();
  @Output() static currentPageEmitter : Subject<any> = new Subject(); // 
  @Output() static currentPageOnGlyphiconChangeEmitter : Subject<any> = new Subject();
  
  @Output() static currentPageNumEmitter : Subject<any> = new Subject();
  @Output() static currentMemActGlyphForViewMemberEmitter : Subject<KvMemberActivityGlyph> = new Subject();
  @Output() static currentMemActGlyphForPhotoMgmtEmitter : Subject<KvMemberActivityGlyph> = new Subject();
  /*
   * @Output() static nextPageKvEmitter: Subject<KvAny> = new Subject();
   * @Output() static previousPageKvEmitter: Subject<KvAny> = new Subject();
   */
  @Output() static datePickerEmitter : Subject<EmitDatePickerResult> = new Subject();
  @Output() static dictionariesUpdatedEmitter : Subject<any> = new Subject();
  @Output() static displayDefaultGlyphiconMenuEmitter : Subject<void> = new Subject(); 
  @Output() static displayDifferentMemberViewEmitter : Subject<any> = new Subject();
  @Output() static displayViewMemberGlyphiconMenuEmitter : Subject<KvMemberActivityGlyph[]> = new Subject(); // 
  @Output() static displayViewMemberGlyphiconMenuForSitUserEmitter : Subject<number> = new Subject(); 
  @Output() static displayPhotoMgmtGlyphiconMenuEmitter : Subject<KvMemberActivityGlyph[]> = new Subject();
  @Output() static displayGlyphiconMenuEmitter : Subject<KvMemberActivityGlyph[]> = new Subject();
  // @Output() static displayGlyphMenuForKvPhotoMgmtPageEmitter: Subject<KvPhoto> = new Subject();
  @Output() static displayProfileViewEmitter : Subject<any> = new Subject(); 
  @Output() static displayPrimaryImageEmitter : Subject<any> = new Subject(); 
  @Output() static displayBgiOutsideAngularEmitter : Subject<any> = new Subject();
  @Output() static displayMyPagerVKvPhotoNavEmitter : Subject<PagerPhotoMgmt | any> = new Subject();
  @Output() static displayPagerVKvPhotoNavEmitter : Subject<any> = new Subject();
  @Output() static displayUserPicsFromServerEmitter : Subject<string | any> = new Subject();
  @Output() static distanceEmitter : Subject<any> = new Subject();
  @Output() static dobEmitter : Subject<any> = new Subject();
  @Output() static dropDownResultEmitter : Subject<EmitDropDownResult> = new Subject();
  @Output() static editImageEmitter: Subject<Content> = new Subject();
  @Output() static editParagraphEmitter : Subject<Content> = new Subject();
  @Output() static elementFullyRenderedEmitter : Subject<any> = new Subject();
  @Output() static emailTileEmitter: Subject<CommunicationActivity> = new Subject();
  @Output() static enoteTileEmitter: Subject<CommunicationActivity> = new Subject();
  @Output() static enoteBoxNonceEmitter: Subject<BoxNonceEntity> = new Subject();
  // @Output() static errorLogEmitter: Subject<any> = new Subject();
  @Output() static executeProfileInfoTasksEmitter : Subject<any> = new Subject();
  @Output() static exitPageEmitter : Subject<any> = new Subject();
  @Output() static facilitatorAccessTokenEmitter : Subject<any> = new Subject();
 // @Output() static fetchSitUserEmitter: Subject<SitUser> = new Subject();
  @Output() static fileEmitter: Subject<File> = new Subject();
  @Output() static fileChunkCountEmitter: Subject<number> = new Subject();
  @Output() static filesEmitter: Subject<File[]> = new Subject();
  @Output() static fileImageEmitter: Subject<string> = new Subject();
  @Output() static fileImagesEmitter: Subject<string[]> = new Subject();
  @Output() static fileNameEmitter : Subject<string> = new Subject();
  @Output() static fireMcLoaderRouteEmitter : Subject<ModuleComponentLoader> = new Subject();
  @Output() static formDataEmitter : Subject<FormData> = new Subject();
  @Output() static gestureDivIdEmitter: Subject<string> = new Subject();
  @Output() static gestureEmitter: Subject<string> = new Subject();
  @Output() static getKeysEmitter : Subject<any> = new Subject(); // to get the NaclPair vai httpService
  /*
   * @Output() static groupChatMessageEmitter: Subject<Chat> = new Subject();
   * @Output() static groupConversationEmitter: Subject<Chat[]> = new Subject();
   */
  @Output() static groupEmitter: Subject<Group> = new Subject();
  @Output() static groupChatMessageEmitter: Subject<Chat> = new Subject();
  @Output() static groupConversationEmitter: Subject<Chat[]> = new Subject();

  @Output() static heartbeatEmitter: Subject<Heartbeat> = new Subject();
  @Output() static heightWidthEmitter : Subject<any> = new Subject();
  @Output() static httpEventEmitter : Subject<HttpEvent<any>> = new Subject();
  @Output() static imageEmitter: Subject<string> = new Subject();
  @Output() static imageFileEmitter: Subject<any> = new Subject();
  @Output() static indexDbBoxNonceDataEmitter: Subject<BoxNonceEntity> = new Subject();
  @Output() static indexDbChatHistoryEmitter: Subject<Chat[]> = new Subject();
  @Output() static indexDbChatHistoryIdsEmitter: Subject<number[]> = new Subject();
  @Output() static indexDbDataEmitter: Subject<IndexedDbData> = new Subject();
  @Output() static indexDbDataFoundWithIndexEmitter: Subject<any[]> = new Subject();
  @Output() static indexDbDataFoundWithKeyEmitter: Subject<any[]> = new Subject();
  @Output() static indexDbDefaultPicsEmitter: Subject<KvPhoto[]> = new Subject();
  @Output() static indexDbEmailTileIdsEmitter: Subject<number[]> = new Subject();
  @Output() static indexDbEmailTilesEmitter: Subject<CommunicationActivity[]> = new Subject();
  @Output() static indexDbEnoteTileIdsEmitter: Subject<number[]> = new Subject();
  @Output() static indexDbEnoteTilesEmitter: Subject<CommunicationActivity[]> = new Subject();
  @Output() static indexDbLoginSuccessEmitter: Subject<LoginSuccess> = new Subject();
  @Output() static indexDbMemberActivitiesEmitter: Subject<MemberActivity[]> = new Subject();
  @Output() static indexDbMyErrorLogsEmitter: Subject<LogError[]> = new Subject();
  @Output() static indexDbMyMemberActivitiesEmitter: Subject<MemberActivity[]> = new Subject();
  @Output() static indexDbProfileImageDictionaryEmitter: Subject<Dictionary<number, ProfileImage>> = new Subject();
  @Output() static indexDbProfilePicsEmitter: Subject<ProfilePics> = new Subject();
  @Output() static indexDbPreferenceEmitter: Subject<Preference> = new Subject();
  @Output() static indexDbProfileInfoEmitter: Subject<any> = new Subject();
  @Output() static indexDbProfileTileIdsEmitter: Subject<number[]> = new Subject();
  @Output() static indexDbProfileTilesEmitter: Subject<ProfileTile[]> = new Subject();
  @Output() static indexDbSaveSuccessfulEmitter : Subject<any> = new Subject();
  @Output() static indexDbNgxSaveSuccessfulEmitter : Subject<any> = new Subject();
  @Output() static indexDbSaveStoreEmitter : Subject<any> = new Subject();
  @Output() static indexDbSitUserEmitter: Subject<SitUser> = new Subject();
  @Output() static indexDbSitUsersEmitter: Subject<SitUser[]> = new Subject();
  @Output() static indexDbTempFilesEmitter: Subject<any[]> = new Subject();
  @Output() static indexDbUserEmitter: Subject<any> = new Subject();
  @Output() static indexDbUserPhotoIdsEmitter: Subject<number[]> = new Subject();
  @Output() static indexDbSitUserBoxNonceDataEmitter: Subject<BoxNonceEntity> = new Subject();
  @Output() static indexDbYourMemberActivitiesEmitter: Subject<MemberActivity[]> = new Subject();
  @Output() static initModalDialogueComponentEmitter: Subject<any> = new Subject();
  // to control spinner; isBusy==true=>start-spinnner; isBusy==false=>stop-spinner
  @Output() static isAddressCompleteEmitter : Subject<boolean> = new Subject();
  @Output() static isBrowsingPicEmitter : Subject<boolean> = new Subject(); //
  @Output() static isCameraVisibleEmitter : Subject<boolean> = new Subject(); // for photoMgmt-camera-control
  @Output() static isCameraGlyphVisibleEmitter : Subject<boolean> = new Subject(); // for photoMgmt-camera-control
  @Output() static isCameraClosedBoolEmitter : Subject<boolean> = new Subject(); // for memberView-camera-control
  @Output() static isConnectionEstablishedEmitter : Subject<boolean> = new Subject();
  @Output() static isCreditCardCompleteEmitter : Subject<boolean> = new Subject();
  @Output() static isEditProfileEmitter : Subject<boolean> = new Subject();  
  @Output() static isImageUploadFormVisibleEmitter : Subject<boolean> = new Subject();
  @Output() static isHomeEmitter : Subject<boolean> = new Subject();
  @Output() static isLoginSuccessEmitter : Subject<boolean> = new Subject();
  @Output() static isMobileEmitter: Subject<boolean> = new Subject();
  @Output() static isModalEmitter : Subject<boolean> = new Subject();
  @Output() static isMyStuffOpenEmitter : Subject<boolean> = new Subject();
  @Output() static isMyProfileEmitter : Subject<boolean> = new Subject();
  @Output() static isModalVisibleEmitter : Subject<boolean> = new Subject();
  @Output() static isNewPhotoUploadedEmitter : Subject<boolean> = new Subject();
  @Output() static isOpenEmitter : Subject<Bool> = new Subject();
  @Output() static isPhotoMgmtEmitter: Subject<boolean> = new Subject();
  @Output() static isReplyEmitter: Subject<boolean> = new Subject();
  @Output() static isShowPhotoUploadEmitter : Subject<boolean> = new Subject();
  @Output() static isSidebarOpenEmitter : Subject<boolean> = new Subject();
  @Output() static isSignupModalEmitter : Subject<boolean> = new Subject(); 
  @Output() static isToggleCameraEmitter : Subject<boolean> = new Subject();
  @Output() static isToggleFileUploadEmitter : Subject<boolean> = new Subject();
  @Output() static isUnlockedEmitter: Subject<boolean> = new Subject();
  @Output() static isViewMemberEmitter: Subject<boolean> = new Subject();
  @Output() static isVsScrollEmitter: Subject<boolean> = new Subject();
  @Output() static isWaitingOnCurrStateOfUserEmitter: Subject<boolean> = new Subject();
  @Output() static keyValueResultEmitter : Subject<KeyValueResult> = new Subject(); 
  @Output() static kvMemberActivityGlyphEmitter : Subject<KvMemberActivityGlyph> = new Subject();
  @Output() static loadActivityComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadAppComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadChatComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadCheckboxRadioComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadChildrenEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadCommunicationComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadGestureComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadKeyValueComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadLandingPageEmitter: Subject<boolean> = new Subject();
  @Output() static loadLogregComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadMasterComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadMemberComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadModalComponentEmitter: Subject<ModuleComponentLoader> = new Subject(); // aka mcLoader
  @Output() static loadModuleEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadProfileComponentEmitter : Subject<ModuleComponentLoader> = new Subject(); 
  // @Output() static loadRemoteDataInBackgroundEmitter : Subject<string> = new Subject();
  @Output() static loadSharedComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadSlaveComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadSpinnerComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static loadStaffComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static localStorageLoginSuccessEmitter: Subject<LoginSuccess> = new Subject();
  @Output() static loginSuccessEmitter : Subject<LoginSuccess> = new Subject();
  @Output() static logRegModelEmitter : Subject<LogRegModel> = new Subject();
  @Output() static logoutEmitter: Subject<any> = new Subject();
  @Output() static mailBoxEmitter: Subject<MailBox> = new Subject();
  @Output() static mainTopCssEmitter: Subject<number> = new Subject();
  @Output() static masterErrorLogEmitter: Subject<any> = new Subject();
  @Output() static mcLoaderEmitter : Subject<ModuleComponentLoader> = new Subject();
  @Output() static mcLoaderToNavigateEmitter : Subject<ModuleComponentLoader> = new Subject()
  //  @Output() static mcLoaderObservableEmitter : Subject<Observable<ModuleComponentLoader>> = new Subject();
  @Output() static membersEmitter: Subject<Member[]> = new Subject();
  @Output() static memberActivityEmitter : Subject<CommunicationActivity> = new Subject();
  @Output() static communicationActivitiesEmitter : Subject<CommunicationActivity[]> = new Subject();
  @Output() static memberViewMgmtModelEmitter : Subject<MemberViewMgmtModel> = new Subject();
  @Output() static myErrorLogEmitter: Subject<any> = new Subject();
  @Output() static myActivityArrEmitter : Subject<CommunicationActivity[]> = new Subject();
  // @Output() static myEmailBoxNonceEmitter : Subject<MemberActivity[]> = new Subject();
  @Output() static memberPhotoEmitter: Subject<KvPhoto> = new Subject();
  @Output() static messageEmitter: Subject<string> = new Subject();
  @Output() static modelEmitter: Subject<any> = new Subject();
  @Output() static myConversationsEmitter: Subject<ChatActivity[]> = new Subject();
  @Output() static myGroupsEmitter: Subject<Chat> = new Subject();
  @Output() static naclEmitter: Subject<NaclPairClient> = new Subject();
  @Output() static navbarActionTypeEmitter: Subject<ActionId> = new Subject();
  /*
   * @Output() static profileAuthorEmitter: Subject<Author> = new Subject();
   * @Output() static signedInAuthorEmitter: Subject<Author> = new Subject();
   * @Output() static totalPaidEmitter: Subject<number> = new Subject();
   * @Output() static myConversationsEmitter: Subject<Chat> = new Subject();
   * @Output() static myGroupsEmitter: Subject<Chat> = new Subject();
   */

  @Output() static offOnEmitter : Subject<OffOn> = new Subject();
  @Output() static offOnResultEmitter: Subject<EmitOffOnResult> = new Subject();
  @Output() static offOnStatusChangeEmitter : Subject<any> = new Subject();
  @Output() static onKvPhotoSelectionChangeEmitter : Subject<KvPhoto|any> = new Subject();
  @Output() static openModalEmitter : Subject<any> = new Subject();
  @Output() static pagedProfileImagesEmitter: Subject<KvAny[]> = new Subject();
  @Output() static pagerEmitter : Subject<Pager> = new Subject();
  @Output() static pagerBreadcrumEmitter : Subject<PagerBreadcrum> = new Subject();
  @Output() static pagerBreadcrumDirectionEmitter : Subject<string> = new Subject(); // for gesture based page-nagivation via nav-arrows
  @Output() static pagerChatEmitter : Subject<PagerChat> = new Subject();
  @Output() static pagedChatsEmitter : Subject<Chat[]> = new Subject();
  @Output() static paragraphEmitter : Subject<Content> = new Subject(); // 
  @Output() static pageSizeEmitter : Subject<any> = new Subject();
  @Output() static pagerPhotoMgmtEmitter : Subject<PagerPhotoMgmt | any> = new Subject();
  @Output() static pagerVKvPhotoNavEmitter : Subject<PagerPhotoMgmt|any> = new Subject();
  @Output() static photoBnEmitter : Subject<BoxNonceEntity> = new Subject();
  @Output() static photoLockUnLockEmitter: Subject<KvPhoto> = new Subject();
  @Output() static photoMgmtMenuObservableEmitter: Subject<Observable<any[]>> = new Subject();
  @Output() static photoMgmtActionEmitter : Subject<PhotoMgmtAction> = new Subject(); // 
  @Output() static photoMgmtPrimaryActionEmitter : Subject<any> = new Subject();
  @Output() static preferenceKvAnyArrEmitter : Subject<KvAny[]> = new Subject();
  @Output() static profileEmitter : Subject<Profile> = new Subject();
  @Output() static profileContentKvAnyArrEmitter : Subject<KvAny[]> = new Subject();
  @Output() static profileImageEmitter: Subject<ProfileTile> = new Subject(); // Need to send the image in ProfileTile
  @Output() static profileImagesEmitter : Subject<KvPhoto[]> = new Subject();
  @Output() static profileImageArrEmitter: Subject<ProfileImage[]> = new Subject();
  @Output() static profileImageKvArrEmitter : Subject<KvAny[]> = new Subject();
  @Output() static profileInfoEmitter : Subject<ProfileInfo> = new Subject();
  @Output() static profileInfoKvAnyArrEmitter : Subject<KvAny[]> = new Subject();
  @Output() static profileInfoViewEmitter : Subject<ProfileInfo> = new Subject();
  @Output() static profileTileEmitter: Subject<ProfileTile> = new Subject();
  @Output() static profileTileCompleteEmitter: Subject<ProfileTile> = new Subject();
  @Output() static profileTilesArrEmitter: Subject<ProfileTileArrEmitterModel> = new Subject();
  @Output() static profileTilesArrayUpdatedEmitter: Subject<boolean> = new Subject();
  @Output() static profilePicsEmitter: Subject<ProfilePics> = new Subject();
  @Output() static radioboxResultEmitter: Subject<EmitRadioboxResult> = new Subject();
  @Output() static receiverSitUserIdEmitter: Subject<number> = new Subject();
  @Output() static refreshPageEmitter : Subject<any> = new Subject(); // 
  @Output() static refreshMemberTileEmitter : Subject<any> = new Subject();
  @Output() static removeComponentEmitter: Subject<ModuleComponentLoader> = new Subject();
  @Output() static resetDbgiEmitter: Subject<boolean> = new Subject();
  @Output() static resetPickedDateEmitter: Subject<boolean> = new Subject();
  @Output() static resetPasswordCodeEmitter : Subject<any> = new Subject();
  @Output() static resetSaltShakerEmitter : Subject<Salt> = new Subject();
  @Output() static returnUrlEmitter : Subject<string> = new Subject();
  @Output() static runBackgroundProcessMethodEmitter : Subject<RemoteDataModel> = new Subject();
  @Output() static runBiasModuleComponentNgAfterViewInitEmitter : Subject<any> = new Subject();
  @Output() static runGetEnumsFromServerEmitter : Subject<any> = new Subject();
  @Output() static runMemberTileAfterViewInitEmitter: Subject<ProfileTile> = new Subject();
  @Output() static runNgAfterViewInitEmitter : Subject<any> = new AsyncSubject();  //
  @Output() static runOffOnComponentNgAfterViewInitEmitter : Subject<any> = new Subject();  //
  @Output() static saveSitUserToIndexedDbAndDictionaryEmitter : Subject<SitUser> = new Subject();
  @Output() static selectedProfileTileEmitter : Subject<ProfileTile> = new Subject();
  @Output() static senderReceiverEmailsEmitter : Subject<SenderReceiverEmails> = new Subject();
  @Output() static setBreadcrumPageEmitter : Subject<string> = new Subject(); // to be used by any cancel() button-action.
  @Output() static setBreadcrumForPageSwitchEmitter : Subject<any> = new Subject(); 
  @Output() static setPageEmitter: Subject<number> = new Subject();
  @Output() static setPhotoMgmtPageEmitter: Subject<KvPhoto> = new Subject();
  @Output() static setPhotoMgmtPageGlyphEmitter : Subject<KvMemberActivityGlyph> = new Subject();
  @Output() static setMemberViewPageEmitter : Subject<KvPhoto> = new Subject();
  @Output() static setMemberViewGlyphMenuEmitter : Subject<any> = new Subject(); 
  @Output() static setPhotoNavItemEmitter : Subject<KvPhoto> = new Subject();
  @Output() static setProfilePicsPageEmitter : Subject<KvPhoto> = new Subject();
  @Output() static setSignedInUserBackgroundImageEmitter : Subject<any> = new Subject();
  @Output() static signedInUserEmitter : Subject<SitUser> = new Subject(); // emitSaveSitUserToIndexedDbAndDictionary
  @Output() static sitUserEmitter : Subject<SitUser> = new Subject(); // emitSaveSitUserToIndexedDbAndDictionary
  @Output() static sitUserBoxNonceEmitter : Subject<BoxNonceEntity> = new Subject();
  @Output() static staffActionTypeEmitter : Subject<string> = new Subject();
  // @Output() static startBiasAnimationEmitter : Subject<boolean> = new Subject();
  @Output() static showSpinnerEmitter : Subject<SpinnerModel> = new Subject();
  @Output() static hideSpinnerEmitter : Subject<any> = new Subject();
  @Output() static streamMessageEmitter: Subject<BoxNonceEntity> = new Subject();
  @Output() static submitArticleSuccessfulEmitter: Subject<string> = new Subject();
  @Output() static tempFileEmitter: Subject<any> = new Subject();
  @Output() static theirActivityArrEmitter: Subject<CommunicationActivity[]> = new Subject();
  @Output() static toggleAccordionEmitter : Subject<any> = new Subject();
  @Output() static toggleCameraGlyphEmitter : Subject<number> = new Subject();
  @Output() static toggleFaderAnimEmitter : Subject<any> = new Subject();
  @Output() static toggleIsCancelZipInstructionEmitter: Subject<boolean> = new Subject();
  @Output() static toggleThisEmitter: Subject<any> = new Subject();
  @Output() static toggleIsMobileEmitter: Subject<boolean> = new Subject();
  @Output() static toggleIsSendFileEmitter: Subject<boolean> = new Subject();
  @Output() static toggleIsUploadEmitter: Subject<boolean> = new Subject();
  @Output() static toggleIsWritePadEmitter: Subject<boolean> = new Subject();
  @Output() static toggleModalDialogEmitter : Subject<boolean> = new Subject();
  @Output() static toSetMemberViewMgmtModelEmitter : Subject<MemberViewMgmtModel> = new Subject();
  @Output() static updateBrdCrumRingEmitter : Subject<ModuleComponentLoader> = new Subject(); // 
  @Output() static updateDistanceAndProfileNameAndOffOnEmitter : Subject<any> = new Subject();
  @Output() static updateKvPhotoOnUserActionEmitter : Subject<KvPhoto> = new Subject();
  @Output() static updatePrimaryImageEmitter : Subject<any> = new Subject();
  @Output() static upgradeEmitter : Subject<PayPalOrder> = new Subject();
  @Output() static upgradedEmitter : Subject<LoginSuccess> = new Subject();
  @Output() static uploadedKvPhotoEmitter : Subject<KvPhoto> = new Subject();
  @Output() static uploadedProfilePicsEmitter: Subject<ProfilePics> = new Subject();
  @Output() static uploadedPicsBoxNonceEmitter: Subject<BoxNonceEntity> = new Subject();
  @Output() static userAndPageTitleEmitter : Subject<UserAndPageTitle> = new Subject();
  @Output() static viewMemberEmitter : Subject<ModuleComponentLoader> = new Subject();
  @Output() static whenScrollEndsEmitter: Subject<boolean> = new Subject();
  @Output() static yourLoginSuccessEmitter : Subject<LoginSuccess> = new Subject();
  @Output() static yourCommunicatorEmitter : Subject<Communicator> = new Subject();
  // @Output() static treeNodeEmitter: Subject<Post> = new Subject();

  /*
   * private loginSuccessSource = new Subject<LoginSuccess>();
   * loginSuccess$ = this.loginSuccessSource.asObservable();
   */

  static anchor: ViewContainerRef; // ref:https:// medium.com/angular-in-depth/lazy-loading-angular-modules-with-ivy-92c3a890eae1
  static actionId: ActionId = new ActionId();
  static activatedComponentDictionary : Dictionary<string, ModuleComponentLoader> = new Dictionary<string, ModuleComponentLoader>();
  static activatedRoute : any;
  static appFeatureArr : AppFeature[];
  static articleDownloadFileName = '';
  // static articleIndex: Article[] = [];
  static articleSubjects: string[] = [];
  static articlePagedParagraphs: Content[] = [];
  // static appModuleFactory: any;
  static avatarProfileTile : ProfileTile = new ProfileTile();
  static basicPagerModel : BasicPagerModel = new BasicPagerModel();
  static breadcrumRing : ModuleComponentLoader[] = [];
  // ------------------------------------------------------------------------------------------------------------
  //  calledSitUserArr array is used for checking if a sitUserId is used to fetch the respective sitUser or not
  //  sitUserService checks for the presence of a SitUser in the SitUserDictionary.
  //  fetchSitUser() method checks calledSitUSerArr to see if a sitUserId is used to make the fetchCall or not.
  //  fetchSitUSer will call only if a particular sitUserId is abnsent in the
  //  calledSitUserArr in order to prevent multiple calls to get the sitUser data
  // ------------------------------------------------------------------------------------------------------------
  static calledSitUserArr : number[] = []; 
  // ------------------------------------------------------------------------------------------------------------
  static cdr : any; // plaeholder for 'ChangeDetectorRef'
  static chatHistoryArr : ChatActivity[] = [];
  static chatMessage : Chat = new Chat();
  static chatMap : Map<number, Chat> = new Map<number, Chat>();
  static chatModel : Chat = new Chat();
  /*
   * static chatModuleFactory: any;
   * static communicationModuleFactory: any;
   */
  static connectionTokenArr: string[] = [];
  static connectionToken = '';
  static conversation: Chat[] = [];
  static currentPage : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  static currentPageGlyph : KvMemberActivityGlyph = new KvMemberActivityGlyph(); 
  static currentMemActGlyphForPhotoMgmt : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  static currentMemActGlyphForViewMember : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  static currentPageNo = 1;
  static currentKvPhoto: KvPhoto = new KvPhoto();
  static currentPhoto: Photo = new Photo();
  static currentBrdcrmPage : BrdCrmKvAny = new BrdCrmKvAny();
  static dbDexieToDictionaryService : DbDexieToDictionaryService;
  static deviceInfo : any;
  static document : Document;
  static window : Window;
  static elementRef : ElementRef;
  static offOnAwayElementRef : ElementRef | HTMLElement;
  static offOnRadioElementRef : ElementRef | HTMLElement;
  static enums: any;
  static environment = 'Development';
  static eNoteTile: ENoteTile = new ENoteTile();

  // static getSitUser : GetSitUser = new GetSitUser();
  static glyphKvArr : KvMemberActivityGlyph[] = [];
  
  // static groupChatMessage: Chat = new Chat();
  // static groupConversation: Chat[] = [];
  static group: Group = new Group();
  static heartbeat: Heartbeat = new Heartbeat();
  static hostName = '';
  static httpService : HttpService;
  static hubConnection : signalR.HubConnection;
  static isAppFeaturePage = false;
  static isDevEnvironment = false;
  static isEditProfile = false;
  static isHome = false;
  static isMobilevar = false;
  static isTablet = false;
  static isDesktop = false;
  static isOnLine = false;
  static isModal = false;
  static isModalVisible = false;
  static isMyProfile = false;
  static isInizializationComplete = true;
  static isLoggedOutByUser = false;
  static isNewPhotoUploaded = false;
  static isPhotoMgmt = false;
  static isShowPhotoUploadUi : Bool = new Bool();
  static isProfileTileDictionaryCreatedFromIndexDb = false;
  static isToggleCamera = false;
  static isUnlocked = false;
  static isViewMember = false;
  static routerKvAny = new BrdCrmKvAny();
  static kvGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];  // TODO: keep this and delete the duplicate with photoMgmtGlyphMenuKvArr
  static kvGlyphArrForViewMember : KvMemberActivityGlyph[] = []; 
  static kvPhotoLock: KvPhoto = new KvPhoto(); // lock.png
  static kvPhotoUnLock: KvPhoto = new KvPhoto(); // unlock.png
  static kvPhotoPrivate: KvPhoto = new KvPhoto(); // lock.jpg
  static kvPhotoPublic: KvPhoto = new KvPhoto(); // unlock.jpg
  // static keyValueModuleFactory: any;
  static logRegModel : LogRegModel = new LogRegModel();
  static loginSuccess: LoginSuccess = new LoginSuccess();
  // static logregModuleFactory: any;
  static masterData: any;
  static masterErrorLogs: LogError[] = [];
  static mcLoader: ModuleComponentLoader = new ModuleComponentLoader();
  static memberActivity : CommunicationActivity = new CommunicationActivity();
  static commActivities: CommunicationActivity[] = [];
  static memberModuleFactory: any;
  static members: Member[] = [];
  static memberPhoto : KvPhoto = new KvPhoto();
  static memberViewMgmtModel : MemberViewMgmtModel = new MemberViewMgmtModel();
  static message = '';
  static anyModel : any;
  static modalModel: ModalModel = new ModalModel();
  static moduleFactory : any;
  static myActivityArr : CommunicationActivity[] = [];
  static myConversations : ChatActivity[] = [];
  static myOutboxConversations : ChatActivity[] = [];
  static myErrorLogs: LogError[] = [];
  static myGroups: Group[] = [];
  static myInfo : Communicator = new Communicator();
  static numberOfTicks = 0;
  static pagerBreadcrum : PagerBreadcrum = new PagerBreadcrum();
  static pageSize = 0;
  static pagerChat : PagerChat = new PagerChat();
  static pagedChats : Chat[] = [];
  static pagerPhotoMgmt : PagerPhotoMgmt = new PagerPhotoMgmt();
  static pagerVKvPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt();
  static payPalOrder : PayPalOrder = new PayPalOrder();
  static photoMgmtAction : PhotoMgmtAction = new PhotoMgmtAction();
  static photoMgmtGlyphMenuKvArr : KvMemberActivityGlyph[] = []; // TODO: reesolve the duplicate with glyphKvArrForPhotoMgmt
  static photoMgmtServiceSitUser: SitUser = new SitUser();
  static primaryImageKvPhoto: KvPhoto = new KvPhoto();
  static primaryImageGlyphMenuKvArr : KvMemberActivityGlyph[] = [];
  static profileContentKvArr : KvAny[] = [];
  static profileImages: KvPhoto[] = [];
  static profileInfo : ProfileInfo = new ProfileInfo();
  static profileInfoView : ProfileInfo = new ProfileInfo();
  static profileModuleFactory: any;
  static profileTile: ProfileTile = new ProfileTile();
  static profileTilesArr: ProfileTile[] = [];
  static profileTileArrEmitterModel: ProfileTileArrEmitterModel = new ProfileTileArrEmitterModel();
  static profilePics : ProfilePics = new ProfilePics();
  static rdm :RemoteDataModel = new RemoteDataModel();
  static returnUrl = '';
  static resetPasswordCode = '';
  static router : any;
  static remoteDateLoadID : number = 0;
  static salty: Salt = new Salt();
  static saltyPair : NaclPairClient = new NaclPairClient();
  static selectedProfileTile : ProfileTile = new ProfileTile();
  static senderInfoArr: Communicator[] = [];
  static senderReceiverEmails: SenderReceiverEmails = new SenderReceiverEmails();
  static sharedModuleFactory: any;
  static sitUser : SitUser = new SitUser();
  static signedInUser : SitUser = new SitUser();
  // static staffModuleFactory : any;
  static spinnerCounter = 0;
  static spinnerModel : SpinnerModel = new SpinnerModel();
  static subscription: Subscription;
  static theirActivityArr: CommunicationActivity[] = [];
  static timer : any;
  static toggleCameraGlyphCounter = 0;
  static unionModuleFactory: any;
  static upgrade : Upgrade = new Upgrade();
  static userAndPageTitle : UserAndPageTitle = new UserAndPageTitle();
  static userPhotoIds: number[] = [];
  static userPhotoBnIds : number[] = [];
  static yourInfo : Communicator = new Communicator();
  static yourLoginSuccess: LoginSuccess = new LoginSuccess();
  static whenScrollEnds = false;
  /*
   * private senderReceiverEmails: SenderReceiverEmails = new SenderReceiverEmails();
   *  --------------------------------------------
   *  Note: variables for componet-container-listeners:
   */
  static createListeners: any[] = [];
  static destroyListeners: any[] = [];
  /*
   *  --------------------------------------------
   *  Note: variables for lazy-loading:
   */
  static activityElementRef: any;
  static appElementRef: any;
  static chatElementRef: any;
  static communicationElementRef: any;
  static keyValueElementRef: any;
  static logregElementRef: any;
  static memberElementRef : any;
  static profileElementRef: any;
  static staffElementRef: any;
  static CopyServiceStatic: any;
  protected timerArray: any[] = [];
  protected emitterDestroyed$: any;
  /*
   * ---------------------------------------------------------------
   * Note: On an Onservable, the view gets rendered each time it is
   *       subscribed (i.e. listened to via next()).
   *       This method is to be used by any method of that needs to
   *       refresh the view with new data where Angular does not
   *       refresh to render the updated data.
   *
   *       A good way to combine this method with a subscriber of
   *       an emitter would make it behave like an observable.
   * ---------------------------------------------------------------
   */
  // public reRender(): void {
  //  /*
  //   * Re-rendering the view on changeDetection:
  //   * ref:https:// stackoverflow.com/questions/50383003/how-to-re-render-a-component-manually-angular-5
  //   */
  //  this.timer = setTimeout(() => {
  //    this.numberOfTicks++;
  //    // the following is required, otherwise the view will not be updated
  //    this.cdr.markForCheck();
  //    // console.log('re-rendering-timer-Ticks: ' + this.numberOfTicks);
  //  }, 1000);
  //  this.timerArray.push(this.timer);
  // }
  /*
   *  -----------------------------------------------------------
   *  Begin of registering and removing component-containers:
   *  ref: https://indepth.dev/posts/1057/here-is-how-to-get-viewcontainerref-before-viewchild-query-is-evaluated
   *  -----------------------------------------------------------
   */
  //  --------------------------------------------
  constructor (public dbServiceService) {
    EmitterSubjectService.dbDexieToDictionaryService = this.dbServiceService.getDbDexieToDictionaryService();''
    // debugger;
  }
  // -------------------------------------------
  // ---------------------------------------------------------------
  static getDataFromLoginSuccess ()
  {
    this.myInfo.email = this.loginSuccess.email;
    this.myInfo.profileName = this.loginSuccess.profileName;
    this.myInfo.sitUserId = this.loginSuccess.signedInUserId;
    this.myInfo.primaryImage = this.loginSuccess.primaryImage;
  }
  // ---------------------------------------------------------------
  static onContainerCreated (fn : any) {
    this.createListeners.push(fn);
  }
  static onContainerDestroyed (fn : any) {
    this.destroyListeners.push(fn);
  }
  static registerContainer (container : any) {
    // debugger;
    this.createListeners.forEach((fn) => {
      fn(container);
    });
  }
  static destroyContainer (container : any) {
    this.destroyListeners.forEach((fn) => {
      fn(container);
    });
  }
  /*
   *  -----------------------------------------------------------
   *  End of registering and removing component-containers:
   *  -----------------------------------------------------------
   */
  // ---------------------------------------------------------------
  //  Note: This is to be used with the novel-centering-system
  // ---------------------------------------------------------------
  static getCenteringHeightWidth (elemId: any) : any
  {
    let point = {
      height: 0,
      width: 0,
    };
    // debugger;
    let parentElemWidth = window.innerWidth;
    let parentElemHeight = window.innerHeight;
    // To be used when @ViewChild( 'loginComponentId' ) childElement ! : ElementRef; can be used
    // ------------------------------------------------------------------------------------------
    // let childElemWidth = this.childElement.nativeElement.offsetWidth;
    // let childElemHeight = this.childElement.nativeElement.offsetHeight;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId))
    {
      let childElem = document.getElementById( elemId ) as HTMLElement;
      let childElemWidth : any;
      let childElemHeight : any;
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( childElem ) )
      {
        let childWidth = childElem.style.width;
        let childeight = childElem.style.height;

        if ( childWidth.toLowerCase().indexOf( '%' ) !== -1 ) // if the width is in %
        {
          let parts = childWidth.split( '%' );
          if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( parts ) )
          {
            let childWidthValue = parseInt( parts[ 0 ] );
            childElemWidth = parentElemWidth * ( childWidthValue / 100 );
          }
        }
        else if ( childWidth.toLowerCase().indexOf( 'px' ) !== -1 ) // if the width is in px
        {
          let parts = childWidth.split( 'px' );
          if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( parts ) )
          {
            let childWidthValue = parseInt( parts[ 0 ] );
            childElemWidth = childWidthValue;
          }
        }
        else if ( childWidth.toLowerCase().indexOf( 'rem' ) !== -1 )
        { // if the width is in rem
          {
            let parts = childWidth.split( 'rem' );
            if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( parts ) )
            {
              let childWidthValue = parseInt( parts[ 0 ] );
              childElemWidth = childWidthValue;
            }
          }
        }
        let centerWidth = Math.ceil( parentElemWidth / 2 - childElemWidth / 2 );
        let centerHeight = Math.ceil( parentElemHeight / 2 - childElemHeight / 2 );
        // debugger;
        point.width = centerWidth;
        point.height = centerHeight;
        // alert( 'in emitter-Service! \n centerWidth: ' + centerWidth + '\n centerHeight: ' + centerHeight );
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(childElem)) {
        childElem.remove();
      }
    }
    
    return point;
  }
  // ---------------------------------------------------------------
  /*
   * ------------------------------------------------------------
   * setSitUserId(id: number) {
   * this.sitUserIdSource.next(id);
   * }
   */
  static setupLockPhotos(): any {
    // debugger;
    this.kvPhotoLock.cryptUserKey = 'tala';
    this.kvPhotoLock.value = '/assets/photos/lock.png';

    this.kvPhotoUnLock.cryptUserKey = 'unlock';
    this.kvPhotoUnLock.value = '/assets/photos/unlock.png';

    this.kvPhotoPrivate.cryptUserKey = 'private';
    this.kvPhotoPrivate.value = '/assets/photos/private.png';

    this.kvPhotoPublic.cryptUserKey = 'static';
    this.kvPhotoPublic.value = '/assets/photos/static.png';

    if (this.loginSuccess && this.loginSuccess.signedInUserId > 0) {
      this.kvPhotoLock.sitUserId = this.loginSuccess.signedInUserId;
      this.kvPhotoUnLock.sitUserId = this.loginSuccess.signedInUserId;
      this.kvPhotoPrivate.sitUserId = this.loginSuccess.signedInUserId;
      this.kvPhotoPublic.sitUserId = this.loginSuccess.signedInUserId;
      // debugger;
    }
    return true;
  }

  // ---------------------------------------------------------------
  // static isSenderFound(sender: Communicator): number {
  //  let i = 0;

  //  if (this.senderInfoArr.length > 0 && sender) {
  //    for (const e of this.senderInfoArr) {
  //      if (e.sitUserId === sender.sitUserId) {
  //        return i;
  //      }
  //      i++;
  //    }
  //  }
  //  return -1;
  // }
  // ------------------------------------------------------------
  // static uniqueAddSenderInfoArr(sender: Communicator) {
  //  if (sender && this.loginSuccess.signedInUserId !== sender.sitUserId) {
  //    const index = this.isSenderFound(sender);

  //    if (index > -1) {
  //      this.senderInfoArr[index] = sender;
  //    } else {
  //      this.senderInfoArr.push(sender);
  //    }
  //  }
  // }
  /*
   *  -----------------------------------------------------------
   *  Begin of Lazy-Loading:
   *  ref: https://medium.com/angular-in-depth/lazy-loading-angular-modules-with-ivy-92c3a890eae1
   *  -----------------------------------------------------------
   */
  static getDocument(): any {
    return this.document;
  }
  static setDocument(value: any) {
    this.document = value;
  }
  static getWindow () : any
  {
    return this.window;
  }
  static setWindow ( value : any )
  {
    this.window = value;
  }
  static geAppFeatureArr () : AppFeature[]
  {
    return this.appFeatureArr;
  }
  static setAppFeatureArr ( value : AppFeature[]) : void
  {
     this.appFeatureArr = value;
  }
  static getAnchor () : any
  {
    return this.anchor;
  }
  static setAnchor ( value : any )
  {
    this.anchor = value;
  }
  static getAppElementRef(): ElementRef {
    return this.appElementRef;
  }
  static setAppElementRef ( value : ElementRef): void {
    this.appElementRef = value;
  }
  // ----------------------------------------------------------
  static isSitUserIdCalled (sitUserId : number) : boolean {
    let isFound = false;
    this.calledSitUserArr.forEach(e => {
      isFound = e === sitUserId;
    });
    return isFound;
  }
  // ----------------------------------------------------------
  static setSitUserIdCalled (sitUserId : number) {
    if (!EmitterSubjectService.isSitUserIdCalled(sitUserId)) {
      this.calledSitUserArr.push(sitUserId);
    }
  }
  // ----------------------------------------------------------
  static getChats () : any[] {
    var chatArr : any[] = [];
    chatArr.push(...this.chatMap.values());
    return chatArr;
  }
  // ----------------------------------------------------------
  static setChats (values : Chat[]) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(values) && values.length > 0) {
      values.map(v => {
        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chatMap.get(v.communicationActivityId))){
          this.chatMap.set(v.communicationActivityId, v);
				}        
      })      
		}    
  }
  // ----------------------------------------------------------
  //  
  static getIsAppFeaturePage () : boolean {
    return this.isAppFeaturePage;
  }
  static setIsAppFeaturePage (value : boolean) {
    this.isAppFeaturePage = value;
  }

  static getChatElementRef () : any {
    return this.chatElementRef;
  }
  static setChatElementRef (value : any) {
    this.chatElementRef = value;
  }
  static getCommunicationElementRef(): any {
    return this.communicationElementRef;
  }
  static setCommunicationElementRef(value: any) {
    this.communicationElementRef = value;
  }
  // ---------------------------------------------------------------
  static getDbDexieToDictionaryService (): any {
    this.dbDexieToDictionaryService;
  }
  static setDbDexieToDictionaryService (value : DbDexieToDictionaryService) : void {
    this.dbDexieToDictionaryService = value;
  }
  // ---------------------------------------------------------------
  static getGlyphs () : KvMemberActivityGlyph[]
  {
    return this.glyphKvArr;
  }
  static setGlyphs (value : KvMemberActivityGlyph[] ) : void
  {
    this.glyphKvArr = value;
  }
  // ---------------------------------------------------------------
  static getViewMemberGlyphs () : KvMemberActivityGlyph[] {
    return this.kvGlyphArrForViewMember;
  }
  static setViewMemberGlyphs (value : KvMemberActivityGlyph[]) : void {
    this.kvGlyphArrForViewMember = value;
  }
  // ---------------------------------------------------------------
  static getPhotoMgmtGlyphs () : KvMemberActivityGlyph[] {
    return this.kvGlyphArrForPhotoMgmt;
  }
  static setPhotoMgmtGlyphs (value : KvMemberActivityGlyph[]) : void {
    this.kvGlyphArrForPhotoMgmt = value;
  }
  // ---------------------------------------------------------------
  static getIsInizializationComplete () : boolean
  {
    return this.isInizializationComplete;
  }
  static setIsInizializationComplete ( value : boolean ): any
  {
    this.isInizializationComplete = value;
    return true;
  }
  // ---------------------------------------------------------------
  static setIsObjectStoreNameFoundInTables (value : boolean) : void {
    this.isProfileTileDictionaryCreatedFromIndexDb = value;
  }
  // ---------------------------------------------------------------
  static getIsObjectStoreNameFoundInTables () : boolean {
    return this.isProfileTileDictionaryCreatedFromIndexDb;
  }
  static getKeyValueElementRef(): any {
    return this.keyValueElementRef;
  }
  static setKeyValueElementRef(value: any) {
    this.keyValueElementRef = value;
  }
  // 
  static getLogRegModel () : any {
    return this.logRegModel;
  }
  static setLogRegModel (value : any) {
    this.logRegModel = value;
  }

  static getLogregElementRef(): any {
    return this.logregElementRef;
  }
  static setLogregElementRef(value: any) {
    this.logregElementRef = value;
  }
  static getMasterData(): any {
    return this.masterData;
  }
  static setMasterData(value: any) {
    this.masterData = value;
  }
  // -----------------------------------------------
  static getMemberElementRef(): any {
    return this.memberElementRef;
  }
  static setMemberElementRef(value: any) {
    this.memberElementRef = value;
  }
  static getMemberActivityElementRef(): any {
    return this.activityElementRef;
  }
  static setMemberActivityElementRef(value: any) {
    this.activityElementRef = value;
  }
  // -----------------------------------------------
  static getMemberViewMgmtModel () : any {
    return this.memberViewMgmtModel;
  }
  static setMemberViewMgmtModel (value : any) {
    this.memberViewMgmtModel = value;
  }
  // -----------------------------------------------
  static getElementRef () : ElementRef
  {
    return this.elementRef;
  }
  static setElementRef ( value : ElementRef ) : void
  {
    this.elementRef = value;
  }
  // -----------------------------------------------
  static getOffOnElement (name : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(name)) {
      if (name.indexOf('spanAway') !== -1) {
        return this.offOnAwayElementRef;
      }
      else if (name.indexOf('spanRadio') !== -1) {
        return this.offOnRadioElementRef;
      }
    }
    return null;
  }
  static setOffOnElement (value : (ElementRef | HTMLElement), name : string) : void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(name)) {
      if (name.indexOf('spanAway') !== -1) {
        this.offOnAwayElementRef = value;
      }
      else if (name.indexOf('spanRadio') !== -1) {
        this.offOnRadioElementRef = value;
      }
    }
  }
  // -----------------------------------------------
  static getPageSize () : any {
    return this.pageSize;
  }
  static setPageSize (value : any) {
    this.pageSize = value;
  }
  // -----------------------------------------------
  static getPagedChats () : Chat[] {
    return this.pagedChats;
  }
  static setPagedChats (value : Chat[]) {
    this.pagedChats = value;
  }
  // -----------------------------------------------
  static getPagerPhotoMgmt () : PagerPhotoMgmt {
    return this.pagerPhotoMgmt;
  }
  static setPagerPhotoMgmt (value : PagerPhotoMgmt) {
    this.pagerPhotoMgmt = value;
  }
  // -----------------------------------------------
  static getPagerVKvPhotoNav () : PagerPhotoMgmt {
    return this.pagerVKvPhotoNav;
  }
  static setPagerVKvPhotoNav (value : PagerPhotoMgmt) {
    this.pagerVKvPhotoNav = value;
  }
  // -----------------------------------------------
  static getProfileElementRef(): any {
    return this.profileElementRef;
  }
  static setProfileElementRef(value: any) {
    this.profileElementRef = value;
  } //  static spinnerTimerArr : any[] = [];
  // remoteDateLoadID
  static getRemoteDateLoadID () : any {
    return this.remoteDateLoadID;
  }
  static setRemoteDateLoadID (value : any) {
    this.remoteDateLoadID = value;
  }

  static getStaffElementRef(): any {
    return this.staffElementRef;
  }
  static setStaffElementRef(value: any) {
    this.staffElementRef = value;
  }
  /*
   *  -----------------------------------------------------------
   *  End of Lazy-Loading:
   *  -----------------------------------------------------------
   * ------------------------------------------------------------
   */
  static getActionId(): ActionId {
    return this.actionId;
  }
  static setActionId(value: ActionId) {
    this.actionId = value;
  }
  // ------------------------------------------------------------
  static setChangeDetectorRef(_cdr: ChangeDetectorRef): void {
    this.cdr = _cdr;
  }
  // ------------------------------------------------------------

  /*
   * getArticlePagedParagraphs(): any {
   * return this.articlePagedParagraphs;
   * }
   * setArticlePagedParagraphs(data: Content[]) {
   * this.articlePagedParagraphs = data;
   * }
   * getArticleSubjects(): any {
   * return this.articleSubjects;
   * }
   * setArticleSubjets(data: string[]): void {
   * this.articleSubjects = data;
   * }
   */

  static getActivedComponentDictionary () : Dictionary<string, ModuleComponentLoader> {
    return this.activatedComponentDictionary;
  }
  static setActivedComponentDictionary ( dictionary : Dictionary<string, ModuleComponentLoader>): void {
    this.activatedComponentDictionary = dictionary;
  }
  static getActivatedRoute(): any {
    return this.activatedRoute;
  }
  static setActivatedRoute(data: any): void {
    this.activatedRoute = data;
  }
  static getAnyModel () : any {
    return this.anyModel;
  }
  static setAnyModel (data : any) : void {
    this.anyModel = data;
  }
 
  static getAvatarProfileTile () : ProfileTile {
    return this.avatarProfileTile;
  }
  static setAvatarProfileTile(data : ProfileTile) : void {
    this.avatarProfileTile = data;
  }

  static getBasicPager (): BasicPagerModel {
    return this.basicPagerModel;
  }

  static setBasicPager (pager : BasicPagerModel) : void {
    this.basicPagerModel = pager;
  }
  // chatHistoryArr
  static getChatHistoryArr () : any[] {
    return this.chatHistoryArr;
  }

  static setChatHistoryArr (ca : any[]) : void {
    this.chatHistoryArr = ca;
  }
  /*
   * ------------------------------------------------------------
   * Begin of module-factories:
   * ------------------------------------------------------------
   */
  static getModuleFactory(): any {
    return this.moduleFactory;
  }
  static setModuleFactory(value: any) {
    this.moduleFactory = value;
  }
  static getSharedModuleFactory(): any {
    return this.sharedModuleFactory;
  }
  static setSharedModuleFactory(value: any) {
    this.sharedModuleFactory = value;
  }
  static getEnvironment(): string {
    return this.environment;
  }
  static setEnvironment(value: string) {
    this.environment = value;
  }
  static getHostName(): string {
    return this.hostName;
  }
  static setHostName(value: string) {
    this.hostName = value;
  }
  static getUnionModuleFactory(): any {
    return this.unionModuleFactory;
  }
  static setUnionModuleFactory(value: any) {
    this.unionModuleFactory = value;
  }
  /*
   * ------------------------------------------------------------
   * End of module-factories:
   * ------------------------------------------------------------
   */
  // ------------------------------------------------------------
  //  Begin of GlyphMenues
  // ------------------------------------------------------------

  static getMemberViewGlyphMenuKvArr () : KvMemberActivityGlyph[]
  {
    return this.kvGlyphArrForViewMember;
  }

  static setMemberViewGlyphMenuKvArr (glyphs : KvMemberActivityGlyph[] ) : void
  {
    this.kvGlyphArrForViewMember = glyphs;
  }
  // ------------------------------------------------------------
  static getPhotoMgmteGlyphMenuKvArr () : KvMemberActivityGlyph[]
  {
    return this.kvGlyphArrForPhotoMgmt; //this.photoMgmtGlyphMenuKvArr;
  }
  // Note: photoMgmtGlyphMenuKvArr === glyphKvArrForPhotoMgmt
  static setPhotoMgmteGlyphMenuKvArr (glyphs : KvMemberActivityGlyph[] ) : void
  {
    this.kvGlyphArrForPhotoMgmt = glyphs; // this.photoMgmtGlyphMenuKvArr = 
  }
  // ------------------------------------------------------------
  static getPrimaryImageGlyphMenuKvArr () : KvMemberActivityGlyph[]
  {
    return this.primaryImageGlyphMenuKvArr;
  }
  static setPrimaryImageGlyphMenuKvArr (glyphs : KvMemberActivityGlyph[]) : void
  {
    this.primaryImageGlyphMenuKvArr = glyphs;
  }
  // ------------------------------------------------------------
  //  End of GlyphMenues
  // ------------------------------------------------------------
  static getBreadcrumRing () : ModuleComponentLoader[] {
    return this.breadcrumRing;
  }
  static setBreadcrumRing ( value : ModuleComponentLoader[]): void {
    this.breadcrumRing = value;
  }
  static getChatModel () : Chat {
    return this.chatModel;
  }
  static setChatModel (value : Chat) {
    this.chatModel = value;
  }
  static getConnectionToken(): any {
    return this.connectionToken;
  }
  static setConnectionToken(value: any) {
    this.connectionToken = value;
  }
  // ------------------------------------------------------------
  static getCurrentBreadcrumPage(): any {
    return this.currentBrdcrmPage;
  }
  static setCurrentBreadcrumPage(data: BrdCrmKvAny): void {
    this.currentBrdcrmPage = data;
  }
  static getCurrentPage () : KvMemberActivityGlyph {
    return this.currentPage;
  }
  static setCurrentPage (data : KvMemberActivityGlyph): void {
    this.currentPage = data;
  }
  static getCurrentKvPhoto(): KvPhoto {
    return this.currentKvPhoto;
  }
  static setCurrentKvPhoto(data: KvPhoto): void {
    this.currentKvPhoto = data;
  }
  static getCurrentPhoto(): Photo {
    return this.currentPhoto;
  }
  static setCurrentPhoto(data: Photo): void {
    this.currentPhoto = data;
  }
  // ------------------------------------------------------------
  static getEnums(): any {
    return this.enums;
  }
  static setEnums(value: any): void {
    this.enums = value;
  }
   static getENoteTile(): ENoteTile {
    return this.eNoteTile;
   }
   static setENoteTile(value: ENoteTile): void {
    this.eNoteTile = value;
   }
  // static getFetchSitUserModel(): GetSitUser {
  //  return this.getSitUser;
  //}
  static getHttpService () : HttpService
  {
    return this.httpService;
  }
  static setHttpService ( data : HttpService ) : void
  {
    this.httpService = data;
  }
  static getHubConnection () : signalR.HubConnection {
    return this.hubConnection;
   }
  static setHubConnection (data : signalR.HubConnection): void {
    this.hubConnection = data;
   }
  // ------------------------------
  static getDeviceInfo () : any {
    return this.deviceInfo;
  }
  static setDeviceInfo (data : any) : void {
    this.deviceInfo = data;
  }
  static getIsMobile(): boolean {
    return this.isMobilevar;
  }
  static setIsMobile(data: boolean): void {
    this.isMobilevar = data;
  }
  static getIsTablet () : boolean {
    return this.isTablet;
  }
  static setIsTablet (data : boolean) : void {
    this.isTablet = data;
  }
  static getIsDesktop () : boolean {
    return this.isDesktop;
  }
  static setIsDesktop (data : boolean) : void {
    this.isDesktop = data;
  }
  // ------------------------------  
  static getIsDevelopment(): boolean {
    return this.isDevEnvironment;
  }
  static setIsDevelopment(data: boolean): any {
    this.isDevEnvironment = data;
    return true;
  }
  static getIsEditProfile () : boolean {
    return this.isEditProfile;
  }
  static setIsEditProfile(data: boolean) {
    this.isEditProfile = data;
  }
  static getHeartbeat () : Heartbeat {
    return this.heartbeat;
  }
  static setHeartbeat (data : Heartbeat) : any {
    this.heartbeat = data;
    return true;
  }  
  static getLoginSuccess(): LoginSuccess {
    return this.loginSuccess;
  }
  static setLoginSuccess(value: LoginSuccess): void {
    this.loginSuccess = value; //CopyServiceStatic.copyLoginSuccessIfExists(this.loginSuccess, logSuccess);
  }
  static getMasterErrorLogs(): LogError[] {
    return this.masterErrorLogs;
  }
  static setMasterErrorLogs(value: LogError[]): any {
    this.masterErrorLogs = value;
    return true;
  }
  static getMCLoader(): ModuleComponentLoader {
    return this.mcLoader;
  }
  static setMCLoader(data: ModuleComponentLoader): void {
    this.mcLoader = data;
  }
  static getMemberActivity () : CommunicationActivity {
    return this.memberActivity;
   }
  static setMemberActivity (data : CommunicationActivity): void {
    this.memberActivity = data;
   }
   static getCommunicationActivities(): CommunicationActivity[] {
    return this.commActivities;
   }
  static setCommunicationActivities (data : CommunicationActivity[]): void {
    this.commActivities = data;
  }
  static getMyActivityArr () : CommunicationActivity[] {
    return this.myActivityArr;
   }
  static setMyActivityArr (value : CommunicationActivity[]): void {
    this.myActivityArr = value;
   }
  static getMemberPhoto(): KvPhoto {
    return this.memberPhoto;
  }
   static getMembers(): any {
    return this.members;
   }
   static setMembers(data: Member[]): void {
    this.members = data;
   }
  static getMessage(): any {
    return this.message;
  }
  static setMessage(data: string): void {
    this.message = data;
  }
  static getIsModalVisible () : boolean {
    return this.isModalVisible;
  }
  static setIsModalVisible (data : boolean) : boolean {
    this.isModalVisible = data;
    return true;
  }
   static getMyConversations() {
    return this.myConversations;
   }
   static setMyConversations(data: ChatActivity[]) {
    this.myConversations = data;
  }
  static getMyOutboxConversations () {
    return this.myOutboxConversations;
  }
  static setMyOutboxConversations (data : ChatActivity[]) {
    this.myOutboxConversations = data;
  }
  static getMyErrorLogs(): LogError[] {
    return this.myErrorLogs;
  }
  static setMyErrorLogs(value: LogError[]): any {
    this.myErrorLogs = value;
    return true;
  }
  static getMyGroup() {
    return this.myGroups;
  }
  static setMyGroups(data: Group[]) {
    this.myGroups = data;
  }  
  static getIsMyProfile () : boolean {
    return this.isMyProfile;
  }
  static setIsMyProfile (data : boolean) : void {
    this.isMyProfile = data;
  }
  static getIsOnLine () : boolean {
    return this.isOnLine;
  }
  static setIsOnLine (data : boolean) : void {
    this.isOnLine = data;
  }
  static getIsLoggedOutByUser () : boolean {
    return this.isLoggedOutByUser;
  }
  static setIsLoggedOutByUser (data : boolean) : void {
    this.isLoggedOutByUser = data;
  }

  static getIsPhotoMgmt () : boolean {
    return this.isPhotoMgmt;
  }
  static setIsPhotoMgmt (data : boolean) : void {
    this.isPhotoMgmt = data;
  }
  static getIsShowPhotoUpload () : boolean {
    return this.isShowPhotoUploadUi.isOpened;
  }
  static setIsShowPhotoUpload (data : boolean) : void {
    this.isShowPhotoUploadUi.isOpened = data;
  }
  static getIsToggleCamera () : boolean {
    return this.isToggleCamera;
  }
  static setIsToggleCamera (data : boolean) : void {
    this.isToggleCamera = data;
  }
  static getIsUnlocked () : boolean {
    return this.isUnlocked;
  }
  static setIsUnlocked (data : boolean) : any {
    this.isUnlocked = data;
    return true;
  }
  static getIsViewMember () : boolean {
    // debugger;
    return this.isViewMember;
  }
  static setIsViewMember (data : boolean) : void {
    this.isViewMember = data;
  }
  static getPagerBreadcrum () : PagerBreadcrum
  {
    return this.pagerBreadcrum;
  }
  static setPagerBreadcrum ( value : PagerBreadcrum ) : void
  {
    this.pagerBreadcrum = value;
    this.breadcrumRing = this.pagerBreadcrum.breadcrumRing;
  }
  static getPagerChat () : PagerChat {
    return this.pagerChat;
  }
  static setPagerChat (value : PagerChat) : void {
    this.pagerChat = value;
  }
  static getPhotoLock(): KvPhoto {
    return this.kvPhotoLock;
  }
  static setPhotoLock(value: KvPhoto): void {
    this.kvPhotoLock = value;
  }
  static getPhotoUnLock(): KvPhoto {
    return this.kvPhotoUnLock;
  }
  static setPhotoUnLock(value: KvPhoto): void {
    this.kvPhotoUnLock = value;
  }
   static getPhotoMgmtAction(): PhotoMgmtAction {
    return this.photoMgmtAction;
   }
   static setPhotoMgmtAction(value: PhotoMgmtAction): any {
    this.photoMgmtAction = value;
    return true;
   }
  static getPhotoMgmtServiceSitUser(): SitUser {
    return this.photoMgmtServiceSitUser;
  }
  static setPhotoMgmtServiceSitUser(value: SitUser): void {
    this.photoMgmtServiceSitUser = value;
  }
  static getPhotoPrivate(): KvPhoto {
    return this.kvPhotoPrivate;
  }
  static setPhotoPrivate(value: KvPhoto): void {
    this.kvPhotoPrivate = value;
  }
  static getPhotoPublic(): KvPhoto {
    return this.kvPhotoPublic;
  }
  static setPhotoPublic(value: KvPhoto): void {
    this.kvPhotoPublic = value;
  }
  static getPrimaryImageKvPhoto(): KvPhoto {
    return this.primaryImageKvPhoto;
  }
  static setPrimaryImageKvPhoto(value: KvPhoto): void {
    this.primaryImageKvPhoto = value;
  }
  static getProfileImages(): KvPhoto[] {
    return this.profileImages;
  }
  static setProfileImages(data: KvPhoto[]) {
    this.profileImages = data;
  }
   static getProfileInfo(): ProfileInfo {
    return this.profileInfo;
   }
   static setProfileInfo(data: ProfileInfo) {
    this.profileInfo = data;
  }
  static getNewProfileTile () : ProfileTile {
    return  new ProfileTile();
  }
 
   static getProfileTile(): ProfileTile {
    return this.profileTile;
   }
   static setProfileTile(data: ProfileTile): void {
    this.profileTile = data;
   }
   static getProfileTilesArr(): ProfileTile[] {
    return this.profileTilesArr;
   }
   static setProfileTilesArr(data: ProfileTile[]): void {
    this.profileTilesArr = data;
   }
   static getProfilePics(): ProfilePics {
    return this.profilePics;
   }
   static setProfilePics(data: ProfilePics): any {
    this.profilePics = data;
    return true;
  }
  static getRemoteDataModel () : RemoteDataModel {
    return this.rdm;
  }
  static setRemoteDataModel (value : RemoteDataModel) : void {
    this.rdm = value;;
  }
  static getResetPasswordCode(): any {
    return this.resetPasswordCode;
  }
  static setResetPasswordCode(data: string): void {
    this.resetPasswordCode = data;
  }
  static getReturnUrl(): any {
    return this.returnUrl;
  }
  static setReturnUrl(data: string): void {
    this.returnUrl = data;
  }
  static getRouter () : any {
    return this.router;
  }
  static setRouter (data : any) : void {
    this.router = data;
  }
  // ------------------------------------------------------------
  static getSalty(): any {
    return this.salty;
  }
  static setSalty(data: Salt): void {
    this.salty = data;
  }
  static getSaltyPair(): any {
    return this.saltyPair;
  }
  static setSaltyPair(data: NaclPairClient): void {
    this.saltyPair = data;
  }
  // 
  static getSelectedProfileTile () : ProfileTile {
    return this.selectedProfileTile;
  }
  static setSelectedProfileTile (data : ProfileTile) : void {
    this.selectedProfileTile = data;
  }
  /*
   * ------------------------------------------------------------
   * Note: This is a unique method in meitterService!!
   * ------------------------------------------------------------
   * getKeysPromise(): Promise<any> {
   * return this.saltyPair;
   * }
   * ------------------------------------------------------------
   */
   static getSenderInfoArr(): Communicator[] {
    return this.senderInfoArr;
   }
   static setSenderInfoArr(value: Communicator[]) {
    this.senderInfoArr = value;
   }
    static getSenderAndReceiverEmails() {
    return this.senderReceiverEmails;
    }
  static setSenderAndReceiverEmails (senderReceiverEmails : any): void {
    this.senderReceiverEmails = senderReceiverEmails;
  }
   static getSpinner () : SpinnerModel {
    return this.spinnerModel;
   }
  static setSpinner (data : SpinnerModel) : void {
    this.spinnerModel = data;
   }  
  
  static setSitUserModel(value: SitUser): void {
    this.sitUser = value;
  }
  static getSignedInUser () : SitUser {
    return this.signedInUser;
  }
  static setSignedInUser (value : SitUser) : void {
    this.signedInUser = value;
  }
  static getSpinnerCounter () : any {
    return this.spinnerCounter;
  }
  static setSpinnerCounter (data :number) : void {
    this.spinnerCounter = data;
  }
   
  static getToggleCameraGlyphCounter () : number {
    return this.toggleCameraGlyphCounter;
  }
  static setToggleCameraGlyphCounter (data : number) : void {
    this.toggleCameraGlyphCounter = data;
  }
  // -------------------------------------------------------
  // Note:  there is no getter/setter for SitUser but these:
  //        emitSitUser() will set the value of sitUser
  // -------------------------------------------------------
  static getSitUserModel () : SitUser {
    return this.sitUser;
  }   
  static getTheirActivityArr () : CommunicationActivity[] {
    return this.theirActivityArr;
   }
  static setTheirActivityArr (value : CommunicationActivity[]): void {
    this.theirActivityArr = value;
   }
  
  // ----------------------------------------
  static getUpgrade () : PayPalOrder
  {
    return this.payPalOrder;
  }
  static setUpgrade (value : PayPalOrder ) : any
  {
    this.payPalOrder = value;
    this.upgrade = value.upgrade;
    return true;
  }
  // ----------------------------------------
  static getUserAndPageTitle () : UserAndPageTitle{
    return this.userAndPageTitle;
  }
  static setUserAndPageTitle (value : UserAndPageTitle) : void {
    this.userAndPageTitle = value;
  }
  // ----------------------------------------
  static getUserPhotoIds(): number[] {
    return this.userPhotoIds;
  }
  static setUserPhotoIds(value: number[]): any {
    this.userPhotoIds = value;
    return true;
  }
  static getUserPhotoBnIds () : number[]
  {
    return this.userPhotoBnIds;
  }
  static setUserPhotoBnIds ( value : number[] ) : any
  {
    this.userPhotoBnIds = value;
    return true;
  }  
  static getCommunicator () : Communicator {
    return this.yourInfo;
  }
  // your info is an alias for yourInfo:
  static setCommunicator (value : Communicator): void {
    this.yourInfo = value;
  }
  // ------------------------------------------------------------
  static getYourCommunicator () : Communicator {
    return this.yourInfo;
  }
  // your info is an alias for yourInfo:
  static setYourCommunicator (value : Communicator) : void {
    this.yourInfo = value;
  }
  static getYourLoginSuccess () : LoginSuccess {
    return this.yourLoginSuccess;
  }
  static setYourLoginSuccess (value : LoginSuccess) : void {
    this.yourLoginSuccess = value;
  }
  static getWhenScrollEnds(): any {
    return this.whenScrollEnds;
  }
  static setWhenScrollEnds(value: boolean): void {
    this.whenScrollEnds = value;
  }
  // ------------------------------------------------------------
  static emitStaffActionType(value: string): void {
    // debugger;
    this.staffActionTypeEmitter.next(value);
  }
  
  // ============================================================
  // Begin of all Emitters:
  // ============================================================
  // SpinnerEmitters:
  // ----------------
  static emitShowSpinner (value : any) : void {
    if (value) {
      // debugger;
      if (typeof value === 'string') {
        this.spinnerModel = new SpinnerModel();
        this.spinnerModel.message = value && value.length > 0 ? '<span class=\'grnDrkRedxSB\'>' + value + '...' + '</span>' : '<span class=\'grnBluGldxSB\'>Loading...</span>';
        this.spinnerModel.isSpinner = this.spinnerModel.isOpen = true; // == isOpen
        this.spinnerModel.callerName = '';
      }
      else if(value instanceof SpinnerModel) {
        // debugger;
        this.spinnerModel = value;
      }
      else {
        // debugger;
        this.spinnerModel = new SpinnerModel();
        this.spinnerModel.message = '<span class=\'grnBluGldxS\'>Busy...</span>';
        this.spinnerModel.isSpinner = this.spinnerModel.isOpen = true; // == isOpen
        this.spinnerModel.callerName = '';
      }
    }
    else {
      // debugger;
      this.spinnerModel = new SpinnerModel();
      this.spinnerModel.message = '<span class=\'grnBluGldxS\'>Busy...</span>';
      this.spinnerModel.isSpinner = this.spinnerModel.isOpen = true; // == isOpen
      this.spinnerModel.callerName = '';
    }
    this.showSpinnerEmitter.next(this.spinnerModel);
  }
   // ------------------------------------------------------------
  static emitHideSpinner (value : any) : void {
    if (typeof value === 'string') {

      this.spinnerModel.message = value && value.length > 0 ? value : '';
      this.spinnerModel.isOpen = false;
      this.spinnerModel.isSpinner = false;
    }    
    else this.spinnerModel.clear();

    this.hideSpinnerEmitter.next(this.spinnerModel);
  }
  // ------------------------------------------------------------
  // static emitStartBiasAnimation ( value : boolean ) : void
  // {
  //  debugger;
  //  this.startBiasAnimationEmitter.next( value );
  // }
  // ------------------------------------------------------------
  // 
  static emitActedGlyphNames (value : any[]) {
    this.actedGlyphNamesEmitter.next(value);
  }

  static emitActedUnlockAndBlockGlyphNames (value : any[]) {
    this.actedUnlockAndBlockGlyphNamesEmitter.next(value);
  }
  static emitActionId(value: ActionId) {
    this.actionId = value;
    this.actionIdEmitter.next(value);
  }
  static emitActivatedRoute(value: any): void {
    this.activatedRoute = value;
    this.activatedRouteEmitter.next(value);
  }

  static emitAddress ( value : any ) : void
  {
    this.addressEmitter.next( value );
  }
  
  static emitAmountModel ( value : AmountModel ) : void
  {
    this.amountModelEmitter.next( value );
  }
   
  static emitIsAppFeaturePage (value : boolean) : void {
    this.isAppFeaturePage = value;
    this.isAppFeaturePageEmitter.next(value);
  }

  static emitAppFeatureArr ( value: AppFeature[] ) : void
  {
    this.appFeatureArr = value;
    this.appFeatureArrEmitter.next( value );
  }

  static emitAppInitUrl (value : any): void {
    this.appInitUrlEmitter.next(value);
  }
  // Background-processing-system:
  // it uses RemoteDataMethod as parameter.
  // it uses its rdm.data field to holding return-data.
  static emitBackgroundProcessMethodDataForComponent (value : RemoteDataModel) : void {
    this.backgroundProcessMethodDataForComponentEmitter.next(value);
  }

  // Background-processing-system:
  // it uses RemoteDataMethod as parameter.
  // it uses its rdm.data field to holding return-data.
  static emitBackgroundProcessMethodDataForService (value : RemoteDataModel) : void {
    this.backgroundProcessMethodDataForServiceEmitter.next(value);
  }

  static emitAvatarProfileTile (value : any) : void {
    this.avatarProfileTile = value;
    this.avatarProfileTileEmitter.next(value);
  }


  
  static emitBasicPager(value) {
    this.basicPagerModel = value;
     this.basicPagerEmitter.next(value);
    }
   

  /*
   * emitArticleSubjects(value): void {
   * this.articleSubjects = value;
   * this.articleSubjectsEmitter.next(value);
   * }
   */

  /*
   * emitArticleDownloadFileName(value): void {
   * this.articleDownloadFileName = value;
   * this.articleDownloadFileNameEmitter.next(value);
   * }
   */
  static emitBoxNonce(value: BoxNonceEntity): void {
    // debugger;
    this.boxNonceEmitter.next(value);
  }
  // ------------------------------------------------------------
  static emitChatImageUploadData(value: any) {
    this.chatImageUploadDataEmitter.next(value);
  }
  static emitChannelStreamMessage(value: any) {
    this.channelStreamMessageEmitter.next(value);
  }
  
  static emitChatActivityHistory (value : any) {
    this.chatHistoryArr = value;
    this.chatActivityHistoryEmitter.next(value);
  }
  static emitChatMessage(value: any) {
    this.chatMessageEmitter.next(value);
  }
  static emitChatModel (value : Chat) {
    this.chatModel = value;
    this.chatModelEmitter.next(value);
  }
  static emitChatModelToSetupComponent (value: Chat) {
    this.chatModelToSetupComponentEmitter.next(value);
  }

  static emitClearMessage(value: any): void {
    // debugger;
    this.clearMessageEmitter.next(value);
  }

  static emitCommunicationActivityBoxNonce ( value : BoxNonceEntity ) : void
  {
    // debugger;
    this.commActivityBoxNonceEmitter.next( value );
  }
  // 
  static emitCommunicationActivity (value : CommunicationActivity) : void {
    // debugger;
    this.communicationActivityEmitter.next(value);
  }
  static emitCommType(value: string): void {
    // debugger;
    this.commTypeEmitter.next(value);
  }
  // ---------------------------------------------
  static emitComputeDistance (hb: Heartbeat) : any {
    this.computeDistanceEmitter.next(hb);
  }
  // ---------------------------------------------
  static emitConversation (value : any) {
    this.conversationEmitter.next(value);
   }
  static emitEnoteBoxNonce(value: BoxNonceEntity): void {
    // debugger;
    this.enoteBoxNonceEmitter.next(value);
  }
  static emitExecuteProfileInfoTasks(value: ProfileInfo): void {
    // debugger;
    this.profileInfo = value;
    this.executeProfileInfoTasksEmitter.next(value);
  }
  // ----------------------------------------------
  // Note: exitPage has the following scheme:
  //       value == ModuleComponentLoader
  //        OR
  //       value==string==pageFromExit.pageToExit
  //       pageFromExit==string, pageToExit.string
  //       example: Members.MemberViewMgmt
  // ----------------------------------------------
  static emitExitPage (value : any) : void {
    // debugger;
    this.exitPageEmitter.next(value);
  }
  static emitMyErrorLog(value: any): void {
    // debugger;
    this.myErrorLogEmitter.next(value);
  }
   
  static emitBatchOfProfileTileIds (value : any[]) : void {
    this.batchOfProfileTileIdsEmitter.next(value);
  }
  
  static emitBlackListCredentialArr (value : KV[]) : void {
    // debugger;
    this.blackListCredentialArrEmitter.next(value);
  }

  static emitBoxEmailBn(value: BoxNonceEntity, boxType: string): void {
    // debugger;
    const boxEmailBn = new BoxEmailBn();

    boxEmailBn.boxNonceEntity = value;
    boxEmailBn.boxType = boxType;
    this.boxEmailBnEmitter.next(boxEmailBn);
  }

  static emitBreadcrumRing ( value : ModuleComponentLoader[]): void {
    this.breadcrumRing = value;
    this.breadcrumRingEmitter.next(value);
  }
  
  static emitCatchAllBackgroundImage (value : any) : void {
    this.catchAllBackgroundImageEmitter.next(value);
  }

  static emitCenterCoordinates(value: CenterCoordinates): void {
    this.centerCoordinatesEmitter.next(value);
  }

  static emitClearCreditCardData ( value : any ) : void
  {
    this.clearCreditCardDataEmitter.next( value );
  }

  static emitClearGlyphiconMenu(value: any): void {
    this.clearGlyphiconMenuEmitter.next(value);
  }
  static emitClearMembersTiles(value: boolean): void {
    this.clearMembersTilesEmitter.next(value);
  }

  static emitClearPageTitle ( value : any ) : void
  {
    this.clearPageTitleEmitter.next( value );
  }
  
  static emitClearPagerPhotoNavData (value : any) : void {
    this.clearPagerPhotoNavDataEmitter.next(value);
  }
  // Note: we do not sent an empty profileImages[]
  //       using the profileImageEmitter since
  //       the listeners carry out tasks on the basis of
  //       the array data. Instead we use this emitter
  //       to clear data on the respective components.
  // ----------------------------------------------------
  static emitClearProfileImages (value : any[]) : void {
    this.clearProfileImagesEmitter.next(value);
  }

  static emitClearUserData(value: any): void {
    this.clearUserDataEmitter.next(value);
  }

  static emitCheckLocalStorageForLoginSuccess (value : any): void {
    this.checkLocalStorageForLoginSuccessEmitter.next(value);
  }
  static emitCheckboxResult(value: any): void {
    this.checkboxResultEmitter.next(value);
  }  
  static emitCheckOffOnStatus (value: OffOn) : void {
    this.checkOffOnStatusEmitter.next(value);
  }
  // 
  static emitCheckOffOnStatusFromProfileTile (value : ProfileTile) : void {
    this.checkOffOnStatusFromProfileTileEmitter.next(value);
  }

  static emitCloseSidebar (value : boolean) : void {
    this.closeSidebarEmitter.next(value);
  }
  // Note: in some components and services, this vairable has an alias that is 'yourInfo'
  static emitCommunicator(value: Communicator): void {
    // this.uniqueAddSenderInfoArr(value);
    this.yourInfo = value;
    this.communicatorEmitter.next(value);
  }
  static emitYourCommunicator (value : Communicator) : void {
    this.yourInfo = value;
    this.communicatorEmitter.next(value);
  }
  static emitConnectionToken(value: string): void {
    this.connectionTokenArr.push(value);
    this.connectionTokenEmitter.next(value);
  }
  
  static emitCreateDictionaryFromDbDixie (value : string) : void {
    this.createDictionaryFromDbDixieEmitter.next(value);
  }

  static emitCurrentFocus (value : any): void {
    this.currentFocusEmitter.next(value);
  }
  static emitCurrentPage (value : any): void {
    this.currentPage = value;
    this.currentPageEmitter.next(value);
  }
  static emitOnKvPhotoSelectionChange (value : KvPhoto) : void {
    this.onKvPhotoSelectionChangeEmitter.next(value);
  }
  static emitCurrentPageOnGlyphiconChange (value : any) : void {
    this.currentPage = value;
    this.currentPageOnGlyphiconChangeEmitter.next(value);
  }
  static emitCurrentPageNum (value : any) : void {
    this.currentPageNo = value;
    this.currentPageNumEmitter.next(value);
  }
  static emitCurrentMemActGlyphForPhotoMgmt (value : any) : void {
    this.currentMemActGlyphForPhotoMgmt = value;
    this.currentMemActGlyphForPhotoMgmtEmitter.next(value);
  }
  static emitCurrentMemActGlyphForViewMember (value : any) : void {
    this.currentMemActGlyphForViewMember = value;
    this.currentMemActGlyphForViewMemberEmitter.next(value);
  }
  static emitDatePicker(value: EmitDatePickerResult): void {
    this.datePickerEmitter.next(value);
  }
  static emitDictionariesUpdated(value: any): void {
    this.dictionariesUpdatedEmitter.next(value);
  }
  static emitDisplayDifferentMemberView ( value ?: any ) : void
  {
    this.displayDifferentMemberViewEmitter.next( value );
  }
  static emitDisplayGlyphiconMenu ( value ?: any ) : void
  {
    this.glyphKvArr = value;
    this.displayGlyphiconMenuEmitter.next(value);
  }

  static emitDisplayViewMemberGlyphiconMenu (value ?: any) : void {
    this.kvGlyphArrForPhotoMgmt = value;
    this.displayViewMemberGlyphiconMenuEmitter.next(value);
  }

  static emitDisplayViewMemberGlyphiconMenuForSitUser (value ?: any) : void {
    this.displayViewMemberGlyphiconMenuForSitUserEmitter.next(value);
  }

  // Note: photoMgmtGlyphMenuKvArr === glyphKvArrForPhotoMgmt
  static emitDisplayPhotoMgmtGlyphiconMenu (value ?: any) : void {
    this.kvGlyphArrForPhotoMgmt = value; // this.photoMgmtGlyphMenuKvArr = 
    this.displayPhotoMgmtGlyphiconMenuEmitter.next(value);
  }
  // static emitDisplayGlyphMenuForKvPhotoMgmtPage(value: KvPhoto): void {
  //  this.displayGlyphMenuForKvPhotoMgmtPageEmitter.next(value);
  // }    
  static emitDisplayDefaultGlyphiconMenu(): void {
    this.displayDefaultGlyphiconMenuEmitter.next();
  }
  static emitDisplayProfileView (value : any) : void {
    this.displayProfileViewEmitter.next(value);
  }
  // No listener for this emitter:
  static emitDisplayPrimaryImage(value?: any): void {
    this.displayPrimaryImageEmitter.next(value);
  }
  // Display Background Image Outside Angular:
  static emitDisplayBgiOutsideAngular (value ?: any) : void {
    this.displayBgiOutsideAngularEmitter.next(value);
  }
  // 

  static emitDisplayUserPicsFromServer (value: string) : void {
    this.displayUserPicsFromServerEmitter.next(value);
  }

  static emitDisplayMyPagerVKvPhotoNav (value ?: any) : void {
    this.displayMyPagerVKvPhotoNavEmitter.next(value);
  }

  static emitDistance (value : any ) : void
  {
    this.distanceEmitter.next( value );
  }

  static emitDob (value : any) : void {
    this.dobEmitter.next(value);
  }

  static emitDropDownResult ( value: EmitDropDownResult) : void
  {
    this.dropDownResultEmitter.next( value );
  }
  static emitEditImage (value : any): void {
    this.editImageEmitter.next(value);
  }
  static emitEditParagraph (value : any): void {
    this.editParagraphEmitter.next(value);
  }

  static emitElementFullyRendered (value : any) : void {
    this.elementFullyRenderedEmitter.next(value);
  }

  static emitEmailTile(value: CommunicationActivity): void {
    this.emailTileEmitter.next(value);
   }
  static emitEnoteTile(value: CommunicationActivity): void {
    this.enoteTileEmitter.next(value);
   }
  static emitFileChunkCount(value: number): void {
    this.fileChunkCountEmitter.next(value);
  }
  static emitFile(value: File): void {
    this.fileEmitter.next(value);
  }
  static emitFiles(value: File[]): void {
    this.filesEmitter.next(value);
  }
  static emitFileImage(value: string): void {
    this.fileImageEmitter.next(value);
  }
  static emitFileImages(value: string[]): void {
    this.fileImagesEmitter.next(value);
  }
  static emitFileName(value: string): void {
    this.fileNameEmitter.next(value);
  }
   
  static emitFireMcLoaderRoute (value : ModuleComponentLoader) : void {
    this.fireMcLoaderRouteEmitter.next(value);
  }
  // formDataEmitter
  static emitFormData (value: FormData) {
    this.formDataEmitter.next(value);
  }
  static emitGestureDivId (value : any): void {
    this.gestureDivIdEmitter.next(value);
  }
  static emitGesture(value: string): void {
    this.gestureEmitter.next(value);
  }
  static emitGetKeys(value: any): void {
    this.getKeysEmitter.next(value);
  }
   
  static emitFacilitatorAccessTokenSitUser ( value : any )
  {
    this.facilitatorAccessTokenEmitter.next( value );
  }

  //static emitFetchSitUser(value: SitUser) {
  //  this.sitUser = value;
  //  this.sitUser = this.sitUser;
  //  this.fetchSitUserEmitter.next(value);
  //}
  static emitGroup(value: Group) {
    this.groupEmitter.next(value);
  }
  static emitGroupChatMessage(value: Chat) {
    this.groupChatMessageEmitter.next(value);
   }
  static emitHeartbeat(value: Heartbeat): void {
    this.heartbeat = value;
    this.heartbeatEmitter.next(value);
  }
  static emitHeightWidth (value : any): void {
    this.heightWidthEmitter.next(value);
  }
  static emitHttpEvent (value : HttpEvent<any>) {
    this.httpEventEmitter.next(value);
  }
  static emitImage(value: string): void {
    this.imageEmitter.next(value);
  }
  static emitImageFile(value: any): void {
    this.imageFileEmitter.next(value);
  }
  /*
   * emitChatMessage(value) {
   * this.chatMessageEmitter.next(value);
   * }
   * emitGroupChatMessage(value) {
   * this.groupChatMessageEmitter.next(value);
   * }
   * ---------------------------------------------------------------
   */
  static emitIndexDbBoxNonceData(value: BoxNonceEntity): void {
    this.indexDbBoxNonceDataEmitter.next(value);
  }
  static emitIndexDbChatHistory(value: Chat[]): void {
    this.indexDbChatHistoryEmitter.next(value);
   }
  static emitIndexDbChatHistoryIds(value: number[]): void {
    this.indexDbChatHistoryIdsEmitter.next(value);
  }
  // ---------------------------------------------------------------
  static emitIndexDbDataFoundWithKey(value: any): void {
    this.indexDbDataFoundWithKeyEmitter.next(value);
  }
  static emitIndexDbDataFoundWithIndex(value: any): void {
    this.indexDbDataFoundWithKeyEmitter.next(value);
  }
  static emitIndexDbData(value: IndexedDbData): void {
    this.indexDbDataEmitter.next(value);
  }
  static emitIndexDbDefaultPics(value: KvPhoto[]): void {
    this.indexDbDefaultPicsEmitter.next(value);
  }
  static emitIndexDbEmailTileIds(value: number[]): void {
    this.indexDbEmailTileIdsEmitter.next(value);
  }
  static emitIndexDbEmailTiles(value: CommunicationActivity[]): void {
    this.indexDbEmailTilesEmitter.next(value);
   }
  static emitIndexDbEnoteTileIds(value: number[]): void {
    this.indexDbEnoteTileIdsEmitter.next(value);
  }
  static emitIndexDbEnoteTiles(value: CommunicationActivity[]): void {
    this.indexDbEnoteTilesEmitter.next(value);
   }
  static emitIndexDbLoginSuccess(value: LoginSuccess): void {
    this.loginSuccess = value;
    this.indexDbLoginSuccessEmitter.next(value);
  }
  static emitIndexDbMemberActivities(value: MemberActivity[]): void {
    this.indexDbMemberActivitiesEmitter.next(value);
   }
  static emitInitModalDialogueComponent (value : any): void {
    this.initModalDialogueComponentEmitter.next(value);
  }
  static emitIndexDbMyErrorLogs(value: LogError[]): void {
    this.myErrorLogs = value;
    this.indexDbMyErrorLogsEmitter.next(value);
  }
  static emitIndexDbMyMemberActivities(value: MemberActivity[]): void {
    this.indexDbMyMemberActivitiesEmitter.next(value);
   }
  static emitIndexDbProfileTileIds(value: number[]): void {
    this.indexDbProfileTileIdsEmitter.next(value);
  }
  static emitIndexDbProfileImageDictionary(value: Dictionary<number, ProfileImage>): void {
    this.indexDbProfileImageDictionaryEmitter.next(value);
   }
  static emitIndexDbProfilePics(value: ProfilePics): void {
    this.indexDbProfilePicsEmitter.next(value);
   }
  static emitIndexDbProfileInfo(value: any): void {
    this.indexDbProfileInfoEmitter.next(value);
  }
  static emitIndexDbProfileTiles(value: ProfileTile[]): void {
    this.indexDbProfileTilesEmitter.next(value);
   }
  static emitIndexDbPreference(value: Preference): void {
    this.indexDbPreferenceEmitter.next(value);
  }
  static emitIndexDbSaveSuccessful (value : any) : void {
    this.indexDbSaveSuccessfulEmitter.next(value);
  }
  static emitIndexDbNgxSaveSuccessful(value: any): void {
    this.indexDbNgxSaveSuccessfulEmitter.next(value);
  }
  static emitIndexDbSaveStore ( value: any ): void
  {
    this.indexDbSaveStoreEmitter.next( value );
  }
  static emitIndexDbSitUser(value: SitUser): void {
    this.sitUser = value;
    this.indexDbSitUserEmitter.next(value);
  }
  static emitIndexDbSitUsers(value: SitUser[]): void {
    this.indexDbSitUsersEmitter.next(value);
  }
  static emitIndexDbSitUserBoxNonceData(value: BoxNonceEntity): void {
    this.indexDbSitUserBoxNonceDataEmitter.next(value);
  }
  static emitIndexDbUser(value: User): void {
    this.indexDbUserEmitter.next(value);
  }
  static emitIndexDbUserPhotoIds(value: any): void {
    this.indexDbUserPhotoIdsEmitter.next(value);
  }
  static emitIndexDbYourMemberActivities(value: MemberActivity[]): void {
    this.indexDbYourMemberActivitiesEmitter.next(value);
   }
  // ---------------------------------------------------------------

  static emitIsAddressComplete ( val : boolean ) : void
  {
    this.isAddressCompleteEmitter.next( val );
  } 

  static emitIsBrowsingPic (val : boolean) : void {
    this.isBrowsingPicEmitter.next(val);
  }
  static emitIsConnectionEstablished ( value : boolean): void {
    this.isConnectionEstablishedEmitter.next(value);
  }

  static emitIsCameraVisible ( value : boolean ) : void
  {
    this.isCameraVisibleEmitter.next( value );
  }
   // -------------------------------------------------------------------------
    // Note: Date: 20220508
    //       isCameraVisibleEmitter && isCameraGlypgVisibleEmitter work as a pair / tuple
    //       emitters that alters the isCameraVisible' value.
    //       the isCameraGlypgVisibleEmitter is emitted by FileUploadComponent and listened to by PagerVerticalPhotoMgmtComponent
    //       and isCameraVisibleEmitter is emitter by PagerVerticalPhotoMgmtComponent and listened to by FileUploadComponent
    //       so that there is no cyclic published and subscriber.
    // -------------------------------------------------------------------------
  static emitIsCameraGlyphVisible (value : boolean) : void {
    this.isCameraGlyphVisibleEmitter.next(value);
  }

  static emitIsCameraClosedBool ( value : boolean ) : void
  {
    this.isCameraClosedBoolEmitter.next( value );
  }

  static emitIsCreditCardComplete ( val : boolean ) : void
  {
    this.isCreditCardCompleteEmitter.next( val );
  }

  // ---------------------------------------------------------------
  static emitIsEditProfile ( value : boolean): void {
    // debugger;
    this.isEditProfile = value;
    this.isEditProfileEmitter.next(value);
  }
  static emitIsImageUploadFormVisible (value : boolean) : void {
    this.isImageUploadFormVisibleEmitter.next(value);
  }
  static emitIsHome ( value : boolean ) : void
  {
    this.isHome = value;
    this.isHomeEmitter.next( value );
  }
  // ----------------------------------------------------
  static emitIsLoginSuccess (value : boolean) : void {
    this.isLoginSuccessEmitter.next(value);
  }
  // ----------------------------------------------------
  static emitIsMobile ( value : boolean): void {
    this.isMobilevar = value;
    this.isMobileEmitter.next(value);
  }
  static emitIsReply ( value : boolean): void {
    this.isReplyEmitter.next(value);
  }
  static emitIsModal ( value : boolean ) : void
  {
    this.isModalEmitter.next( value );
  }
  static emitIsMyStuffOpen ( value : boolean ) : void
  {
    this.isMyStuffOpenEmitter.next( value );
  }
  // 
  static emitIsMyProfile (value : boolean) : void {
    this.isMyProfile = value;
    this.isMyProfileEmitter.next(value);
  }
  static emitIsModalVisible ( value : boolean ) : void
  {
    this.isModalVisibleEmitter.next(value);
  } 
  static emitIsShowPhotoUpload (value : boolean) : void {
    this.isShowPhotoUploadUi.isOpened = value; 
    this.isShowPhotoUploadEmitter.next(value);
  }
  // 
  static emitIsSidebarOpen (value : boolean) : void {
    this.isSidebarOpenEmitter.next(value);
  }

  static emitIsSignupModal ( value : boolean ) : void
  {
    this.isSignupModalEmitter.next(value);
  }
  // 
  static emitIsNewPhotoUploaded (value : boolean) : void {
    this.isNewPhotoUploaded = value;
    this.isNewPhotoUploadedEmitter.next(value);
  }
  static emitIsOpen ( value: Bool ) : void
  {
    this.isOpenEmitter.next( value );
  }
  static emitIsPhotoMgmt ( value : boolean): void {
    this.isPhotoMgmt = value;
    this.isPhotoMgmtEmitter.next(value);
  }
  static emitIsToggleCamera ( value : boolean): void {
    this.isToggleCameraEmitter.next(value);
  }
  static emitIsToggleFileUpload (value : boolean) : void {
    this.isToggleFileUploadEmitter.next(value);
  }
  static emitIsUnlocked(value: boolean): void {
    this.isUnlocked = value;
    this.isUnlockedEmitter.next(value);
  }
  static emitIsViewMember(value: boolean): void {
    this.isViewMember = value;
    // debugger;
    this.isViewMemberEmitter.next(value);
  }
  static emitIsVsScroll ( value : boolean): void {
    this.isVsScrollEmitter.next(value);
  }
  static emitIsWaitingOnCurrStateOfUser ( value : boolean): void {
    this.isWaitingOnCurrStateOfUserEmitter.next(value);
  }
  static emitKeyValueResult(value: KeyValueResult): void {
    this.keyValueResultEmitter.next(value);
  }
  //
  static emitKvMemberActivityGlyph (value : KvMemberActivityGlyph) : void {
    this.kvMemberActivityGlyphEmitter.next(value);
  }
  /*
   * -------------------------------------------------------------------
   * Begin of Module & Component Loader Emitters
   * -------------------------------------------------------------------
   */
  static emitLoadChildren(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadChildrenEmitter.next(this.mcLoader);
  }
  static emitLoadModule(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    /*
     * this.message = 'in emitterService->static emitLoadModule()-> emitting mcLoader.componentName :';
     * this.message += this.mcLoader.componentName + '\t mcLoader.moduleName: ' + this.mcLoader.moduleName;
     * alert(this.message);
     */
    this.loadModuleEmitter.next(this.mcLoader);
  }
  static emitLoadActivityComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadActivityComponentEmitter.next(this.mcLoader);
  }
  static emitLoadAppComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadAppComponentEmitter.next(this.mcLoader);
  }
  static emitLoadStaffComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadStaffComponentEmitter.next(this.mcLoader);
  }
  static emitLoadChatComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadChatComponentEmitter.next(this.mcLoader);
  }
  static emitLoadCheckboxRadioComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadCheckboxRadioComponentEmitter.next(this.mcLoader);
  }
  static emitLoadCommunicationComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadCommunicationComponentEmitter.next(this.mcLoader);
  }
  static emitLoadGestureComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadGestureComponentEmitter.next(this.mcLoader);
  }
  static emitLoadLogregComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadLogregComponentEmitter.next(this.mcLoader);
  }
  static emitLoadKeyValueComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadKeyValueComponentEmitter.next(this.mcLoader);
  }
  static emitLoadLandingPage(value: boolean): void {
    this.loadLandingPageEmitter.next(value);
  }
  static emitLoadMasterComponents(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadMasterComponentEmitter.next(this.mcLoader);
  }
  static emitLoadMemberComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadMemberComponentEmitter.next(this.mcLoader);
  }
  static emitLoadProfileComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadProfileComponentEmitter.next(this.mcLoader);
  }
  // TODO: delete after testing/before deployment:
  //static emitLoadRemoteDataInBackground (value : string) : void {
  //  this.loadRemoteDataInBackgroundEmitter.next(value);
  //}

  static emitLoadModalComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadModalComponentEmitter.next(this.mcLoader);
  }
  static emitLoadSharedComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadSharedComponentEmitter.next(this.mcLoader);
  }
  static emitLoadSlaveComponents(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadSlaveComponentEmitter.next(this.mcLoader);
  }
  static emitLoadSpinnerComponent(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.loadSpinnerComponentEmitter.next(this.mcLoader);
  }
  /*
   * -------------------------------------------------------------------
   * End of Module & Component Loader Emitters
   * -------------------------------------------------------------------
   */
  static emitLocalStorageLoginSuccess(value: LoginSuccess): void {
    this.loginSuccess = value;
    this.localStorageLoginSuccessEmitter.next(value);
  }
  // 
  static emitLogRegModel (value : LogRegModel) : void {
    this.logRegModel = value; 
    // debugger;
    this.logRegModelEmitter.next(JSON.parse(JSON.stringify(value)));
  }

  static emitLoginSuccess(value: LoginSuccess): void {
    this.loginSuccess = value; // CopyServiceStatic.copyLoginSuccessIfExists( this.loginSuccess, value );
    // debugger;
    this.loginSuccessEmitter.next(value);
  }
  static emitLogout (value : LoginSuccess) : void {
    this.loginSuccess = value;
    this.logoutEmitter.next(value);
    this.isLoggedOutByUser = true;
  }
  /*
   * static emitMyConversations(value) {
   * this.myConversationsEmitter.next(value);
   * }
   */
  static emitMailBox(value: MailBox): void {
    this.mailBoxEmitter.next(value);
   }
  // Note: the value for this emitter is always a string and a positive/negative #%, eg. -10%
  static emitMainTopCss(value: number): void {
    this.mainTopCssEmitter.next(value);
  }
  // -----------------------------------------------------------------
  //  Note: the activatedComponentRouteDictionary gets populated with
  //        mcLoader model when each time a calling function emits
  //        the mcLoader.
  // -----------------------------------------------------------------
  static emitMCLoader(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    
    this.mcLoaderEmitter.next(value);
  }
  // -----------------------------------------------------------------
  static emitMCLoaderToNavigate (value : ModuleComponentLoader) : void {
    this.mcLoader = value;
    
    this.mcLoaderToNavigateEmitter.next(value);
  }
  // -----------------------------------------------------------------
  static emitMemberPhoto(value: KvPhoto): void {
    this.memberPhoto = value;
    this.memberPhotoEmitter.next(value);
  }
  static emitMemberActivity(value: CommunicationActivity): void {
    this.memberActivity = value;
    this.memberActivityEmitter.next(value);
   }
  static emitCommunicationActivities(value: CommunicationActivity[]): void {
    this.commActivities = value;
    this.communicationActivitiesEmitter.next(value);
  }
  
  static emitToSetMemberViewMgmtModel (value : MemberViewMgmtModel) : void {
    this.memberViewMgmtModel = value;
    this.toSetMemberViewMgmtModelEmitter.next(value);
  }

  static emitMemberViewMgmtModel (value : MemberViewMgmtModel) : void {
    this.memberViewMgmtModel = value;
    this.memberViewMgmtModelEmitter.next(value);
  }
  static emitMembers(value: Member[]): void {
    this.membersEmitter.next(value);
   }
  static emitMessage (value : any): void {
    this.message = value;
    this.messageEmitter.next(value);
  }
  static emitModel (value : any) {
    this.anyModel = value;
    this.modelEmitter.next(value);
  }
   static emitMyConversations(value: ChatActivity[]) {
    this.myConversationsEmitter.next(value);
   }
  static emitMyGroups (value : any) {
    this.myGroupsEmitter.next(value);
   }
   static emitMyActivityArr(value: CommunicationActivity[]): void {
    this.commActivities = this.myActivityArr = value;
    this.myActivityArrEmitter.next(value);
  }
  // static emitMyEmailBoxNonce ( value : MemberActivity[] ) : void
  // {
  //  this.myEmailBoxNonceEmitter.next( value );
  // }
  static emitNavbarActionType(value: ActionId) {
    this.navbarActionTypeEmitter.next(value);
  }

  static emitOffOn (value : OffOn) : void {
    this.offOnEmitter.next(value);
  }

  static emitOffOnResult (value : any): void {
    this.offOnResultEmitter.next(value);
  }
  static emitOffOnStatusChange(value: any): void {
    this.offOnStatusChangeEmitter.next(value);
  }

  static emitOpenModal ( value : any ) : void
  {
    this.openModalEmitter.next( value );
  }

  static emitPagedProfileImages (value : any): void {
    this.pagedProfileImagesEmitter.next(value);
  }
  static emitPager (value : any): void {
    this.pagerEmitter.next(value);
  }
  static emitPagerBreadcrum ( value : PagerBreadcrum ) : void
  {
    this.pagerBreadcrum = value;
    this.breadcrumRing = this.pagerBreadcrum.breadcrumRing;
    this.pagerBreadcrumEmitter.next( value );
  } 
   
  static emitPagerChat (value : any) : void {
    this.pagerChat = value;
    this.pagerChatEmitter.next(value);
  }
  //
  static emitPagedChats (value : Chat[]) : void {
    this.pagedChats = value;
    this.pagedChatsEmitter.next(value);
  }
  static emitPagerBreadcrumDirection (value : string) : void {
    this.pagerBreadcrumDirectionEmitter.next(value);
  }
  // 
  static emitDisplayPagerVKvPhotoNav (value : any) : void {
    this.displayPagerVKvPhotoNavEmitter.next(value);
  }
  static emitParagraph (value : any): void {
    this.paragraphEmitter.next(value);
  }
  static emitPageSize (value : any) : void {
    this.pageSizeEmitter.next(value);
  }
  static emitPagerPhotoMgmt (value : PagerPhotoMgmt | any) : void {
    this.pagerPhotoMgmtEmitter.next(value);
  }
  static emitPagerVKvPhotoNav (value : PagerPhotoMgmt|any) : void {
    this.pagerVKvPhotoNavEmitter.next(value);
  }
  // BoxNonceEntity
  static emitPhotoBn ( value:any ) : void
  {
    this.photoBnEmitter.next( value );
  }

  static emitPhotoLockUnLock(kv: KvPhoto): void {
    // debugger;
    if (kv.key.toLowerCase().indexOf('tala') !== -1) {
      this.kvPhotoLock = kv;
    } else if (kv.key.toLowerCase().indexOf('unlock') !== -1) {
      this.kvPhotoUnLock = kv;
    } else if (kv.key.toLowerCase().indexOf('private') !== -1) {
      this.kvPhotoPrivate = kv;
    } else if (kv.key.toLowerCase().indexOf('static') !== -1) {
      this.kvPhotoPublic = kv;
    }
    this.photoLockUnLockEmitter.next(kv);
  }
  static emitPhotoMgmtMenuObservable(value: any): void {
    this.photoMgmtMenuObservableEmitter.next(value);
  }
  static emitPhotoMgmtPrimaryAction (value : any) : void {
    this.photoMgmtPrimaryActionEmitter.next(true);
  }
  static emitPhotoMgmtAction(value: PhotoMgmtAction): void {
    this.photoMgmtAction = value;
    this.photoMgmtActionEmitter.next(value);
  }
  
  static emitPreferenceKvAnyArr (value : KvAny[]) : void {
    this.preferenceKvAnyArrEmitter.next(value);
  }
  static emitProfile(value: Profile): void {
    this.profileEmitter.next(value);
  }
  
  static emitProfileContentKvAnyArr (value : KvAny[]) : void {
    this.profileContentKvAnyArrEmitter.next(value);
  }

  static emitProfileInfoKvAnyArr (value : KvAny[]) : void {
    this.profileInfoKvAnyArrEmitter.next(value);
  }

  // Since emitting an image string does not say whom it belongs to, we sent the entire profileTile
   static emitProfileImage(value: ProfileTile): void {
    this.profileImageEmitter.next(value);
  }

  // ----------------------------------------------------
  // Note: we do not sent an empty profileImages[]
  //       using the profileImageEmitter since
  //       the listeners carry out tasks on the basis of
  //       the array data. Instead we use clearProfileImagesEmitter
  //       to clear data on the respective components.
  // ----------------------------------------------------
  static emitProfileImages(value: KvPhoto[]): void {
    this.profileImages = value;
    // debugger;
    this.profileImagesEmitter.next(value);
  }
  // ----------------------------------------------------

   static emitProfileImageArr(value: ProfileImage[]): void {
    this.profileImageArrEmitter.next(value);
   }
  static emitProfileImageKvArr(value: KvAny[]): void {
    this.profileImageKvArrEmitter.next(value);
  }
  static emitProfileInfo (value : ProfileInfo) : void {
    // debugger;
    this.memberViewMgmtModel.profileInfo = value;
    this.profileInfo = value;
    this.profileInfoEmitter.next(value);
  }

  static emitProfileInfoView (value : ProfileInfo) : void {
    // debugger;
    this.memberViewMgmtModel.profileInfoView = value;
    this.profileInfoView = value;
    this.profileInfoViewEmitter.next(value);
  }

  static emitProfileTile(value: ProfileTile): void {
    // debugger;
    this.memberViewMgmtModel.profileTile = value;
    this.profileTile = value;
    this.profileTileEmitter.next(value);
  }  
   static emitProfileTileComplete(pTile: ProfileTile): void {
    // alert('emitterService->static emitProfileTileComplete. pTile: ' + pTile.profileName);
    this.profileTileCompleteEmitter.next(pTile);
   }
   static emitProfileTilesArr(value: ProfileTileArrEmitterModel): void {
    this.profileTilesArr = value.arr;
    this.profileTilesArrEmitter.next(value);
   }
  static emitProfileTilesArrayUpdated (value : any): void {
    this.profileTilesArrayUpdatedEmitter.next(value);
  }
   static emitProfilePics(value: ProfilePics): void {
    // debugger;
    this.profilePics = value;
    this.profilePicsEmitter.next(value);
   }
  static emitRadioboxResult (value : any): void {
    this.radioboxResultEmitter.next(value);
  }
  static emitReceiverSitUserId(value: number): void {
    this.receiverSitUserIdEmitter.next(value);
  }
  static emitRefreshPage(value: boolean): void {
    this.refreshPageEmitter.next(value);
  }  
  static emitRefreshMemberTile (value: any): void {
  this.refreshMemberTileEmitter.next( value );
  }
  static emitRemoveComponent ( value : ModuleComponentLoader): void {
    this.removeComponentEmitter.next(value);
  }
  static emitResetDbgi(value: boolean): void {
    this.resetDbgiEmitter.next(value);
  }

  static emitResetPickedDate(value: boolean): void {
    this.resetPickedDateEmitter.next(value);
  }
  static emitResetPasswordCode(code: any): void {
    this.resetPasswordCodeEmitter.next(code);
  }

  static emitResetSaltShaker ( value : Salt ) : void
  {
    this.resetSaltShakerEmitter.next( value );
  }

  static emitReturnUrl (value : any): void {
    this.returnUrl = value;
    this.returnUrlEmitter.next(value);
  }
  // Background-processing-system:
  static emitRunBackgroundProcessMethod (value : RemoteDataModel) : void {
    this.rdm = value;
    this.runBackgroundProcessMethodEmitter.next(value);
  }
  static emitRunBiasModuleComponentNgAfterViewInit ( value : any ) : void
  {
    this.runBiasModuleComponentNgAfterViewInitEmitter.next( value );
  }
  static emitRunGetEnumsFromServer ( value : true ) : void
  {
    this.runGetEnumsFromServerEmitter.next( value );
  }
  static emitRunMemberTileAfterViewInit(value: ProfileTile): void {
    this.runMemberTileAfterViewInitEmitter.next(value);
  }
  static emitRunNgAfterViewInit(value: any): void {
    this.runNgAfterViewInitEmitter.next(value);
  }
  
  static emitRunOffOnComponentNgAfterViewInit (value : any) : void {
    this.runOffOnComponentNgAfterViewInitEmitter.next(value);
  }
  static emitSaltyPair(saltyPair: NaclPairClient): void {
    this.setSaltyPair(saltyPair);
    this.naclEmitter.next(saltyPair);
  }


  // static emitSaveSitUserToIndexedDbAndDictionary ( value )
  // {
  //  this.saveSitUserToIndexedDbAndDictionaryEmitter.next( value );
  // }  

  static emitSelectedProfileTile (value : any) {
    this.selectedProfileTile = value;
    this.selectedProfileTileEmitter.next(value);
  }

  static emitSenderReceiverEmails (value : any) {
    this.senderReceiverEmails = value;
    this.senderReceiverEmailsEmitter.next(value);
  }
  //  -----------------------------------------------------------------
  // Note: This emitter is to be used by any component that has a cancel-button.
  //       Because once cancelled, the user should go back to the previous page (if that is the desired behavior)
  static emitSetBreadcrumForPage ( value : any ) : void
  {
    this.setBreadcrumPageEmitter.next( value );
  }
  static emitSetBreadcrumForPageSwitch(value: any): void {
    this.setBreadcrumForPageSwitchEmitter.next(value);
  }
  static emitSetMemberViewPage(page: KvPhoto): void {
    this.setMemberViewPageEmitter.next(page);
  }
  static emitSetMemberViewGlyphMenu ( value :any ) : void
  {
    this.setMemberViewGlyphMenuEmitter.next( value );
  } //

  static emitSetPage (page : number) : void {
    this.setPageEmitter.next(page);
  }
  static emitSetPhotoNavItem(kvp: KvPhoto): void {
    this.setPhotoNavItemEmitter.next(kvp);
  }
  static emitSetPhotoMgmtPage(page: KvPhoto): void {
    this.currentKvPhoto = page;
    this.setPhotoMgmtPageEmitter.next(page);
  }
  static emitSetPhotoMgmtPageGlyph (page : KvMemberActivityGlyph): void {
    this.setPhotoMgmtPageGlyphEmitter.next(page);
  }
  static emitSetProfilePicsPage(page: KvPhoto): void {
    this.setProfilePicsPageEmitter.next(page);
  }
  static emitSetSignedInUserBackgroundImage (value: boolean) : void {
    this.setSignedInUserBackgroundImageEmitter.next(value);
	}
  static emitSignedInUser(value: SitUser): void {
    this.signedInUser = value;
    this.signedInUserEmitter.next(value);
  }
  
  static emitSitUser (value : SitUser) : void {
    this.sitUser = value;
    this.sitUserEmitter.next(value);
  }
  static emitSitUserBoxNonce ( value : BoxNonceEntity ) : void
  {
    this.sitUserBoxNonceEmitter.next( value );
  }  
  // ------------------------------------------------------------  
  static emitStreamMessage(value: BoxNonceEntity) {
    this.streamMessageEmitter.next(value);
  }
  static emitSubmitArticleSuccessful (value : any): void {
    this.submitArticleSuccessfulEmitter.next(value);
  }
  static emitTempFile(value: any): void {
    this.tempFileEmitter.next(value);
  }
   static emitTheirActivityArr(value: CommunicationActivity[]): void {
    this.commActivities = this.theirActivityArr = value;
    this.theirActivityArrEmitter.next(value);
   }
  static emitToggleFaderAnim (value : any): void {
    this.toggleFaderAnimEmitter.next(value);
  }
  //
  static emitToggleAccordion (value : any) : void {
    this.toggleAccordionEmitter.next(value);
  }

  static emitToggleCameraGlyph (value: any) : void {
    this.toggleCameraGlyphEmitter.next(value);
  }

  static emitToggleIsCancelZipInstruction(): void {
    this.toggleIsCancelZipInstructionEmitter.next(true);
  }
  static emitToggleIsWritePad(): void {
    this.toggleIsWritePadEmitter.next(true);
  }
  static emitToggleIsMobile(): void {
    this.isMobilevar = !this.isMobilevar;
    this.toggleIsMobileEmitter.next(true);
  }
  static emitToggleThis(value: any): void {
    this.toggleThisEmitter.next(value);
  }
  static emitToggleIsSendFile(value: boolean): void {
    this.toggleIsSendFileEmitter.next(value);
  }
  static emitToggleIsUpload(): void {
    this.toggleIsUploadEmitter.next(true);
  }

  static emitToggleModalDialog(): void {
    this.toggleModalDialogEmitter.next(true);
  }
  /*
   * static emitTreeNode(value) {
   * this.treeNodeEmitter.next(value);
   * }
   */
  static emitUpdateBrdCrumRing(value: ModuleComponentLoader): void {
    this.mcLoader = value;
    this.updateBrdCrumRingEmitter.next(this.mcLoader);
  }
  // 
  static emitUpdateKvPhotoOnUserAction (value ?: KvPhoto) : void {
    this.updateKvPhotoOnUserActionEmitter.next(value);
  }

  static emitUpdatePrimaryImage(value?: any): void {
    this.updatePrimaryImageEmitter.next(value);
  }
  
  static emitUpdateDistanceAndProfileNameAndOffOn (value : any) : void {
    this.updateDistanceAndProfileNameAndOffOnEmitter.next(value);
  }
  static emitUpgrade ( value : PayPalOrder ) : void
  {
    this.payPalOrder = value;
    this.upgrade = value.upgrade;
    this.upgradeEmitter.next( value );
  }
  static emitUpgraded (value : LoginSuccess) : void {
    this.loginSuccess = value;
    this.upgradedEmitter.next(value);
  }

  static emitUploadedKvPhoto (value : KvPhoto) : void {
    this.uploadedKvPhotoEmitter.next(value);
  }

  static emitUploadedProfilePics (value : ProfilePics) : void {    
    this.uploadedProfilePicsEmitter.next(value);
   }
   static emitUploadedPicsBoxNonce(value: BoxNonceEntity): void {
    this.uploadedPicsBoxNonceEmitter.next(value);
   }
  static emitUserAndPageTitle (value : UserAndPageTitle) : void {
    this.userAndPageTitle = value;
    this.userAndPageTitleEmitter.next(value);
  }
  
  static emitViewMember (value:ModuleComponentLoader) : void {
    this.mcLoader = value;
    this.viewMemberEmitter.next(value);
  }
  static emitWhenScrollEnds (value : any): void {
    this.whenScrollEnds = value;
    this.whenScrollEndsEmitter.next(this.whenScrollEnds);
  }
  /*
   *  Note: there will be only one user data at a time in yourLoginSuccess that is not the signedInUser.
   *        for example whenever the user changes a profileView from any previous page, it will update yourLoginSucccess
   */
  static emitYourLoginSuccess(value: LoginSuccess): void {
    this.yourLoginSuccess = value;
    this.yourLoginSuccessEmitter.next(this.yourLoginSuccess);
  }

  // static emitYourCommunicator (value : Communicator) : void {
  //   this.yourCommunicator = value;
	//}
  /*
   * static static getInstance(): EmitterService {
   * if (EmitterSubjectService.instance == null) {
   *   EmitterSubjectService.instance = new EmitterService();
   * }
   * return EmitterSubjectService.instance;
   * }
   */
   // ---------------------------------------------------------------
  initialize ()
  {

  }
   // ---------------------------------------------------------------

  public ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.timerArray.forEach((timer) => clearInterval(timer));
    // this.profileTileViewSubscription.unsubscribe();
  }
}
